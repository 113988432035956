<script setup lang="ts">
import { computed } from 'vue'
import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'

const props = withDefaults(
  defineProps<{
    placeholder?: string
    icon?: IconName
    /**
     * When true, the dropdown trigger will have a transparent
     * background and hover effect.
     */
    inline?: boolean
    size?: 'md' | 'lg'
    /**
     * Set to true when the dropdown is open to force the trigger
     * to have its active/hovered style.
     */
    active?: boolean
    disabled?: boolean
  }>(),
  { placeholder: 'Add...', icon: undefined, size: 'md' },
)

const backgroundColor = computed<'active' | 'inactive' | 'active:inline' | 'inactive:inline'>(
  () => {
    if (props.active) {
      return props.inline ? 'active:inline' : 'active'
    } else {
      return props.inline ? 'inactive:inline' : 'inactive'
    }
  },
)
</script>

<template>
  <div
    class="flex h-7 select-none items-center gap-1 rounded-corner-8 text-sm-12px-default outline-none group-focus-visible/popup-trigger:shadow-focus-ring-primary"
    :class="[
      backgroundColor === 'active' && !disabled && 'bg-background-gray-subtlest-hovered',
      backgroundColor === 'inactive' &&
        !disabled &&
        'bg-background-gray-subtlest hover:bg-background-gray-subtlest-hovered',
      backgroundColor === 'active:inline' && !disabled && 'bg-background-transparent-hovered',
      backgroundColor === 'inactive:inline' &&
        !disabled &&
        'bg-background-transparent hover:bg-background-transparent-hovered',
      disabled && 'bg-background-disabled',
      size === 'md' ? 'p-1 pr-1.5' : 'p-1.5 pr-2',
    ]"
  >
    <div
      v-if="icon"
      class="flex min-h-5 items-center justify-center"
    >
      <IconSprite
        :icon="icon"
        class="px-0.5"
        :class="disabled ? 'text-icon-disabled' : 'text-icon-subtle'"
      />
    </div>
    <div
      class="flex min-h-5 grow basis-0 flex-row flex-wrap items-center gap-1 overflow-hidden px-1 leading-tight"
      :class="disabled ? 'text-text-disabled' : 'text-text'"
    >
      <slot>
        <span>{{ placeholder }}</span>
      </slot>
    </div>
    <div
      class="flex min-h-5 items-center justify-center"
      :class="disabled ? 'text-icon-disabled' : 'text-icon-subtlest'"
    >
      <IconSprite icon="chevron-select" />
    </div>
  </div>
</template>
