import { ToolProvider } from '@/backend/types'
import { defaultToolConfig, geminiOutputTypes } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const gemini15FlashTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Gemini 1.5 Flash',
  description: 'Gemini 1.5 Flash is the fastest and cheapest version of Google Gemini 1.5.',
  outputTypes: geminiOutputTypes,
  provider: ToolProvider.google_ai,
  icon: 'company-google',
}
