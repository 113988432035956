<script setup lang="ts">
import { useFileType } from '@/sharedComposables/useFileType'
import { computed } from 'vue'
import GroundingModalImage from './GroundingModalImage.vue'
import GroundingModalPdf from './GroundingModalPdf.vue'
import { useGroundingStore } from './useGroundingStore'
import { fileURL } from './useProject'

const groundingStore = useGroundingStore()

const activeInputField = computed(() =>
  groundingStore.inputFields.find((field) => field.propertyId === groundingStore.activeInputId),
)

const displayUrl = computed(() =>
  activeInputField.value ? fileURL(activeInputField.value, 'display') : null,
)

const isPdf = computed(() => useFileType(displayUrl).value === 'pdf')
</script>

<template>
  <GroundingModalPdf
    v-if="isPdf && activeInputField"
    show-sidebar
    storage-prefix="grounding-modal"
    :file-path="displayUrl ?? ''"
    :grounding-info="{
      sources: groundingStore.activeInputSources,
      ocrPages: activeInputField.ocrPages ?? [],
    }"
    v-bind="$attrs"
  />
  <GroundingModalImage
    v-else-if="activeInputField?.manualValue && activeInputField.ocrPages"
    :image-path="activeInputField.manualValue"
    :sources="groundingStore.activeInputSources"
    :ocr-page="activeInputField.ocrPages[0]"
    v-bind="$attrs"
  />
</template>
