import type { MarkSpec, SchemaSpec } from 'prosemirror-model'
import { Schema } from 'prosemirror-model'
import { mentionNodeSpec } from '../ProseMirror/mentionables'

/**
 * Spec for a ProseMirror schema that can render a markdown document. Is
 * copied from https://github.com/ProseMirror/prosemirror-markdown/blob/1.13.1/src/schema.ts#L5-L154
 * because:
 * - prosemirror-markdown doesn't export the spec (only the schema), and
 *   we need to extend the spec to define our own markdown+featureA schemas.
 * - We want to change the toDOM() methods to add our own tailwind classes
 *
 */
export const schemaSpec: SchemaSpec = {
  nodes: {
    doc: {
      content: 'block+',
    },

    paragraph: {
      content: 'inline*',
      group: 'block',
      parseDOM: [{ tag: 'p' }],
      toDOM() {
        return ['p', { class: 'text-sm-12px-light my-0.5' }, 0]
      },
    },

    mention: mentionNodeSpec,

    blockquote: {
      content: 'block+',
      group: 'block',
      parseDOM: [{ tag: 'blockquote' }],
      toDOM() {
        return [
          'blockquote',
          {
            class: 'text-sm-12px-light my-1 pl-4 border-l-4 border-border',
          },
          0,
        ]
      },
    },

    horizontal_rule: {
      group: 'block',
      parseDOM: [{ tag: 'hr' }],
      toDOM() {
        return ['hr', { class: 'border-t border-border my-2' }]
      },
    },

    heading: {
      attrs: { level: { default: 1 } },
      content: '(text | image)*',
      group: 'block',
      defining: true,
      parseDOM: [
        { tag: 'h1', attrs: { level: 1 } },
        { tag: 'h2', attrs: { level: 2 } },
        { tag: 'h3', attrs: { level: 3 } },
        { tag: 'h4', attrs: { level: 4 } },
        { tag: 'h5', attrs: { level: 5 } },
        { tag: 'h6', attrs: { level: 6 } },
      ],
      toDOM(node) {
        const { level } = node.attrs
        switch (level) {
          case 1:
            return ['h1', { class: 'text-markdown-1heading my-4' }, 0]
          case 2:
            return ['h2', { class: 'text-markdown-2heading my-4' }, 0]
          case 3:
            return ['h3', { class: 'text-markdown-3heading my-2' }, 0]
          default:
            return ['p', { class: 'text-sm-12px-light my-0.5' }, 0]
        }
      },
    },

    code_block: {
      content: 'text*',
      group: 'block',
      code: true,
      defining: true,
      marks: '',
      attrs: { params: { default: '' } },
      parseDOM: [
        {
          tag: 'pre',
          preserveWhitespace: 'full',
          getAttrs: (node) => ({ params: (node as HTMLElement).getAttribute('data-params') || '' }),
        },
      ],
      toDOM() {
        return [
          'pre',
          { class: 'bg-background-gray-subtlest p-2 my-1 rounded-corner-6 w-max min-w-full' },
          ['code', 0],
        ]
      },
    },

    ordered_list: {
      content: 'list_item+',
      group: 'block',
      attrs: { order: { default: 1 }, tight: { default: false } },
      parseDOM: [
        {
          tag: 'ol',
          getAttrs(dom) {
            return {
              order: dom.hasAttribute('start') ? +(dom.getAttribute('start') || 1) : 1,
              tight: (dom as HTMLElement).hasAttribute('data-tight'),
            }
          },
        },
      ],
      toDOM() {
        return [
          'ol',
          { class: 'text-sm-12px-default list-decimal ml-6 marker:text-sm-12px-default' },
          0,
        ]
      },
    },

    bullet_list: {
      content: 'list_item+',
      group: 'block',
      attrs: { tight: { default: false } },
      parseDOM: [
        {
          tag: 'ul',
          getAttrs: (dom) => ({ tight: (dom as HTMLElement).hasAttribute('data-tight') }),
        },
      ],
      toDOM() {
        return ['ul', { class: 'text-sm-12px-default list-disc ml-6' }, 0]
      },
    },

    list_item: {
      content: 'block+',
      defining: true,
      parseDOM: [{ tag: 'li' }],
      toDOM() {
        return ['li', 0]
      },
    },

    text: {
      group: 'inline',
    },

    image: {
      inline: true,
      attrs: {
        src: {},
        alt: { default: null },
        title: { default: null },
      },
      group: 'inline',
      draggable: true,
      parseDOM: [
        {
          tag: 'img[src]',
          getAttrs(dom) {
            return {
              src: (dom as HTMLElement).getAttribute('src'),
              title: (dom as HTMLElement).getAttribute('title'),
              alt: (dom as HTMLElement).getAttribute('alt'),
            }
          },
        },
      ],
      toDOM(node) {
        return ['img', node.attrs]
      },
    },

    hard_break: {
      inline: true,
      group: 'inline',
      selectable: false,
      parseDOM: [{ tag: 'br' }],
      toDOM() {
        return ['br']
      },
    },
  },

  marks: {
    em: {
      parseDOM: [
        { tag: 'i' },
        { tag: 'em' },
        { style: 'font-style=italic' },
        { style: 'font-style=normal', clearMark: (m) => m.type.name == 'em' },
      ],
      toDOM() {
        return ['em']
      },
    },

    strong: {
      parseDOM: [
        { tag: 'strong' },
        { tag: 'b', getAttrs: (node) => node.style.fontWeight != 'normal' && null },
        { style: 'font-weight=400', clearMark: (m) => m.type.name == 'strong' },
        {
          style: 'font-weight',
          getAttrs: (value) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
        },
      ],
      toDOM() {
        return ['strong']
      },
    } as MarkSpec,

    link: {
      attrs: {
        href: {},
        title: { default: null },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs(dom) {
            return {
              href: (dom as HTMLElement).getAttribute('href'),
              title: dom.getAttribute('title'),
            }
          },
        },
      ],
      toDOM(mark) {
        return [
          'a',
          {
            href: mark.attrs.href,
            class: 'text-link cursor-pointer underline decoration-link',
            target: '_blank',
            title: mark.attrs.href,
          },
          0,
        ]
      },
    },

    code: {
      parseDOM: [{ tag: 'code' }],
      toDOM() {
        return ['code']
      },
    },
  },
}

export const schema = new Schema(schemaSpec)
