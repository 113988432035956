<script setup lang="ts">
import { pluralize } from '@/shared/utils/string'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { computed } from 'vue'
import { useBilling } from '../Billing/useBilling'
import ObjectURLImage from '../Projects/ObjectURLImage.vue'
import { useProjects, type Project } from '../Projects/useProjects'
import { PROJECT_NAME_FALLBACK } from './constants'
import { useProject } from './useProject'
import { getCoverImageUrl } from './utils'

/**
 * Renders a list of all other projects in the workspace, with a thumbnail
 * and the project name. When a project is selected, it emits the `change` event
 * with the project ID.
 */

defineProps<{
  value: string | null | undefined
}>()

defineEmits<{
  (e: 'change', propertyId: string): void
}>()

const projectsStore = useProjects()
const projectStore = useProject()
const billingStore = useBilling()

const otherProjects = computed<
  Array<{
    id: string
    data: Project & { imageUrl: string | null }
  }>
>(() =>
  projectsStore.mainProjects
    .filter((p) => p.id !== projectStore.projectId)
    .map((p) => ({ id: p.id, data: { ...p, imageUrl: getCoverImageUrl(p, 'low') } })),
)

/** Gets the number of entities on a given project */
const getEntityCount = (projectId: string) =>
  billingStore.projectLimits[projectId]?.entity_count?.total?.limitUsage || 0

const numberFormatter = new Intl.NumberFormat()
</script>

<template>
  <ListMenu
    :items="otherProjects"
    search-by-field="name"
  >
    <template #item="{ active, item, key, setActiveItem }">
      <ListMenuItem
        :key="key"
        :label="item.data.name || PROJECT_NAME_FALLBACK"
        :active="active"
        :aria-selected="active"
        @mousemove="setActiveItem(key)"
        @select="$emit('change', item.data.id)"
      >
        <template #prefix>
          <div class="flex gap-1">
            <IconSprite
              icon="check"
              size="sm"
              :class="item.data.id === value ? 'text-icon-subtle' : 'opacity-0'"
            />
            <div class="flex size-5 items-center justify-center">
              <ObjectURLImage
                v-if="item.data.imageUrl"
                class="size-4 rounded-corner-4 object-cover"
                :url="item.data.imageUrl"
                :loading="false"
              />
              <IconSprite
                v-else
                icon="table"
                size="sm"
                class="text-icon-subtlest"
              />
            </div>
          </div>
        </template>
        <template
          v-if="getEntityCount(item.data.id)"
          #suffix
        >
          <div class="pl-1 text-xs-11px-light text-text-subtlest">
            {{ numberFormatter.format(getEntityCount(item.data.id)) }}
            {{ pluralize(getEntityCount(item.data.id), 'entity', 'entities') }}
          </div>
        </template>
      </ListMenuItem>
    </template>
  </ListMenu>
</template>
