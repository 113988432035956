import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const whisperTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Whisper',
  description: "Whisper is OpenAI's text transcription model for audio and video files.",
  outputTypes: [PropertyType.text],
  provider: ToolProvider.open_ai,
  icon: 'company-openai',
  usesPrompt: false,
}
