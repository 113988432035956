import { useNodeViewFactory } from '@prosemirror-adapter/vue'
import type { Mode } from '../ProseMirror.vue'

/**
 * Provides ProseMirror NodeViews - NodeViews make it so that we can
 * render Vue components inside the ProseMirror editor.
 * @see https://prosemirror.net/docs/ref/#view.EditorProps.nodeViews
 *
 * This function will return an object that maps each node type to a
 * ProseMirror NodeView. Any node types not included here will be
 * rendered as regular ProseMirror nodes, following the spec from
 * each node's `toDOM` method.
 */
export const useProseMirrorComponents = (nodeViewOptions: Mode['nodeViewOptions']) => {
  if (!nodeViewOptions) {
    return {}
  }

  const nodeViewFactory = useNodeViewFactory()
  if (!nodeViewFactory) {
    return
  }

  return Object.fromEntries(nodeViewOptions.map((n) => [n.name, nodeViewFactory(n)]))
}
