import type { SnakeToCamelCaseObj } from '@/shared/types'
import { post } from './client'
import type { BackendMeta } from './types'

export type CreateConnectionMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/integrations/{integration_id}/connections',
  'post'
>
type Body = CreateConnectionMeta['body']
type Params = CreateConnectionMeta['pathParams']

export const createConnection = ({ workspaceId, integrationId }: SnakeToCamelCaseObj<Params>) =>
  post<Body, CreateConnectionMeta['successResponse'], CreateConnectionMeta['path']>(
    `/api/workspaces/${workspaceId}/integrations/${integrationId}/connections`,
    undefined,
  )
