import { ToolProvider } from '@/backend/types'
import { defaultToolConfig, geminiOutputTypes } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const geminiProTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Gemini Pro',
  description: "Gemini Pro is Google's most powerful Gemini 1.0 model.",
  outputTypes: geminiOutputTypes,
  provider: ToolProvider.google_ai,
  icon: 'company-google',
}
