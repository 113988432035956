import { defineStore } from 'pinia'
import { computed, markRaw, ref } from 'vue'

type UploadItem =
  | {
      file: File
      fileName: string
      progress: number
      status: 'ready' | 'getting-url'
      url?: string
      workspaceId: string
      projectId: string
      entityId: string
      propertyId: string
      parentEntityId: string | null
    }
  | {
      file: File
      fileName: string
      progress: number
      status: 'uploading' | 'confirming' | 'done'
      url: string
      workspaceId: string
      projectId: string
      entityId: string
      propertyId: string
      parentEntityId: string | null
    }
  | {
      file: File
      fileName: string
      progress: 0
      status: 'error'
      error: string
      url?: string
      workspaceId: string
      projectId: string
      entityId: string
      propertyId: string
      parentEntityId: string | null
    }

type PendingItem = {
  id: string
  file: File
  workspaceId: string
  projectId: string
  propertyId: string
  parentEntityId: string | null
  status: 'idle' | 'creating-entity'
}

const id = ({
  entityId,
  projectId,
  propertyId,
  workspaceId,
  parentEntityId,
}: {
  workspaceId: string
  projectId: string
  entityId: string
  propertyId: string
  parentEntityId: string | null
}) => `${workspaceId}-${projectId}-${entityId}-${propertyId}-${parentEntityId || ''}`

const pendingId = ({
  filename,
  projectId,
  propertyId,
  workspaceId,
  parentEntityId,
}: {
  workspaceId: string
  projectId: string
  propertyId: string
  filename: string
  parentEntityId: string | null
}) => `${workspaceId}-${projectId}-${propertyId}-${filename}-${parentEntityId || ''}`

export const useFieldUpload = defineStore('fieldUpload', () => {
  const uploads = ref(new Map<string, UploadItem>())

  /**
   * Adds a new file to be uploaded to the given cell of the given entity
   * This upload is in the initial ready state, and the next step is to fire
   * a request to the start upload endpoint to the backend.
   */
  const addUpload = ({
    entityId,
    file,
    projectId,
    propertyId,
    workspaceId,
    parentEntityId,
  }: {
    workspaceId: string
    projectId: string
    entityId: string
    propertyId: string
    parentEntityId: string | null
    file: File
  }) =>
    uploads.value.set(id({ workspaceId, projectId, entityId, propertyId, parentEntityId }), {
      file: markRaw(file),
      fileName: file.name,
      progress: 0,
      status: 'ready',
      workspaceId,
      projectId,
      entityId,
      propertyId,
      parentEntityId: parentEntityId || null,
    })

  /**
   * Retrieve the assigned upload object for the given cell of the given entity
   */
  const getUpload = ({
    entityId,
    parentEntityId,
    projectId,
    propertyId,
    workspaceId,
  }: {
    workspaceId: string
    projectId: string
    entityId: string
    propertyId: string
    parentEntityId: string | null
  }) => uploads.value.get(id({ workspaceId, projectId, entityId, propertyId, parentEntityId }))

  /**
   * Change state of the given upload object to "starting".
   * This means the request to start the upload has been fired to the backend.
   * Once a response is received, the next step is to call `setUploadUploading`
   * and actually upload the file.
   */
  const setUploadGettingUrl = ({
    entityId,
    parentEntityId,
    projectId,
    propertyId,
    workspaceId,
  }: {
    workspaceId: string
    projectId: string
    entityId: string
    propertyId: string
    parentEntityId: string | null
  }) => {
    const upload = getUpload({ workspaceId, projectId, entityId, propertyId, parentEntityId })
    if (upload) {
      upload.status = 'getting-url'
    }
  }

  /**
   * Change state of the given upload object to "uploading".
   * This means we got the url to upload to and have started the upload.
   * We will now be calling the setProgress action until done
   */
  const setUploadUploading = (
    workspaceId: string,
    projectId: string,
    entityId: string,
    propertyId: string,
    url: string,
    parentEntityId: string | null,
  ) => {
    const upload = getUpload({ workspaceId, projectId, entityId, propertyId, parentEntityId })
    if (upload) {
      upload.status = 'uploading'
      upload.url = url
    }
  }

  /**
   * Set upload progress of the given upload object
   */
  const setUploadProgress = (
    workspaceId: string,
    projectId: string,
    entityId: string,
    propertyId: string,
    progress: number,
    parentEntityId: string | null,
  ) => {
    const upload = getUpload({ workspaceId, projectId, entityId, propertyId, parentEntityId })
    if (upload) {
      upload.progress = progress
    }
  }

  /**
   * Put the given upload into confirming status. This means the actual file upload is done,
   * and we're now in the process of telling the backend this is the case.
   *
   * After we get a response, the next step is to mark the upload process as fully done.
   */
  const setUploadConfirming = (
    workspaceId: string,
    projectId: string,
    entityId: string,
    propertyId: string,
    parentEntityId: string | null,
  ) => {
    const upload = getUpload({ workspaceId, projectId, entityId, propertyId, parentEntityId })
    if (upload) {
      upload.status = 'confirming'
    }
  }

  /**
   * Mark the upload as fully done. The next step is to clear it from the store.
   */
  const setUploadDone = (
    workspaceId: string,
    projectId: string,
    entityId: string,
    propertyId: string,
    parentEntityId: string | null,
  ) => {
    const upload = getUpload({ workspaceId, projectId, entityId, propertyId, parentEntityId })
    if (!upload) {
      return
    }

    upload.status = 'done'
  }

  /**
   * Put the given upload into an error state.
   *
   * The error string is currently a free-form string. Once we have proper error handling,
   * we should make it an enumerated set of codes, so the UI can consistently rely on them.
   */
  const setUploadError = (
    workspaceId: string,
    projectId: string,
    entityId: string,
    propertyId: string,
    error: string,
    parentEntityId: string | null,
  ) => {
    const upload = getUpload({ workspaceId, projectId, entityId, propertyId, parentEntityId })
    if (!upload) {
      return
    }
    // because an errored upload is of a different type, we have to re-assign it
    uploads.value.set(id({ workspaceId, projectId, entityId, propertyId, parentEntityId }), {
      file: markRaw(upload.file),
      fileName: upload.fileName,
      status: 'error',
      progress: 0,
      error,
      workspaceId,
      projectId,
      entityId,
      propertyId,
      parentEntityId: parentEntityId || null,
    })
  }

  /**
   * Remove an upload object from the store
   */
  const removeUpload = (
    workspaceId: string,
    projectId: string,
    entityId: string,
    propertyId: string,
    parentEntityId: string | null,
  ) => {
    uploads.value.delete(id({ workspaceId, projectId, entityId, propertyId, parentEntityId }))
  }

  const nextReadyUpload = computed(() =>
    Array.from(uploads.value.values()).find((upload) => ['ready'].includes(upload.status)),
  )

  const nextDoneUpload = computed(() =>
    Array.from(uploads.value.values()).find((upload) => ['done'].includes(upload.status)),
  )

  const pendingUploads = ref(new Map<string, PendingItem>())

  const addPendingUploads = ({
    files,
    projectId,
    propertyId,
    workspaceId,
    parentEntityId,
  }: {
    workspaceId: string
    projectId: string
    propertyId: string
    parentEntityId: string | null
    files: File[]
  }) =>
    files.forEach((file) => {
      const id = pendingId({
        workspaceId,
        projectId,
        propertyId,
        filename: file.name,
        parentEntityId,
      })
      pendingUploads.value.set(id, {
        id,
        file,
        status: 'idle',
        workspaceId,
        projectId,
        propertyId,
        parentEntityId,
      })
    })

  const nextPendingUpload = computed(() =>
    Array.from(pendingUploads.value.values()).find((upload) => upload.status === 'idle'),
  )

  /**
   * Mark the pending upload associated with the given id as "creating-entity".
   *
   * This means a project entity (row) is being created for it.
   * Once that is done, the pending upload will be upgraded to a proper upload.
   */
  const setPendingUploadCreatingEntity = (id: string) => {
    const pendingUpload = pendingUploads.value.get(id)
    if (!pendingUpload) {
      return
    }

    pendingUpload.status = 'creating-entity'
  }

  /**
   * Convert the given pending upload into a proper upload by associating it
   * with the given entity id and moving it to the upload map.
   */
  const upgradePendingUpload = (id: string, entityId: string) => {
    const pendingUpload = pendingUploads.value.get(id)
    if (!pendingUpload) {
      return
    }
    pendingUploads.value.delete(id)
    addUpload({
      workspaceId: pendingUpload.workspaceId,
      projectId: pendingUpload.projectId,
      entityId,
      propertyId: pendingUpload.propertyId,
      file: pendingUpload.file,
      parentEntityId: pendingUpload.parentEntityId,
    })
  }

  /**
   * Retrieve pendign upload by project id, property id and filename
   */
  const getPendingUpload = (
    workspaceId: string,
    projectId: string,
    propertyId: string,
    filename: string,
    parentEntityId: string | null,
  ) =>
    pendingUploads.value.get(
      pendingId({ workspaceId, projectId, propertyId, filename, parentEntityId }),
    )

  return {
    addPendingUploads,
    addUpload,
    getPendingUpload,
    getUpload,
    nextDoneUpload,
    nextPendingUpload,
    nextReadyUpload,
    removeUpload,
    setUploadConfirming,
    setUploadDone,
    setUploadError,
    setUploadGettingUrl,
    setUploadProgress,
    setUploadUploading,
    setPendingUploadCreatingEntity,
    upgradePendingUpload,
  }
})
