<script setup lang="ts">
/**
 * Only the dialog for workspace creation. Handles the creation flow, but showing
 * and hiding it is managed by a separate component.
 */
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { createWorkspace } from '@/backend/createWorkspace'

import DarwinButton from '@/uiKit/DarwinButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import TextField from '@/uiKit/TextField.vue'

import ModalDialogHeader from '@/uiKit/ModalDialogHeader.vue'
import { useWorkspaces } from './useWorkspaces'

const emit = defineEmits<{ (e: 'close'): void }>()
const name = ref('')
const nameError = ref<string>()
const setName = (newName: string) => {
  nameError.value = undefined
  name.value = newName
}
const store = useWorkspaces()

const router = useRouter()

const validateAndDispatch = async () => {
  nameError.value = undefined
  if (!name.value || name.value.length < 1) {
    nameError.value = 'The name is required'
    return
  }

  if (store.workspaces.find((w) => w.name === name.value)) {
    nameError.value = 'A workspace with this name already exists'
    return
  }

  const result = await createWorkspace(name.value)

  if (!result.ok) {
    nameError.value = 'There was an error when creating the workspace'
    return
  }

  store.addWorkspace({
    id: result.data.id,
    name: name.value,
    role: null,
    createdAt: result.data.created_at,
  })
  router.push(`/${result.data.id}`)
  name.value = ''
  emit('close')
}
</script>

<template>
  <ModalDialog
    to="#main"
    open
    aria-label="Create a Workspace"
    @close="emit('close')"
  >
    <template #header>
      <ModalDialogHeader
        title="Create a Workspace"
        description="Create a new workspace to put your projects in"
      ></ModalDialogHeader>
    </template>
    <template #body>
      <form
        id="create-workspace"
        class="flex flex-col gap-2"
        @submit.prevent="validateAndDispatch"
      >
        <TextField
          size="md"
          :error="nameError"
          :value="name"
          placeholder="Type in a name"
          label="Workspace Name"
          @change="setName"
        />
      </form>
    </template>
    <template #footer="{ close }">
      <DarwinButton
        variant="neutral"
        size="md"
        @click="close"
        >Cancel</DarwinButton
      >
      <DarwinButton
        tabindex="2"
        variant="black"
        size="md"
        form="create-workspace"
        type="submit"
      >
        Create Workspace
      </DarwinButton>
    </template>
  </ModalDialog>
</template>
