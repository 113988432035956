import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const o1MiniTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'o1 Mini',
  description:
    '80% cheaper than o1 and ~3-5x faster. Unlike o1, the mini version is optimised for STEM reasoning. Expect worse performance on tasks requiring non-STEM factual knowledge.',
  outputTypes: [PropertyType.text],
  provider: ToolProvider.open_ai,
  isRateLimited: true,
  icon: 'company-openai',
}
