import type {
  SpaceResponse,
  SpaceResponseErrorMessage,
  SpaceResponseLogMessage,
  SpaceResponseProgressMessage,
  SpaceResponseQueryMessage,
  SpaceResponseResultMessage,
} from '@/backend/types'
import { exhaustiveGuard } from '@/shared/utils/typeAssertions'
import { SPACE_NAME_FALLBACK } from './constants'
import type {
  ErrorMessage,
  LogMessage,
  ProgressMessage,
  QueryMessage,
  ResultMessage,
  Space,
} from './types'

/**
 * Serialize a an API space object into a frontend domain
 * space object.
 */
export const serializeSpace = (spaceResponse: SpaceResponse): Space => ({
  createdAt: spaceResponse.created_at,
  id: spaceResponse.id,
  messages: spaceResponse.messages.map(serializeMessage),
  status: spaceResponse.status,
  updatedAt: spaceResponse.updated_at,
  name: spaceResponse.name || SPACE_NAME_FALLBACK,
})

/**
 * Serialize an API message object into a frontend domain
 * message object.
 */
export const serializeMessage = (
  messageResponse: SpaceResponse['messages'][number],
): Space['messages'][number] => {
  switch (messageResponse.type) {
    case 'query':
      return serializeQueryMessage(messageResponse)
    case 'progress':
      return serializeProgressMessage(messageResponse)
    case 'result':
      return serializeResultMessage(messageResponse)
    case 'log':
      return serializeLogMessage(messageResponse)
    default:
      return exhaustiveGuard(messageResponse, 'Unknown message type')
  }
}

function serializeLogMessage(message: SpaceResponseLogMessage): LogMessage {
  return {
    id: message.id,
    createdAt: message.created_at,
    updatedAt: message.updated_at,
    type: 'log',
    data: message.data,
    message: message.message,
  }
}

function serializeQueryMessage(message: SpaceResponseQueryMessage): QueryMessage {
  return {
    id: message.id,
    createdAt: message.created_at,
    updatedAt: message.updated_at,
    type: 'query',
    data: message.data,
    query: message.query,
    userId: message.user_id,
  }
}

function serializeErrorMessage(message: SpaceResponseErrorMessage): ErrorMessage {
  return {
    id: message.id,
    createdAt: message.created_at,
    updatedAt: message.updated_at,
    type: 'error',
    parentMessageId: message.parent_message_id,
    text: message.text,
  }
}

function serializeProgressMessage(message: SpaceResponseProgressMessage): ProgressMessage {
  return {
    id: message.id,
    createdAt: message.created_at,
    updatedAt: message.updated_at,
    type: 'progress',
    messages: message.messages.map((m) =>
      m.type === 'error' ? serializeErrorMessage(m) : serializeProgressMessage(m),
    ),
    parentMessageId: message.parent_message_id,
    pctComplete: message.pct_complete,
    text: message.text,
    status: message.status,
  }
}

function serializeResultMessage(message: SpaceResponseResultMessage): ResultMessage {
  return {
    id: message.id,
    createdAt: message.created_at,
    updatedAt: message.updated_at,
    type: 'result',
    results: message.results,
  }
}
