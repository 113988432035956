<script setup lang="ts">
import PopOver from '@/uiKit/PopOver.vue'
import { ref, watch } from 'vue'
import type { ComponentProps } from 'vue-component-type-helpers'

const props = defineProps<{
  open: boolean
  target: ComponentProps<typeof PopOver>['targetSelector']
}>()

const timeouts: NodeJS.Timeout[] = []
const addTimeout = (fn: () => void, ms?: number) => {
  const timeout = setTimeout(fn, ms)
  timeouts.push(timeout)
  return timeout
}
const clearTimeouts = () => timeouts.forEach(clearTimeout)

const propOpen = ref(false)
const vIfOpen = ref(false)
watch(
  () => props.open,
  (isOpen) => {
    clearTimeouts()
    if (isOpen) {
      vIfOpen.value = true
      addTimeout(() => (propOpen.value = true))
    } else {
      propOpen.value = false
      addTimeout(() => (vIfOpen.value = false), 300)
    }
  },
)
</script>

<template>
  <!-- we use a v-if to avoid creating a popover for every cell, hindering perf -->
  <PopOver
    v-if="vIfOpen"
    :open="propOpen"
    :target-selector="target"
    :placement="{
      allowedPlacements: ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end'],
    }"
  >
    <template #content>
      <div
        class="relative w-max max-w-[352px] overflow-hidden rounded-corner-6 border border-border"
      >
        <div class="bg-surface-popover-inverted p-2 text-text-inverted-irreversible">
          <div class="flex flex-col justify-start gap-2 self-stretch">
            <div class="flex flex-col gap-0.5">
              <div
                class="select-none text-sm-12px-default font-medium text-text-inverted-irreversible"
              >
                This column has no inputs
              </div>
              <div class="select-none text-sm-12px-light text-text-inverted-subtle-irreversible">
                For this column currently has no configured inputs, and will not infer any data
                until properly setup. You can add inputs by clicking on the property header, and
                going to the inputs menu.
              </div>
            </div>
          </div>
        </div>
      </div></template
    >
  </PopOver>
</template>
