<script setup lang="ts">
import { PropertyType } from '@/backend/types'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import type { IconName } from '@/uiKit/IconName'
import IconSprite from '@/uiKit/IconSprite.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed } from 'vue'
import type { Field } from './Fields/types'
import { useSelectedFields } from './useSelectedFields'

const props = defineProps<{
  fieldHasGroundTruth?: boolean
  isCorrectedModelOutput?: boolean
  reasoning?: string | null
  hasGroundingClaim?: boolean
  lastCorrectedBy?: {
    name?: string | null
    avatarUrl?: string | null
  }
  tool?: {
    name: string
    icon: IconName
  }
  propertyType: PropertyType
  status: Field['status']
}>()

defineEmits<{
  (e: 'open' | 'reset' | 'lock' | 'open-mousedown' | 'reset-mousedown' | 'lock-mousedown'): void
}>()

const labelId = 'project-table-cell-tooltip-label'

const isCollectionField = computed(
  () =>
    props.propertyType === PropertyType.collection ||
    props.propertyType === PropertyType.file_collection,
)

const { recalculateSelectedFields } = useSelectedFields()
const onRecalculate = async () => {
  if (props.status === 'computing') return
  await recalculateSelectedFields({ force: true })
}
</script>

<template>
  <div class="flex rounded-corner-8 bg-surface-popover-inverted">
    <div class="flex items-center">
      <div
        class="p-1.5"
        data-theme="dark"
      >
        <AvatarIcon
          v-if="isCorrectedModelOutput"
          :url="lastCorrectedBy?.avatarUrl"
          :full-text="lastCorrectedBy?.name"
          shape="circle"
          size="xs"
        />
        <IconSprite
          v-else-if="tool"
          class="text-text-inverted-irreversible"
          :icon="tool.icon"
          size="md"
        />
      </div>
      <div
        v-if="lastCorrectedBy"
        :id="labelId"
        class="grow cursor-default py-1.5 pr-2 text-sm-12px-light text-text-inverted-irreversible"
      >
        Corrected by {{ lastCorrectedBy.name }}
      </div>
      <div
        v-else-if="tool"
        :id="labelId"
        class="grow cursor-default py-1.5 pr-2 text-sm-12px-light text-text-inverted-irreversible"
      >
        Computed by {{ tool.name }}
      </div>
    </div>
    <div
      class="flex"
      data-theme="dark"
    >
      <DividerLine
        direction="vertical"
        color="default"
      />
      <DarwinButton
        v-if="isCorrectedModelOutput"
        size="sm"
        variant="transparent"
        class="w-full rounded-none"
        @click="$emit('reset')"
        @mousedown="$emit('reset-mousedown')"
      >
        Reset
      </DarwinButton>
      <template v-if="reasoning">
        <div class="flex size-7 min-w-7 items-center justify-center p-0.5">
          <ToolTip
            :placement="{ allowedPlacements: ['top'] }"
            :offset="2"
          >
            <template #content>
              <h3 class="text-sm-12px-default text-text-inverted-irreversible">Reasoning</h3>
              <p class="text-sm-12px-light text-text-inverted-subtle-irreversible">
                {{ reasoning }}
              </p>
            </template>
            <IconButton
              aria-label="Reasoning"
              icon="info"
              size="md"
              class="hover:cursor-default"
              variant="transparent"
            />
          </ToolTip>
        </div>
        <DividerLine
          direction="vertical"
          color="default"
        />
      </template>
      <template v-if="tool && !isCorrectedModelOutput">
        <div class="flex items-center justify-center p-0.5">
          <DarwinButton
            variant="transparent"
            size="xs"
            :class="status === 'computing' && 'pointer-events-none'"
            @click="onRecalculate"
          >
            <template #leading-icon>
              <CircularProgress
                v-if="status === 'computing'"
                size="xs"
              />
              <IconSprite
                v-else
                icon="refresh"
              />
            </template>
            <template #default> Recompute </template>
          </DarwinButton>
        </div>
      </template>

      <template v-if="!isCorrectedModelOutput && !isCollectionField">
        <DividerLine
          direction="vertical"
          color="default"
        />
        <div class="flex size-7 items-center justify-center p-0.5">
          <ToolTip
            :title="
              fieldHasGroundTruth
                ? 'When unlocked, this field will no longer be immutable.'
                : 'Locking a field will prevent it from being recomputed.'
            "
            :offset="2"
            :placement="{ allowedPlacements: ['top'] }"
          >
            <IconButton
              aria-label="Lock this field"
              data-lock-field-button
              size="md"
              variant="transparent"
              :icon="fieldHasGroundTruth ? 'lock-fill' : 'unlock'"
              @click="$emit('lock')"
              @mousedown="$emit('lock-mousedown')"
            />
          </ToolTip>
        </div>
      </template>

      <template v-if="hasGroundingClaim && !isCorrectedModelOutput">
        <DividerLine
          direction="vertical"
          color="default"
        />
        <div class="flex size-7 min-w-7 items-center justify-center p-0.5">
          <ToolTip
            title="Open citations for this field"
            :placement="{ allowedPlacements: ['top'] }"
          >
            <IconButton
              aria-label="AI citations"
              icon="idea"
              size="md"
              variant="transparent"
              @click="$emit('open')"
              @mousedown="$emit('open-mousedown')"
            />
          </ToolTip>
        </div>
      </template>
    </div>
  </div>
</template>
