<script lang="ts">
/** Creates an element that will wrap each mention component in the editor */
export const getMentionContainer = () => {
  const mentionContainer = document.createElement('span')
  mentionContainer.classList.add('inline-flex')
  mentionContainer.classList.add('py-0.5')
  return mentionContainer
}
</script>

<script setup lang="ts">
import { useAskGo } from '@/modules/Project/useAskGo'
import { useProject } from '@/modules/Project/useProject'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import { useNodeViewContext } from '@prosemirror-adapter/vue'
import { computed } from 'vue'
import type { Mentionable } from '../ProseMirror/ProseMirror.vue'
/**
 * Renders a badge that represents a mention in the editor, e.g.
 * "Describe what is happening in @image"
 */

const { contentRef, node } = useNodeViewContext()

const projectStore = useProject()

const attrs = computed(() => node.value.attrs as Mentionable)

const askGoStore = useAskGo()
</script>

<template>
  <BadgeItem
    :ref="contentRef"
    data-test="prosemirror-mention-badge"
    :data-mention-id="attrs.id"
    :label="attrs.label"
    size="xs"
    variant="blue"
    @click="attrs.openOnClick && projectStore.setSelectedProperty(attrs.id.slice(1))"
    @mouseenter="attrs.previewPropertyOnHover && askGoStore.highlightProperty(attrs.id.slice(1))"
    @mouseleave="attrs.previewPropertyOnHover && askGoStore.highlightProperty()"
  />
</template>
