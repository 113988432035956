export const keyboardTargetIsInput = (event: KeyboardEvent): boolean => {
  const target = event.target
  if (target instanceof HTMLElement) {
    const isTypingIntoTextField =
      target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.isContentEditable
    if (isTypingIntoTextField) {
      return true
    }
  }

  return false
}

/** Returns true if a given mouse event is a right-click. */
export const isRightClick = (event: MouseEvent): boolean => {
  // The right button on a mouse with >1 button
  const isRightButton = event.button === 2

  // On Mac, a ctrl+click is treated as a context menu click
  const isMacRightClick = event.ctrlKey && event.button === 0

  return isRightButton || isMacRightClick
}

/**
 * Adds an event listener to the specified target element for the given event(s), and returns a function to remove it.
 * @param target The target element to add the event listener to.
 * @param event The event(s) to listen for.
 * @param handler The function to be called when the event is triggered.
 * @param options An optional object that specifies characteristics about the event listener.
 * @returns A function that removes the event listener(s) from the target element.
 */
export function addEventListener<TEvent extends keyof WindowEventMap>(
  target: Window,
  event: TEvent | TEvent[],
  handler: (this: Window, event: WindowEventMap[TEvent]) => unknown,
  options?: boolean | AddEventListenerOptions,
): VoidFunction

/**
 * Adds an event listener to the specified target element for the given event(s), and returns a function to remove it.
 * @param target The target element to add the event listener to.
 * @param event The event(s) to listen for.
 * @param handler The function to be called when the event is triggered.
 * @param options An optional object that specifies characteristics about the event listener.
 * @returns A function that removes the event listener(s) from the target element.
 */
export function addEventListener<TEvent extends keyof DocumentEventMap>(
  target: Document,
  event: TEvent | TEvent[],
  handler: (this: Document, event: DocumentEventMap[TEvent]) => unknown,
  options?: boolean | AddEventListenerOptions,
): VoidFunction

/**
 * Adds an event listener to the specified target element for the given event(s), and returns a function to remove it.
 * @param target The target element to add the event listener to.
 * @param event The event(s) to listen for.
 * @param handler The function to be called when the event is triggered.
 * @param options An optional object that specifies characteristics about the event listener.
 * @returns A function that removes the event listener(s) from the target element.
 */
export function addEventListener<
  TElement extends HTMLElement,
  TEvent extends keyof HTMLElementEventMap,
>(
  target: TElement,
  event: TEvent | TEvent[],
  handler: (this: TElement, event: HTMLElementEventMap[TEvent]) => unknown,
  options?: boolean | AddEventListenerOptions,
): VoidFunction

/**
 * Adds an event listener to the specified target element for the given event(s), and returns a function to remove it.
 * @param target The target element to add the event listener to.
 * @param event The event(s) to listen for.
 * @param handler The function to be called when the event is triggered.
 * @param options An optional object that specifies characteristics about the event listener.
 * @returns A function that removes the event listener(s) from the target element.
 */
export function addEventListener<TEvent extends keyof MediaQueryListEventMap>(
  target: MediaQueryList,
  event: TEvent | TEvent[],
  handler: (this: MediaQueryList, event: MediaQueryListEventMap[TEvent]) => unknown,
  options?: boolean | AddEventListenerOptions,
): VoidFunction

/**
 * Adds an event listener to the specified target element for the given event(s), and returns a function to remove it.
 * @param target The target element to add the event listener to.
 * @param event The event(s) to listen for.
 * @param handler The function to be called when the event is triggered.
 * @param options An optional object that specifies characteristics about the event listener.
 * @returns A function that removes the event listener(s) from the target element.
 */
export function addEventListener(
  target: EventTarget,
  event: string | string[],
  handler: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions,
): VoidFunction

export function addEventListener(
  target: EventTarget,
  event: string | string[],
  handler: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions,
): VoidFunction {
  const events = Array.isArray(event) ? event : [event]

  for (const event of events) {
    target.addEventListener(event, handler, options)
  }

  return () => {
    for (const event of events) {
      target.removeEventListener(event, handler, options)
    }
  }
}
