<script setup lang="ts">
import { exhaustiveGuard, invariant } from '@/shared/utils/typeAssertions'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import { computed, ref } from 'vue'
import { type ID } from './Filters/FilterItemMatcher.vue'
import FilterItemSelectValuePicker from './Filters/FilterItemSelectValuePicker.vue'
import FilterItemTextValue from './Filters/FilterItemTextValue.vue'
import {
  assertIsValidSelectMatcher,
  assertIsValidTextMatcher,
  getFilterSubjectForProperty,
  isTextLikeFilter,
  isTextLikeFilterSubject,
  type FieldValueFilter,
  type FilterableProperty,
  type MatcherName,
  type TextLikeFilter,
} from './Filters/types'
import { FILTER_MENU_ID } from './ModelInputMenuFilters.vue'
import ModelInputMenuFiltersFilterTemplate from './ModelInputMenuFiltersFilterTemplate.vue'
/**
 * Renders a component that allows the user to update a text/select filter,
 * applied to a tool input.
 */

const props = defineProps<{
  properties: FilterableProperty[]
  filter: FieldValueFilter
}>()

const emit = defineEmits<{
  (e: 'update', filter: FieldValueFilter): void
}>()

const values = ref<string[]>(props.filter.matcher?.values ?? [])

const selectedProperty = computed<FilterableProperty | null>(() => {
  const propertyId = props.filter.matcher.property_id
  return props.properties.find((p) => p.id === propertyId) ?? null
})

const onNewMatcher = (matchId: ID) => {
  if (!selectedProperty.value) return

  if (isTextLikeFilter(props.filter)) {
    assertIsValidTextMatcher(matchId)
    const filter: FieldValueFilter = {
      ...props.filter,
      matcher: {
        ...props.filter.matcher,
        name: matchId,
      },
    }
    emit('update', filter)
  } else if (props.filter.subject === 'field_select_option_value') {
    let values = props.filter.matcher.values
    if (matchId === 'property_not_set') {
      matchId = 'property_none_of'
      if (isSelectProperty(selectedProperty.value)) {
        values = selectedProperty.value.config.options.map((option) => option.value)
      }
    }

    assertIsValidSelectMatcher(matchId)

    const filter: FieldValueFilter = {
      ...props.filter,
      matcher: {
        ...props.filter.matcher,
        name: matchId,
        values,
      },
    }
    emit('update', filter)
  } else {
    // @ts-expect-error - we should never reach this point. If TS complains here, we need to handle another filter subject
    throw new Error(`Unsupported property type: ${props.filter.subject}`)
  }
}

const onCreateFilter = (property: FilterableProperty) => {
  const subject = getFilterSubjectForProperty(property.type)
  if (subject === 'field_select_option_value') {
    emit('update', {
      subject: 'field_select_option_value',
      matcher: {
        name: 'property_any_of',
        values: values.value,
        property_id: property.id,
      },
    })
  } else if (isTextLikeFilterSubject(subject)) {
    emit('update', {
      subject,
      matcher: {
        name: 'property_contains_any_of',
        values: values.value,
        property_id: property.id,
        case_sensitive: false,
      },
    })
  } else {
    exhaustiveGuard(subject, 'Unhandled filter subject')
  }
}

const truncatedValue = computed(() => {
  const maxLength = 12
  const value = props.filter.matcher?.values[0]
  if (!value) return ''

  return value.length > maxLength
    ? `${value.slice(0, maxLength / 2)}...${value.slice(-maxLength / 2)}`
    : value
})
const isTextModalOpen = ref(false)

const onUpdateTextFilter = (data: {
  caseSensitive: boolean
  values: string[]
  matcherName: MatcherName
}) => {
  if (!selectedProperty.value) return

  assertIsValidTextMatcher(data.matcherName)
  invariant(isTextLikeFilter(props.filter), 'Filter must be a text-like filter')

  const filter: TextLikeFilter = {
    subject: props.filter.subject,
    matcher: {
      name: data.matcherName,
      property_id: selectedProperty.value.id,
      values: data.values,
      case_sensitive: data.caseSensitive,
    },
  }
  emit('update', filter)
}

const onCloseTextDialog = () => {
  isTextModalOpen.value = false
}

const onChangeSelectValues = (values: string[]) => {
  if (!selectedProperty.value) return

  const matcherName = props.filter.matcher.name
  assertIsValidSelectMatcher(matcherName)

  emit('update', {
    subject: 'field_select_option_value',
    matcher: {
      name: matcherName,
      values,
      property_id: selectedProperty.value.id,
    },
  })
}
</script>

<template>
  <ModelInputMenuFiltersFilterTemplate
    :properties="properties"
    :selected-property="selectedProperty"
    :selected-matcher="filter.matcher.name"
    @update:property="onCreateFilter($event)"
    @update:matcher="onNewMatcher"
  >
    <template #value>
      <FilterItemSelectValuePicker
        v-if="
          filter.subject === 'field_select_option_value' &&
          selectedProperty &&
          isSelectProperty(selectedProperty)
        "
        :property="selectedProperty"
        :filter="filter"
        :positioning="{ placement: 'bottom-start' }"
        class="size-full hover:!bg-background-gray-subtlest-hovered focus:outline-none"
        :teleport-to="`#${FILTER_MENU_ID}`"
        aria-label="Edit Filter Value"
        @values="onChangeSelectValues"
      />
      <button
        v-if="isTextLikeFilter(filter)"
        class="flex w-full cursor-pointer items-center gap-0.5 px-1.5 py-1 text-sm-12px-default text-text-subtle hover:bg-background-transparent-hovered focus:outline-none active:bg-background-transparent-pressed"
        :class="{ 'bg-background-transparent-hovered': isTextModalOpen }"
        aria-label="Edit Filter Value"
        @click="isTextModalOpen = true"
      >
        "{{ truncatedValue }}"
      </button>
    </template>
  </ModelInputMenuFiltersFilterTemplate>
  <FilterItemTextValue
    v-if="selectedProperty && isTextLikeFilter(filter)"
    :is-open="isTextModalOpen"
    :property-name="selectedProperty.name"
    :filter="filter"
    @update="onUpdateTextFilter"
    @close="onCloseTextDialog"
  />
</template>
