<script setup lang="ts">
import { type Entity, useProject } from '@/modules/Project/useProject'
import { useSerializeFieldToText } from '@/modules/Project/useSerializeFieldToText'
import CrumbItem from '@/modules/Workspaces/Crumbs/CrumbItem.vue'
import { computed } from 'vue'

const props = defineProps<{
  entity: Entity
  mask?: boolean
}>()

const projectStore = useProject()
const serializeField = useSerializeFieldToText()

const label = computed(() => {
  const firstPropId = projectStore.activeView?.view.propertyIds.at(0)
  if (!firstPropId) return

  const field = props.entity.fields.get(firstPropId)
  if (!field) return

  if (field.type === 'reference') {
    return projectStore.properties.find((p) => p.id === field.propertyId)?.name
  } else if (field.type === 'collection') {
    return field.manualName || field.entityId
  }

  return serializeField(field)
})
</script>

<template>
  <CrumbItem :mask="mask">
    <template v-if="label">{{ label }}</template>
    <template v-else>
      <span class="text-text-subtle">(No field content)</span>
    </template>
  </CrumbItem>
</template>
