<script setup lang="ts">
import { type View } from '@/modules/Project/useProject'
import CrumbItem from '@/modules/Workspaces/Crumbs/CrumbItem.vue'
import { useCrumbItems } from '@/modules/Workspaces/Crumbs/useCrumbItems'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

defineProps<{
  view: View
  mask?: boolean
}>()

const { collection } = useCrumbItems()
const router = useRouter()

const route = computed(() => {
  const { params, query } = router.currentRoute.value

  if (collection.value) {
    return { name: 'WorkspaceSubProjectTableView', params, query }
  }

  return { name: 'WorkspaceProjectTableView', params, query }
})
</script>

<template>
  <CrumbItem
    :to="route"
    :mask="mask"
    >{{ view.name }}</CrumbItem
  >
</template>
