<script lang="ts">
export type ProjectWithCoverImage = Project & { coverImageUrl: string | null }
</script>

<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import { computed } from 'vue'
import { PROJECT_NAME_FALLBACK } from '../Project/constants'
import { getCoverImageUrl } from '../Project/utils'
import ObjectURLImage from '../Projects/ObjectURLImage.vue'
import { useProjects, type Project } from '../Projects/useProjects'

const props = defineProps<{
  value: ProjectWithCoverImage[]
}>()

defineEmits<{
  (e: 'select:item', project: ProjectWithCoverImage): void
}>()

const projectsStore = useProjects()

const projects = computed<ProjectWithCoverImage[]>(() =>
  projectsStore.projects.map((p) => ({
    ...p,
    coverImageUrl: getCoverImageUrl(p, 'low'),
  })),
)

const getDisabledState = (projectId: string) => {
  return props.value.length === 1 && props.value[0].id !== projectId
}
</script>

<template>
  <Menu.Root v-slot="{ getTriggerProps }">
    <DarwinButton
      size="md"
      variant="neutral"
      rounded
      type="button"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
    >
      <template #leading-icon>
        <IconSprite icon="api" />
      </template>
      Source
    </DarwinButton>
    <Menu.Content class="go-scrollbar max-h-96 min-w-56 overflow-y-auto overflow-x-hidden">
      <Menu.GroupTitle label="Projects" />
      <Menu.Item
        v-for="project in projects"
        :key="project.id"
        :disabled="getDisabledState(project.id)"
        @select="$emit('select:item', project)"
      >
        <div class="flex items-center gap-1">
          <SwitchButton
            tabindex="-1"
            :checked="value.some((p) => p.id === project.id)"
            :disabled="getDisabledState(project.id)"
          />
          <ObjectURLImage
            v-if="project.coverImageUrl"
            class="size-4 rounded-corner-4 object-cover"
            :url="project.coverImageUrl"
            :loading="false"
          />
          <IconSprite
            v-else
            icon="table"
            size="sm"
            class="text-icon-subtlest"
          />
          <div class="ml-1">
            {{ project.name || PROJECT_NAME_FALLBACK }}
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.GroupTitle label="Integrations" />
      <Menu.Item>
        <div class="flex items-center gap-1">
          <SwitchButton />

          <IconSprite
            icon="company-google"
            size="sm"
            class="text-icon-subtlest"
          />
          <div class="ml-1">TODO: Google Drive</div>
        </div>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
