import { get } from './client'
import type { BackendMeta } from './types'

export type GetSpace = BackendMeta<'/api/workspaces/{workspace_id}/spaces/{space_id}', 'get'>

export type GetSpaceParams = {
  workspaceId: string
  spaceId: string
}

export const getSpace = ({ workspaceId, spaceId }: GetSpaceParams) =>
  get<undefined, GetSpace['successResponse'], GetSpace['path']>(
    `/api/workspaces/${workspaceId}/spaces/${spaceId}`,
    undefined,
  )
