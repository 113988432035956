<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useSpaceStore } from './useSpaceStore'

const spaceStore = useSpaceStore()
</script>

<template>
  <div
    class="flex size-full flex-col items-center p-10 transition-colors duration-250"
    :class="[
      spaceStore.space?.status === 'running' ? 'bg-surface-secondary' : 'bg-surface-primary',
      spaceStore.space?.status === 'success' ? 'justify-start' : 'justify-center',
    ]"
  >
    <RouterView />
  </div>
</template>
