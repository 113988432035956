<script setup lang="ts">
import ClaimIndicator from '@/sharedComponents/ClaimIndicator.vue'
import { useGroundingInteractions } from '@/sharedComposables/useGroundingInteractions'
import { useNodeViewContext } from '@prosemirror-adapter/vue'
import { captureException } from '@sentry/vue'
import { computed, watch } from 'vue'
import { findNode } from '../ProseMirror/utils'
import type { ClaimAttrs, ClaimStartAttrs } from './schema'
/**
 * Renders a pill to represent a grounding claim in the editor. When
 * clicked, it will apply a mark to all text outside of the claim to make
 * it less visible.
 */

const { contentRef, node, view, getPos } = useNodeViewContext()

const attrs = computed(() => node.value.attrs as ClaimAttrs)

/**
 * When a claim pill is clicked, apply a mark to all text outside of the claim text
 * to make it less visible.
 */
const onSelectClaim = () => {
  const startNode = findNode(
    view.state.doc,
    (n) =>
      n.type.name === 'claim_start' && (n.attrs as ClaimStartAttrs).claimId === attrs.value.claimId,
  )
  if (!startNode) {
    captureException(new Error('Could not find claim start node'))
    return
  }

  const tr = view.state.tr

  // Add a faint mark to all nodes before the claim.
  const mark = view.state.schema.marks.faint.create()
  tr.addMark(0, startNode.pos, mark)

  const endPos = getPos()
  if (endPos === undefined) {
    return
  }

  const docEnd = view.state.doc.resolve(0).end()
  if (docEnd === undefined) {
    return
  }

  // Add a faint mark to all nodes after the claim.
  const endMark = view.state.schema.marks.faint.create()
  tr.addMark(endPos + 1, docEnd, endMark)

  view.dispatch(tr)
}

const { selected } = useGroundingInteractions()
watch(selected, (newSelected) => {
  if (newSelected?.sourceId === Number(attrs.value.sourceId)) {
    onSelectClaim()
  }
})
</script>

<template>
  <ClaimIndicator
    :ref="contentRef"
    :data-claim-pill-claim="attrs.claimId"
    :data-claim-pill-source="attrs.sourceId"
    :data-from="attrs.range[0]"
    :data-to="attrs.range[1]"
    :source-id="Number(attrs.sourceId)"
    @click="onSelectClaim"
  />
</template>
