<script lang="ts">
export type Mentionable = {
  id: string
  label: string
  icon: IconName
  group: string
  openOnClick?: boolean
  previewPropertyOnHover?: boolean
}

export type Mode = {
  /**
   * The schema that the editor should use. This defines the structure of the document and which
   * nodes and marks are allowed.
   * @see https://prosemirror.net/examples/schema/
   */
  schema: Schema
  /** Function to parse a string into a ProseMirror document. */
  parser: {
    parse: (value: string) => Node
  }
  /** Function to get a text value from the ProseMirror document */
  serializer: {
    serialize: (doc: Node) => string
  }
  nodeViewOptions?: Array<VueNodeViewUserOptions & { name: string }>
}

export type ProseMirrorProps = {
  value: string
  readonly?: boolean
  adaptForTableMode?: boolean
  mentionableInputs?: Mentionable[]
  mode: Mode
  toolbar?: boolean
  /** When provided, will persist the height to localStorage using this key */
  savedHeightKey?: string
  ariaLabel?: string
  ariaLabelledBy?: string
  placeholder?: string
}
</script>

<script setup lang="ts">
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { isValidUrl } from '@/shared/utils/string'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import type { IconName } from '@/uiKit/IconName'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import type { VueNodeViewUserOptions } from '@prosemirror-adapter/vue'
import { useResizeObserver, useStorage } from '@vueuse/core'
import { exampleSetup } from 'prosemirror-example-setup'
import type { Node, Schema } from 'prosemirror-model'
import { EditorState, Selection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { ref, shallowRef, toRef, triggerRef, useTemplateRef, watch } from 'vue'
import { useProseMirrorComponents } from './components/useProseMirrorComponents'
import { useMentionablePlugin } from './mentionables/useMentionablePlugin'
import { placeholderPlugin } from './placeholder-plugin'
import ProseMirrorToolbar from './ProseMirrorToolbar.vue'
import { useMarkState } from './useMarkState'
import { useMenuBar } from './useMenuBar'

const props = withDefaults(defineProps<ProseMirrorProps>(), {
  toolbar: undefined,
  mentionableInputs: undefined,
  savedHeightKey: undefined,
  ariaLabel: undefined,
  ariaLabelledBy: undefined,
  placeholder: '',
})

const emit = defineEmits<{
  (e: 'save' | 'input', value: string): void
  (e: 'enter' | 'cancel' | 'tab', event: KeyboardEvent): void
}>()

const state = shallowRef<EditorState>()
const view = shallowRef<EditorView>()

const editor = useTemplateRef('editor')

const { setMarkState, markState } = useMarkState(view, props.mode.schema)

// tracks if the user has edited the content, since the blur event is triggered always and serialization always returns some formatting differences
const hasValueChanged = ref(false)
const shouldSkipBlur = ref(false)
const listMenuRef = ref()

const mentionable = useMentionablePlugin({
  view,
  mentionables: toRef(props, 'mentionableInputs'),
  schema: props.mode.schema,
  triggerListMenuSelectionUp: () => listMenuRef.value?.moveSelectionUp(),
  triggerListMenuSelectionDown: () => listMenuRef.value?.moveSelectionDown(),
  triggerListMenuSelect: () => listMenuRef.value?.triggerSelect(),
})

/**
 * Returns an editor state object that will parse the provided string value and
 * apply the necessary plugins.
 */
const createEditor = (value: string) =>
  EditorState.create({
    plugins: [
      placeholderPlugin(props.placeholder),
      mentionable.plugin,
      ...exampleSetup({ schema: props.mode.schema, menuBar: false, floatingMenu: false }),
    ],
    doc: props.mode.parser.parse(value),
  })

watch(
  () => [props.value, props.readonly],
  () => {
    hasValueChanged.value = false
    // updates the editor value, god this looks ugly, isn't there a better way?
    if (view.value) {
      const editorState = createEditor(props.value)
      view.value.updateState(editorState)
      setMarkState(editorState)
    }
    triggerRef(view)
  },
)

defineExpose({
  focus() {
    view.value?.focus()
    // puts the caret at the end of the text
    if (!view.value) return
    const selection = Selection.atEnd(view.value.state.doc)
    const tr = view.value.state.tr.setSelection(selection)
    const state = view.value.state.apply(tr)
    view.value.updateState(state)
    // scrolls so that the caret is visible
    view.value.dispatch(view.value.state.tr.scrollIntoView())
  },
  view,
})

const getSerializedValue = () => {
  assertIsNotNullOrUndefined(view.value, 'No editor view available - cannot serialize value')

  return props.mode.serializer.serialize(view.value.state.doc)
}
const nodeViews = props.adaptForTableMode
  ? undefined
  : useProseMirrorComponents(props.mode.nodeViewOptions)
// Initialise the rich text editor as soon as the editor element is available
watch(
  () => editor.value,
  () => {
    if (!editor.value) return
    state.value = createEditor(props.value)

    let attributes: ConstructorParameters<typeof EditorView>[1]['attributes'] = {
      role: 'textbox',
    }
    if (props.ariaLabel) {
      attributes['aria-label'] = props.ariaLabel
    }
    if (props.ariaLabelledBy) {
      attributes['aria-labelledby'] = props.ariaLabelledBy
    }

    view.value = new EditorView(editor.value, {
      attributes,
      state: state.value,
      editable: () => !props.readonly,
      nodeViews,
      plugins: [],
      dispatchTransaction(transaction) {
        if (!view.value) {
          return
        }
        if (transaction.docChanged) {
          hasValueChanged.value = true
        }

        let newState = view.value.state.apply(transaction)

        view.value.updateState(newState)

        setMarkState(newState)

        if (props.toolbar) {
          toolbar.refreshToolbarState()
          toolbar.setToolbarPosition()
        }

        emit('input', getSerializedValue())
      },
      handlePaste: (_view, event) => {
        const clipboardData = event.clipboardData
        if (!clipboardData) return false

        const text = clipboardData.getData('text/plain')
        if (!text) return false

        const schemaHasLink = !!props.mode.schema.marks.link
        /** If the user has pasted a URL then convert the selected range into a link. */
        if (schemaHasLink && isValidUrl(text)) {
          toolbar.toggleLink(text)
          return true
        }

        /**
         * Trigger all effects that depend on the view. This is because the
         * mentionable parser runs when the view changes, and we want to parse
         * any mentions that might have been in the pasted data so that they
         * can be rendered correctly.
         */
        triggerRef(view)
        return false
      },
      handleClick: (_view, _pos, event) => {
        /**
         * By default, links will not open when clicked if inside a contenteditable. We override
         * that behavior here.
         */
        const anchor = event.target instanceof HTMLElement && event.target.closest('a')
        if (anchor && isValidUrl(anchor.href)) {
          window.open(anchor.href, '_blank')
          return true
        }

        return false
      },

      handleDOMEvents: {
        focus() {
          view.value?.focus()
        },
        keydown(_e, event: KeyboardEvent) {
          if (
            !props.adaptForTableMode &&
            (event.key === 'ArrowLeft' ||
              event.key === 'ArrowRight' ||
              event.key === 'ArrowUp' ||
              event.key === 'ArrowDown')
          ) {
            // stop propagation in entity view mode and avoid navigation between previous and next entities
            event.stopPropagation()
          }

          if (!props.adaptForTableMode) return
          event.stopPropagation()

          if (event.key === 'Escape') {
            event.preventDefault()
            emit('cancel', event)
            shouldSkipBlur.value = true
          }

          if (event.key === 'Enter') {
            if (event.shiftKey) {
              return
            }
            event.preventDefault()
            emit('enter', event)
          }

          if (event.key === 'Tab') {
            emit('tab', event)
          }
        },

        blur(_view: EditorView, event: FocusEvent) {
          const isClickingToolbar =
            event.relatedTarget instanceof HTMLElement &&
            !!event.relatedTarget.closest('#prosemirror-toolbar')
          if (isClickingToolbar) {
            return
          }

          toolbar.selectionCoords.value = null
          mentionable.menuCoords.value = null

          if (shouldSkipBlur.value) {
            shouldSkipBlur.value = false
            return
          }
          if (!hasValueChanged.value) return

          const serialized = getSerializedValue()
          if (!serialized) return
          emit('save', serialized)
        },
      },
    })
    triggerRef(view)
  },
  { immediate: true },
)

useResizeObserver(editor, () => {
  if (!props.savedHeightKey) return
  height.value = editor.value?.getBoundingClientRect().height
})

// Persist height to localStorage
const height = useStorage(props.savedHeightKey ?? 'default-textarea-key', 150)

watch(editor, (el) => {
  if (!el || !props.savedHeightKey) return
  el.style.height = `${height.value}px`
})

const toolbar = useMenuBar(view, props.mode.schema, editor)
const { toolbarMarkerRef } = toolbar

const markdownToolbarEnabled = useFeatureFlags(FeatureFlag.MARKDOWN_TOOLBAR)
</script>

<template>
  <div
    v-bind="$attrs"
    ref="editor"
    class="go-scrollbar h-max min-w-full cursor-text [&>*:focus-visible]:outline-none [&>*]:h-max [&>*]:min-h-full [&>*]:w-full"
    :class="[adaptForTableMode && 'w-max max-w-[500px]']"
    @paste.stop
    @scroll="toolbar.setToolbarPosition"
  />

  <Teleport to="body">
    <ListMenu
      v-if="!!mentionable.menuCoords.value"
      ref="listMenuRef"
      class="fixed"
      :style="{
        left: `${mentionable.menuCoords.value.left + 8}px`,
        top: `${mentionable.menuCoords.value.top + 18}px`,
      }"
      :items="mentionable.filteredItems.value"
      :group-by-predicate="(item) => item.data.group"
      :initial-active-item-predicate="() => true"
      has-group-titles
      @select="mentionable.onSelectItem"
    >
      <template #item="{ item, active }">
        <ListMenuItem
          :active="active"
          :label="item.data.label"
          :icon="item.data.icon"
          @mousedown.stop.prevent="mentionable.onSelectItem(item.data)"
        />
      </template>
    </ListMenu>
  </Teleport>
  <template
    v-if="
      markdownToolbarEnabled &&
      props.toolbar &&
      toolbar.selectionCoords.value &&
      !toolbar.toolbarOutOfBounds.value
    "
  >
    <div
      ref="toolbarMarkerRef"
      class="fixed"
      :style="{
        left: `${toolbar.selectionCoords.value.left}px`,
        top: `${toolbar.selectionCoords.value.top}px`,
      }"
    ></div>
    <ProseMirrorToolbar
      :bold="markState.strong"
      :italic="markState.em"
      :code="markState.code"
      :link="markState.link"
      :text-style-label="toolbar.textStyleLabel.value"
      :is-bullet-list-active="toolbar.isBulletListActive.value"
      :is-ordered-list-active="toolbar.isOrderedListActive.value"
      :is-blockquote-active="toolbar.isBlockquoteActive.value"
      class="fixed"
      :style="{
        left: `${toolbar.adjustedMenuPosition.value.left}px`,
        top: `${toolbar.adjustedMenuPosition.value.top}px`,
        transform: 'translate(-4px, calc(-100% - 8px))',
      }"
      @toggle:bold="toolbar.toggleBold"
      @toggle:italic="toolbar.toggleItalic"
      @toggle:code="toolbar.toggleCode"
      @insert:heading1="toolbar.setHeading(1)"
      @insert:heading2="toolbar.setHeading(2)"
      @insert:heading3="toolbar.setHeading(3)"
      @insert:paragraph="toolbar.setParagraph"
      @insert:code-block="toolbar.setCodeBlock"
      @insert:bullet-list="toolbar.toggleBulletList"
      @insert:numbered-list="toolbar.toggleOrderedList"
      @insert:quote="toolbar.toggleBlockquote"
      @insert:link="toolbar.toggleLink"
      @remove:link="toolbar.toggleLink"
      @insert:divider="toolbar.insertDivider"
      @insert:image="toolbar.insertImage"
      @close="toolbar.onCloseMenu"
    />
  </template>
</template>

<style>
/*
  I don't know why this is needed but an element with this class is added
  after mention nodes and causes line breaks where we don't want them.
 */
.ProseMirror-separator {
  display: none;
}

.ProseMirror[data-placeholder]::before {
  position: absolute;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.6; /* Simulate subtler placeholder color */
}
</style>
