<script setup lang="ts">
import { createApiKey } from '@/backend/createApiKey'
import { toast } from '@/shared/toast'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import Select from '@/uiKit/Select'
import TextField from '@/uiKit/TextField.vue'
import { ref } from 'vue'
import { useApiKeys, type ApiKey } from './useApiKeys'
import { Expiration, expirationToDays } from './utils'
import WorkspaceSettingsApiKeysAcknowledgeDialog from './WorkspaceSettingsApiKeysAcknowledgeDialog.vue'

const creating = ref<boolean>(false)
const error = ref<string>()
const name = ref<string>()
const expiration = ref<Expiration>(Expiration.week)
const createdApiKey = ref<ApiKey | null>(null)

const apiKeyStore = useApiKeys()

const { captureAnalyticsEvent } = useAnalytics()

const onSubmit = async () => {
  creating.value = true
  const apiKeyResult = await createApiKey(expirationToDays(expiration.value), name.value)
  if (apiKeyResult.ok) {
    creating.value = false
    createdApiKey.value = apiKeyResult.data
    apiKeyStore.addApiKey(apiKeyResult.data)

    captureAnalyticsEvent(ANALYTICS_EVENT.API_KEY_CREATED)
  } else {
    creating.value = false
    if (apiKeyResult.error.code === 'duplicate_name') {
      error.value = 'Name already taken'
      return
    }
    toast.error('Failed to create API key')
  }

  name.value = undefined
  expiration.value = Expiration.week
}
</script>

<template>
  <div class="flex gap-2">
    <TextField
      size="md"
      :error="error"
      :value="name || ''"
      placeholder="API key name (optional)"
      @change="name = $event"
      @input="error = undefined"
    />
    <Select
      class="w-64"
      leading-icon="clock"
      :value="expiration"
      :items="
        Object.values(Expiration).map((expiration) => ({
          value: expiration,
          label: expiration,
        }))
      "
      @change="expiration = $event"
    />
    <div class="flex flex-col">
      <DarwinButton
        variant="black"
        size="sm"
        @click="onSubmit"
        >Create API key
      </DarwinButton>
    </div>
  </div>
  <WorkspaceSettingsApiKeysAcknowledgeDialog
    :api-key="createdApiKey"
    title="API key created"
    description="Save this key somewhere safe and accessible. For security reasons, you won't be able to see it again. If you lose this key, you will need to create a new one."
    @ack="createdApiKey = null"
  />
</template>
