<script setup lang="ts">
import AutosizedTextarea from '@/sharedComponents/AutosizedTextarea.vue'
import MarkdownEditor from '@/sharedComponents/MarkdownEditor/MarkdownEditor.vue'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import { ref, watch } from 'vue'
import { CLOSE_LIBRARY_DOCUMENT_DIALOG_ID, EDIT_DOCUMENT_DIALOG_ID } from './constants'
/**
 * This component is used to create or edit a library document. It renders a
 * couple of text fields so that users can update the document's name and content.
 */

const props = defineProps<{ open: boolean; initialTitle?: string; initialContent?: string }>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'save', payload: { title: string; content: string }): void
}>()

const title = ref(props.initialTitle ?? '')
const content = ref(props.initialContent ?? '')

watch(
  () => props.open,
  (isOpen) => {
    if (isOpen) {
      title.value = props.initialTitle ?? ''
      content.value = props.initialContent ?? ''
    }
  },
)

const onSave = () => {
  emit('save', { title: title.value, content: content.value })
  emit('close')
}

const confirmationIsOpen = ref(false)
const onConfirmClose = () => {
  confirmationIsOpen.value = false
  emit('close')
}
const onClose = () => {
  if (title.value !== props.initialTitle || content.value !== props.initialContent) {
    confirmationIsOpen.value = true
  } else {
    emit('close')
  }
}
</script>

<template>
  <ModalDialog
    :id="EDIT_DOCUMENT_DIALOG_ID"
    :open="open"
    fullscreen
    v-bind="$attrs"
    @close="onClose"
  >
    <div class="flex h-full flex-col">
      <div class="flex h-10 items-center justify-between rounded-t-2xl bg-surface-secondary p-2">
        <DarwinButton
          variant="transparent"
          size="xs"
          @click="onClose"
        >
          <template #leading-icon>
            <IconSprite icon="close" />
          </template>
          <span class="text-text-subtle"> Close </span>
        </DarwinButton>
        <div class="grow text-center text-sm-12px-default text-text-subtle">
          {{ title || initialTitle || 'New document' }}
        </div>
        <DarwinButton
          variant="black"
          size="xs"
          @click="onSave"
        >
          Save document
        </DarwinButton>
      </div>
      <div
        class="flex h-full min-h-0 grow flex-col items-center overflow-y-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      >
        <div class="flex h-full w-[640px] flex-col items-stretch gap-8">
          <AutosizedTextarea
            v-model="title"
            aria-label="Name"
            single-line
            placeholder="New document"
            class="mt-16 rounded-corner-8 bg-background-transparent p-1 text-display-lg-36px-bold text-text placeholder:text-text-subtlest focus:outline-none hover:[&:not(:disabled):not(:focus)]:bg-background-transparent-hovered"
          />

          <MarkdownEditor
            toolbar
            aria-label="Content"
            class="mb-16 h-max grow resize-none"
            :value="content"
            placeholder="Start writing your text here…"
            @save="(text) => (content = text)"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-2"></div>
  </ModalDialog>

  <ConfirmationDialog
    :id="CLOSE_LIBRARY_DOCUMENT_DIALOG_ID"
    :force-z-index="30"
    data-library-child
    title="Close without saving"
    description="Your changes are not saved. Closing this document will erase any unsaved changes. This action is irreversible."
    :open="confirmationIsOpen"
    confirm-text="Close without saving"
    @confirm="onConfirmClose"
    @close.stop="confirmationIsOpen = false"
  ></ConfirmationDialog>
</template>
