import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { tools } from '@/modules/Project/Tools/tool-registry'
import { computed } from 'vue'

/**
 * In Go, there are a large number of models, but users will only see a subset
 * of them. This composable returns the list of field tools that are enabled
 * for the current user.
 */
export const useFieldToolOptions = () => {
  const isWhisperToolEnabled = useFeatureFlags(FeatureFlag.WHISPER_TOOL)
  const isBingSearchEnabled = useFeatureFlags(FeatureFlag.BING_SEARCH_ENABLED)
  const isUrlScrapeEnabled = useFeatureFlags(FeatureFlag.URL_PROPERTY_TOOL)
  const isGoToolEnabled = useFeatureFlags(FeatureFlag.GO_TOOL_ENABLED)

  return computed(() => {
    return Object.values(tools).filter((tool) => {
      if (tool.id === 'whisper' && !isWhisperToolEnabled.value) return false
      if (tool.id === 'bing_search' && !isBingSearchEnabled.value) return false
      if (tool.id === 'url_scrape' && !isUrlScrapeEnabled.value) return false
      if (tool.id === 'go' && !isGoToolEnabled.value) return false

      return true
    })
  })
}
