import { getEntity } from '@/backend/getEntity'
import { getProject } from '@/backend/getProject'
import type { Field } from '@/modules/Project/Fields/types'
import { useRouteTrace, type UrlTraceItem } from '@/modules/Project/routing/useRouteTrace'
import { serializeEntity, type Entity } from '@/modules/Project/useProject'
import { useResolveProjectRoute } from '@/modules/Project/useResolveProjectRoute'
import { useSerializeFieldToText } from '@/modules/Project/useSerializeFieldToText'
import { serializeProject, type Project } from '@/modules/Projects/useProjects'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { objectMapBy } from '@/shared/utils'
import { unique } from '@/shared/utils/array'
import { invariant } from '@/shared/utils/typeAssertions'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { ref, watch } from 'vue'
import type { RouteLocation } from 'vue-router'

export type ProjectBreadcrumb = {
  route: RouteLocation
  label: string
}

export function useProjectBreadcrumbs() {
  const { currentTrace, getSerializedTrace } = useRouteTrace()
  const workspace = useCurrentWorkspace()
  const resolveProjectRoute = useResolveProjectRoute()

  const projects = ref<Record<string, Project>>({})
  const entities = ref<Record<string, Entity>>({})

  const breadcrumbs = ref<ProjectBreadcrumb[]>([])

  watch(
    currentTrace,
    async (trace) => {
      projects.value = await fetchTracedProjects()
      entities.value = await fetchTracedEntities()

      const crumbs: ProjectBreadcrumb[] = []

      for (const part of trace) {
        // For each trace item, we need to create a crumb.
        // However, label of a crumb depends on the previous trace item because
        // the previous one contains information about cell (entity + property) on which
        // the user clicked on order to get to the next crumb item.
        // A `trace` parameter for a crumb consists of all previous trace items.
        const partIndex = trace.indexOf(part)
        const previousPart = partIndex > 0 ? trace.at(partIndex - 1) : undefined
        const routeTrace = getSerializedTrace(partIndex)

        const project = projects.value[part.projectId]
        invariant(project, `Project ${part.projectId} not found`)
        const previousProject = previousPart ? projects.value[previousPart.projectId] : undefined

        const projectCrumbName = getProjectCrumbName({
          traceItem: part,
          project,
          previousProject,
          previousTraceItem: previousPart,
          entities: entities.value,
        })

        const routeParams = {
          parentEntityId: previousPart?.entityId,
          parentProjectId: previousPart?.projectId,
          parentPropertyId: previousPart?.propertyId,
          parentViewId: previousPart?.viewId,
          projectId: part.projectId,
          trace: routeTrace,
          workspaceId: workspace.value.id,
        }

        crumbs.push({
          label: projectCrumbName,
          route: resolveProjectRoute(routeParams),
        })

        const view = project.views?.find((view) => view.id === part.viewId)
        if (view && view.name !== 'main') {
          crumbs.push({
            label: view.name,
            route: resolveProjectRoute({ ...routeParams, viewId: part.viewId }),
          })
        }
      }

      breadcrumbs.value = crumbs
    },
    { immediate: true },
  )

  /**
   * Fetch projects from backend which are referenced in the current trace.
   */
  async function fetchTracedProjects(): Promise<Record<string, Project>> {
    const uniqueProjectIds = unique(currentTrace.value.map((traceItem) => traceItem.projectId))
    const allLoaders = uniqueProjectIds.map((projectId) => {
      return useDataLoader(() => getProject(workspace.value.id, projectId))
    })

    const results = await Promise.all(allLoaders.map((loader) => loader.load()))
    const serializedProjects = results
      .filter((r) => r.ok)
      .map((result) => serializeProject(result.data))

    return objectMapBy(serializedProjects, 'id')
  }

  /**
   * Fetch entities from backend which are referenced in the current trace.
   */
  async function fetchTracedEntities(): Promise<Record<string, Entity>> {
    const allLoaders = currentTrace.value
      .filter((traceItem) => traceItem.entityId)
      .map((traceItem) => {
        return useDataLoader(() => {
          return getEntity(workspace.value.id, traceItem.projectId, traceItem.entityId)
        })
      })

    const results = await Promise.all(allLoaders.map((loader) => loader.load()))
    const serializedEntities = results
      .filter((r) => r.ok)
      .map((result) => serializeEntity(result.data))

    return objectMapBy(serializedEntities, 'id')
  }

  return { breadcrumbs }
}

function getProjectCrumbName(props: {
  entities: Record<string, Entity>
  previousProject?: Project
  previousTraceItem?: UrlTraceItem
  project: Project
  traceItem: UrlTraceItem
}) {
  const { traceItem, project, entities, previousTraceItem, previousProject } = props
  const { propertyId = '', entityId = '' } = previousTraceItem || {}

  invariant(project, `Project ${traceItem.projectId} not found`)

  const defaultProjectName = project.name || project.id

  const entity = entities[entityId]
  if (!entity) {
    return defaultProjectName
  }

  const field = entity.fields.get(propertyId)
  if (!field) {
    return defaultProjectName
  }

  const collectionName = getManualCollectionName(field)
  if (collectionName) {
    return collectionName
  }

  const previousEntity = entities[previousTraceItem?.entityId || '']

  if (!previousProject || !previousEntity) {
    return defaultProjectName
  }

  const parentEntityName = getParentEntityName({
    entity: entity,
    project: previousProject,
    viewId: previousTraceItem?.viewId || '',
  })

  const parentPropertyName = previousProject.properties.find((p) => field.propertyId === p.id)?.name

  let name = parentPropertyName || defaultProjectName
  if (parentEntityName) {
    name += ' - ' + parentEntityName
  }

  return name
}

function getManualCollectionName(field: Field): string {
  if (field.type === 'collection') {
    return field.manualName || ''
  } else if (field.type === 'file_collection') {
    return field.manualFilename || field.manualName || ''
  }

  return ''
}

function getParentEntityName(props: { entity: Entity; project: Project; viewId: string }) {
  const serializeFieldToText = useSerializeFieldToText()

  const { entity, project, viewId } = props
  const propId = project.views?.find((view) => view.id === viewId)?.propertyIds.at(0) || ''
  const field = entity.fields.get(propId)
  return field ? serializeFieldToText(field) : ''
}
