<script setup lang="ts">
import { clamp } from '@vueuse/core'
import { computed } from 'vue'

const props = defineProps<{
  max: number
  value: number
  color: 'blue' | 'critical' | 'warning' | 'success'
}>()

const clampedValue = computed<number>(() => clamp(props.value, 0, props.max))
const step = computed<number>(() => Math.round((clampedValue.value * 10) / props.max))
</script>

<template>
  <svg
    role="meter"
    aria-valuemin="0"
    :aria-valuemax="max"
    :aria-valuenow="clampedValue"
    :aria-valuetext="`${clampedValue} of ${max}`"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84312 1.02202C8.87931 0.502822 9.39675 0.155666 9.89471 0.316493L10.8325 0.619367C11.3305 0.780194 11.5438 1.36336 11.266 1.80455L10.033 3.76328C9.85483 4.04626 9.50579 4.17241 9.18639 4.06925C8.867 3.9661 8.65921 3.66011 8.68243 3.32709L8.84312 1.02202Z"
      :class="[
        step < 1 && 'fill-icon-gray-subtle',
        step >= 1 && color === 'blue' && 'fill-icon-primary',
        step >= 1 && color === 'warning' && 'fill-icon-warning',
        step >= 1 && color === 'critical' && 'fill-icon-critical',
        step >= 1 && color === 'success' && 'fill-icon-success',
      ]"
    />
    <path
      d="M12.706 2.72647C13.0423 2.32757 13.6662 2.34903 13.974 2.77008L14.5535 3.56301C14.8613 3.98406 14.689 4.5805 14.2034 4.77515L12.0476 5.63936C11.7361 5.76422 11.3792 5.66235 11.1818 5.39229C10.9844 5.12222 10.9972 4.75328 11.2129 4.49742L12.706 2.72647Z"
      :class="[
        step < 2 && 'fill-icon-gray-subtle',
        step >= 2 && color === 'blue' && 'fill-icon-primary',
        step >= 2 && color === 'warning' && 'fill-icon-warning',
        step >= 2 && color === 'critical' && 'fill-icon-critical',
        step >= 2 && color === 'success' && 'fill-icon-success',
      ]"
    />
    <path
      d="M15 6.73351C15.5079 6.60728 16 6.98916 16 7.50961V8.48973C16 9.01018 15.5079 9.39205 15 9.26583L12.7448 8.70543C12.419 8.62446 12.1904 8.33348 12.1904 7.99967C12.1904 7.66585 12.419 7.37487 12.7448 7.29391L15 6.73351Z"
      :class="[
        step < 3 && 'fill-icon-gray-subtle',
        step >= 3 && color === 'blue' && 'fill-icon-primary',
        step >= 3 && color === 'warning' && 'fill-icon-warning',
        step >= 3 && color === 'critical' && 'fill-icon-critical',
        step >= 3 && color === 'success' && 'fill-icon-success',
      ]"
    />
    <path
      d="M14.2821 11.2243C14.7677 11.419 14.9399 12.0154 14.6322 12.4365L14.0526 13.2294C13.7448 13.6504 13.1209 13.6719 12.7846 13.273L11.2915 11.502C11.0758 11.2462 11.063 10.8773 11.2604 10.6072C11.4578 10.3371 11.8148 10.2353 12.1262 10.3601L14.2821 11.2243Z"
      :class="[
        step < 4 && 'fill-icon-gray-subtle',
        step >= 4 && color === 'blue' && 'fill-icon-primary',
        step >= 4 && color === 'warning' && 'fill-icon-warning',
        step >= 4 && color === 'critical' && 'fill-icon-critical',
        step >= 4 && color === 'success' && 'fill-icon-success',
      ]"
    />
    <path
      d="M11.3359 14.1951C11.6136 14.6363 11.4003 15.2195 10.9023 15.3803L9.96454 15.6832C9.46657 15.844 8.94913 15.4969 8.91294 14.9777L8.75225 12.6726C8.72904 12.3396 8.93682 12.0336 9.25622 11.9305C9.57561 11.8273 9.92465 11.9534 10.1028 12.2364L11.3359 14.1951Z"
      :class="[
        step < 5 && 'fill-icon-gray-subtle',
        step >= 5 && color === 'blue' && 'fill-icon-primary',
        step >= 5 && color === 'warning' && 'fill-icon-warning',
        step >= 5 && color === 'critical' && 'fill-icon-critical',
        step >= 5 && color === 'success' && 'fill-icon-success',
      ]"
    />
    <path
      d="M7.15673 14.9774C7.12054 15.4966 6.6031 15.8438 6.10513 15.683L5.16735 15.3801C4.66938 15.2193 4.45606 14.6361 4.7338 14.1949L5.96687 12.2362C6.14502 11.9532 6.49406 11.8271 6.81345 11.9302C7.13285 12.0334 7.34063 12.3393 7.31742 12.6724L7.15673 14.9774Z"
      :class="[
        step < 6 && 'fill-icon-gray-subtle',
        step >= 6 && color === 'blue' && 'fill-icon-primary',
        step >= 6 && color === 'warning' && 'fill-icon-warning',
        step >= 6 && color === 'critical' && 'fill-icon-critical',
        step >= 6 && color === 'success' && 'fill-icon-success',
      ]"
    />

    <path
      d="M3.29451 13.2731C2.95821 13.672 2.33431 13.6506 2.02655 13.2295L1.44696 12.4366C1.1392 12.0155 1.31147 11.4191 1.79706 11.2244L3.9529 10.3602C4.26436 10.2354 4.62134 10.3372 4.81874 10.6073C5.01613 10.8774 5.00329 11.2463 4.78758 11.5022L3.29451 13.2731Z"
      :class="[
        step < 7 && 'fill-icon-gray-subtle',
        step >= 7 && color === 'blue' && 'fill-icon-primary',
        step >= 7 && color === 'warning' && 'fill-icon-warning',
        step >= 7 && color === 'critical' && 'fill-icon-critical',
        step >= 7 && color === 'success' && 'fill-icon-success',
      ]"
    />
    <path
      d="M1.00002 9.26595C0.492057 9.39218 6.59882e-08 9.0103 4.31013e-08 8.48985L0 7.50973C-2.28869e-08 6.98928 0.492058 6.6074 1.00002 6.73363L3.25517 7.29403C3.58098 7.37499 3.80954 7.66597 3.80954 7.99979C3.80954 8.33361 3.58098 8.62459 3.25517 8.70555L1.00002 9.26595Z"
      :class="[
        step < 8 && 'fill-icon-gray-subtle',
        step >= 8 && color === 'blue' && 'fill-icon-primary',
        step >= 8 && color === 'warning' && 'fill-icon-warning',
        step >= 8 && color === 'critical' && 'fill-icon-critical',
        step >= 8 && color === 'success' && 'fill-icon-success',
      ]"
    />
    <path
      d="M1.71796 4.77528C1.23237 4.58062 1.0601 3.98419 1.36786 3.56313L1.94744 2.7702C2.2552 2.34915 2.87911 2.32769 3.21541 2.72659L4.70848 4.49755C4.92419 4.7534 4.93703 5.12234 4.73963 5.39241C4.54224 5.66247 4.18526 5.76434 3.8738 5.63949L1.71796 4.77528Z"
      :class="[
        step < 9 && 'fill-icon-gray-subtle',
        step >= 9 && color === 'blue' && 'fill-icon-primary',
        step >= 9 && color === 'warning' && 'fill-icon-warning',
        step >= 9 && color === 'critical' && 'fill-icon-critical',
        step >= 9 && color === 'success' && 'fill-icon-success',
      ]"
    />

    <path
      :class="[
        step < 10 && 'fill-icon-gray-subtle',
        step >= 10 && color === 'blue' && 'fill-icon-primary',
        step >= 10 && color === 'warning' && 'fill-icon-warning',
        step >= 10 && color === 'critical' && 'fill-icon-critical',
        step >= 10 && color === 'success' && 'fill-icon-success',
      ]"
      d="M4.66446 1.80443C4.38672 1.36324 4.60005 0.780072 5.09801 0.619245L6.0358 0.316371C6.53376 0.155544 7.0512 0.502701 7.08739 1.0219L7.24808 3.32697C7.27129 3.65999 7.06351 3.96598 6.74412 4.06913C6.42472 4.17229 6.07568 4.04613 5.89754 3.76315L4.66446 1.80443Z"
    />
  </svg>
</template>
