<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import { computed } from 'vue'

const props = defineProps<{
  isWaitingForConfiguration: boolean
  status: Field['status']
  type?: Field['type']
}>()

const textContent = computed(() => {
  if (props.isWaitingForConfiguration) {
    return 'Waiting for configuration...'
  }

  if (props.status === 'computing') {
    return 'Calculating...'
  }

  return 'Waiting for configuration...'
})
</script>

<template>
  <div class="flex size-full items-center justify-center">
    <p
      class="inline-flex grow-0 resize-none items-center justify-center bg-background-transparent p-2 text-md-13px-light text-text-subtlest placeholder:text-opacity-40 focus:outline-none"
      :class="
        status === 'computing' &&
        'animate-gradient bg-gradient-to-r from-background-gray-sunken via-background-black to-background-gray-sunken bg-3x bg-clip-text'
      "
    >
      {{ textContent }}
    </p>
  </div>
</template>
