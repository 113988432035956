import { useAnalytics } from '@/sharedComposables/useAnalytics'
import { useAuth0, type AuthorizationParams } from '@auth0/auth0-vue'
import { watchEffect } from 'vue'
import { RouterView, createRouter, createWebHistory } from 'vue-router'

import { getUser } from '@/backend/getUser'

import { serializeUser, useUser } from '@/modules/IdentityAndAccess/useUser'
import EmptyEntityView from '@/modules/Project/EmptyEntityView.vue'
import EntityView from '@/modules/Project/EntityView.vue'
import WorkspaceProject from '@/modules/Project/ProjectView.vue'
import WorkflowView from '@/modules/Project/WorkflowView.vue'
import WorkspaceDetails from '@/modules/Workspaces/WorkspaceDetails.vue'
import WorkspaceList from '@/modules/Workspaces/WorkspaceList.vue'
import WorkspacePageHeader from '@/modules/Workspaces/WorkspacePageHeader.vue'

import ErrorPage from '@/modules/App/ErrorPage.vue'
import LogInWithWorkEmailPage from '@/modules/App/LogInWithWorkEmailPage.vue'
import VerifyEmailPage from '@/modules/App/VerifyEmailPage.vue'
import LogoutPage from '@/modules/IdentityAndAccess/LogoutPage.vue'
import { useUtmParams } from '@/sharedComposables/useUtmParams'
import AgenticChat from './modules/AgenticChat/AgenticChat.vue'
import AgenticChatPageHeader from './modules/AgenticChat/AgenticChatPageHeader.vue'
import AgenticSpace from './modules/AgenticChat/AgenticSpace.vue'
import InitialQuestion from './modules/AgenticChat/InitialQuestion.vue'
import StripeCheckoutRedirect from './modules/Billing/StripeCheckoutRedirect.vue'
import { stripePortalRedirect } from './modules/Billing/stripePortalRedirect'
import AcceptInvitation from './modules/IdentityAndAccess/AcceptInvitation.vue'
import ProjectSidebar from './modules/Projects/ProjectSidebar.vue'
import KnowledgeHubCrumbs from './modules/Workspaces/KnowledgeHub/KnowledgeHubCrumbs.vue'
import KnowledgeHubHome from './modules/Workspaces/KnowledgeHub/KnowledgeHubHome.vue'
import WorkspaceHomepage from './modules/Workspaces/WorkspaceHomepage.vue'
import WorkspaceHomepageCrumbs from './modules/Workspaces/WorkspaceHomepageCrumbs.vue'
import WorkspaceSettingsAiModels from './modules/WorkspaceSettings/WorkspaceSettingsAiModels.vue'
import WorkspaceSettingsApiKeys from './modules/WorkspaceSettings/WorkspaceSettingsApiKeys.vue'
import WorkspaceSettingsBilling from './modules/WorkspaceSettings/WorkspaceSettingsBilling.vue'
import WorkspaceSettingsPlans from './modules/WorkspaceSettings/WorkspaceSettingsPlans.vue'
import WorkspaceSettingsProfile from './modules/WorkspaceSettings/WorkspaceSettingsProfile.vue'
import WorkspaceSettingsSidebar from './modules/WorkspaceSettings/WorkspaceSettingsSidebar.vue'
import WorkspaceSettingsUsers from './modules/WorkspaceSettings/WorkspaceSettingsUsers.vue'
import WorkspaceSettingsWorkspace from './modules/WorkspaceSettings/WorkspaceSettingsWorkspace.vue'

const shouldBlockMobileUse = { value: true }
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: 'AcceptInvitation',
      path: '/accept-invitation',
      component: AcceptInvitation,
      props: (route) => ({
        workspaceId: route.query.workspace_id,
        projectId: route.query.project_id,
        inviteToken: route.query.token,
      }),
    },
    // Visited when the user is redirect to Go after a Stripe checkout
    {
      name: 'StripeCheckoutRedirect',
      path: '/plan_checkout',
      component: StripeCheckoutRedirect,
      props: (route) => ({ status: route.query.status, workspaceId: route.query.workspace_id }),
    },
    // Visited when the user is redirected to Go after updating their billing details in the Stripe portal
    {
      name: 'StripePortalRedirect',
      path: '/plan_management',
      redirect: stripePortalRedirect,
    },
    {
      name: 'NotAvailableOnMobile',
      path: '/not-available-on-mobile',
      component: ErrorPage,
      props: {
        title: "Go isn't available on Mobile",
        message: 'Please use a desktop or laptop to access Go.',
        showButton: false,
      },
    },
    {
      name: 'LogInWithWorkEmail',
      path: '/log-in-with-work-email',
      component: LogInWithWorkEmailPage,
    },
    {
      name: 'VerifyEmail',
      path: '/verify-email',
      component: VerifyEmailPage,
    },
    {
      name: 'ErrorPage',
      path: '/error',
      component: ErrorPage,
      beforeEnter: (to, from) => {
        if (!from.name && !to.redirectedFrom?.name) {
          // Prevent malicious actors injecting custom errors into the app, only
          // continue with the navigation if the error was triggered from within
          // the app.
          return {
            name: 'ErrorPage',
            query: {},
          }
        }
      },
      props: (route) => ({ title: route.query.title, message: route.query.message }),
    },
    {
      name: 'Home',
      path: '/',
      components: {
        default: WorkspaceList,
        BreadCrumbs: RouterView,
      },
      props: {
        BreadCrumbs: () => ({ name: 'BreadCrumbs' }),
      },
      children: [
        {
          name: 'Workspace',
          path: ':workspaceId',
          components: { default: WorkspaceDetails, BreadCrumbs: RouterView },
          redirect: { name: 'WorkspaceProjects' },
          props: {
            default: (route) => ({ workspaceId: route.params.workspaceId }),
            Sidebar: () => ({ name: 'Sidebar' }),
            BreadCrumbs: () => ({ name: 'BreadCrumbs' }),
          },
          children: [
            {
              path: 'chat',
              name: 'AgenticChat',
              components: {
                default: AgenticChat,
                Sidebar: ProjectSidebar,
                BreadCrumbs: AgenticChatPageHeader,
              },
              props: {
                default: true,
                Sidebar: (route) => ({
                  name: 'Sidebar',
                  workspaceId: route.params.workspaceId,
                  open: true,
                }),
                BreadCrumbs: (route) => ({
                  name: 'BreadCrumbs',
                  workspaceId: route.params.workspaceId,
                }),
              },
              children: [
                {
                  name: 'AgenticChatInitialQuestion',
                  path: '',
                  component: InitialQuestion,
                },
                {
                  name: 'AgenticChatSpace',
                  path: ':spaceId',
                  component: AgenticSpace,
                  props: true,
                },
              ],
            },
            // /projects - Homepage
            {
              path: 'projects',
              name: 'WorkspaceProjects',
              props: {
                default: true,
                Sidebar: (route) => ({
                  name: 'Sidebar',
                  workspaceId: route.params.workspaceId,
                  open: true,
                }),
                BreadCrumbs: (route) => ({
                  name: 'BreadCrumbs',
                  workspaceId: route.params.workspaceId,
                }),
              },
              components: {
                default: WorkspaceHomepage,
                Sidebar: ProjectSidebar,
                BreadCrumbs: WorkspaceHomepageCrumbs,
              },
            },
            // /projects/:projectId - Project
            {
              name: 'WorkspaceProject',
              path: 'projects/:projectId',
              redirect: {
                name: 'WorkspaceProjectTable',
              },
              children: [
                // /projects/:projectId - Project Table
                {
                  name: 'WorkspaceProjectTable',
                  path: '',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      projectId: route.params.projectId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/views/:viewId - Project View
                {
                  name: 'WorkspaceProjectTableView',
                  path: 'views/:viewId',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      projectId: route.params.projectId,
                      viewId: route.params.viewId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/entities - Project Entities (empty state)
                {
                  name: 'WorkspaceProjectEntityViewEmpty',
                  path: 'entities',
                  props: {
                    default: (route) => ({
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EmptyEntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/entities/:entityId - Entity View
                {
                  name: 'WorkspaceProjectEntityView',
                  path: 'entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/views/:viewId/entities/:entityId - Entity View (specific project view)
                {
                  name: 'WorkspaceProjectEntitySubView',
                  path: 'views/:viewId/entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                      viewId: route.params.viewId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/workflow - Workflow View
                {
                  name: 'WorkflowView',
                  path: 'workflow',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      projectId: route.params.projectId,
                    }),
                    BreadCrumbs: true,
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                  },
                  components: {
                    default: WorkflowView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
              ],
            },
            // /projects/:projectId/collections/:parentProjectId - SubProject
            {
              name: 'WorkspaceSubProject',
              path: 'projects/:parentProjectId/collections/:projectId',
              redirect: {
                name: 'WorkspaceSubProjectTable',
              },
              children: [
                // /projects/:projectId/collections/:parentProjectId - SubProject Table
                {
                  name: 'WorkspaceSubProjectTable',
                  path: '',
                  props: {
                    default: (route) => ({
                      workspaceId: route.params.workspaceId,
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      parentEntityId: route.query.parentEntityId,
                      parentViewId: route.query.parentViewId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/entities - SubProject Entities (empty state)
                {
                  name: 'WorkspaceSubProjectEntityViewEmpty',
                  path: 'entities',
                  props: {
                    default: (route) => ({
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EmptyEntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/entities/:entityId - SubProject Entity View
                {
                  name: 'WorkspaceSubProjectEntityView',
                  path: 'entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/views/:viewId - SubProject View
                {
                  name: 'WorkspaceSubProjectTableView',
                  path: 'views/:viewId',
                  props: {
                    default: (route) => ({
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      viewId: route.params.viewId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: WorkspaceProject,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
                // /projects/:projectId/collections/:parentProjectId/views/:viewId/entities/:entityId - SubProject Entity View (specific view)
                {
                  name: 'WorkspaceSubProjectEntitySubView',
                  path: 'views/:viewId/entities/:entityId',
                  props: {
                    default: (route) => ({
                      entityId: route.params.entityId,
                      parentProjectId: route.params.parentProjectId,
                      projectId: route.params.projectId,
                      viewId: route.params.viewId,
                      workspaceId: route.params.workspaceId,
                      parentEntityId: route.query.parentEntityId,
                    }),
                    Sidebar: (route) => ({
                      workspaceId: route.params.workspaceId,
                      open: false,
                    }),
                    BreadCrumbs: true,
                  },
                  components: {
                    default: EntityView,
                    Sidebar: ProjectSidebar,
                    BreadCrumbs: WorkspacePageHeader,
                  },
                },
              ],
            },
            // /settings - Workspace Settings
            {
              path: 'settings',
              name: 'WorkspaceSettings',
              redirect: { name: 'WorkspaceSettingsProfile' },
              props: {
                default: true,
                Sidebar: {
                  open: true,
                },
                BreadCrumbs: false,
              },
              children: [
                {
                  path: 'profile',
                  name: 'WorkspaceSettingsProfile',
                  components: {
                    default: WorkspaceSettingsProfile,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'general',
                  redirect: { name: 'WorkspaceSettingsWorkspace' },
                },
                {
                  path: 'workspace',
                  name: 'WorkspaceSettingsWorkspace',
                  components: {
                    default: WorkspaceSettingsWorkspace,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'users',
                  name: 'WorkspaceSettingsUsers',
                  components: {
                    default: WorkspaceSettingsUsers,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'plans',
                  name: 'WorkspaceSettingsPlans',
                  components: {
                    default: WorkspaceSettingsPlans,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'billing',
                  name: 'WorkspaceSettingsBilling',
                  components: {
                    default: WorkspaceSettingsBilling,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
                {
                  path: 'api-keys',
                  name: 'WorkspaceSettingsApiKeys',
                  components: {
                    default: WorkspaceSettingsApiKeys,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                },
                {
                  path: 'ai-models',
                  name: 'WorkspaceSettingsAiModels',
                  components: {
                    default: WorkspaceSettingsAiModels,
                    Sidebar: WorkspaceSettingsSidebar,
                  },
                  props: true,
                },
              ],
            },
            // /knowledge-hub - Knowledge Hub
            {
              path: 'knowledge-hub',
              name: 'KnowledgeHub',
              props: {
                default: true,
                Sidebar: (route) => ({
                  name: 'Sidebar',
                  workspaceId: route.params.workspaceId,
                  open: true,
                }),
                BreadCrumbs: (route) => ({
                  name: 'BreadCrumbs',
                  workspaceId: route.params.workspaceId,
                }),
              },
              components: {
                default: KnowledgeHubHome,
                Sidebar: ProjectSidebar,
                BreadCrumbs: KnowledgeHubCrumbs,
              },
            },
          ],
        },
      ],
    },
    {
      name: 'logout',
      path: '/logout',
      component: LogoutPage,
    },
  ],
})

function watchEffectOnceAsync<T>(watcher: () => T) {
  return new Promise<void>((resolve) => {
    const stopWatch = watchEffect(() => {
      if (watcher()) {
        resolve()
        stopWatch()
      }
    })
  })
}

const isMobile = () =>
  ['Android', 'iPad', 'iPhone'].some((userAgent) => navigator.userAgent.includes(userAgent))

const INVITATION_STORAGE_KEY = 'go:invitation'

const { identifyUser } = useAnalytics()

const { setUtmParams } = useUtmParams()
router.beforeEach(async (to) => {
  const { isLoading, isAuthenticated, loginWithRedirect, user: userInfo, error } = useAuth0()

  setUtmParams(to)

  // First things first, let's check if the user is from a mobile device
  if (shouldBlockMobileUse.value && isMobile()) {
    if (to.name === 'NotAvailableOnMobile') {
      return
    }
    return {
      name: 'NotAvailableOnMobile',
    }
  }
  // Handle errors from the Auth0 login (currently we have only one case - user needs to verify email)
  if (error.value?.error_description == 'log_in_with_work_email') {
    // If the user is already going to the log-in-with-work-email page then let them continue
    if (to.name === 'LogInWithWorkEmail') {
      return
    }
    // Otherwise redirect to the Verify Email page
    return {
      name: 'LogInWithWorkEmail',
    }
  } else if (error.value) {
    // If the user is already going to the verify-email page then let them continue
    if (to.name === 'VerifyEmail') {
      return
    }
    // Otherwise redirect to the Verify Email page
    return {
      name: 'VerifyEmail',
    }
  }

  const invitationFromStorage = localStorage.getItem(INVITATION_STORAGE_KEY)
  if (invitationFromStorage) {
    const invitation = JSON.parse(invitationFromStorage)
    localStorage.removeItem(INVITATION_STORAGE_KEY)
    return {
      name: 'AcceptInvitation',
      query: invitation,
    }
  }

  const userStore = useUser()

  const getAndSetUser = async () => {
    const response = await getUser()

    if (!response.ok && response.error?.code === 'not_found') {
      if (!userInfo?.value?.email) {
        throw new Error('No email associated with user')
      }
    }

    if (response.ok) {
      userStore.setUser(serializeUser(response.data))
      identifyUser()
    }
  }

  const login = async () => {
    if (isAuthenticated.value) {
      if (!userStore?.user) {
        await getAndSetUser()
      }

      return
    }

    const authorizationParams: AuthorizationParams = { scope: 'profile email' }

    if (to.query.screen_hint === 'signup') authorizationParams.screen_hint = 'signup'

    // The user is not authenticated, so redirect them to Auth0 to log in.
    // But before doing so,
    if (to.name === 'AcceptInvitation') {
      localStorage.setItem(INVITATION_STORAGE_KEY, JSON.stringify(to.query))

      const { workspace_id, project_id, token } = to.query
      authorizationParams.screen_hint = 'signup'
      authorizationParams.scope = `profile email invitation:${workspace_id}:${project_id ?? ''}:${token}`
    }

    await loginWithRedirect({
      appState: { target: to.fullPath },
      authorizationParams,
    })

    if (isAuthenticated.value) {
      await getAndSetUser()
    }

    return
  }

  if (!isLoading.value) {
    return login()
  }

  // if we are loading, wait for the loading to finish before trying to log in, then kill the watcher
  await watchEffectOnceAsync(() => !isLoading.value)

  return login()
})
