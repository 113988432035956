<script setup lang="ts">
import { useResolveProjectRoute } from '@/modules/Project/useResolveProjectRoute'
import { type IntegrationConnection } from '@/modules/Workspaces/KnowledgeHub/integration-types'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { useProfileImage } from '@/modules/WorkspaceSettings/useProfileImage'
import type { WorkspaceMember } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { pluralize } from '@/shared/utils/string'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import IconButton from '@/uiKit/IconButton.vue'
import type { IconName } from '@/uiKit/IconName'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import { useTimeAgo } from '@vueuse/core'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

const props = defineProps<{
  connection: {
    name: string
    user?: WorkspaceMember
    icon?: IconName
  } & IntegrationConnection
}>()

defineEmits<{
  remove: []
}>()

const resolveProjectRoute = useResolveProjectRoute()
const workspace = useCurrentWorkspace()

const projectRoute = computed(() => {
  if (props.connection.status !== 'started') {
    return ''
  }

  return resolveProjectRoute({
    projectId: props.connection.project_id,
    workspaceId: workspace.value.id,
  })
})

const startedConnection = computed(() => {
  if (props.connection.status === 'started') {
    return props.connection
  }

  return undefined
})

const profileImage = useProfileImage(props.connection.user_id)

const timeAgo = computed<string>(() => {
  const lastSync = startedConnection.value?.last_synced_at
  if (!lastSync) return ''

  const oneMinute = 1000 * 60
  return useTimeAgo(lastSync, { updateInterval: oneMinute }).value
})

/**
 * Label for the number of entities synced.
 */
const entityCount = computed<string>(() => {
  if (!startedConnection.value) return ''
  const count = startedConnection.value.items_synced || 0
  const pluralized = pluralize(count, 'entity', 'entities')
  return count.toLocaleString() + ' ' + pluralized
})
</script>

<template>
  <RouterLink
    class="group grid h-12 grid-cols-[64px_repeat(5,1fr)] items-center gap-3"
    :class="{ 'cursor-default': !projectRoute }"
    :to="projectRoute"
  >
    <div
      class="pointer-events-none absolute -inset-2 rounded-corner-10 bg-background-transparent-hovered opacity-0 transition group-hover:opacity-100"
    />

    <div
      v-if="connection.icon"
      class="grid h-full place-items-center rounded-corner-8 bg-background-gray-subtlest"
    >
      <IconSprite
        :icon="connection.icon"
        size="xxxl"
      />
    </div>

    <div>
      <p class="text-md-13px-bold">{{ connection.name }}</p>
      <p class="text-sm-12px-light text-text-subtle">
        {{ connection.user?.email }}
      </p>
    </div>

    <div class="flex items-center gap-2">
      <div class="size-1.5 rounded-full bg-icon-success" />

      <p
        v-if="startedConnection"
        class="text-sm-12px-light text-text-subtle"
      >
        <template v-if="startedConnection.items_synced === null">Syncing…</template>
        <template v-else>
          {{ entityCount }}
        </template>
      </p>
    </div>

    <p class="text-sm-12px-light text-text-subtle">
      <template v-if="timeAgo"> Last synced {{ timeAgo }} </template>
    </p>

    <p class="text-sm-12px-light text-text-subtle">
      <span class="flex items-center gap-1.5">
        <AvatarIcon
          size="xs"
          :full-text="connection.user?.fullName"
          shape="circle"
          :url="profileImage"
          class="inline-block"
        />
        {{ connection.user?.fullName }}
      </span>
    </p>

    <div class="flex items-center justify-between">
      <div class="flex items-center justify-between gap-1.5 text-text-subtle">
        <IconSprite icon="annotators" />
        <p class="text-sm-12px-light">Shared</p>
      </div>

      <Menu.Root
        v-slot="{ getTriggerProps }"
        close-on-select
      >
        <IconButton
          aria-label="Open integration menu"
          icon="more-dots"
          variant="transparent"
          size="md"
          v-bind="getTriggerProps()"
          @click.prevent
        />
        <Menu.Content>
          <Menu.Item
            icon="trash"
            label="Delete"
            critical
            class="min-w-28 text-left"
            @select="$emit('remove')"
          />
        </Menu.Content>
      </Menu.Root>
    </div>
  </RouterLink>
</template>
