import type { Property, PropertyInput } from '@/modules/Project/Properties/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { computed } from 'vue'
import { useLibraryStore, type LibraryItem } from '../Library/libraryStore'
import { countFilters } from './Filters/utils'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { useParentProject } from './useParentProject'
import { useProject } from './useProject'
import { useProperty } from './useProperty'
import { useSubProject } from './useSubProject'

export type ModelInputItem = {
  id: string
  data:
    | (Property & {
        group: 'Properties'
        filters?: PropertyInput['filters']
        filterCount: number
        viaPropertyId?: string
      })
    | (LibraryItem & { group: 'Library'; propertyId: string })
}

/**
 * There is a backend limitation that properties with subprojects cannot be used as inputs
 * to tools. Instead the user must select a property from the subproject to use as the
 * input.
 * @see https://linear.app/v7labs/issue/GO-2248/bug-collection-subproperties-not-available-until-refresh
 * @see https://linear.app/v7labs/issue/GO-3099/bug-users-can-select-file-collection-properties-as-inputs
 */
const propertyCanBeInput = (property: Pick<Property, 'type'>): boolean =>
  property.type !== 'file_collection' && property.type !== 'pdf' && property.type !== 'collection'

/**
 * AI model properties take other properties as inputs. This composable provides:
 * 1. The list of all properties and library items that can be used as inputs on the current view
 * 2. The list of properties and library items that are currently selected as inputs
 */
export const useModelInputs = () => {
  const propertyStore = useProperty()
  const projectStore = useProject()
  const subProjectStore = useSubProject()
  const parentProjectStore = useParentProject()

  const libraryStore = useLibraryStore()

  const libraryInputs = computed<ModelInputItem[]>(() =>
    libraryStore.libraryItems.map<ModelInputItem>((item) => {
      assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')
      const itemType = item.type
      const propertyId =
        itemType === 'file'
          ? libraryStore.library.fileProperty.id
          : libraryStore.library.textProperty.id

      return {
        id: item.id,
        data: { ...item, group: 'Library', propertyId },
      }
    }),
  )

  const relatedProjectStore = useRelatedProjectsStore()
  const propertyInputs = computed<ModelInputItem[]>(() => {
    const propertyIsIncluded = (p: Property): boolean =>
      propertyStore.editedInputs.some(({ propertyId }) => p.id === propertyId)

    // We should only show properties that are in the main view or the active view
    const propertiesToShow = propertyStore.otherProperties.filter((p) => {
      const isInMainView = projectStore.mainView?.view.propertyIds?.includes(p.id)
      const isInActiveView = projectStore.activeView?.view.propertyIds?.includes(p.id)
      const isAlreadySelectedAsInput = propertyIsIncluded(p)
      return isInMainView || isInActiveView || isAlreadySelectedAsInput
    })

    const inputs = [
      ...propertiesToShow,
      ...subProjectStore.properties,
      ...parentProjectStore.properties,
    ]
      .toSorted((a, b) => (propertyIsIncluded(a) && !propertyIsIncluded(b) ? -1 : 1))
      .reduce<ModelInputItem[]>((acc, p) => {
        const isChildOfSelected =
          p.parentPropertyId && p.parentPropertyId === propertyStore.savedProperty?.id

        if (!propertyCanBeInput(p) || isChildOfSelected) {
          return acc
        }

        if (p.id === propertyStore.savedProperty?.id) return acc

        const matchingInput = propertyStore.editedInputs.find((e) => e.propertyId === p.id)

        const relatedProperties =
          p.type === 'reference' ? relatedProjectStore.getPropertiesForReference(p.id) : []

        const asInputItem: ModelInputItem = {
          id: p.id,
          data: {
            ...p,
            group: 'Properties',
            filters: matchingInput?.filters,
            filterCount: countFilters(matchingInput?.filters),
          },
        }

        return [
          ...acc,
          asInputItem,
          ...relatedProperties.map<ModelInputItem>((rp) => ({
            id: rp.id,
            data: {
              ...rp,
              group: 'Properties',
              filterCount: 0,
              viaPropertyId: p.id,
            },
          })),
        ]
      }, [])

    return inputs
  })

  const inputIdOptions = computed(() => [...propertyInputs.value, ...libraryInputs.value])

  const hasLibraryInputs = computed(() => libraryInputs.value.length > 0)

  const selectedLibraryItems = computed(() =>
    libraryInputs.value.filter((i) =>
      propertyStore.editedInputs.some((e) => e.entityId === i.data.id),
    ),
  )

  const selectedProperties = computed(() =>
    propertyInputs.value.filter((i) =>
      propertyStore.editedInputs.some((e) => e.propertyId === i.data.id),
    ),
  )

  const selectedInputIdOptions = computed(() => [
    ...selectedLibraryItems.value,
    ...selectedProperties.value,
  ])

  return {
    inputIdOptions,
    selectedInputIdOptions,
    hasLibraryInputs,
  }
}
