import { watchEffect, type Ref } from 'vue'

/**
 * This composable should be called from every table cell. It will scroll the cell
 * completely into view when it becomes selected.
 * @param element - The element that should be scrolled into view. Usually this will be
 *                  some floating element inside the cell.
 * @param isSelected - A boolean ref that indicates whether the cell is selected or not.
 */
export const useScrollOnSelected = (
  element: Ref<HTMLElement | null | undefined>,
  isSelected: Ref<boolean>,
) => {
  watchEffect(
    () => {
      if (!isSelected.value || !element.value) {
        return
      }
      if (element.value instanceof HTMLElement) {
        element.value.scrollIntoView({ inline: 'nearest', behavior: 'smooth', block: 'nearest' })
      }
    },
    {
      flush: 'post',
    },
  )
}
