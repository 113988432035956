import type { Node } from 'prosemirror-model'

/**
 * Finds the node in the given ProseMirror document that satisfies
 * the given predicate.
 */
export const findNode = (node: Node, predicate: (n: Node) => boolean) => {
  let found: { node: Node; pos: number } | undefined
  node.descendants((node, pos) => {
    if (predicate(node)) {
      found = { node, pos }
    }
    if (found) return false
  })
  return found
}
