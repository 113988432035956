import type { Field } from '@/modules/Project/Fields/types'
import { exhaustiveGuard, invariant } from '@/shared/utils/typeAssertions'
import type { Entity } from '../useProject'
import { matchSelectValue } from './fieldMaybePassesFilters'
import type { FieldNameFilter, FilenameFilter, Filter, MatcherName } from './types'
import { getCaseInsensitiveValue } from './utils'

/** Returns a boolean value to indicate whether an entity passes the given filter. */
export const entityPassesFilters = (entity: Entity, filter: Filter | undefined): boolean => {
  if (!filter) {
    return true
  }

  if ('matcher' in filter) {
    if (filter.subject === 'active_view_id') {
      return filterByActiveView(entity, filter.matcher.name, filter.matcher.values)
    }

    if (
      filter.subject === 'select_option_value' ||
      filter.subject === 'field_select_option_value'
    ) {
      return filterBySelectOptionValue(entity, {
        matcherName: filter.matcher.name,
        propertyId: filter.matcher.property_id,
        values: filter.matcher.values,
        caseSensitive:
          filter.matcher.name === 'property_all_of' ? false : filter.matcher.case_sensitive,
      })
    }

    if (filter.subject === 'parent_entity_id') {
      return filterByParentId(entity, {
        matcherName: filter.matcher.name,
        values: filter.matcher.values,
      })
    }

    if (filter.subject === 'status' || filter.subject === 'field_status') {
      return filterByStatus(entity, filter.matcher.property_id, filter.matcher.values)
    }

    if (filter.subject === 'id' || filter.subject === 'entity_id') {
      return filterById(entity, filter.matcher.name, filter.matcher.values)
    }

    if (filter.subject === 'text_value' || filter.subject === 'field_text_value') {
      const field = entity.fields.get(filter.matcher.property_id)
      if (!field) {
        return false
      }
      return filterByTextValue(field, {
        matcherName: filter.matcher.name,
        values: filter.matcher.values,
        caseSensitive: filter.matcher.case_sensitive,
      })
    }

    if (filter.subject === 'field_file_name') {
      const field = entity.fields.get(filter.matcher.property_id)
      invariant(field && 'manualFilename' in field, 'Expected field to be a file field')

      return filterbyFilename(field, filter.matcher)
    }

    if (filter.subject === 'field_metadata_field_name') {
      const field = entity.fields.get(filter.matcher.property_id)
      invariant(field && 'manualName' in field, 'Field must have a manualName property')

      return filterByFieldName(field, filter.matcher)
    }

    if (filter.subject === 'entity_status') {
      throw new Error('TODO: implement entity_status filter')
    }

    if (filter.subject === 'field_value_semantics') {
      throw new Error('TODO: implement semantic filter')
    }

    // If TS is complaining in this function, you might need to handle another subject here.
    // Don't just return false, as that would mask the error and not apply the filter.
    exhaustiveGuard(filter, 'Unhandled filter subject')
  }

  if (filter.conjunction === 'or') {
    return filter.filters.some((f) => entityPassesFilters(entity, f))
  }

  return filter.filters.every((f) => entityPassesFilters(entity, f))
}

const filterByActiveView = (
  entity: Entity,
  matcher: Extract<MatcherName, 'any_of' | 'none_of'>,
  viewIds: string[],
): boolean => {
  if (!entity.activeViewIds) {
    return false
  }

  if (matcher === 'any_of') {
    return entity.activeViewIds.some((id) => viewIds.includes(id)) ?? false
  }

  return !entity.activeViewIds.some((id) => viewIds.includes(id))
}

const filterBySelectOptionValue = (
  entity: Entity,
  {
    matcherName,
    propertyId,
    values,
    caseSensitive = true,
  }: {
    matcherName: Extract<MatcherName, 'property_any_of' | 'property_none_of' | 'property_all_of'>
    propertyId: string
    values: string[]
    caseSensitive?: boolean
  },
): boolean => {
  const field = entity.fields.get(propertyId)
  if (!field) {
    return false
  }

  return matchSelectValue(field, { matcherName, values, caseSensitive })
}

const filterByParentId = (
  entity: Entity,
  {
    matcherName,
    values,
  }: { matcherName: Extract<MatcherName, 'any_of' | 'none_of'>; values: string[] },
): boolean => {
  if (matcherName === 'any_of') {
    return !!entity.parentEntityId && values.includes(entity.parentEntityId)
  }

  return !entity.parentEntityId || !values.includes(entity.parentEntityId)
}

const filterByStatus = (entity: Entity, propertyId: string, values: string[]) => {
  const field = entity.fields.get(propertyId)
  if (!field) {
    return false
  }

  return values.includes(field.status)
}

const filterById = (
  entity: Entity,
  matcherName: Extract<MatcherName, 'any_of' | 'none_of'>,
  values: string[],
) => {
  if (matcherName === 'any_of') {
    return values.includes(entity.id)
  }

  return !values.includes(entity.id)
}

export const filterByTextValue = (
  field: Field,
  {
    caseSensitive = true,
    matcherName,
    values: _values,
  }: {
    matcherName:
      | 'property_any_of'
      | 'property_none_of'
      | 'property_contains_any_of'
      | 'property_contains_none_of'
    values: string[]
    caseSensitive?: boolean
  },
): boolean => {
  if (field.type !== 'text') {
    return false
  }

  const manualValue = caseSensitive ? field.manualValue : getCaseInsensitiveValue(field.manualValue)
  const toolValue = caseSensitive ? field.toolValue : getCaseInsensitiveValue(field.toolValue)
  const values = _values.map((v) => (caseSensitive ? v : v.toLowerCase()))

  if (matcherName === 'property_any_of') {
    return values.some((v) => manualValue === v || toolValue === v)
  }

  if (matcherName === 'property_none_of') {
    if (caseSensitive) {
      return !values.some((v) => manualValue === v || toolValue === v)
    }
  }

  if (matcherName === 'property_contains_any_of') {
    return values.some((v) => manualValue?.includes(v) || toolValue?.includes(v))
  }

  if (matcherName === 'property_contains_none_of') {
    return !values.some((v) => manualValue?.includes(v) || toolValue?.includes(v))
  }

  return false
}

/**
 * Filters a file-like field by filename.
 * @param field
 * @param matcher
 * @returns
 */
export function filterbyFilename(
  field: Extract<Field, { manualFilename: Field<'file'>['manualFilename'] }>,
  matcher: FilenameFilter['matcher'],
): boolean {
  const manualFilename = matcher.case_sensitive
    ? field.manualFilename
    : field.manualFilename?.toLowerCase()
  const toolFilename = matcher.case_sensitive
    ? field.toolFilename
    : field.toolFilename?.toLowerCase()
  const normalizedValues = matcher.values.map((v) => (matcher.case_sensitive ? v : v.toLowerCase()))

  if (matcher.name === 'property_any_of') {
    if (manualFilename) {
      return normalizedValues.some((v) => manualFilename.includes(v))
    }

    return normalizedValues.some((v) => toolFilename?.includes(v))
  }

  if (matcher.name === 'property_none_of') {
    if (manualFilename) {
      return !normalizedValues.some((v) => manualFilename.includes(v))
    }

    return !normalizedValues.some((v) => toolFilename?.includes(v))
  }

  if (matcher.name === 'property_contains_any_of') {
    if (manualFilename) {
      return normalizedValues.some((v) => manualFilename.includes(v))
    }

    return normalizedValues.some((v) => toolFilename?.includes(v))
  }

  if (matcher.name === 'property_contains_none_of') {
    if (manualFilename) {
      return !normalizedValues.some((v) => manualFilename.includes(v))
    }

    return !normalizedValues.some((v) => toolFilename?.includes(v))
  }

  exhaustiveGuard(matcher, 'Unhandled matcher name')
  return false
}

/**
 *
 * @param field
 * @param matcher
 * @returns
 */
export function filterByFieldName(
  field: Extract<Field, { manualName: Field<'collection'>['manualName'] }>,
  matcher: FieldNameFilter['matcher'],
): boolean {
  const manualName = matcher.case_sensitive ? field.manualName : field.manualName?.toLowerCase()
  const normalizedValues = matcher.values.map((v) => (matcher.case_sensitive ? v : v.toLowerCase()))

  if (matcher.name === 'property_any_of') {
    return normalizedValues.some((v) => manualName === v)
  }

  if (matcher.name === 'property_none_of') {
    return !normalizedValues.some((v) => manualName === v)
  }

  if (matcher.name === 'property_contains_any_of') {
    return !!manualName && normalizedValues.some((v) => manualName.includes(v))
  }

  if (matcher.name === 'property_contains_none_of') {
    return !manualName || !normalizedValues.some((v) => manualName.includes(v))
  }

  exhaustiveGuard(matcher, 'Unhandled matcher name')
  return false
}
