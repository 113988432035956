import { invariant } from '@/shared/utils/typeAssertions'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

/**
 * The URL is the ultimate source of truth for information on the active
 * workspace/project/entity IDs, as the rest of the frontend loads data based
 * on these IDs.
 *
 * Getting the IDs from here is quicker than getting them from the stores,
 * as the stores often won't be populated until the full workspace/entity/project
 * has been loaded. By using this composable we can load data asynchronously
 * instead of waiting for the workspace to be fully loaded before we fetch
 * anything inside of it.
 */
export const useRouteParams = () => {
  const route = useRoute()

  const parentEntityId = computed(() => {
    invariant(
      !Array.isArray(route.query.parentEntityId),
      'Multiple parentEntityId values found in route',
    )
    return route.query.parentEntityId
  })

  const parentProjectId = computed(() => {
    invariant(
      !Array.isArray(route.params.parentProjectId),
      'Multiple parentProjectId values found in route',
    )
    return route.params.parentProjectId
  })

  const workspaceId = computed(() => {
    invariant(
      !Array.isArray(route.params.workspaceId),
      'Multiple workspaceId values found in route',
    )
    return route.params.workspaceId
  })

  const projectId = computed(() => {
    invariant(!Array.isArray(route.params.projectId), 'Multiple projectId values found in route')
    return route.params.projectId
  })

  const viewId = computed(() => {
    invariant(!Array.isArray(route.params.viewId), 'Multiple viewId values found in route')
    return route.params.viewId
  })

  const parentViewId = computed(() => {
    invariant(
      !Array.isArray(route.query.parentViewId),
      'Multiple parentViewId values found in route',
    )
    return route.query.parentViewId
  })

  const entityId = computed(() => {
    invariant(!Array.isArray(route.params.entityId), 'Multiple entityId values found in route')
    return route.params.entityId
  })

  const spaceId = computed(() => {
    invariant(!Array.isArray(route.params.spaceId), 'Multiple spaceId values found in route')
    return route.params.spaceId
  })

  return {
    entityId,
    parentEntityId,
    parentProjectId,
    parentViewId,
    projectId,
    viewId,
    workspaceId,
    spaceId,
  }
}
