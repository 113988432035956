import type { SnakeToCamelCaseObj } from '@/shared/types'
import { remove } from './client'
import type { BackendMeta } from './types'

export type DeleteConnectionMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/integrations/{integration_id}/connections/{connection_id}',
  'delete'
>
type Body = DeleteConnectionMeta['body']
type Params = DeleteConnectionMeta['pathParams']

export const deleteConnection = ({
  workspaceId,
  integrationId,
  connectionId,
}: SnakeToCamelCaseObj<Params & Body>) =>
  remove<Body, DeleteConnectionMeta['successResponse'], DeleteConnectionMeta['path']>(
    `/api/workspaces/${workspaceId}/integrations/${integrationId}/connections/${connectionId}`,
    undefined,
  )
