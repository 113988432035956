<script lang="ts" setup>
import { useLimitedAction } from '@/modules/Billing/useLimitedAction'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import type { Field } from '@/modules/Project/Fields/types.ts'
import type { Staleness } from '@/modules/Project/useDotIndicator'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed, type ComputedRef } from 'vue'
import type { ToolTipProps } from './ToolTip.vue'
import ToolTip from './ToolTip.vue'

const props = defineProps<{ field: Field; staleness: Staleness }>()

// https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/AGIDB---Design-System?node-id=5%3A2771&mode=dev

const { workspaceId, projectId } = useRouteParams()

const tooltipTitle = computed(() => {
  if (props.field.status === 'error') {
    return 'An error occurred'
  }

  if (props.field.groundTruth) {
    return 'This field is locked'
  }

  if (props.field.status === 'computing') {
    return 'This field is being computed'
  }

  if (props.staleness === 'stale') {
    return 'This field is stale'
  }

  if (props.staleness === 'maybe-stale') {
    return 'This field may be stale'
  }

  if (props.field.errorMessage) {
    return props.field.errorMessage
  }

  return ''
})

const tooltipBody = computed(() => {
  if (props.field.status === 'error') {
    return props.field.errorMessage || ''
  }

  if (props.field.groundTruth) {
    return 'Locked fields are immutable and do not recompute.'
  }

  if (props.field.status === 'computing') {
    return 'Please wait until the AI Tool generates a value.'
  }

  if (props.staleness) {
    return 'Recompute the field to get the latest value.'
  }

  return ''
})

const tooltipProps: ComputedRef<ToolTipProps> = computed(() => {
  const toolTipProps: ToolTipProps = {
    title: tooltipTitle.value,
    body: tooltipBody.value,
    placement: { allowedPlacements: ['top', 'right', 'left'] },
  }
  return toolTipProps
})

const { recalculateEntity } = useLimitedAction()
const recalculate = async () => {
  if (!hasRecalculatePermission.value) {
    return
  }

  if (props.field.status === 'error' || props.staleness) {
    await recalculateEntity({
      workspaceId: workspaceId.value,
      projectId: projectId.value,
      entityId: props.field.entityId,
      propertyIds: [props.field.propertyId],
      force: props.staleness === 'maybe-stale',
    })
  }
}

const permissionStore = usePermissionsStore()
const hasRecalculatePermission = computed(
  () => permissionStore.currentProjectPermissions.recalculate_entities ?? false,
)

const showRecalculateButton = computed(
  () =>
    !props.field.groundTruth &&
    props.field.status !== 'computing' &&
    (props.staleness || props.field.status === 'error' || props.field.errorMessage),
)

const recalculateButtonColour = computed<'red' | 'yellow' | null>(() => {
  if (!showRecalculateButton.value) {
    return null
  }

  if (props.field.status === 'error') {
    return 'red'
  }

  return 'yellow'
})
</script>

<template>
  <ToolTip v-bind="tooltipProps">
    <button
      v-if="showRecalculateButton"
      class="-mr-1 grid size-5 place-items-center"
      :class="hasRecalculatePermission ? 'group/dot cursor-pointer' : 'cursor-default'"
      aria-label="Recalculate field"
      @click="recalculate"
    >
      <div
        class="relative size-1.5 overflow-hidden rounded-full transition-all duration-250 ease-out group-hover/dot:size-5"
        :class="[
          recalculateButtonColour === 'red' && ['bg-icon-critical'],
          recalculateButtonColour === 'yellow' && ['bg-icon-stale'],
        ]"
      >
        <IconSprite
          icon="process"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-90 text-[12px] opacity-0 transition-all duration-150 ease-out group-hover/dot:rotate-0 group-hover/dot:opacity-100 group-hover/dot:delay-100"
        />
      </div>
    </button>
    <svg
      v-else
      view-box="0 0 12 12"
      class="size-3 rounded-corner-4 bg-background-transparent hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        class="transition-opacity"
        :class="[
          field.status === 'complete' && ['fill-icon-success'],
          field.status === 'computing' && ['fill-background-stages-model'],
          staleness && ['fill-icon-stale'],
          field.status === 'computing'
            ? 'animate-pulse opacity-10'
            : 'opacity-0 group-hover/dot:opacity-10',
        ]"
      />
      <circle
        cx="6"
        cy="6"
        r="3"
        :class="[
          field.groundTruth
            ? 'fill-background-success'
            : [
                field.status === 'complete' &&
                  (staleness ? ['fill-icon-stale'] : ['fill-icon-success']),
                field.status === 'computing' && ['fill-background-stages-model'],
              ],
        ]"
      />
    </svg>
  </ToolTip>
</template>
