<script setup lang="ts">
import { PropertyTool, PropertyType, ToolProvider } from '@/backend/types'
import { manualTool } from '@/modules/Project/Tools/registry/manual.ts'
import { tools } from '@/modules/Project/Tools/tool-registry.ts'
import { useModelProviders } from '@/modules/WorkspaceSettings/modelProvidersStore'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed } from 'vue'

/**
 * Renders a list of tools that can be applied to a property, grouped by
 * provider.
 */

type ListItemTool = {
  tool: PropertyTool
  label: string
  provider: ToolProvider
  isCustomKey?: boolean
}

const props = defineProps<{
  /** The tool that is currently selected */
  activeTool: PropertyTool
  /** The type of property */
  activeType: PropertyType
  /** If true, there will be a search input that will filter the list */
  searchable?: boolean
  /**
   * If true, then the component will only render models that are
   * compatible with grounding.
   */
  grounded: boolean
}>()

defineEmits<{
  (e: 'change', tool: PropertyTool): void
}>()

const modelProvidersStore = useModelProviders()

/**
 * When grounding is enabled, these are the only tools that are available.
 */
const isAvailableBasedOnGrounding = (tool: PropertyTool) =>
  !props.grounded || !!modelProvidersStore.groundingConfigs.get(tool)

const providerRemap: Record<string, ToolProvider> = {
  [PropertyTool.ocr]: ToolProvider.internal,
  // url scrape should be next to bing search
  [PropertyTool.url_scrape]: ToolProvider.bing_search,
  [PropertyTool.go]: ToolProvider.internal,
  [PropertyTool.web_search]: ToolProvider.internal,
}

const getModels = (provider: ToolProvider): ListItemTool[] => {
  if (!modelProvidersStore.providers[provider]) {
    return []
  }

  return modelProvidersStore.providers[provider].tools
    .filter(
      (tool) =>
        modelProvidersStore.enabledTools.includes(tool.name) &&
        tool.supportedOutputTypes.includes(props.activeType) &&
        isAvailableBasedOnGrounding(tool.name),
    )
    .map((tool) => ({
      tool: tool.name,
      label: tools[tool.name].label,
      provider: providerRemap[tool.name] ?? tools[tool.name].provider,
      isCustomKey: !!modelProvidersStore.providerConfig[provider]?.apiKeyPrefix,
    }))
}

const ungroupedModels = computed<ListItemTool[]>(() => {
  const models = [
    ...getModels(ToolProvider.internal),
    ...getModels(ToolProvider.azure_ocr),
    ...getModels(ToolProvider.bing_search),
    ...getModels(ToolProvider.fire_crawl),
    ...getModels(ToolProvider.amazon_textract),
  ]

  if (
    (!props.grounded && props.activeType !== PropertyType.collection) ||
    props.activeType === PropertyType.collection
  ) {
    models.unshift({
      tool: PropertyTool.manual,
      label: manualTool.label,
      provider: ToolProvider.internal,
    })
  }

  return models
})
const openAiModels = computed(() => getModels(ToolProvider.open_ai))
const googleModels = computed(() => getModels(ToolProvider.google_ai))
const anthropicModels = computed(() => getModels(ToolProvider.anthropic))
const azureOpenAiModels = computed(() => getModels(ToolProvider.azure_open_ai))
const vertexAnthropicModels = computed(() => getModels(ToolProvider.vertex_ai_claude))
</script>

<template>
  <ListMenu
    role="listbox"
    class="min-w-[240px]"
    aria-label="Select a tool"
    :items="
      [
        ...ungroupedModels,
        ...openAiModels,
        ...azureOpenAiModels,
        ...googleModels,
        ...anthropicModels,
        ...vertexAnthropicModels,
      ].map((m) => ({
        id: m.tool,
        data: m,
      }))
    "
    :initial-active-item-predicate="(item) => item.data.tool === activeTool"
    :group-by-predicate="(item) => item.data.provider"
    :search-by-field="searchable ? 'label' : undefined"
  >
    <template
      #item="{
        item: {
          data: { tool, isCustomKey },
        },
        active,
        key,
        setActiveItem,
      }"
    >
      <ToolTip
        :title="tools[tool].description"
        :arrow="true"
        :placement="{ allowedPlacements: ['right', 'left'] }"
      >
        <ListMenuItem
          :label="tools[tool].label"
          :active="active"
          :aria-selected="active"
          default-hover-disabled
          :icon="tools[tool].icon"
          @mousemove="setActiveItem(key)"
          @select="$emit('change', tool)"
        >
          <template #prefix>
            <IconSprite
              :icon="tool === activeTool ? 'check' : 'blank'"
              class="mr-1 text-icon-subtle"
            />
          </template>
          {{ tools[tool].label }}
          <span
            v-if="tools[tool].provider === ToolProvider.vertex_ai_claude"
            class="ml-auto text-xs-11px-light text-text-subtlest"
          >
            via Vertex AI
          </span>
          <span
            v-else-if="tools[tool].provider === ToolProvider.azure_open_ai"
            class="ml-auto text-xs-11px-light text-text-subtlest"
          >
            via Azure
          </span>
          <span
            v-else-if="isCustomKey"
            class="ml-auto text-xs-11px-light text-text-subtlest"
          >
            Custom key
          </span>
          <template
            v-if="tools[tool].isNew"
            #suffix
          >
            <BadgeItem
              label="New"
              size="xs"
              variant="blue"
            />
          </template>
          <template
            v-else-if="tools[tool].isRateLimited"
            #suffix
          >
            <ToolTip
              :placement="{ allowedPlacements: ['bottom-start'] }"
              title="The model provider has rate limited this tool, so it might be temporarily unavailable."
            >
              <BadgeItem
                label="Rate Limited"
                size="xs"
                variant="warning"
              />
            </ToolTip>
          </template>
        </ListMenuItem>
      </ToolTip>
    </template>
  </ListMenu>
</template>
