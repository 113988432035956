<script setup lang="ts">
import { useResolveProjectRoute } from '@/modules/Project/useResolveProjectRoute'
import CrumbItem from '@/modules/Workspaces/Crumbs/CrumbItem.vue'
import EntityCrumb from '@/modules/Workspaces/Crumbs/EntityCrumb.vue'
import ProjectCrumb from '@/modules/Workspaces/Crumbs/ProjectCrumb.vue'
import { useActiveCollectionName } from '@/modules/Workspaces/Crumbs/useActiveCollectionName'
import { useCrumbItems } from '@/modules/Workspaces/Crumbs/useCrumbItems'
import type { ProjectBreadcrumb } from '@/modules/Workspaces/Crumbs/useProjectBreadcrumbs'
import { useProjectBreadcrumbs } from '@/modules/Workspaces/Crumbs/useProjectBreadcrumbs'
import ViewCrumb from '@/modules/Workspaces/Crumbs/ViewCrumb.vue'
import WorkspaceCrumb from '@/modules/Workspaces/Crumbs/WorkspaceCrumb.vue'
import { omit } from '@/shared/utils'
import CrumbChevron from '@/sharedComponents/CrumbChevron.vue'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

const { collection, entity, onlyProject, view } = useCrumbItems()
const { workspaceId, parentEntityId, parentProjectId, parentViewId } = useRouteParams()
const { breadcrumbs } = useProjectBreadcrumbs()
const activeCollectionName = useActiveCollectionName()
const resolveProjectRoute = useResolveProjectRoute()
const howManyCrumbsShouldBeVisible = 2

const customView = computed(() =>
  view.value && view.value.name !== 'main' ? view.value : undefined,
)
const crumbs = computed(() => {
  const all = [...breadcrumbs.value]

  if (hiddenActiveCollectionCrumbItem.value) {
    all.push(hiddenActiveCollectionCrumbItem.value)
  }

  const partitionIndex =
    all.length + currentRouteCrumbItemCount.value - howManyCrumbsShouldBeVisible

  const hidden = all.slice(0, partitionIndex)
  const visible = all.slice(partitionIndex)

  return { hidden, visible }
})

const currentRouteCrumbItemCount = computed(() => {
  return [collection.value, customView.value, entity.value].reduce((cnt, item) => {
    return cnt + (item ? 1 : 0)
  }, 0)
})

const activeCollectionCrumbItem = computed<ProjectBreadcrumb | undefined>(() => {
  if (!collection.value) return
  return {
    label: activeCollectionName.value || '',
    route: resolveProjectRoute({
      workspaceId: workspaceId.value,
      parentProjectId: parentProjectId.value,
      projectId: collection.value.id,
      parentEntityId: parentEntityId.value,
      parentViewId: parentViewId.value,
      trace: 'same',
    }),
  } satisfies ProjectBreadcrumb
})

const hiddenActiveCollectionCrumbItem = computed<ProjectBreadcrumb | undefined>(() => {
  if (
    currentRouteCrumbItemCount.value <= howManyCrumbsShouldBeVisible ||
    !activeCollectionCrumbItem.value
  ) {
    return
  }

  return activeCollectionCrumbItem.value
})

/**
 * List of various objects that are passed to crumbs, their type doesn't matter.
 * We can use them to check which one is the last one.
 */
const crumbThings = computed<unknown[]>(() => {
  const items = [
    onlyProject.value,
    ...crumbs.value.visible,
    activeCollectionCrumbItem.value,
    customView.value,
    entity.value,
  ]
  return items.filter(Boolean)
})

const isLast = (item: unknown) => crumbThings.value.at(-1) === item
</script>

<template>
  <nav
    class="flex flex-row items-center justify-start overflow-hidden [&>*]:grow-0"
    aria-label="crumbs"
  >
    <WorkspaceCrumb />
    <template v-if="onlyProject">
      <CrumbChevron />
      <ProjectCrumb
        :mask="isLast(onlyProject)"
        :project="onlyProject"
      />
    </template>

    <!-- Hidden project and view crumbs -->
    <template v-if="crumbs.hidden.length > 0">
      <CrumbChevron />
      <Menu.Root v-slot="{ getTriggerProps, menu }">
        <IconButton
          aria-label="Open breadcrumb list"
          aria-haspopup="true"
          icon="more-dots"
          size="md"
          variant="transparent"
          v-bind="{ ...omit(getTriggerProps(), ['disabled']) }"
        />

        <Menu.Content
          class="min-w-56"
          role="menu"
        >
          <RouterLink
            v-for="(crumb, index) in crumbs.hidden"
            :key="index"
            :to="crumb.route"
            @click="menu.setOpen(false)"
          >
            <Menu.Item class="px-2">
              {{ crumb.label }}
            </Menu.Item>
          </RouterLink>
        </Menu.Content>
      </Menu.Root>
    </template>

    <!-- Visible project and view crumbs -->
    <template
      v-for="crumb in crumbs.visible"
      :key="crumb.route.fullPath"
    >
      <CrumbChevron />
      <CrumbItem
        ref="elements"
        :mask="isLast(crumb)"
        :to="crumb.route"
      >
        {{ crumb.label }}
      </CrumbItem>
    </template>

    <!-- Active screen item -->
    <template v-if="!hiddenActiveCollectionCrumbItem && activeCollectionCrumbItem">
      <CrumbChevron />
      <CrumbItem
        ref="elements"
        :mask="isLast(activeCollectionCrumbItem)"
        :to="activeCollectionCrumbItem.route"
        >{{ activeCollectionCrumbItem.label }}</CrumbItem
      >
    </template>
    <template v-if="customView">
      <CrumbChevron />
      <ViewCrumb
        ref="elements"
        :mask="isLast(customView)"
        :view="customView"
      />
    </template>
    <template v-if="entity">
      <CrumbChevron />
      <EntityCrumb
        ref="elements"
        :mask="isLast(entity)"
        :entity="entity"
      />
    </template>
  </nav>
</template>
