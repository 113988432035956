import { captureException } from '@sentry/vue'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { ProgressMessage, QueryMessage, ResultMessage, Space } from './types'

/**
 * This global store holds information on agentic chat spaces, storing all
 * spaces in the workspace, and the current space being viewed.
 */
export const useSpaceStore = defineStore('space', () => {
  const allSpaces = ref<Space[]>([])

  const currentSpaceId = ref<string | null>(null)
  const space = computed<Space | null>(
    () => allSpaces.value.find((s) => s.id === currentSpaceId.value) || null,
  )

  const queryMessages = computed<QueryMessage[] | null>(
    () => space.value?.messages.filter((m) => m.type === 'query') || [],
  )

  const progressMessages = computed<ProgressMessage[]>(
    () => space.value?.messages.filter((m) => m.type === 'progress') || [],
  )

  const conversation = computed<Array<QueryMessage | ResultMessage>>(
    () =>
      space.value?.messages
        .filter((m) => m.type === 'query' || m.type === 'result')
        .toSorted((a, b) => (a.createdAt < b.createdAt ? -1 : 1)) || [],
  )

  const status = computed(() => space.value?.status)

  const reset = () => {
    allSpaces.value = []
    currentSpaceId.value = null
  }

  /**
   * Idempotantly sets a space in the store, adding it if it doesn't exist or
   * updating it if it does.
   */
  const setSpace = (s: Space) => {
    const index = allSpaces.value.findIndex((space) => space.id === s.id)
    if (index === -1) {
      allSpaces.value.push(s)
    } else {
      allSpaces.value = allSpaces.value.with(index, s)
    }
  }

  /**
   * Updates a space by overwriting all of the provided property values.
   */
  const updateSpace = (spaceId: string, partialSpace: Partial<Omit<Space, 'id'>>) => {
    const spaceToUpdate = allSpaces.value.find((s) => s.id === spaceId)
    if (!spaceToUpdate) {
      captureException(new Error('Space not found'), { data: { spaceId } })
      return
    }

    setSpace({ ...spaceToUpdate, ...partialSpace })
  }

  /**
   * Idempotently adds messages to a space in the store.
   */
  const putMessages = (spaceId: string, messages: Space['messages']) => {
    const spaceToUpdate = allSpaces.value.find((s) => s.id === spaceId)
    if (!spaceToUpdate) {
      captureException(new Error(`Space not found`), { data: { spaceId } })
      return
    }

    messages.forEach((message) => {
      const messageIndex = spaceToUpdate.messages.findIndex((m) => m.id === message.id)
      if (messageIndex === -1) {
        spaceToUpdate.messages.push(message)
      } else {
        spaceToUpdate.messages = spaceToUpdate.messages.with(messageIndex, message)
      }
    })

    setSpace(spaceToUpdate)
  }

  /**
   * Deletes a space, and returns a function to revert the deletion,
   * to support optimistic UI.
   */
  const deleteSpace = (spaceId: string) => {
    const index = allSpaces.value.findIndex((s) => s.id === spaceId)
    if (index === -1) {
      return () => {}
    }

    const spaceToDelete = allSpaces.value[index]
    const revert = () => {
      allSpaces.value.splice(index, 0, spaceToDelete)
    }

    allSpaces.value.splice(index, 1)

    return revert
  }

  return {
    allSpaces,

    setSpace,
    updateSpace,
    deleteSpace,

    currentSpaceId,
    space,

    status,
    queryMessages,
    progressMessages,
    conversation,
    putMessages,

    reset,
  }
})
