import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useElementBounding } from '@vueuse/core'
import { setBlockType, toggleMark, wrapIn } from 'prosemirror-commands'
import type { NodeType, Schema } from 'prosemirror-model'
import { liftListItem, wrapInList } from 'prosemirror-schema-list'
import { type EditorState, type Transaction } from 'prosemirror-state'
import { liftTarget } from 'prosemirror-transform'
import { findParentNodeOfType } from 'prosemirror-utils'
import type { EditorView } from 'prosemirror-view'
import { computed, ref, watch, type Ref, type ShallowRef } from 'vue'

/**
 * Provides functions for interacting with items in the menu bar, and for positioning
 * the menu bar.
 */
export const useMenuBar = (
  view: Ref<EditorView | undefined>,
  schema: Schema,
  editorRef: Readonly<ShallowRef<HTMLDivElement | null>>,
) => {
  const textStyleLabel = ref<
    'Heading 1' | 'Heading 2' | 'Heading 3' | 'Paragraph' | 'Text style' | 'Code block'
  >('Text style')
  const isBulletListActive = ref(false)
  const isOrderedListActive = ref(false)
  const isBlockquoteActive = ref(false)

  const setBold = toggleMark(schema.marks.strong)

  const toggleBold = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    setBold(view.value.state, view.value.dispatch, view.value)
  }

  const toggleItalicCommand = toggleMark(schema.marks.em)
  const toggleItalic = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    toggleItalicCommand(view.value.state, view.value.dispatch, view.value)
  }

  const toggleCodeCommand = toggleMark(schema.marks.code)
  const toggleCode = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    toggleCodeCommand(view.value.state, view.value.dispatch, view.value)
  }

  const setHeading1Command = setBlockType(schema.nodes.heading, { level: 1 })
  const setHeading2Command = setBlockType(schema.nodes.heading, { level: 2 })
  const setHeading3Command = setBlockType(schema.nodes.heading, { level: 3 })
  const setHeading = (level: 1 | 2 | 3) => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    switch (level) {
      case 1:
        setHeading1Command(view.value.state, view.value.dispatch)
        break
      case 2:
        setHeading2Command(view.value.state, view.value.dispatch)
        break
      case 3:
        setHeading3Command(view.value.state, view.value.dispatch)
        break
    }
    onCloseMenu()
  }

  const setCodeBlockCommand = setBlockType(schema.nodes.code_block, null)
  const setCodeBlock = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    setCodeBlockCommand(view.value.state, view.value.dispatch)
    onCloseMenu()
  }

  const toggleBlockquote = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    const { state, dispatch } = view.value
    const { blockquote } = schema.nodes

    // Check if the selection is inside a blockquote
    const isActive = hasParentOf(state, blockquote)

    if (isActive) {
      // Lift content out of blockquote
      liftBlockquote(state, dispatch)
    } else {
      // Wrap content in blockquote
      wrapIn(blockquote)(state, dispatch)
    }
  }

  // Helper function to check if the selection is within a node of the given type
  const hasParentOf = (state: EditorState, type: NodeType) => {
    const { selection } = state
    return findParentNodeOfType(type)(selection)
  }

  // Helper function to lift content out of blockquote
  const liftBlockquote = (state: EditorState, dispatch: (e: Transaction) => void) => {
    const { $from, $to } = state.selection
    const range = $from.blockRange($to)

    if (!range) return false

    // Find the nearest blockquote ancestor
    const target = liftTarget(range)

    if (target == null) return false

    dispatch(state.tr.lift(range, target).scrollIntoView())
    return true
  }

  const setParagraphCommand = setBlockType(schema.nodes.paragraph, null)
  const setParagraph = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    setParagraphCommand(view.value.state, view.value.dispatch)
  }

  const toggleLink = (href?: string) => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    const { state, dispatch } = view.value
    const { from, to } = state.selection
    const linkMark = schema.marks.link

    if (state.doc.rangeHasMark(from, to, linkMark)) {
      // Remove the link mark
      toggleMark(linkMark)(state, dispatch)
    } else {
      // Add the link mark with the specified href
      toggleMark(linkMark, { href, title: href })(state, dispatch)
    }
    onCloseMenu()
  }

  const toggleBulletList = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    const { state, dispatch } = view.value
    const { bullet_list, list_item } = schema.nodes

    toggleList(bullet_list, list_item)(state, dispatch)
  }

  const toggleOrderedList = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    const { state, dispatch } = view.value
    const { ordered_list, list_item } = schema.nodes

    toggleList(ordered_list, list_item)(state, dispatch)
  }

  const toggleList =
    (listType: NodeType, itemType: NodeType) =>
    (state: EditorState, dispatch: (e: Transaction) => void) => {
      const { selection } = state
      const { $from, $to } = selection
      const range = $from.blockRange($to)
      if (!range) return false

      const parentList = findParentNodeOfType(listType)(selection)
      if (parentList) {
        // Lift the selection out of the list
        return liftListItem(itemType)(state, dispatch)
      } else {
        // Wrap the selection in the list
        return wrapInList(listType)(state, dispatch)
      }
    }

  const insertDivider = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    const { state, dispatch } = view.value
    const { horizontal_rule } = schema.nodes

    const { $to } = state.selection

    const hrNode = horizontal_rule.create()
    const tr = state.tr.insert($to.pos, hrNode)

    dispatch(tr.scrollIntoView())
    onCloseMenu()
  }

  const insertImage = (url: string) => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    const { state, dispatch } = view.value

    const { selection } = state
    const position = selection.$to.pos

    const imageNode = schema.nodes.image.create({ src: url })
    const tr = state.tr.insert(position, imageNode)

    dispatch(tr.scrollIntoView())
    onCloseMenu()
  }

  const updateSelectionTextStyle = () => {
    if (!view.value) return
    const { $from } = view.value.state.selection
    const type = $from.node().type
    const attrs = $from.node().attrs
    if (type.name === 'heading' && attrs.level === 1) {
      textStyleLabel.value = 'Heading 1'
      return
    }
    if (type.name === 'heading' && attrs.level === 2) {
      textStyleLabel.value = 'Heading 2'
      return
    }
    if (type.name === 'heading' && attrs.level === 3) {
      textStyleLabel.value = 'Heading 3'
      return
    }
    if (type.name === 'paragraph') {
      textStyleLabel.value = 'Paragraph'
      return
    }
    if (type.name === 'code_block') {
      textStyleLabel.value = 'Code block'
      return
    }
    textStyleLabel.value = 'Text style'
  }

  const refreshToolbarState = () => {
    assertIsNotNullOrUndefined(view.value, 'No editor view')
    updateSelectionTextStyle()
    isBulletListActive.value = !!hasParentOf(view.value?.state, schema.nodes.bullet_list)
    isOrderedListActive.value = !!hasParentOf(view.value?.state, schema.nodes.ordered_list)
    isBlockquoteActive.value = !!hasParentOf(view.value?.state, schema.nodes.blockquote)
  }

  /** Coordinates of the selected text */
  const selectionCoords = ref<{ left: number; top: number; bottom: number; right: number } | null>(
    null,
  )

  const setToolbarPosition = () => {
    if (!view.value) return

    const hasSelection = view.value.state.selection.from !== view.value.state.selection.to

    if (hasSelection) {
      selectionCoords.value = view.value.coordsAtPos(view.value.state.selection.from)
    } else {
      selectionCoords.value = null
    }
  }

  const editorBbox = useElementBounding(editorRef)
  watch(editorBbox.top, setToolbarPosition)

  const onCloseMenu = () => {
    selectionCoords.value = null
    view.value?.focus()
  }

  /**
   * Will be true if the current selection is outside the visible editor window. In this
   * case, the toolbar should not be displayed as it will appear disconnected from the
   * editor.
   */
  const toolbarOutOfBounds = computed<boolean>(() => {
    if (!selectionCoords.value || !editorRef.value) {
      return true
    }

    const editorBbox = editorRef.value.getBoundingClientRect()
    const isAboveEditor = selectionCoords.value.top < editorBbox.top
    const isBelowEditor = selectionCoords.value.top > editorBbox.bottom
    return isAboveEditor || isBelowEditor
  })

  /**
   * There now follows a horrible hack.
   *
   * The toolbar has position:fixed to position it at the same position as the selection
   * in the viewport. However, when there is a translated ancestor, the fixed position
   * is relative to that ancestor's bounding box instead of relative to the viewport.
   *
   * To work around this, we use a marker element to work out how much we need to
   * compensate when setting the position.
   *
   * Some alternatives I suggested and why they didn't work:
   * - Absolute positioning: overflow issues
   * - Teleport: difficult to handle exceptions to `onClickOutside()` handlers and focus
   * - Popover API: not fully supported by browsers, requires all floating ancestors to also
   *   use the popover API
   */
  const toolbarMarkerRef = ref<HTMLElement | null>(null)
  const markerBbox = useElementBounding(toolbarMarkerRef)

  /**
   * Work out how 'incorrect' the marker position is, and adjust the toolbar position
   * accordingly.
   */
  const adjustedMenuPosition = computed(() => {
    if (!selectionCoords.value) {
      return { left: 0, top: 0 }
    }

    // Work out the left/top adjustment needed for the toolbar to be positioned
    // relative to the viewport.
    const leftAdjust = selectionCoords.value.left - markerBbox.left.value
    const topAdjust = selectionCoords.value.top - markerBbox.top.value

    return {
      left: selectionCoords.value.left + leftAdjust,
      top: selectionCoords.value.top + topAdjust,
    }
  })

  return {
    toggleBold,
    toggleItalic,
    toggleCode,
    setHeading,
    setParagraph,
    setCodeBlock,
    toggleBlockquote,
    toggleLink,
    toggleBulletList,
    toggleOrderedList,
    insertDivider,
    insertImage,
    hasParentOf,
    textStyleLabel,
    refreshToolbarState,
    isBulletListActive,
    isOrderedListActive,
    isBlockquoteActive,

    toolbarOutOfBounds,
    selectionCoords,
    setToolbarPosition,
    onCloseMenu,
    toolbarMarkerRef,
    adjustedMenuPosition,
  }
}
