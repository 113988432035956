import type { Field } from '@/modules/Project/Fields/types'
import { computed, type Ref } from 'vue'

export const useFieldStatusIndicator = (field: Ref<Field | undefined>) => {
  const isModelOutput = computed<boolean>(
    () =>
      !!field.value &&
      field.value.toolValue !== null &&
      field.value.manualValue === null &&
      field.value.status === 'complete',
  )

  return { isModelOutput }
}
