import { listFilteredEntities } from '@/backend/listFilteredEntities'
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { usePaginatedLoader } from '@/sharedComposables/usePaginatedLoader'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { useTooltip } from '@/sharedComposables/useTooltip'
import type { Placement } from '@floating-ui/vue'
import { useDebounce, watchDebounced } from '@vueuse/core'
import { computed, ref, watch, type Ref } from 'vue'
import { ENTITY_NAME_FALLBACK } from './constants'
import { isFilterableProperty, type SimpleFilter } from './Filters/types'
import { getSimpleFilterForProperty } from './Filters/utils'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import type { FieldPreview } from './types/fieldPreview'
import { useFieldPreviews } from './useFieldPreviews'
import { serializeEntity, type Entity } from './useProject'
import { useSerializeFieldToText } from './useSerializeFieldToText'

export type FullOrPreviewEntity = Entity | { id: string; fields: Map<Property['id'], FieldPreview> }

export type ReferenceEntityListItem = {
  id: string
  data: {
    name: string
    group: 'selected' | 'unselected'
  } & Entity
}

/**
 * This is a god composable for reference field. It handles all the logic
 * for loading and toggling entities in the field's list of entities (both
 * matched and unmatched).
 *
 * It is used in both the project table and entity view.
 */
export const useReferenceField = ({
  field,
  property,
}: {
  field: Ref<Field<'reference'>>
  property: Ref<Property<'reference'>>
}) => {
  const relatedProjectStore = useRelatedProjectsStore()
  const relatedProjectId = computed(() => property.value.config.projectId)
  const relatedProject = computed(() => relatedProjectStore.getRelatedProject(property.value.id))

  const { workspaceId, projectId } = useRouteParams()

  /**
   * All entities to render as badges in the cell, including those that are in an
   * optimistic state.
   */
  const previewEntities = ref<FullOrPreviewEntity[]>([])

  /** From the backend - all selected entity IDs */
  const savedEntityIds = computed(
    () => field.value.manualEntityIds || field.value.toolEntityIds || [],
  )

  /**
   * IDs of all entities that should be shown as selected in the UI. Not all of these
   * may be saved on the backend yet.
   */
  const localEntityIds = ref<string[]>([])
  watch(
    savedEntityIds,
    (newIds) => {
      localEntityIds.value = [...newIds]
    },
    { immediate: true },
  )

  /** When true, we should show a loading skeleton in the list of related project entities */
  const showSkeletonList = computed(() => {
    const isLoadingData =
      selectedEntityLoader.status.value === 'loading' ||
      unselectedEntityLoader.status.value === 'loading'

    return isLoadingData && allLoadedEntities.value.length === 0
  })

  /** Skeleton items to render while loading */
  const skeletonItems = ref<ReferenceEntityListItem[]>([])

  /** Search term to filter entities in the dropdown by */
  const searchText = ref('')
  watchDebounced(
    searchText,
    () => {
      // Before resetting the list, set the skeleton items to the current list value so that
      // the skeleton lits takes up the same space as the reguler list in its pre-search state.
      skeletonItems.value = listItems.value
      resetAllData()
      loadMoreEntities()
    },
    {
      debounce: 300,
    },
  )

  /** Property used to get an entity's name */
  const nameProperty = computed(() => relatedProjectStore.getNameProperty(property.value.id))

  /**
   * Build a filter based on the text the user has entered in the
   * search box. This filter object is sent to the backend so that
   * the menu is populated with entities matching the search term.
   */
  const fieldFilter = computed(() =>
    nameProperty.value && isFilterableProperty(nameProperty.value) && searchText.value
      ? getSimpleFilterForProperty(nameProperty.value, searchText.value)
      : undefined,
  )

  /**
   * Utilities to load more selected entities from the related
   * project.
   *
   * We have separate loaders for selected and unselected entities
   * because we have no way sort by selected/unselected status
   * using the API
   */
  const selectedEntityLoader = usePaginatedLoader(
    ({ after, first }) => {
      assertIsNotNullOrUndefined(
        relatedProjectId.value,
        'Tried to load entities for unconfigured reference property',
      )

      if (savedEntityIds.value.length === 0) {
        /**
         * If there are no selected entities, we don't need to
         * make a request because and can simulate a response that
         * indicates there is no data to return and no next page.
         */
        return new Promise((resolve) => {
          resolve({
            data: {
              data: [],
              metadata: {
                current_offset: null,
                end_cursor: null,
                has_next_page: false,
                total_count: 0,
                has_previous_page: false,
                page_size: 0,
                start_cursor: null,
              },
            },
            ok: true,
          })
        })
      }

      const filters: SimpleFilter[] = [
        {
          subject: 'entity_id',
          matcher: {
            name: 'any_of',
            values: localEntityIds.value,
          },
        },
      ]

      if (fieldFilter.value) {
        filters.push(fieldFilter.value)
      }

      return listFilteredEntities({
        filter: {
          conjunction: 'and',
          filters,
        },
        projectId: relatedProjectId.value,
        workspaceId: workspaceId.value,
        after,
        first,
      })
    },
    {
      limit: 20,
      serializer: serializeEntity,
    },
  )

  /**
   * Utilities to load more unselected entities from the related
   * project.
   *
   * We have separate loaders for selected and unselected entities
   * because we have no way sort by selected/unselected status
   * using the API
   */
  const unselectedEntityLoader = usePaginatedLoader(
    ({ after, first }) => {
      assertIsNotNullOrUndefined(
        relatedProjectId.value,
        'Tried to load entities for unconfigured reference property',
      )

      const entityIdFilter: SimpleFilter | undefined = previewEntities.value?.length
        ? {
            subject: 'entity_id',
            matcher: {
              name: 'none_of',
              values: localEntityIds.value,
            },
          }
        : undefined

      const simpleFilters = [fieldFilter.value, entityIdFilter].filter((f) => !!f)

      return listFilteredEntities({
        filter:
          simpleFilters.length > 0 ? { conjunction: 'and', filters: simpleFilters } : undefined,
        projectId: relatedProjectId.value,
        workspaceId: workspaceId.value,
        after,
        first,
      })
    },
    {
      limit: 20,
      serializer: serializeEntity,
    },
  )

  const allLoadedEntities = computed(() => [
    ...selectedEntityLoader.data.value,
    ...unselectedEntityLoader.data.value,
  ])

  /** Clear all loaded selected/unselected entities */
  const resetAllData = () => {
    selectedEntityLoader.reset()
    unselectedEntityLoader.reset()
  }

  /**
   * Load more entities into the list. Selected entities will be
   * loaded first, then unselected entities.
   */
  const loadMoreEntities = async () => {
    if (!property.value.config.projectId) {
      // Not necessarily an error, the related project might have been
      // deleted
      return
    }

    if (selectedEntityLoader.canLoadMore.value) {
      await selectedEntityLoader.loadMore()
    }

    if (!selectedEntityLoader.canLoadMore.value && unselectedEntityLoader.canLoadMore.value) {
      await unselectedEntityLoader.loadMore()
    }
  }

  /**
   * The previews from the backend don't include property types so we need to call this
   * composable to augment the preview fields with property types.
   */
  const fieldPreviews = useFieldPreviews(
    computed(() => field.value.subprojectPreview),
    computed(() => relatedProjectStore.getPropertiesForReference(property.value.id)),
  )

  const setFieldValue = useSetFieldValue()
  const updateFieldValue = () => {
    const noChanges =
      savedEntityIds.value.length === localEntityIds.value.length &&
      previewEntities.value.every((e) => savedEntityIds.value?.includes(e.id))
    if (noChanges) {
      return
    }

    setFieldValue({
      entityId: field.value.entityId,
      projectId: projectId.value,
      fields: {
        [property.value.id]: {
          field: field.value,
          newValue: localEntityIds.value.length
            ? {
                conjunction: 'and',
                filters: [
                  {
                    subject: 'entity_id',
                    matcher: {
                      name: 'any_of',
                      values: localEntityIds.value,
                    },
                  },
                ],
              }
            : null,
        },
      },
      workspaceId: workspaceId.value,
    })
  }

  const serializeFieldToText = useSerializeFieldToText()
  /**
   * Returns the name of the entity to display in the badge and at the top of
   * the preview popover.
   */
  const getRelatedEntityName = (entity: FullOrPreviewEntity): string => {
    if (!nameProperty.value) {
      return ENTITY_NAME_FALLBACK
    }

    const nameField = 'fields' in entity && entity.fields.get(nameProperty.value.id)
    if (nameField) {
      return serializeFieldToText(nameField) || ENTITY_NAME_FALLBACK
    }

    return ENTITY_NAME_FALLBACK
  }

  const entityPreviewPopover = useTooltip()

  // Entity List Item that is currently hovered
  const listitemHoverElement = ref<HTMLElement | null>(null)
  const debouncedListItemHoverElement = useDebounce(
    listitemHoverElement,
    entityPreviewPopover.debounceMs,
  )
  const listItemHoverEntity = computed<ReferenceEntityListItem | null>(() => {
    if (!debouncedListItemHoverElement.value) {
      return null
    }

    const entityId = debouncedListItemHoverElement.value.dataset.entityId
    if (!entityId) {
      return null
    }

    return listItems.value.find((item) => item.id === entityId) || null
  })

  /** Returns true if and only if there is an related entity with the given id */
  const entityIsSelected = (id: string) => localEntityIds.value.some((entityId) => entityId === id)

  // Trigger the preview dialog when the user hovers over a list item
  const onMouseoverListItem = (item: ReferenceEntityListItem['data']) => {
    /**
     * Ideally we would be able to use a template ref here, but its made
     * difficult by the fact that the list item elements are rendered
     * within a slot, and in <ListMenu> the item is in a nested v-for.
     */
    const el = document.querySelector(`[data-entity-id="${item.id}"]`)
    if (el instanceof HTMLElement) {
      listitemHoverElement.value = el
      entityPreviewPopover.trigger.onMouseOver()
    }
  }

  // Remove the preview dialog when the user stops hovering over a list item
  const onMouseleaveListItem = () => {
    listitemHoverElement.value = null
    entityPreviewPopover.trigger.onMouseLeave()
  }

  /**
   * Toggles the entity in the list of selected entities.
   */
  const onToggleEntity = async (entity: ReferenceEntityListItem) => {
    /**
     * Trigger this event so that the preview dialog disappears. Without this,
     * the dialog loses its anchor and moves to the top left of the viewport.
     */

    if (entityIsSelected(entity.id)) {
      previewEntities.value = previewEntities.value.filter((e) => e.id !== entity.id)
      localEntityIds.value = localEntityIds.value.filter((id) => id !== entity.id)
    } else {
      previewEntities.value.push(entity.data)
      localEntityIds.value.push(entity.id)
    }
  }

  const listItems = computed<ReferenceEntityListItem[]>(() =>
    allLoadedEntities.value.map((entity) => {
      const nameField = nameProperty.value && entity.fields.get(nameProperty.value.id)
      const name = nameField ? serializeFieldToText(nameField) : ''
      return {
        id: entity.id,
        data: {
          ...entity,
          name,
          group: entityIsSelected(entity.id) ? 'selected' : 'unselected',
        },
      }
    }),
  )

  // Sync the selected entities when the backend value is updated
  watch(
    () => [savedEntityIds.value, fieldPreviews.value] as const,
    ([newIds, newPreviews]) => {
      // Build a map of entityId->preview so that the lookup is O(1)
      const previewMap = Object.fromEntries(previewEntities.value.map((e) => [e.id, e]))

      previewEntities.value = newIds.reduce<FullOrPreviewEntity[]>((acc, entityId) => {
        const fullEntity = allLoadedEntities.value.find((e) => e.id === entityId)
        if (fullEntity) {
          return [...acc, fullEntity]
        }

        const previewFieldMap = newPreviews[entityId]
        if (previewFieldMap) {
          return [
            ...acc,
            {
              id: entityId,
              fields: previewFieldMap,
            },
          ]
        }

        const previousPreview = previewMap[entityId]
        if (previousPreview) {
          return [...acc, previousPreview]
        }

        return acc
      }, [])
    },
    { immediate: true },
  )

  const entityLimit = computed(() => property.value.config.entityLimit)
  const hasReachedEntityLimit = computed(() => localEntityIds.value.length >= entityLimit.value)

  /**
   * We anchor the popover to the list item. If there is a scrollbar, we need
   * to account for this and increase the offset so that the popover doesn't
   * render above the scrollbar.
   */
  const getPreviewOffset = (placement: Placement, listMenuRef?: HTMLElement) => {
    const hasScrollbar = Boolean(
      listMenuRef?.scrollHeight && listMenuRef.scrollHeight > listMenuRef.clientHeight,
    )

    return {
      mainAxis: hasScrollbar && placement.startsWith('right') ? 16 : 5,
      alignmentAxis: -2,
    }
  }

  const noMatchFound = computed(
    () =>
      !!field.value.toolValue &&
      field.value.toolEntityIds?.length === 0 &&
      previewEntities.value.length === 0,
  )

  return {
    localEntityIds,
    searchText,
    resetAllData,
    loadMoreEntities,
    getRelatedEntityName,
    updateFieldValue,
    previewEntities,
    onToggleEntity,
    onMouseoverListItem,
    onMouseleaveListItem,
    nameProperty,
    listItems,
    entityPreviewPopover,
    debouncedListItemHoverElement,
    listItemHoverEntity,
    relatedProject,
    entityLimit,
    hasReachedEntityLimit,
    savedEntityIds,
    getPreviewOffset,
    allLoadedEntities,
    showSkeletonList,
    skeletonItems,
    noMatchFound,
  }
}
