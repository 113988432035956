import type { MarkdownSerializerState } from 'prosemirror-markdown'
import { MarkdownSerializer } from 'prosemirror-markdown'
import type { Mark, Node } from 'prosemirror-model'

/**
 * Copied from https://github.com/ProseMirror/prosemirror-markdown/blob/master/src/to_markdown.ts
 * because it's not exported from prosemirror-markdown.
 */
type MarkSerializerSpec = {
  /// The string that should appear before a piece of content marked
  /// by this mark, either directly or as a function that returns an
  /// appropriate string.
  open:
    | string
    | ((state: MarkdownSerializerState, mark: Mark, parent: Node, index: number) => string)
  /// The string that should appear after a piece of content marked by
  /// this mark.
  close:
    | string
    | ((state: MarkdownSerializerState, mark: Mark, parent: Node, index: number) => string)
  /// When `true`, this indicates that the order in which the mark's
  /// opening and closing syntax appears relative to other mixable
  /// marks can be varied. (For example, you can say `**a *b***` and
  /// `*a **b***`, but not `` `a *b*` ``.)
  mixable?: boolean
  /// When enabled, causes the serializer to move enclosing whitespace
  /// from inside the marks to outside the marks. This is necessary
  /// for emphasis marks as CommonMark does not permit enclosing
  /// whitespace inside emphasis marks, see:
  /// http:///spec.commonmark.org/0.26/#example-330
  expelEnclosingWhitespace?: boolean
  /// Can be set to `false` to disable character escaping in a mark. A
  /// non-escaping mark has to have the highest precedence (must
  /// always be the innermost mark).
  escape?: boolean
}

/**
 * Maps node names in the commonmark schema to functions that take a serializer
 * state and such a node, and serializes the node.
 *
 * This object will be imported extended with serializers for custom node types,
 * e.g. grounding claims, mentions.
 *
 * Is mostly copied from here:
 * https://github.com/ProseMirror/prosemirror-markdown/blob/1.13.1/src/to_markdown.ts#L74-L151
 * with some updates to fix linting/ts errors.
 */
export const nodeSerializers: {
  [node: string]: (state: MarkdownSerializerState, node: Node, parent: Node, index: number) => void
} = {
  blockquote(state, node) {
    state.wrapBlock('> ', null, node, () => state.renderContent(node))
  },
  code_block(state, node) {
    // Make sure the front matter fences are longer than any dash sequence within it
    const backticks = node.textContent.match(/`{3,}/gm)
    const fence = backticks ? backticks.sort().slice(-1)[0] + '`' : '```'

    state.write(fence + (node.attrs.params || '') + '\n')
    state.text(node.textContent, false)
    // Add a newline to the current content before adding closing marker
    state.write('\n')
    state.write(fence)
    state.closeBlock(node)
  },
  heading(state, node) {
    state.write(state.repeat('#', node.attrs.level) + ' ')
    state.renderInline(node, false)
    state.closeBlock(node)
  },
  horizontal_rule(state, node) {
    state.write(node.attrs.markup || '---')
    state.closeBlock(node)
  },
  bullet_list(state, node) {
    state.renderList(node, '  ', () => (node.attrs.bullet || '*') + ' ')
  },
  ordered_list(state, node) {
    const start = node.attrs.order || 1
    const maxW = String(start + node.childCount - 1).length
    const space = state.repeat(' ', maxW + 2)
    state.renderList(node, space, (i) => {
      const nStr = String(start + i)
      return state.repeat(' ', maxW - nStr.length) + nStr + '. '
    })
  },
  list_item(state, node) {
    state.renderContent(node)
  },
  paragraph(state, node) {
    state.renderInline(node)
    state.closeBlock(node)
  },

  image(state, node) {
    state.write(
      '![' +
        state.esc(node.attrs.alt || '') +
        '](' +
        node.attrs.src.replace(/[()]/g, '\\$&') +
        (node.attrs.title ? ' "' + node.attrs.title.replace(/"/g, '\\"') + '"' : '') +
        ')',
    )
  },
  hard_break(state, node, parent, index) {
    for (let i = index + 1; i < parent.childCount; i++)
      if (parent.child(i).type != node.type) {
        state.write('\\\n')
        return
      }
  },
  text(state, node) {
    const inAutolink = 'inAutolink' in state ? state.inAutolink : false
    state.text(node.text || '', !inAutolink)
  },

  mention: (state, node) => {
    const id = node.attrs.id
    if (typeof id !== 'string') {
      throw new Error('Mention Node must have string id')
    }
    state.write(`@<${id}>`)
  },
}

export const markSerializers: {
  [mark: string]: MarkSerializerSpec
} = {
  em: { open: '*', close: '*', mixable: true, expelEnclosingWhitespace: true },
  strong: { open: '**', close: '**', mixable: true, expelEnclosingWhitespace: true },
  link: {
    open(state, mark, parent, index) {
      if ('inAutolink' in state) {
        state.inAutolink = isPlainURL(mark, parent, index)
        return state.inAutolink ? '<' : '['
      }

      return '['
    },
    close(state, mark) {
      const inAutolink = 'inAutolink' in state ? state.inAutolink : false
      if ('inAutolink' in state) {
        state.inAutolink = undefined
      }

      return inAutolink
        ? '>'
        : '](' +
            mark.attrs.href.replace(/[()"]/g, '\\$&') +
            (mark.attrs.title ? ` "${mark.attrs.title.replace(/"/g, '\\"')}"` : '') +
            ')'
    },
    mixable: true,
  },
  code: {
    open(_state, _mark, parent, index) {
      return backticksFor(parent.child(index), -1)
    },
    close(_state, _mark, parent, index) {
      return backticksFor(parent.child(index - 1), 1)
    },
    escape: false,
  },
}

export const serializer = new MarkdownSerializer(nodeSerializers, markSerializers)

/**
 * Copied from
 * https://github.com/ProseMirror/prosemirror-markdown/blob/1.13.1/src/to_markdown.ts#L153-L160
 */
function backticksFor(node: Node, side: number) {
  const ticks = /`+/g
  let m
  let len = 0
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (node.isText) while ((m = ticks.exec(node.text!))) len = Math.max(len, m[0].length)
  let result = len > 0 && side > 0 ? ' `' : '`'
  for (let i = 0; i < len; i++) result += '`'
  if (len > 0 && side < 0) result += ' '
  return result
}

/**
 * Copied from
 * https://github.com/ProseMirror/prosemirror-markdown/blob/1.13.1/src/to_markdown.ts#L162-L166
 */
function isPlainURL(link: Mark, parent: Node, index: number) {
  if (link.attrs.title || !/^\w+:/.test(link.attrs.href)) return false
  const content = parent.child(index)
  if (
    !content.isText ||
    content.text != link.attrs.href ||
    content.marks[content.marks.length - 1] != link
  )
    return false
  return index == parent.childCount - 1 || !link.isInSet(parent.child(index + 1).marks)
}
