<script setup lang="ts">
import { deleteSpace } from '@/backend/deleteSpace'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import IconButton from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import { useSpaceStore } from './useSpaceStore'

const props = defineProps<{
  spaceId: string
}>()

const { workspaceId } = useRouteParams()

const spaceStore = useSpaceStore()
const onDeleteSpace = async () => {
  const revert = spaceStore.deleteSpace(props.spaceId)
  const res = await deleteSpace({ workspaceId: workspaceId.value, spaceId: props.spaceId })

  if (!res.ok) {
    revert()
  }
}
</script>

<template>
  <Menu.Root v-slot="{ getTriggerProps }">
    <IconButton
      icon="more-dots"
      aria-label="More options"
      size="xs"
      variant="transparent"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
      @click.prevent
    />
    <Menu.Content>
      <Menu.Item
        icon="trash"
        critical
        @click="onDeleteSpace"
      >
        Delete Chat
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
