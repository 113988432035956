import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const goTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Go',
  description: 'Dynamically adjusts the model to find the best answer.',
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  provider: ToolProvider.open_ai,
  icon: 'company-v7',
}
