import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const awsOcrTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'AWS OCR',
  description:
    'Provided by Amazon Textract, a state-of-the-art multi-language Optical Character Recognition model.',
  outputTypes: [PropertyType.text, PropertyType.json],
  provider: ToolProvider.amazon_textract,
  icon: 'ocr',
}
