<script lang="ts">
export const ITEM_HEIGHT = 32
export const COLS_BEFORE_LOAD = 4
</script>

<script setup lang="ts">
import {
  debouncedWatch,
  onKeyStroke,
  useElementSize,
  useEventListener,
  useScroll,
  useWindowSize,
} from '@vueuse/core'
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue'

import { bulkRemoveEntities } from '@/backend/bulkRemoveEntities'

import { useVerticalVirtualList } from '@/sharedComposables/useVerticalVirtualList'

import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'

import { getEntity } from '@/backend/getEntity'
import { setFieldValue } from '@/backend/setFieldValue'
import { isSelectAllKeybinding } from '@/modules/Project/keybindings'
import type { Property } from '@/modules/Project/Properties/types'
import { toast } from '@/shared/toast'
import { useSetFieldValue, type SaveValueParams } from '@/shared/useSetFieldValue'
import { keyboardTargetIsInput } from '@/shared/utils/event'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { captureException } from '@sentry/vue'
import pLimit from 'p-limit'
import { useBilling } from '../Billing/useBilling'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { useParentProject } from '../Project/useParentProject'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import ProjectTableActionBar from './ProjectTableActionBar.vue'
import ProjectTableAddRow from './ProjectTableAddRow.vue'
import ProjectTableContextMenu from './ProjectTableContextMenu.vue'
import ProjectTableFileCollectionSubscriber from './ProjectTableFileCollectionSubscriber.vue'
import ProjectTableHeader from './ProjectTableHeader.vue'
import ProjectTableRow from './ProjectTableRow.vue'
import { useAskGo } from './useAskGo'
import { useCreateEntity } from './useCreateEntity'
import { useFieldUploadQueue } from './useFieldUploadQueue'
import { useFilters } from './useFilters'
import { useListEntities } from './useListEntities'
import { usePinnedColumn } from './usePinnedColumn'
import { serializeEntity, serializeFields, useProject, type ViewInfo } from './useProject'
import { useProperty } from './useProperty'
import { useTable, type CellLocation } from './useTable'
import { useTableClipboard } from './useTableClipboard'
import {
  getGridCellFromEvent,
  TELEPORTED_CELL_SELECTOR,
  useTableInteractions,
} from './useTableInteractions'
import { ACTION_BAR_Z_INDEX, PINNED_SHADOW_Z_INDEX, useTableZIndices } from './useTableZIndices'

const props = defineProps<{
  parentEntityId?: string
}>()

const projectStore = useProject()

const workspacesStore = useWorkspaces()
const propertyStore = useProperty()
const parentProjectStore = useParentProject()

const pendingIdsToDelete = ref<string[]>([])

const {
  list,
  containerProps,
  wrapperProps,
  scrollTo,
  state: rangeState,
  // TODO: Try to remove in the integration PR,
  // as it may be fixed differently there
} = useVerticalVirtualList(
  computed(() => {
    return projectStore.activeView?.entities?.slice() ?? []
  }),
  { itemHeight: ITEM_HEIGHT, overscan: 20 },
)

const { width: tableWidth, height: tableHeight } = useElementSize(containerProps.ref)

const INITIAL_ENTITY_LOAD_COUNT = 50
const rowsInView = computed(() =>
  containerProps.ref ? Math.ceil(tableHeight.value / ITEM_HEIGHT) : INITIAL_ENTITY_LOAD_COUNT,
)

const listEntities = useListEntities()
const hasLoadedInitialRange = ref(false)
const loadRange = async ({ start, end }: (typeof rangeState)['value'], viewInfo?: ViewInfo) => {
  if (!projectStore.projectId || !workspacesStore.currentWorkspace || !viewInfo) {
    // this should not be possible, but typescript says it is
    return
  }

  // force the initial range state when trying to load entities for a view that has not been loaded yet
  if (!viewInfo.entities) {
    start = 0
    end = rowsInView.value
  }

  // if entities are stale we want to reload them all even if some are already loaded
  if (!projectStore.areEntitiesStale) {
    // shift the start index to the nearest non-loaded entity
    for (let i = start; i <= end; i++) {
      start = i
      if (!viewInfo.entities?.[i]) {
        break
      }
    }
    // shift the end index to the nearest non-loaded entity
    for (let i = end - 1; i > start; i--) {
      end = i + 1
      if (!viewInfo.entities?.[i]) {
        break
      }
    }
    if (start >= end) {
      // all entities in the range are already loaded
      return
    }
  }

  const entitiesResult = await listEntities({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    start,
    end,
    parentEntityId: props.parentEntityId,
    viewId: viewInfo.id,
  })

  if (!entitiesResult.ok) return

  const listedEntities = entitiesResult.data.data.map(serializeEntity)
  const totalCountFromServer = entitiesResult.data.metadata.total_count
  projectStore.spliceEntities(listedEntities, totalCountFromServer, start, viewInfo.id)
  if (projectStore.areEntitiesStale) {
    projectStore.dropEntitiesOutsideOfView(viewInfo.id, start, start + listedEntities.length)
    projectStore.setEntitiesStale(false)
  }
  hasLoadedInitialRange.value = true
}

/**
 * If we haven't loaded entities yet, then build an array of undefined
 * entities so that we can render a skeleton table.
 */
const entityList = computed<typeof list.value>(() =>
  hasLoadedInitialRange.value || list.value.length > 0
    ? list.value
    : Array.from({ length: INITIAL_ENTITY_LOAD_COUNT }).map((_, i) => ({
        data: undefined,
        index: i,
      })),
)

debouncedWatch(
  () => [rangeState.value, projectStore.activeView, projectStore.areEntitiesStale] as const,
  ([newRange, activeView]) => {
    loadRange(newRange, activeView)
  },
  { debounce: 100, immediate: true },
)

onBeforeUnmount(() => {
  projectStore.clearAllSelectedEntities()
})

const scrollIntoView = (container: HTMLElement, selector: string) => {
  const ellToScrollTo = container.querySelector(selector)
  if (
    ellToScrollTo &&
    !ellToScrollTo.isSameNode(document.activeElement) &&
    ellToScrollTo instanceof HTMLElement
  ) {
    ellToScrollTo.scrollIntoView()
  }
}

watch(
  () => propertyStore.savedProperty,
  (newVal) =>
    nextTick(() => {
      // if the property sidebar is open it might hide the selected column so we need to scroll to it
      if (newVal && containerProps.ref.value) {
        scrollIntoView(containerProps.ref.value, '[data-column-selected=true]')
      }
    }),
)

watch(
  () => projectStore.activeView?.id,
  () => {
    projectStore.clearAllSelectedEntities()
  },
)

watch(
  () => projectStore.visibleProperties.length,
  (newVal, oldVal) => {
    if (oldVal === 0) {
      /**
       * If the old value was 0 then either:
       * - the component was just mounted
       * - the user has just added a first property
       * In either case, we don't want to scroll.
       */
      return
    }

    nextTick(() => {
      // only scroll if we have added a col
      if (newVal > oldVal) {
        // scrollTo will trigger the scroll event,
        // which will also sync the header scroll position
        containerProps.ref.value?.scrollTo({ left: 999999 })
      }
    })
  },
)

watch(
  () => projectStore.activeView?.id,
  async () => {
    await nextTick()
    containerProps.ref.value?.scrollTo({ left: 0 })
  },
)

const { captureAnalyticsEvent } = useAnalytics()

const billingStore = useBilling()
const { createEntity } = useCreateEntity()
const addRow = async () => {
  const ok = await createEntity(props.parentEntityId)
  if (!ok) return

  captureAnalyticsEvent(ANALYTICS_EVENT.ENTITY_CREATED)

  // give the component a chance to rerender, then scroll to bottom
  nextTick(() => scrollTo((projectStore.activeView?.entities?.length ?? 0) - 1))
}

const removePendingDeletionEntities = async () => {
  if (
    !projectStore.projectId ||
    !workspacesStore.currentWorkspace ||
    pendingIdsToDelete.value.length <= 0
  ) {
    return
  }

  const result = await bulkRemoveEntities(
    workspacesStore.currentWorkspace.id,
    projectStore.projectId,
    pendingIdsToDelete.value,
  )
  if (result.ok) {
    if (billingStore.fieldUsage) {
      billingStore.fieldUsage.limitUsage -=
        pendingIdsToDelete.value.length * projectStore.properties.length
    }

    projectStore.removeEntitiesById(pendingIdsToDelete.value)
  }

  pendingIdsToDelete.value = []
}

const tableStore = useTable()
const selectedRange = computed(() => tableStore.selectedRange)

const tableBody = ref<HTMLElement | null>(null)
const { moveCellFocusDown, onTabKeystroke } = useTableInteractions({
  tableRef: tableBody,
  rowCount: computed(() => projectStore.activeView?.entities?.length ?? 0),
  columnCount: computed(() => projectStore.visibleProperties.length),
  canEditCells: computed(() => !!permissionStore.currentProjectPermissions.update_entities),
})

/**
 * Handle a table cell emitting the 'next' event. Depending on how the event
 * was triggered, we may need to move the focus horizontally or vertically.
 */
const onNext = (e?: KeyboardEvent) => {
  if (!e) {
    moveCellFocusDown()
    return
  }

  if (e.key === 'Enter') {
    moveCellFocusDown()
    return
  }

  if (e.key === 'Tab') {
    onTabKeystroke(e)
    return
  }
}

useFieldUploadQueue(2)

useTableClipboard(selectedRange)

const toggleRowSelection = (entityId: string) => {
  projectStore.toggleEntity(entityId, !isRowSelected(entityId))
}

const extendRowSelection = (rowIndex: number) => {
  const entity = projectStore.activeView?.entities?.at(rowIndex)
  if (entity) {
    projectStore.extendRowSelectionTo(entity.id)
  }
}

const isRowSelected = (entityId: string): boolean => projectStore.selectedEntityIds.has(entityId)

onKeyStroke('Escape', () => {
  projectStore.clearAllSelectedEntities()
})

/** Indices of all properties that should be rendered in this view */
const cells = computed(() => projectStore.visibleProperties.map((property, colIndex) => colIndex))

const scrollState = useScroll(containerProps.ref)

const windowSize = useWindowSize()
/**
 * Indices of all properties that are visible in the current
 * scrolled range
 */
const columnsInView = computed<number[]>(() => {
  if (!projectStore.projectLoaded) return [...new Array(COLS_BEFORE_LOAD).fill(0).map((_, i) => i)]

  const inView: number[] = []
  let processedWidth = 0
  const padding = windowSize.width.value * 2

  const viewStart = scrollState.x.value - padding
  const viewEnd = scrollState.x.value + tableWidth.value + padding

  projectStore.visibleProperties.forEach((property, index) => {
    const width = projectStore.getWidth(property.id)
    const columnStart = processedWidth
    const columnEnd = processedWidth + width
    processedWidth += width

    if (columnEnd < viewStart || columnStart > viewEnd) {
      return
    }

    inView.push(index)
  })

  return inView
})

const tableScrollStore = useTableZIndices()
// When the container ref is assigned, pass it to the Z Indices store so
// we can track its scroll position and assign the correct z-index to the header
// and first column.
watch(
  containerProps.ref,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      tableScrollStore.tableRef = newValue
    } else if (!newValue && oldValue) {
      tableScrollStore.tableRef = null
    }
  },
  {
    immediate: true,
  },
)

const filterStore = useFilters()
// Scroll to the top when the filters have changed, as the scroll position may be
// out of view after the new filters are applied.
watch(
  () => filterStore.currentFilter,
  () => {
    if (!tableScrollStore.tableRef) {
      return
    }

    tableScrollStore.tableRef.scrollTop = 0
  },
)

/**
 * We have 2 separate grid layouts - the header and the rest of the table.
 * This is so that the header is always rendered, even when the table flickers
 * out of view while we load more virtual rows.
 */
const gridTemplateColumns = computed(() => {
  let propertyWidths = [...new Array(COLS_BEFORE_LOAD)].map(() => '320px')

  if (projectStore.projectLoaded) {
    // Fixed first column, then auto size the property columns, then fill
    // the rest of the space with the 'add property' column
    propertyWidths = projectStore.visibleProperties.map(
      ({ id }) => `${projectStore.getWidth(id)}px`,
    )
  }

  return `${indexColWidth.value}px ${propertyWidths.join(' ')} minmax(32px, 1fr)`
})

const addRowGridStyles = computed(() => {
  return {
    gridTemplateColumns: gridTemplateColumns.value,
  }
})

const gridStyles = computed(() => {
  return {
    gridTemplateColumns: gridTemplateColumns.value,
    // Every row is the same height - entity rows and the header/footer
    gridTemplateRows: `repeat(${(projectStore.activeView?.entities?.length ?? 0) + 1}, ${ITEM_HEIGHT}px)`,
  }
})

const pinnedColumn = usePinnedColumn()
const firstColumnShadowStyles = computed(() => {
  if (!containerProps.ref.value) {
    return {}
  }

  const propertyWidths = projectStore.visibleProperties.map(({ id }) => projectStore.getWidth(id))
  const pinnedColWidth = propertyWidths
    .slice(0, pinnedColumn.numPinnedProperties)
    .reduce((acc, curr) => acc + curr, 0)

  const entityCount = projectStore.activeView?.entities?.length ?? 0
  /** Full height, minus the height of the bottom “new entity” row */
  const fullHeight = `calc(100% - ${ITEM_HEIGHT}px)`
  /** Exact height for existing number of rows */
  const rowCountHeight = `${(entityCount + 1) * ITEM_HEIGHT}px`

  return {
    height: `min(${fullHeight}, ${rowCountHeight})`,
    width: indexColWidth.value + pinnedColWidth + 'px',
  }
})

const onDeleteSelectedEntities = () => {
  contextMenu.value = null
  pendingIdsToDelete.value = Array.from(projectStore.selectedEntityIds)
}

const contextMenu = ref<
  | (CellLocation & {
      x: number
      y: number
    })
  | null
>(null)
const onContextMenu = (e: PointerEvent | MouseEvent) => {
  e.preventDefault()
  const cell = getGridCellFromEvent(e)
  if (!cell) {
    contextMenu.value = null
    return
  }

  const columnStart = projectStore.visibleProperties
    .slice(0, cell.colIndex)
    .reduce((acc, p) => acc + projectStore.getWidth(p.id), 0)

  // For virtual scrolling, we give the table a top margin to account for the
  // rows we don't render. We need to account for this top margin when
  // positioning the context menu.
  let marginTop = Number(wrapperProps.value.style.marginTop.replace('px', ''))
  if (isNaN(marginTop)) {
    captureException(new Error('Margin top is not a number'))
    marginTop = 0
  }

  contextMenu.value = {
    ...cell,
    x: indexColWidth.value + columnStart + e.offsetX,
    y: ITEM_HEIGHT * (cell.rowIndex + 1) + e.offsetY + 8 - marginTop,
  }

  if (!tableStore.hasSelectedMultipleCells || !tableStore.isCellInSelectedRange(cell)) {
    // If right clicking on a cell outside of the selected range, we should
    // clear the selected range and select the entity that was right clicked

    tableStore.clearFocusedAndSelected()
    const entityId = projectStore.activeView?.entities?.[cell.rowIndex]?.id
    if (!entityId) {
      return
    }

    // If already selected, leave as is
    if (entityId && projectStore.selectedEntityIds.has(entityId)) {
      return
    }

    // Otherwise, clear all and select this one
    projectStore.clearAllSelectedEntities()
    if (entityId) {
      toggleRowSelection(entityId)
    }
  }
}

const permissionStore = usePermissionsStore()

const onSetFieldValues = async (
  propertyId: string,
  valueMap: Partial<Record<string, string[] | null>>,
) => {
  const projectId = projectStore.projectId
  const workspaceId = workspacesStore.currentWorkspace?.id

  if (!projectId || !workspaceId) {
    throw new Error('Project ID or workspace ID is missing when setting field values')
  }

  projectStore.selectedEntityIds.clear()

  let successCount = 0
  // Limit to 10 outstanding requests at a time
  const limit = pLimit(10)
  await Promise.all(
    Object.entries(valueMap).map(([entityId, value]) =>
      limit(async () => {
        const res = await setFieldValue({
          entityId,
          projectId,
          workspaceId,
          fields: {
            [propertyId]: { options: value ?? [] },
          },
        })
        if (res.ok) {
          successCount++
        } else {
          const entityRes = await getEntity(workspaceId, projectId, entityId)

          if (entityRes.ok) {
            Array.from(serializeFields(entityRes.data.fields).values()).forEach(
              projectStore.updateField,
            )
          }
        }
      }),
    ),
  )

  const numberOfEntities = Object.keys(valueMap).length

  if (successCount === 0) {
    toast.error('Failed to bulk update values')
  } else if (successCount < numberOfEntities) {
    toast.warning(
      `Value applied to ${successCount} of ${numberOfEntities} entities. Some entities may have failed to update.`,
    )
  } else {
    toast.success(
      `Value applied to ${numberOfEntities} ${numberOfEntities > 1 ? 'entities' : 'entity'}`,
    )
  }
}

const indexColWidth = computed(() => {
  const baseWidth = 48 // Fits numbers up to 10k
  const extendedWidth = 64 // Gives more space for larger numbers to be shown
  return rangeState.value.end >= 10_000 ? extendedWidth : baseWidth
})

const containerScrollState = useScroll(containerProps.ref)

const askGoStore = useAskGo()

watch(
  () => projectStore.selectedProperty,
  (selectedProperty) => {
    if (!askGoStore.isOpen) return
    if (!selectedProperty) return

    const index = projectStore.visibleProperties.indexOf(selectedProperty)
    if (index === -1) return

    const width = projectStore.getWidth(selectedProperty.id)

    const columnStart = projectStore.visibleProperties
      .slice(0, index)
      .reduce((acc, p) => acc + projectStore.getWidth(p.id), 0)

    // This margin is computed as the size of the Property Configuration Menu, so it's never on top of the Ask Go sidebar
    const margin = 360

    if (
      columnStart + margin + containerScrollState.x.value > tableWidth.value ||
      columnStart < containerScrollState.x.value
    ) {
      containerScrollState.x.value = columnStart - tableWidth.value / 2 + width / 2
    }
  },
)

const updateField = useSetFieldValue()
/**
 * Handle the delete key press event when a range of cells is selected,
 * clearing the value from each cell.
 */
onKeyStroke(
  ['Delete', 'Backspace'],
  (event) => {
    if (!tableStore.selectedRange || keyboardTargetIsInput(event)) {
      return
    }

    const entities = projectStore.activeView?.entities?.slice(
      tableStore.selectedRange.start.rowIndex,
      tableStore.selectedRange.end.rowIndex + 1,
    )
    const propertyIds = projectStore.visibleProperties
      .map((p) => p.id)
      .slice(tableStore.selectedRange.start.colIndex, tableStore.selectedRange.end.colIndex + 1)

    const limit = pLimit(10)
    entities?.forEach((entity) =>
      limit(async () => {
        if (!entity) {
          return
        }
        assertIsNotNullOrUndefined(projectStore.projectId)
        assertIsNotNullOrUndefined(workspacesStore.currentWorkspace)

        const fields = Array.from(entity.fields.values()).reduce<SaveValueParams['fields']>(
          (acc, field) => {
            if (propertyIds.includes(field.propertyId)) {
              acc[field.propertyId] = { newValue: null, field }
            }
            return acc
          },
          {},
        )

        return updateField({
          fields,
          projectId: projectStore.projectId,
          workspaceId: workspacesStore.currentWorkspace?.id,
          entityId: entity.id,
        })
      }),
    )
  },
  { target: tableBody },
)

const fileCollections = computed<Property<'file_collection'>[]>(() =>
  projectStore.visibleProperties.filter((p) => p.type === 'file_collection'),
)

/**
 * Listen to cmdOrCtrl+a to toggle selection of all rows
 */
useEventListener(document, 'keydown', (e: KeyboardEvent) => {
  if (isSelectAllKeybinding(e) && !keyboardTargetIsInput(e)) {
    e.preventDefault() // Prevent selection of all text on screen
    projectStore.toggleAllEntities()
  }
})
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="w-full flex-1 [contain:strict]">
      <div class="z-0 contents">
        <div
          class="pointer-events-none absolute transition-opacity"
          :class="tableScrollStore.leftScroll === 0 ? 'opacity-0' : 'opacity-100'"
          :style="{
            ...firstColumnShadowStyles,
            zIndex: PINNED_SHADOW_Z_INDEX,
            // Tilt shadow to the right, rather than full tw shadow-sm
            boxShadow: '8px 0px 12px -8px rgb(0 0 0 / 0.05)',
          }"
        />
        <div class="flex h-full flex-col">
          <div
            class="flex-1 bg-surface-primary scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
            :class="{
              'rounded-tr-corner-12 border-r border-r-border-subtle': propertyStore.sidebarIsOpen,
            }"
            data-table-container
            data-test="table-container"
            role="grid"
            v-bind="containerProps"
            aria-multiselectable="true"
          >
            <div
              data-table-wrapper
              class="relative grid min-h-0 min-w-fit flex-1 flex-col bg-surface-primary"
              :style="gridStyles"
              v-bind="wrapperProps"
            >
              <div
                id="grid-container"
                ref="tableBody"
                class="contents select-none"
                @contextmenu="onContextMenu"
                @mousedown="contextMenu = null"
              >
                <ProjectTableHeader :index-col-width="indexColWidth" />
                <ProjectTableRow
                  v-for="{ data: row, index: rowIndex } in entityList"
                  :key="rowIndex"
                  :row-index="rowIndex"
                  :cells="cells"
                  :columns-in-view="columnsInView"
                  :plural-selection="projectStore.selectedEntityIds.size > 1"
                  :workspace-id="workspacesStore.currentWorkspace?.id"
                  :project-id="projectStore.projectId"
                  :entity-id="row?.id"
                  :index-col-width="indexColWidth"
                  @extend-selection="extendRowSelection(rowIndex)"
                  @toggle="row && toggleRowSelection(row.id)"
                  @next="onNext"
                />
              </div>
              <ProjectTableContextMenu
                v-if="contextMenu"
                :[`data-${TELEPORTED_CELL_SELECTOR}`]="''"
                :x="contextMenu.x"
                :y="contextMenu.y"
                :cell="{
                  rowIndex: contextMenu.rowIndex,
                  colIndex: contextMenu.colIndex,
                }"
                @close="contextMenu = null"
                @entities:delete="onDeleteSelectedEntities"
              />
              <ProjectTableActionBar
                v-if="projectStore.selectedEntityIds.size"
                class="fixed bottom-14 left-1/2 -translate-x-1/2"
                :class="ACTION_BAR_Z_INDEX"
                :style="{ zIndex: ACTION_BAR_Z_INDEX }"
                @delete="onDeleteSelectedEntities"
                @set-value="onSetFieldValues"
              />
            </div>
          </div>
          <ProjectTableAddRow
            v-if="
              permissionStore.currentProjectPermissions.create_entities &&
              (projectStore.activeView?.id == projectStore.mainView?.id ||
                parentProjectStore.isParentPropertyManualCollection)
            "
            class="grid"
            :number-of-properties="projectStore.properties.length"
            :index-col-width="indexColWidth"
            :style="addRowGridStyles"
            @create-entity="addRow"
          >
            New entity
          </ProjectTableAddRow>
          <div
            v-else
            class="col-span-full h-0 border-t border-border-subtle"
          />
        </div>
      </div>
    </div>
    <ConfirmationDialog
      :open="pendingIdsToDelete.length > 0"
      :title="`Delete ${pendingIdsToDelete.length > 1 ? 'these entities' : 'this entity'}?`"
      data-test="delete-confirmation-dialog"
      :description="`${
        pendingIdsToDelete.length > 1 ? 'These entities ' : 'This entity will'
      } will be deleted immediately. You can't undo this action.`"
      @confirm="removePendingDeletionEntities"
      @close="pendingIdsToDelete = []"
    />
    <ProjectTableFileCollectionSubscriber
      v-for="property in fileCollections"
      :key="property.id"
      :property="property"
    />
  </div>
</template>
