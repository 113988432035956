import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const gpt35Tool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'GPT 3.5 Turbo',
  description:
    'An early and cheap GPT series model. GPT4 Omni Mini is both cheaper and more powerful.',
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.url,
  ],
  provider: ToolProvider.open_ai,
  icon: 'company-openai',
}
