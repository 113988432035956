<script lang="ts">
export type IconButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
</script>

<script lang="ts" setup>
import { computed } from 'vue'
import type { IconName } from './IconName'
import IconSprite from './IconSprite.vue'

/**
 * This component is a partial implementation of the IconButton component on figma
 *
 * https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/V7-Design-System-(WIP)?node-id=163:3109
 *
 * It's a WIP so subject to change
 */
const props = defineProps<{
  icon?: IconName
  size: IconButtonSize
  variant: 'brand' | 'neutral' | 'outline' | 'transparent' | 'critical' | 'black'
  active?: boolean
  /**
   * Allow "true" and "false" as strings to be passed in because this is how
   * headless component libraries (i.e. zag.js) pass in booleanish attributes
   * to DOM elements.
   */
  disabled?: boolean | 'true' | 'false'
  rounded?: boolean
}>()

const disabledBool = computed(() => props.disabled === true || props.disabled === 'true')
</script>

<template>
  <button
    :disabled="disabledBool"
    class="flex select-none flex-col items-center justify-center transition-colors focus-visible:shadow-focus-ring-primary focus-visible:outline-none"
    :class="[
      disabledBool ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
      rounded && 'rounded-full',
      // Sizes
      size === 'xs' && `size-4 ${!rounded && 'rounded-corner-4'}`,
      size === 'sm' && `size-5 ${!rounded && 'rounded-corner-4'}`,
      size === 'md' && `size-6 ${!rounded && 'rounded-corner-6'}`,
      size === 'lg' && `size-7 ${!rounded && 'rounded-corner-8'}`,
      size === 'xl' && `size-8 ${!rounded && 'rounded-corner-10'}`,
      size === 'xxl' && `size-9 ${!rounded && 'rounded-corner-12'}`,
      // Variants
      variant === 'transparent' && [
        'text-icon-subtle',
        'border-icon-subtle',
        'focus-visible:bg-background-transparent-pressed',
        'focus-visible:bg-surface-secondary',
        'focus-visible:text-icon-subtle',
        'bg-background-transparent',
        !disabledBool &&
          'data-[state=open]:bg-background-transparent-hovered hover:bg-background-transparent-hovered',
        !disabledBool && 'active:bg-background-transparent-pressed',
        active && '!bg-background-transparent-pressed',
      ],
      variant === 'neutral' && [
        'text-icon-subtle',
        'border-icon-subtle',
        'focus-visible:shadow-focus-ring-primary',
        'focus-visible:bg-surface-secondary',
        'focus-visible:text-icon-subtle',
        'bg-background-gray-subtlest',
        !disabledBool && 'hover:bg-background-gray-subtlest-hovered',
        !disabledBool && 'active:bg-background-gray-subtlest-pressed',
        active && '!bg-background-gray-subtlest-pressed',
      ],
      variant === 'brand' && [
        'bg-background-primary',
        'text-text-inverted-irreversible',
        !disabled && 'hover:bg-background-primary-hovered',
        !disabled && 'active:bg-background-primary-pressed',
      ],
      variant === 'black' && [
        'text-text-inverted',
        'bg-background-black',
        !disabled && 'hover:bg-background-black-hovered',
        !disabled && 'active:bg-background-black-pressed',
      ],
      variant === 'critical' && [
        'text-text-inverted-irreversible',
        'bg-background-critical',
        !disabled && 'hover:bg-background-critical-hovered',
        !disabled && 'active:bg-background-critical-pressed',
      ],
      variant === 'outline' && [
        'text-icon-subtle',
        'border border-border',
        'focus-visible:bg-background-transparent-pressed',
        'focus-visible:bg-surface-secondary',
        'focus-visible:text-icon-subtle',
        'bg-background-transparent',
        !disabled &&
          'data-[state=open]:bg-background-transparent-hovered hover:bg-background-transparent-hovered',
        !disabled && 'active:bg-background-transparent-pressed',
        active && '!bg-background-transparent-pressed',
      ],
    ]"
  >
    <IconSprite
      v-if="icon"
      :icon="icon"
      :size="size"
    />
    <slot v-else />
  </button>
</template>
