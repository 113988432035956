<script setup lang="ts">
import type { Claim } from '@/modules/Project/useGroundingStore'
import { invariant } from '@/shared/utils/typeAssertions'
import { useGroundingInteractions } from '@/sharedComposables/useGroundingInteractions'
import { useEventListener } from '@vueuse/core'
import { computed, useTemplateRef } from 'vue'
import ProseMirror, { type ProseMirrorProps } from '../ProseMirror/ProseMirror.vue'
import { parser } from './parser'
import ProseMirrorClaim from './ProseMirrorClaim.vue'
import { schema } from './schema'
import { serializer } from './serializer'

type Props = Pick<ProseMirrorProps, 'ariaLabel' | 'ariaLabelledBy' | 'placeholder' | 'value'>
const props = defineProps<Props & { claims: Claim[] }>()

/**
 * The grounded text, with claims inserted at the correct positions. Claims are
 * serialized in a way that will be picked up and parsed by our custom
 * markdown-it plugin.
 */
const textWithClaims = computed(() => {
  type ClaimStartOrEnd = { position: number; type: 'start' | 'end' } & Claim
  const claimMarks = props.claims.reduce<Array<ClaimStartOrEnd>>((acc, claim) => {
    acc.push({ position: claim.start, type: 'start', ...claim })
    acc.push({ position: claim.end, type: 'end', ...claim })
    return acc
  }, [])

  const getSerializedClaims = (claim: ClaimStartOrEnd) => {
    if (claim.type === 'start') {
      return `<CLAIM_START:${claim.id}>`
    }

    if (claim.type === 'end') {
      return claim.sources
        .map((sourceId) => `<CLAIM:${claim.id}:${sourceId}:${claim.start}:${claim.end}>`)
        .join('')
    }
  }

  const toolValueWithClaims = claimMarks
    // Process the claims in reverse order to avoid changing the positions of
    // text that is yet to be processed
    .toSorted((a, b) => b.position - a.position)
    .reduce((text, claim) => {
      const insertClaimAt = claim.position
      return `${text.slice(0, insertClaimAt)}${getSerializedClaims(claim)}${text.slice(insertClaimAt)}`
    }, props.value)

  return toolValueWithClaims
})

const { clickClaimPill, selected } = useGroundingInteractions({
  /**
   * Scroll to a claim when a source bounding box pill is clicked.
   */
  onSourcePillClick: ({ sourceId }) => {
    const view = editor.value?.view
    invariant(view)

    const claim = document.querySelector(`[data-claim-pill-source="${sourceId}"]`)
    invariant(claim)

    claim.scrollIntoView({ behavior: 'smooth' })
    selected.value = { sourceId }
  },
})

const editor = useTemplateRef('editor')
/** Remove all faint marks from the view. */
const removeAllFaintMarks = () => {
  const view = editor.value?.view
  invariant(view)

  const docStart = view.state.doc.resolve(0).start()
  const docEnd = view.state.doc.resolve(0).end()
  const tr = view.state.tr.removeMark(docStart, docEnd, schema.marks.faint)
  view.dispatch(tr)
}

/**
 * Scroll to a source bounding box when a claim is clicked.
 */
useEventListener('click', (event: Event) => {
  if (!(event.target instanceof HTMLElement)) {
    return
  }

  const claimIndicator = event.target.closest('[data-claim-pill-claim]')
  const sourceIndicator = event.target.closest('[data-source]')
  if (!claimIndicator) {
    if (!sourceIndicator) {
      removeAllFaintMarks()
    }
    return
  }

  const claimId = claimIndicator.getAttribute('data-claim-pill-claim')
  const sourceId = claimIndicator.getAttribute('data-claim-pill-source')

  if (!claimId || !sourceId) {
    return
  }

  clickClaimPill(Number(claimId), Number(sourceId))
})
</script>

<template>
  <ProseMirror
    v-bind="$attrs"
    ref="editor"
    :mode="{
      parser,
      serializer,
      schema,
      nodeViewOptions: [
        {
          name: 'claim',
          component: ProseMirrorClaim,
        },
      ],
    }"
    readonly
    :value="textWithClaims"
  />
</template>

<style lang="scss">
// List markers are rendered outside of the nodes to which we attach the `faint` mark,
// so we have this workaround fade them out.
li:has([data-faint])::marker {
  color: var(--color-text-disabled);
}
</style>
