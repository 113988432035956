import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const dallE3Tool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'DALL-E 3',
  description: 'Dall-E 3 is a text-to-image model by OpenAI.',
  outputTypes: [PropertyType.file],
  provider: ToolProvider.open_ai,
  icon: 'company-openai',
}
