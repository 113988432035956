<script setup lang="ts">
import type { Property } from '@/modules/Project/Properties/types'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import FilterItemEntityId from './FilterItemEntityId.vue'
import FilterItemSelect from './FilterItemSelect.vue'
import FilterItemText from './FilterItemText.vue'
import { isFilterableProperty, isTextLikeFilter, type SimpleFilter } from './types'

const props = defineProps<{
  filter: SimpleFilter
  index: number
  property: Property | undefined
}>()

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'update', payload: { filter: SimpleFilter; index: number }): void
}>()

const onUpdate = (filter: SimpleFilter) => {
  emit('update', { filter, index: props.index })
}
</script>

<template>
  <FilterItemSelect
    v-if="filter.subject === 'field_select_option_value' && isSelectProperty(property)"
    :filter="filter"
    :property="property"
    @delete="$emit('delete')"
    @update="onUpdate"
  />
  <FilterItemText
    v-else-if="isTextLikeFilter(filter) && property && isFilterableProperty(property)"
    :filter="filter"
    :property="property"
    @delete="$emit('delete')"
    @update="onUpdate"
  />
  <FilterItemEntityId
    v-else-if="filter.subject === 'entity_id'"
    :filter="filter"
    @delete="$emit('delete')"
    @update="onUpdate"
  />
</template>
