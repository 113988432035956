import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const imagenTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Imagen',
  description: "Imagen is Google's text-to-image model.",
  outputTypes: [PropertyType.file],
  provider: ToolProvider.google_ai,
  icon: 'company-google',
}
