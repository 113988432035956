export async function getFirstNBytes(file: File, n: number) {
  // Ensure n is not greater than the file size
  const bytesToRead = Math.min(n, file.size)

  // Slice the first n bytes of the file
  const slice = file.slice(0, bytesToRead)

  // Convert the slice to an ArrayBuffer
  const arrayBuffer = await slice.arrayBuffer()

  return arrayBuffer
}

export function bufferToString(buffer: ArrayBuffer) {
  const decoder = new TextDecoder('utf-8') // Use the correct encoding
  return decoder.decode(new Uint8Array(buffer))
}

export const fileNameIsPdf = (filename: string) => {
  return filename.replace(/\?.*$/, '').toLowerCase().endsWith('.pdf')
}

const extensionToType = {
  pdf: 'application/pdf',
  '': 'application/octet-stream',
  png: 'image/png',
  jpg: 'image/jpeg',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
} as const
type FileExtension = keyof typeof extensionToType

function isFileExtension(extension: string): extension is FileExtension {
  return Object.keys(extensionToType).includes(extension)
}

/**
 * Programatically triggers the input+change events on an input of type file
 */
export async function addFilesToInput(fileUrl: string, inputElement: HTMLInputElement) {
  // Fetch the PDF file and convert it to a Blob
  const fileToAddRes = await fetch(fileUrl)
  const blobToAdd = await fileToAddRes.blob()

  const fileName = fileUrl.split('/').pop() ?? ''
  const fileExtension = fileName?.split('.').pop() ?? ''
  const fileType = isFileExtension(fileExtension)
    ? extensionToType[fileExtension]
    : 'application/octet-stream'

  // Create a new File object from the Blob
  const fileToAdd = new File([blobToAdd], fileName, { type: fileType })

  // Create a DataTransfer object and add the File to it
  // It's hacky, but we need to do it
  // https://dev.to/code_rabbi/programmatically-setting-file-inputs-in-javascript-2p7i
  const list = new DataTransfer()
  list.items.add(fileToAdd)

  inputElement.files = list.files
  // Dispatch the input and change events
  inputElement.dispatchEvent(new Event('change', { bubbles: true }))
  inputElement.dispatchEvent(new Event('input', { bubbles: true }))
}

/**
 * A recursive data structure, meant to represent a filesystem directory.
 */
export type Directory = {
  name: string
  children: Array<Directory | File>
}

/**
 * Handle a drop event on the dropzone, and build a directory structure from the
 * dropped directories. If no directories were dropped (i.e. files were dropped),
 * then this function does nothing and the event is handled by the FileUpload component.
 *
 * We can't handle this event in the FileUpload component because `<input type="file">`
 * can accept *either* files or directories, but not both. So this is a workaround
 * to allow both files and directories to be dropped onto the FileCell.
 */
export const getFilesAndDirectoriesFromDragEvent = async (
  e: DragEvent,
): Promise<(Directory | File)[] | null> => {
  if (!e.dataTransfer) {
    return null
  }

  const directories: Array<Directory | File> = []
  await Promise.all(
    Array.from(e.dataTransfer.items).map(async (item) => {
      const entry = item.webkitGetAsEntry()
      if (!entry) {
        return
      }

      const directory: Directory = {
        name: entry.name,
        children: [],
      }
      // This method isn't available in all browsers, so if it's not available we return early.
      if (!item.getAsFileSystemHandle) {
        return
      }

      const handle = await item.getAsFileSystemHandle()
      if (handle.kind === 'file') {
        const file = await handle.getFile()
        directories.push(file)
        return
      }

      for await (const value of handle.values()) {
        if (value.kind === 'directory' || value.name === '.DS_Store') {
          /**
           * TODO: GO-2797
           * Handle nested directories
           */
          continue
        }

        const file = await value.getFile()
        directory.children.push(file)
      }

      directories.push(directory)
    }),
  )

  return directories
}

export const getFilenameFromUrl = (url: string) => {
  return new URL(url).pathname.split('/').pop() || null
}
