import { MarkdownSerializer } from 'prosemirror-markdown'
import { markSerializers, nodeSerializers } from '../MarkdownEditor/serializer'

/**
 * Serializes a ProseMirror document (with grounding claims) into a markdown
 * string.
 */
export const serializer = new MarkdownSerializer(
  {
    ...nodeSerializers,
    /**
     * We don't serialize claims, because (for now) we don't support editing
     * markdown documents that contain grounding claims.
     */
    claim() {},
    claim_start() {},
  },
  {
    ...markSerializers,
    faint: {
      open: '',
      close: '',
    },
  },
)
