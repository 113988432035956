import { getEntity } from '@/backend/getEntity'
import { listEntityIds, type ListEntityIdsArgs } from '@/backend/listEntityIds'
import { listFilteredEntityIds } from '@/backend/listFilteredEntityIds'
import { useEntity } from '@/modules/Project/useEntity'
import { serializeEntity, useProject } from '@/modules/Project/useProject'
import { useResolveEntityRoute } from '@/modules/Project/useResolveEntityRoute'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getGroupFilter, useFilters } from './useFilters'

export const usePrevOrNextEntity = () => {
  const entityStore = useEntity()
  const filtersStore = useFilters()
  const projectStore = useProject()
  const router = useRouter()
  const { parentEntityId, workspaceId, parentProjectId, parentViewId } = useRouteParams()
  const resolveEntityRoute = useResolveEntityRoute()

  const listEntityIdsFn = (args: ListEntityIdsArgs, activeViewId?: string) => {
    if (!filtersStore.currentFilter || filtersStore.currentFilter.filters.length === 0) {
      return listEntityIds({
        ...args,
        active_view_id: activeViewId,
        parent_entity_id: parentEntityId.value || undefined,
      })
    }

    return listFilteredEntityIds({
      ...args,
      filter: getGroupFilter({
        filter: filtersStore.currentFilter,
        activeViewId,
        parentEntityId: parentEntityId.value || undefined,
      }),
    })
  }

  const projectId = computed(() => projectStore.projectId ?? '')
  const entityId = computed(() => entityStore.entityId ?? '')
  const viewId = computed(() => projectStore.activeView?.id ?? '')

  const loadNeighbours = async () => {
    if (!workspaceId.value || !projectId.value || !entityId.value) {
      return
    }

    if (!entityStore.nextId) {
      listEntityIdsFn(
        {
          workspaceId: workspaceId.value,
          projectId: projectId.value,
          after: entityId.value,
          first: 25,
        },
        projectStore.activeView?.id,
      ).then((result) => {
        if (!result.ok) return
        if (result.data.data.length === 0) return
        const minId = result.data.data[0]
        entityStore.insertNextIds(result.data.data, entityId.value)

        getEntity(workspaceId.value, projectId.value, minId).then((result) => {
          if (!result.ok) return
          entityStore.updateEntity(serializeEntity(result.data))
        })
      })
    } else if (!entityStore.next) {
      getEntity(workspaceId.value, projectId.value, entityStore.nextId).then((result) => {
        if (!result.ok) return
        entityStore.updateEntity(serializeEntity(result.data))
      })
    }

    if (!entityStore.prevId) {
      listEntityIdsFn(
        {
          workspaceId: workspaceId.value,
          projectId: projectId.value,
          before: entityId.value,
          last: 25,
        },
        projectStore.activeView?.id,
      ).then((result) => {
        if (!result.ok) return
        if (result.data.data.length === 0) return
        const maxId = result.data.data[result.data.data.length - 1]
        entityStore.insertPrevIds(result.data.data, entityId.value)

        getEntity(workspaceId.value, projectId.value, maxId).then((result) => {
          if (!result.ok) return
          entityStore.updateEntity(serializeEntity(result.data))
        })
      })
    } else if (!entityStore.prev) {
      getEntity(workspaceId.value, projectId.value, entityStore.prevId).then((result) => {
        if (!result.ok) return
        entityStore.updateEntity(serializeEntity(result.data))
      })
    }
  }

  const previousEntityId = computed(() => entityStore.prev?.id)
  const nextEntityId = computed(() => entityStore.next?.id)

  const entityLoader = useDataLoader(() => {
    return getEntity(workspaceId.value, projectId.value, entityId.value)
  })

  const entityLoaded = ref(false)
  const loadAndSetEntity = async (entityId: string) => {
    if (!projectStore.projectLoaded || !workspaceId.value || !projectId.value) {
      return
    }

    entityStore.setEntityId(entityId)

    const result = await entityLoader.load()
    if (result.ok) {
      const loadedEntity = serializeEntity(result.data)
      if (entityStore.entity?.id !== loadedEntity.id) {
        entityStore.entities = [{ id: loadedEntity.id, entity: loadedEntity }]
      } else {
        entityStore.updateEntity(loadedEntity)
      }
      entityLoaded.value = true
      entityStore.areEntitiesStale = false
      loadNeighbours()
    }
  }

  const navigateToPrevEntity = () => {
    if (!previousEntityId.value) return

    const route = resolveEntityRoute({
      entityId: previousEntityId.value,
      parentEntityId: parentEntityId.value,
      parentProjectId: parentProjectId.value,
      parentViewId: parentViewId.value,
      projectId: projectId.value,
      viewId: viewId.value,
      workspaceId: workspaceId.value,
    })

    router.push(route)
  }

  const navigateToNextEntity = () => {
    if (!nextEntityId.value) return

    const route = resolveEntityRoute({
      entityId: nextEntityId.value,
      parentEntityId: parentEntityId.value,
      parentProjectId: parentProjectId.value,
      parentViewId: parentViewId.value,
      projectId: projectId.value,
      viewId: viewId.value,
      workspaceId: workspaceId.value,
    })

    router.push(route)
  }

  return {
    loadNeighbours,
    previousEntityId,
    nextEntityId,
    loadAndSetEntity,
    entityLoaded,
    navigateToNextEntity,
    navigateToPrevEntity,
  }
}
