<script setup lang="ts">
import { hasUrlScheme } from '@/shared/utils/string'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import InlineTextField from '@/uiKit/InlineTextField.vue'
import Menu from '@/uiKit/Menu'
import SelectDropdownTrigger from '@/uiKit/SelectDropdownTrigger.vue'
import { useEventListener } from '@vueuse/core'
import { ref } from 'vue'

defineProps<{
  bold: boolean
  italic: boolean
  code: boolean
  link: boolean
  isBulletListActive: boolean
  isOrderedListActive: boolean
  isBlockquoteActive: boolean
  textStyleLabel?: string
}>()

const emit = defineEmits<{
  (
    e:
      | 'toggle:bold'
      | 'toggle:italic'
      | 'toggle:code'
      | 'insert:heading1'
      | 'insert:heading2'
      | 'insert:heading3'
      | 'insert:paragraph'
      | 'insert:bullet-list'
      | 'insert:numbered-list'
      | 'insert:code-block'
      | 'insert:quote'
      | 'insert:divider'
      | 'remove:link'
      | 'close',
  ): void
  (e: 'insert:link' | 'insert:image', href: string): void
}>()

const showTextInput = ref(false)
const insertingLink = ref(false)
const insertingImage = ref(false)
const linkHref = ref('')

const openTextInput = (mode: 'image' | 'link') => {
  if (mode === 'image') insertingImage.value = true
  if (mode === 'link') insertingLink.value = true
  showTextInput.value = true
}

const submit = () => {
  if (!linkHref.value) return

  const linkWithScheme = hasUrlScheme(linkHref.value) ? linkHref.value : `https://${linkHref.value}`

  if (insertingLink.value) {
    emit('insert:link', linkWithScheme)
  } else if (insertingImage.value) {
    emit('insert:image', linkWithScheme)
  }
  showTextInput.value = false
  insertingLink.value = false
  insertingImage.value = false
}

const cancel = () => {
  showTextInput.value = false
  linkHref.value = ''
  insertingLink.value = false
  insertingImage.value = false
}

useEventListener(
  'keydown',
  (e) => {
    if (e.key === 'Escape') {
      e.stopImmediatePropagation()
      emit('close')
    }
  },
  {
    /**
     * capture: true is necessary to prevent the escape keydown from being
     * picked up by a ModalDialog, which will close the dialog and clear
     * the editor from the DOM.
     */
    capture: true,
  },
)
</script>

<template>
  <div
    id="prosemirror-toolbar"
    class="z-1 flex flex-row gap-1.5"
  >
    <div
      class="flex items-center gap-0.5 rounded-corner-10 bg-surface-popover p-0.5 shadow-sm outline outline-1 outline-border-subtle"
    >
      <template v-if="showTextInput">
        <form
          class="contents"
          @submit.prevent="submit"
        >
          <InlineTextField
            :value="linkHref"
            required
            autofocus
            :style="{ width: '300px' }"
            placeholder="Enter URL"
            size="sm"
            @input="linkHref = $event"
          />
          <DarwinButton
            variant="neutral"
            size="sm"
            @mousedown.prevent="cancel"
          >
            Cancel
          </DarwinButton>

          <DarwinButton
            variant="black"
            size="sm"
            @mousedown.prevent="submit"
          >
            Save
          </DarwinButton>
        </form>
      </template>

      <template v-else>
        <Menu.Root
          v-slot="{ getTriggerProps, menu }"
          disable-teleport
          :positioning="{ offset: { mainAxis: 4 }, sameWidth: true }"
        >
          <SelectDropdownTrigger
            v-bind="getTriggerProps()"
            role="button"
            class="w-max"
            inline
            :active="menu.open"
            >{{ textStyleLabel ?? 'Text style' }}</SelectDropdownTrigger
          >
          <Menu.Content class="min-w-56">
            <Menu.Item @select="$emit('insert:heading1')">Heading 1</Menu.Item>
            <Menu.Item @select="$emit('insert:heading2')">Heading 2</Menu.Item>
            <Menu.Item @select="$emit('insert:heading3')">Heading 3</Menu.Item>
            <Menu.Divider />
            <Menu.Item @select="$emit('insert:paragraph')">Paragraph</Menu.Item>
            <Menu.Divider />
            <Menu.Item @select="$emit('insert:code-block')">Code</Menu.Item>
          </Menu.Content>
        </Menu.Root>

        <DividerLine
          :width="1"
          direction="vertical"
          color="default"
          style="height: 16px"
        />

        <IconButton
          icon="bold"
          title="Bold"
          aria-label="Bold"
          size="lg"
          :active="bold"
          variant="transparent"
          @mousedown.prevent="$emit('toggle:bold')"
          @keydown.space.stop.prevent="$emit('toggle:bold')"
          @keydown.enter.stop.prevent="$emit('toggle:bold')"
        />
        <IconButton
          icon="italic"
          title="Italic"
          aria-label="Italic"
          size="lg"
          :active="italic"
          variant="transparent"
          @mousedown.prevent="$emit('toggle:italic')"
        />
        <IconButton
          icon="link"
          :active="link"
          title="Link"
          aria-label="Link"
          size="lg"
          variant="transparent"
          @mousedown.prevent="link ? $emit('remove:link') : openTextInput('link')"
        />
        <IconButton
          icon="code-block"
          title="Code"
          aria-label="Code"
          size="lg"
          :active="code"
          variant="transparent"
          @mousedown.prevent="$emit('toggle:code')"
        />

        <DividerLine
          :width="1"
          direction="vertical"
          color="default"
          style="height: 16px"
        />

        <IconButton
          icon="bullet-list"
          title="Bullet list"
          aria-label="Bullet list"
          size="lg"
          :active="isBulletListActive"
          variant="transparent"
          @mousedown.prevent="$emit('insert:bullet-list')"
        />
        <IconButton
          icon="numbered-list"
          title="Numbered list"
          aria-label="Numbered list"
          size="lg"
          :active="isOrderedListActive"
          variant="transparent"
          @mousedown.prevent="$emit('insert:numbered-list')"
        />
        <IconButton
          icon="quote"
          title="Quote"
          aria-label="Quote"
          size="lg"
          :active="isBlockquoteActive"
          variant="transparent"
          @mousedown.prevent="$emit('insert:quote')"
        />
      </template>
    </div>
    <Menu.Root
      v-if="!showTextInput"
      v-slot="{ getTriggerProps, menu }"
      disable-teleport
      :positioning="{ offset: { mainAxis: 4 } }"
    >
      <div
        class="rounded-corner-10 bg-surface-popover p-0.5 shadow-sm outline outline-1 outline-border-subtle"
      >
        <IconButton
          icon="plus"
          title="Insert more"
          aria-label="More"
          size="lg"
          variant="transparent"
          v-bind="getTriggerProps()"
          :active="menu.open"
        />
      </div>
      <Menu.Content class="min-w-56">
        <Menu.Item @select="$emit('insert:divider')">Divider</Menu.Item>
        <Menu.Item @select="openTextInput('image')">Image</Menu.Item>
      </Menu.Content>
    </Menu.Root>
  </div>
</template>
