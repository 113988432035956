import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const ocrTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'OCR',
  description: 'OCR is our state-of-the-art multi-language Optical Character Recognition model.',
  outputTypes: [PropertyType.text, PropertyType.json],
  provider: ToolProvider.azure_ocr,
  icon: 'ocr',
  usesPrompt: false,
}
