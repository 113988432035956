<script setup lang="ts">
import type { FileProperty } from '@/modules/Project/Properties/types'
import MarkdownWithGroundingClaims from '@/sharedComponents/MarkdownWithGroundingClaims/MarkdownWithGroundingClaims.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import TabList from '@/uiKit/TabList.vue'
import { computed, provide, watchEffect } from 'vue'
import { useRoutes } from '../App/useRoutes'
import GroundingModalFile from './GroundingModalFile.vue'
import { TYPE_ICON } from './icons'
import { useGroundingFeedback } from './useGroundingFeedback'
import { useGroundingStore } from './useGroundingStore'
import { useOpenGrounding } from './useOpenGrounding'
import { usePrevOrNextEntity } from './usePrevOrNextEntity'
import { useProject } from './useProject'

provide('in-grounding', true)

const projectStore = useProject()
const groundingStore = useGroundingStore()
const { previousEntityId, nextEntityId, navigateToPrevEntity, navigateToNextEntity } =
  usePrevOrNextEntity()
const { openGrounding } = useOpenGrounding()

// Keep the store in sync with the field that the open modal is showing
watchEffect(async () => {
  if (!groundingStore.field) {
    return
  }

  const firstInput = groundingStore.inputFields[0]
  if (!firstInput || groundingStore.activeInputId) {
    return
  }
  groundingStore.activeInputId = firstInput.propertyId
})

const { captureAnalyticsEvent } = useAnalytics()

watchEffect(() => {
  if (!groundingStore.field) return
  captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_OPENED)
})

const targetProperty = computed(() =>
  groundingStore.field ? projectStore.propertiesById[groundingStore.field.propertyId] : null,
)

const targetFieldValue = computed(() => {
  return groundingStore.field?.manualValue ?? groundingStore.field?.toolValue
})

const pdfProperties = computed(() => {
  return groundingStore.inputFields
    .map((field) => projectStore.propertiesById[field.propertyId])
    .filter((property): property is FileProperty => {
      return (
        property?.type === 'file' &&
        groundingStore.sources.some((source) => source.propertyId === property.id)
      )
    })
})

const activeInputName = computed(() => {
  if (!groundingStore.activeInputId) return undefined
  return projectStore.propertiesById[groundingStore.activeInputId]?.name
})

const shouldShowSourceTabs = computed(() => groundingStore.inputFields.length > 1)

const { currentlyInEntityView } = useRoutes()
async function changeToPrevEntity() {
  if (!groundingStore.field?.propertyId) return
  await openGrounding({
    entityId: previousEntityId.value,
    propertyId: groundingStore.field?.propertyId,
  })

  if (currentlyInEntityView.value) {
    navigateToPrevEntity()
  }
}

async function changeToNextEntity() {
  if (!groundingStore.field?.propertyId) return
  openGrounding({ entityId: nextEntityId.value, propertyId: groundingStore.field?.propertyId })
  if (currentlyInEntityView.value) {
    navigateToNextEntity()
  }
}

const submitGroundingFeedback = useGroundingFeedback()
</script>

<template>
  <ModalDialog
    :outline="false"
    :open="Boolean(groundingStore.field)"
    to="body"
    aria-label="Grounding Modal"
    class="overflow-hidden !bg-background-transparent"
    @close="groundingStore.reset"
  >
    <div
      class="h-[calc(100vh-3rem)] w-[calc(100vw-3rem)] overflow-y-auto overflow-x-hidden bg-surface-popover p-2 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      data-test="grounding-modal"
    >
      <div class="grid size-full grid-cols-10 gap-2">
        <div class="col-span-7 flex flex-col overflow-hidden rounded-corner-12 bg-surface-tertiary">
          <div
            v-if="shouldShowSourceTabs"
            class="flex justify-center border-b border-border-subtle p-1.5 pt-2"
          >
            <TabList
              v-if="groundingStore.activeInputField"
              id="grounding-modal-tab-list"
              fullwidth
              :items="
                pdfProperties.map((p) => ({
                  value: p.id,
                  leadingIcon: TYPE_ICON[p.type],
                  label: p.name,
                  hasClaims: groundingStore.getSourcesForProperty(p.id).length > 0,
                }))
              "
              :value="groundingStore.activeInputField.propertyId"
              @change="groundingStore.activeInputId = $event"
            >
              <template #trigger-content="{ item, isActive }">
                <IconSprite
                  v-if="item.leadingIcon"
                  :icon="item.leadingIcon"
                  :class="isActive ? 'text-icon-subtle' : 'text-icon-subtlest'"
                />
                <div>
                  {{ item.label }}
                </div>

                <div
                  v-if="item.hasClaims"
                  class="ml-[5px] size-[7px] rounded-full bg-background-stages-model"
                />
              </template>
            </TabList>
          </div>
          <GroundingModalFile
            class="pr-3"
            role="tabpanel"
            :aria-label="activeInputName"
          />
        </div>

        <div
          v-if="targetProperty"
          class="col-span-3 flex flex-col overflow-auto rounded-corner-12"
        >
          <div class="flex h-11 shrink-0 items-center gap-2 pl-2.5 pr-2">
            <IconSprite
              :icon="TYPE_ICON[targetProperty.type]"
              class="text-icon-subtlest"
            />
            <div class="grow text-sm-12px-default text-text">{{ targetProperty.name }}</div>
            <div class="flex items-center gap-2">
              <IconButton
                icon="chevron-top"
                size="md"
                variant="transparent"
                rounded
                class="text-icon-subtle"
                aria-label="Previous entity"
                :disabled="!previousEntityId"
                @click="changeToPrevEntity"
              />
              <IconButton
                icon="chevron-bottom"
                size="md"
                variant="transparent"
                rounded
                class="text-icon-subtle"
                aria-label="Next entity"
                :disabled="!nextEntityId"
                @click="changeToNextEntity"
              />
              <div class="flex overflow-hidden rounded-full bg-surface-tertiary">
                <IconButton
                  :icon="groundingStore.field?.score === 1 ? 'thumbs-up-fill' : 'thumbs-up'"
                  size="md"
                  variant="transparent"
                  class="w-[34px] text-icon-subtle"
                  aria-label="Positive feedback"
                  @click="submitGroundingFeedback(1)"
                />
                <DividerLine
                  class="!h-6"
                  direction="vertical"
                  color="subtle"
                />
                <IconButton
                  :icon="groundingStore.field?.score === -1 ? 'thumbs-down-fill' : 'thumbs-down'"
                  size="md"
                  variant="transparent"
                  class="w-[34px] text-icon-subtle"
                  aria-label="Negative feedback"
                  @click="submitGroundingFeedback(-1)"
                />
              </div>
              <IconButton
                icon="close"
                size="md"
                variant="neutral"
                rounded
                class="text-icon-subtle"
                aria-label="Close AI citations dialog"
                @click="groundingStore.reset"
              />
            </div>
          </div>
          <div class="overflow-y-auto px-2">
            <MarkdownWithGroundingClaims
              :value="targetFieldValue || ''"
              :claims="groundingStore.claims"
            />
          </div>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
