import { PropertyType } from '@/backend/types'

/**
 * Default reusable values for tool configuration.
 *
 * Deliberately not a `Partial<ToolDef>` so that you see its values when hovering over it.
 * If a value no longer applies to most tools, feel free to remove it from here.
 */
export const defaultToolConfig = {
  usesPrompt: true,
  isNew: false,
  isRateLimited: false,
} as const

/**
 * Common output types for Gemini tools.
 * This is not a source of truth, just a syntactic convenience.
 */
export const geminiOutputTypes = [
  PropertyType.text,
  PropertyType.single_select,
  PropertyType.multi_select,
]
