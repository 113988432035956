<script setup lang="ts">
import PopOver from '@/uiKit/PopOver.vue'
import { computed, nextTick, onMounted, ref } from 'vue'

import { PropertyType, type CollectionSubPropertyType } from '@/backend/types'
import { useProperty } from '@/modules/Project/useProperty'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'

import CollectionTypeProperty from './CollectionTypeProperty.vue'

defineProps<{ error?: boolean }>()

const propertyStore = useProperty()
const tempName = ref('')
const tempType = ref<CollectionSubPropertyType>(PropertyType.text)
const propertiesRef = ref<HTMLElement>()

const resetValues = () => {
  tempName.value = ''
  tempType.value = PropertyType.text
}

const filteredProperties = computed(() =>
  propertyStore.visibleProperties.filter((property) =>
    property.name.toLowerCase().includes(tempName.value.toLowerCase()),
  ),
)

const suggestToCreateText = computed(() => {
  if (tempName.value.length === 0) return
  if (propertyStore.visibleProperties.some((property) => property.name === tempName.value)) return
  return `Create ${tempName.value}`
})

const createNewProperty = (name: string, type: CollectionSubPropertyType) => {
  propertyStore.createCollectionProperty({ name, type })
  resetValues()
  nextTick(() => {
    propertiesRef.value?.scrollTo({
      top: 99999,
      behavior: 'smooth',
    })
  })
}

const placeholderComponent = ref()

onMounted(() => {
  resetValues()
})
</script>

<template>
  <PopOver
    :open="error ?? false"
    arrow
    :placement="{ allowedPlacements: ['left-start', 'right-start'] }"
  >
    <template #trigger>
      <CollectionTypeProperty
        ref="placeholderComponent"
        :name="tempName"
        :type="tempType"
        class="w-full"
        :show-advanced="false"
        placeholder="Search or add properties"
        aria-label="Search or add properties"
        @update="
          (event) => {
            tempName = event.name
            tempType = event.type
          }
        "
        @create="createNewProperty($event.name, $event.type)"
      />
    </template>
    <template #content>
      <div
        class="relative z-[999999] max-w-[320px] rounded-corner-6 border border-border bg-surface-popover-inverted p-2 text-text-inverted-irreversible"
      >
        <p class="text-sm-12px-default">Create property first</p>
        <p class="text-sm-12px-light">
          List the columns of the table that you want this Collection to populate
        </p>
      </div>
    </template>
  </PopOver>
  <div
    ref="propertiesRef"
    class="flex flex-col items-start justify-start overflow-auto scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
    v-bind="$attrs"
  >
    <CollectionTypeProperty
      v-for="(property, idx) in filteredProperties"
      :key="idx"
      :name="property.name"
      :type="property.type"
      class="w-full"
      @update="propertyStore.updateCollectionProperty($event, property.name)"
      @delete="propertyStore.deleteCollectionProperty(property.name)"
    />
    <ListMenuItem
      v-if="suggestToCreateText"
      :label="suggestToCreateText"
      aria-label="Create new property"
      :active="true"
      icon="plus"
      @click.prevent="(createNewProperty(tempName, tempType), placeholderComponent.focus())"
    />
  </div>
</template>
./CollectionTypeProperty.vue
