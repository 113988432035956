<script setup lang="ts">
import MarkdownEditor from '@/sharedComponents/MarkdownEditor/MarkdownEditor.vue'
import FlowerProgress from '@/uiKit/FlowerProgress.vue'
import { computed } from 'vue'
import type { ProgressMessage } from '../types'

const props = defineProps<{
  message: ProgressMessage
}>()

const meterColor = computed(() => {
  if (props.message.pctComplete === 100) return 'success'

  return 'warning'
})
</script>

<template>
  <div class="rounded-xl bg-surface-primary text-sm-12px-default text-text shadow-sm">
    <div
      class="flex items-center justify-between gap-9 px-2.5 pt-3"
      :class="message.messages.length ? 'border-b border-dashed border-border-subtle pb-2' : 'pb-3'"
    >
      <div>
        <MarkdownEditor
          :value="message.text"
          class="max-w-prose"
          readonly
        />
      </div>
      <div
        v-if="typeof message.pctComplete === 'number'"
        class="flex gap-1"
      >
        <div>{{ message.pctComplete }}%</div>
        <FlowerProgress
          :color="meterColor"
          :max="100"
          :min="0"
          :value="message.pctComplete"
        />
      </div>
    </div>
    <div
      v-if="message.messages.length > 0"
      class="pb-3 pt-2"
    >
      <MarkdownEditor
        v-for="child in message.messages"
        :key="child.id"
        readonly
        :value="child.text"
        class="max-w-prose px-2.5 text-text-subtlest"
      />
    </div>
  </div>
</template>
