import type { Field } from '@/modules/Project/Fields/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import { useProject } from './useProject'

/**
 * We took a product decision to not allow pasting into fields of these types.
 * See the product spec for more details:
 *  - https://linear.app/v7labs/project/copypasting-da7c5a6a9f01/overview
 */
type DeserializeableFieldType = Exclude<
  Field['type'],
  'collection' | 'file' | 'file_collection' | 'pdf' | 'reference'
>

type DeserializerParams<Type extends DeserializeableFieldType> = {
  text: string
  field: Field<Type>
}
type Deserializer<Type extends DeserializeableFieldType> = (
  params: DeserializerParams<Type>,
) => Field<Type>['manualValue'] | undefined

/**
 * Composable that provides a function used to deserialize text (usually sourced
 * from the clipboard) into a field value.
 */
export const useDeserializeTextToField = () => {
  const projectStore = useProject()
  const deserializeSingleSelect: Deserializer<'single_select'> = ({ field, text }) => {
    const property = projectStore.propertiesById[field.propertyId]
    assertIsNotNullOrUndefined(property, 'Property not found')
    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const availableOptions = property.config.options.map((option) => option.value)
    const pastedOptions = text.split(',').map((option) => option.trim())
    const draftValue = pastedOptions.filter((option) => availableOptions.includes(option))[0]

    return draftValue ? [draftValue] : undefined
  }

  const deserializeMultiSelect: Deserializer<'multi_select'> = ({ field, text }) => {
    const property = projectStore.propertiesById[field.propertyId]
    assertIsNotNullOrUndefined(property, 'Property not found')
    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const availableOptions = property.config.options.map((option) => option.value)
    const pastedOptions = text.split(',').map((option) => option.trim())

    const draftValue = pastedOptions.filter((option) => availableOptions.includes(option))

    return draftValue.length > 0 ? draftValue : undefined
  }

  const workspaceMembersStore = useWorkspaceMembers()
  const deserializeUserSelect: Deserializer<'user_select'> = ({ text, field }) => {
    const property = projectStore.propertiesById[field.propertyId]
    assertIsNotNullOrUndefined(property, 'Property not found')
    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const availableUserIds = property.config.options.map((option) => option.value)

    // These could be either emails or full names
    const pastedValues = text.split(',').map((value) => value.trim())
    const pastedIds = pastedValues.reduce<string[]>((acc, nameOrEmailOrId) => {
      const user = workspaceMembersStore.workspaceMembers.find(
        (member) =>
          member.fullName === nameOrEmailOrId ||
          member.email === nameOrEmailOrId ||
          member.id === nameOrEmailOrId,
      )

      if (!user) {
        return acc
      }

      return [...acc, user.id]
    }, [])

    const draftValue = pastedIds.filter((id) => availableUserIds.includes(id))
    return draftValue.length > 0 ? draftValue : undefined
  }

  /**
   * Maps each field type to its deserializer function. Is typed in such a way
   * that will cause a TS error if a new field type is added and not handled
   * here.
   */
  const deserializers: { [T in DeserializeableFieldType]: Deserializer<T> } = {
    text: textDeserializer,
    json: jsonDeserializer,
    url: urlDeserializer,
    multi_select: deserializeMultiSelect,
    single_select: deserializeSingleSelect,
    user_select: deserializeUserSelect,
  }

  const deserializeField = <FieldType extends DeserializeableFieldType>({
    field,
    fieldType,
    text,
  }: {
    text: string
    fieldType: FieldType
    field: Field<FieldType>
  }) => {
    const deserializer = deserializers[fieldType]
    return deserializer({ text, field })
  }

  return deserializeField
}

function textDeserializer({ text }: DeserializerParams<'text'>) {
  return text
}

function jsonDeserializer({ text }: DeserializerParams<'json'>) {
  return text
}

function urlDeserializer({ text }: DeserializerParams<'url'>) {
  return text
}
