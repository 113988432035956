import { getSpace } from '@/backend/getSpace'
import { listSpaces } from '@/backend/listSpaces'
import { toast } from '@/shared/toast'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { useLoadAllPaginatedResults } from '@/sharedComposables/useLoadAllPaginatedResults'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { captureException } from '@sentry/vue'
import { watch, type Ref } from 'vue'
import { serializeSpace } from './serializers'
import { useSpaceStore } from './useSpaceStore'

/**
 * This composable function will load all the workspace's spaces into
 * the spaces store when the workspace ID changes.
 */
export const useLoadSpaces = (workspaceId: Ref<string>) => {
  const spaceStore = useSpaceStore()
  const loadAllSpaces = useLoadAllPaginatedResults(
    () => listSpaces({ workspaceId: workspaceId.value }),
    serializeSpace,
    (spaces) => spaces.forEach(spaceStore.setSpace),
    20,
  )

  watch(
    workspaceId,
    () => {
      spaceStore.reset()
      loadAllSpaces()
    },
    {
      immediate: true,
    },
  )

  const { spaceId } = useRouteParams()
  const spaceLoader = useDataLoader(() =>
    getSpace({
      spaceId: spaceId.value,
      workspaceId: workspaceId.value,
    }),
  )
  watch(
    spaceId,
    async (newId) => {
      if (!newId) {
        spaceStore.currentSpaceId = null
        return
      }

      /**
       * Load the space data outside of the store. This is intentionally not happening in the
       * store, because there are also websocket handlers that will update the store. If we
       * make API calls in the store, we could easily end up in the situation where websocket
       * handlers cause API requests, which cause websocket handlers, and so on ad infinitum.
       */
      const res = await spaceLoader.load()
      if (!res.ok) {
        captureException(new Error('Failed to load space'))
        toast.warning('Failed to load space, please refresh the page.')
        return
      }

      spaceStore.setSpace(serializeSpace(res.data))
      spaceStore.currentSpaceId = newId
    },
    {
      immediate: true,
    },
  )
}
