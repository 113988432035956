<script setup lang="ts">
import type {
  MultiSelectProperty,
  SingleSelectProperty,
  UserSelectProperty,
} from '@/modules/Project/Properties/types'
import { toggleArray } from '@/shared/utils'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import CheckBox from '@/uiKit/CheckBox.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import RadioButton from '@/uiKit/RadioButton.vue'
import { computed } from 'vue'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'

const props = defineProps<{
  value: string | string[] | null
  property: SingleSelectProperty | MultiSelectProperty | UserSelectProperty
  readonly: boolean
  forceUserValue?: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', val: string[]): void
}>()

const valueToColorMap = computed(() =>
  Object.fromEntries(props.property.config.options.map((o) => [o.value, o.color])),
)

const normalizedValue = computed(() => (props.value ? [props.value].flat() : []))

const workspaceMembersStore = useWorkspaceMembers()

const userValue = (option: { color?: string | null | undefined; value: string }) => {
  const member = workspaceMembersStore.workspaceMembers.find((member) => member.id === option.value)
  return member ? `${member.firstName} ${member.lastName}` : option.value
}

const availableOptions = computed(() => {
  const allOptions = props.property.config.options.map((option) => ({
    id: option.value,
    data: {
      label: isUser.value || props.forceUserValue ? userValue(option) : option.value,
    },
  }))

  return [
    ...allOptions.filter((option) => isSelected(option) || isUserSelected(option)),
    ...allOptions.filter((option) => !isSelected(option) && !isUserSelected(option)),
  ]
})

const toggleValueAndSubmit = (val: string) => {
  if (props.readonly) return

  if (props.property.type === 'single_select') {
    const newValue = [val]
    emit('submit', newValue)
  } else if (props.property.type === 'multi_select') {
    if (props.forceUserValue) {
      const option = availableOptions.value.find((o) => o.data.label === val)
      if (!option) {
        const newValue = toggleArray(normalizedValue.value, val)
        emit('submit', newValue)
        return
      }

      const newValue = toggleArray(normalizedValue.value, option.id)
      emit('submit', newValue)
      return
    }
    const newValue = toggleArray(normalizedValue.value, val)
    emit('submit', newValue)
  } else if (isUser.value) {
    const option = availableOptions.value.find((o) => o.data.label === val)
    if (!option) {
      const newValue = [val]
      emit('submit', newValue)
      return
    }
    const newValue = [option.id]
    emit('submit', newValue)
  }
}

const isUser = computed(() => props.property.type === 'user_select')

const isSelected = (item: { id: string; data: { label: string } }) =>
  props.forceUserValue
    ? normalizedValue.value?.includes(item.id)
    : normalizedValue.value?.includes(item.data.label)

const isUserSelected = (item: { id: string; data: { label: string } }) =>
  normalizedValue.value?.includes(item.id)
</script>

<template>
  <ListMenu
    search-by-field="label"
    :items="availableOptions"
    no-results-text="No result"
    class="w-full !rounded-none border-t border-t-border-subtle !bg-[transparent] shadow-none"
    create-enabled
    :autofocus="false"
    :group-by-predicate="
      (item) => (isSelected(item) || isUserSelected(item) ? 'Selected' : 'Available')
    "
    deselect-on-mouse-leave
    @select="(data) => toggleValueAndSubmit(data.label)"
  >
    <template #item="{ key, item, active, setActiveItem }">
      <ListMenuItem
        :label="item.data.label"
        :active="active"
        class="group flex w-full items-center justify-between"
        :class="[
          active && readonly ? 'cursor-not-allowed' : 'cursor-pointer',
          readonly &&
            'bg-background-transparent data-[highlighted]:bg-background-transparent hover:bg-background-transparent focus:bg-background-transparent active:bg-background-transparent',
        ]"
        default-hover-disabled
        :aria-selected="isSelected(item)"
        @select="toggleValueAndSubmit(item.data.label)"
        @mousemove="setActiveItem(key)"
      >
        <template #prefix>
          <RadioButton
            v-if="props.property.type === 'single_select'"
            :checked="isSelected(item)"
            :class="readonly || isSelected(item) || active ? 'opacity-100' : 'opacity-0'"
            class="pointer-events-none transition"
            :disabled="readonly && !isSelected(item)"
          />
          <CheckBox
            v-if="props.property.type === 'multi_select'"
            :checked="forceUserValue ? isUserSelected(item) : isSelected(item)"
            :class="
              readonly || (forceUserValue ? isUserSelected(item) : isSelected(item)) || active
                ? 'opacity-100'
                : 'opacity-0'
            "
            class="pointer-events-none transition"
            :disabled="readonly && !(forceUserValue ? isUserSelected(item) : isSelected(item))"
          />
          <RadioButton
            v-if="isUser"
            :checked="isUserSelected(item)"
            :class="readonly || isUserSelected(item) || active ? 'opacity-100' : 'opacity-0'"
            class="pointer-events-none transition"
            :disabled="readonly && !isUserSelected(item)"
          />
        </template>
        <BadgeItem
          :leading-icon="isUser || props.forceUserValue ? 'user-fill' : undefined"
          :label="item.data.label"
          size="sm"
          :variant="isUser || props.forceUserValue ? 'blue' : 'warning'"
          class="whitespace-nowrap"
          :rainbow-color="
            isUser || props.forceUserValue
              ? valueToColorMap[item.id]
              : valueToColorMap[item.data.label]
          "
        />
        <div class="grow" />
        <IconButton
          v-if="
            !readonly &&
            ((props.property.type === 'single_select' && isSelected(item)) ||
              (props.property.type === 'user_select' && isUserSelected(item)))
          "
          icon="close"
          size="sm"
          aria-label="Clear values"
          variant="transparent"
          tabindex="-1"
          @click.prevent.stop="emit('submit', [])"
        />
      </ListMenuItem>
    </template>
  </ListMenu>
</template>
