<script setup lang="ts">
import QueryMessage from './Messages/QueryMessage.vue'
import ResultMessage from './Messages/ResultMessage.vue'
import { useSpaceStore } from './useSpaceStore'

const spaceStore = useSpaceStore()
</script>

<template>
  <div class="w-[640px] max-w-full p-2">
    <div class="mb-8 text-display-md-28px-light">
      <h1 class="text-text">{{ spaceStore.space?.name }}</h1>
    </div>
    <div class="flex flex-col items-stretch gap-8">
      <template
        v-for="message in spaceStore.conversation"
        :key="message.id"
      >
        <QueryMessage
          v-if="message.type === 'query'"
          class="self-end"
          :message="message"
        />
        <ResultMessage
          v-else-if="message.type === 'result'"
          class="self-start"
          :message="message"
        />
      </template>
    </div>
  </div>
</template>
