import { useAuth0 } from '@auth0/auth0-vue'
import { computed, toValue, type ComputedRef, type MaybeRefOrGetter } from 'vue'
import { useWorkspaceMembers } from './useWorkspaceMembers'

/**
 * Gets the profile image URL for the given user ID. Returns
 * null if the user has no profile image or if the user doesn't
 * exist in the active workspace.
 */
export const useProfileImage = (userId: MaybeRefOrGetter<string>): ComputedRef<string | null> => {
  const { user: auth0User } = useAuth0()
  const workspaceMembersStore = useWorkspaceMembers()

  const user = computed(() =>
    workspaceMembersStore.workspaceMembers.find(
      (m) => m.email === auth0User.value?.email && m.id === toValue(userId),
    ),
  )

  /**
   * Currently there is no property on the backend user object
   * for the image URL, so we can only fetch the image for
   * the current user. Eventually we will be able to return
   * `user.imageURL` for every user.
   */
  const imageUrl = computed<string | null>(() =>
    user.value?.id === toValue(userId) ? (auth0User.value?.picture ?? null) : null,
  )

  return imageUrl
}
