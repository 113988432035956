<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import { computed, ref, toRef } from 'vue'
import { useScrollOnSelected } from './useScrollOnSelected'
import { useTableCellFocus } from './useTableCellFocus'
/**
 * This component is used for a table cell when the cell is in a state where the model
 * value is not available.
 */

const props = defineProps<{
  status: Field['status']
  error?: string | null
  isSelected: boolean
  type?: Field['type']
  manualValue?: Field['manualValue']
}>()

// The text content of the cell, based on the status and error
const textContent = computed(() => {
  if (props.status === 'error') {
    return props.error ? `Error: ${props.error}` : 'Error'
  }

  if (props.status === 'computing') {
    if (props.type === 'file') {
      return 'Understanding this file'
    } else {
      return 'Calculating...'
    }
  }

  return 'Waiting for configuration...'
})

const cell = ref<HTMLElement>()

useTableCellFocus({
  cell,
  isSelected: toRef(props, 'isSelected'),
  // This cell can never be focused, only selected
  isFocused: ref(false),
})
useScrollOnSelected(cell, toRef(props, 'isSelected'))
</script>

<template>
  <div
    ref="cell"
    class="shrink grow basis-0 truncate p-2 pl-3 text-sm-12px-light text-text outline-none"
    :class="[status !== 'error' && 'text-text-subtlest']"
  >
    <div v-if="type === 'url' && status === 'computing'">
      <BadgeItem
        size="sm"
        :label="manualValue as string"
        variant="neutral"
      >
        <template #leading-icon>
          <CircularProgress
            size="xs"
            color="neutral"
          />
        </template>
      </BadgeItem>
    </div>
    <template v-else>
      {{ textContent }}
    </template>
  </div>
</template>
