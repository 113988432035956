import { listEntities } from '@/backend/listEntities'
import { listFilteredEntities } from '@/backend/listFilteredEntities'
import { getGroupFilter, useFilters } from './useFilters'
import { useProject } from './useProject'
import { useSorting } from './useSorting'

/**
 * This composable returns a function that can be used to list entities in a
 * project, taking into account the current filter state.
 */
export const useListEntities = () => {
  const filterStore = useFilters()
  const sortingStore = useSorting()
  const projectStore = useProject()

  return async ({
    end,
    projectId,
    start,
    viewId,
    workspaceId,
    parentEntityId,
  }: {
    workspaceId: string
    projectId: string
    start: number
    end: number
    parentEntityId?: string
    viewId?: string
  }) => {
    const order_by = sortingStore.sortingRules.reduce<string[]>((acc, rule) => {
      const property = projectStore.properties.find((p) => p.id === rule.propertyId)
      if (!property) {
        return acc
      }

      return [...acc, property.slug]
    }, [])

    const order_directions = sortingStore.sortingRules.map((r) => r.direction)

    if (filterStore.currentFilter?.filters.length) {
      const filter = getGroupFilter({
        filter: filterStore.currentFilter,
        parentEntityId,
        activeViewId: viewId,
      })

      return listFilteredEntities({
        workspaceId,
        projectId,
        offset: start,
        limit: start === end ? undefined : end - start + 1,
        filter,
        order_directions,
        order_by,
      })
    }

    return listEntities(
      workspaceId,
      projectId,
      start,
      end,
      parentEntityId,
      viewId,
      order_by,
      order_directions,
    )
  }
}
