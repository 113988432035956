<script setup lang="ts">
import { deleteProjectCoverImage } from '@/backend/deleteProjectCover'
import { updateProject } from '@/backend/updateProject'
import { uploadProjectCoverImage } from '@/backend/uploadProjectCover'
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import { PROJECT_NAME_FALLBACK } from '@/modules/Project/constants'
import { useProjects } from '@/modules/Projects/useProjects'
import { useRecentProjects } from '@/modules/Projects/useRecentProjects'
import { copyToClipboard } from '@/shared/clipboard'
import { toast } from '@/shared/toast'
import { omit } from '@/shared/utils'
import { getWindow } from '@/shared/utils/window'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconButton, { type IconButtonSize } from '@/uiKit/IconButton.vue'
import Menu from '@/uiKit/Menu'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import ModalDialogHeader from '@/uiKit/ModalDialogHeader.vue'
import TextField from '@/uiKit/TextField.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import DeleteProjectDialog from './DeleteProjectDialog.vue'

const props = withDefaults(
  defineProps<{
    workspaceId: string
    projectId: string
    size?: IconButtonSize
  }>(),
  {
    size: 'xs',
  },
)

const isDeleting = ref(false)
const isRenaming = ref(false)
const newProjectName = ref('')

const { resolve, currentRoute, replace } = useRouter()
const onDeleteProject = () => {
  isDeleting.value = false
  // Handle the case where the user has deleted the project that
  // they are currently viewing
  if (currentRoute.value.params.projectId === props.projectId) {
    replace({ name: 'WorkspaceProjects', params: { workspaceId: props.workspaceId } })
  }
}

const link = computed(() =>
  resolve({
    name: 'WorkspaceProject',
    params: { workspaceId: props.workspaceId, projectId: props.projectId },
  }),
)

const handleRemoveProjectCover = async () => {
  setCoverImageUploading(true)

  await deleteProjectCoverImage({
    workspaceId: props.workspaceId,
    projectId: props.projectId,
  })

  setCoverImageUploading(false)
}

const fileInput = ref<HTMLInputElement | null>(null)

const handleFileUpload = async (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0) {
    const file = files[0]

    const formData = new FormData()
    formData.append('file', file)

    setCoverImageUploading(true)

    await uploadProjectCoverImage({
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      formData,
    })

    setCoverImageUploading(false)
  }
}

const projectsStore = useProjects()
const recentProjectsStore = useRecentProjects()

const setCoverImageUploading = (value: boolean) => {
  projectsStore.setCoverImageDownloadError(props.projectId, false)
  recentProjectsStore.setCoverImageDownloadError(props.projectId, false)

  projectsStore.setCoverImageUploading(props.projectId, value)
  recentProjectsStore.setCoverImageUploading(props.projectId, value)
}

const handleRename = async () => {
  if (newProjectName.value.trim() === '') return

  const result = await updateProject(
    props.workspaceId,
    props.projectId,
    newProjectName.value.trim(),
  )

  if (result.ok) {
    projectsStore.updateProject(props.projectId, { name: result.data.name })
  } else {
    toast.error('Failed to rename project. Please try again later.')
  }

  isRenaming.value = false
}

const permissionsStore = usePermissionsStore()
const canDelete = computed(() => {
  return !!permissionsStore.allProjectPermissions[props.projectId]?.delete_projects
})
const canUpdate = computed(() => {
  return !!permissionsStore.allProjectPermissions[props.projectId]?.update_projects
})

defineOptions({
  inheritAttrs: false,
})
</script>

<template>
  <Menu.Root v-slot="{ getTriggerProps }">
    <IconButton
      icon="more-dots"
      data-test="project-menu-trigger"
      :size="size"
      variant="transparent"
      v-bind="{ ...omit(getTriggerProps(), ['onSelect', 'disabled']), ...$attrs }"
      @click="(e) => e.preventDefault()"
    >
      Click me
    </IconButton>
    <Menu.Content class="min-w-56">
      <Menu.Item
        icon="arrow-top-right"
        @select="() => getWindow()?.open(link.href, '_blank')"
      >
        Open in a new tab
      </Menu.Item>
      <Menu.Item
        icon="arrow-top-right"
        @select="() => getWindow()?.open(link.href, '_blank', 'toolbar=0,location=0,menubar=0')"
      >
        Open in a new window
      </Menu.Item>
      <ToolTip
        class="w-full"
        arrow
        :placement="{ allowedPlacements: ['left'] }"
        :title="projectId"
      >
        <Menu.Item
          icon="copy"
          @select="() => copyToClipboard('Project ID', projectId)"
        >
          Copy project ID
        </Menu.Item>
      </ToolTip>

      <Menu.Divider />

      <Menu.Item
        icon="picture"
        class="relative"
      >
        Upload cover picture

        <input
          ref="fileInput"
          class="absolute inset-0 size-full cursor-pointer opacity-0"
          type="file"
          @change="handleFileUpload"
        />
      </Menu.Item>
      <Menu.Item
        icon="remove-picture"
        @select="handleRemoveProjectCover"
      >
        Delete cover picture
      </Menu.Item>

      <Menu.Divider v-if="canUpdate || canDelete" />

      <Menu.Item
        v-if="canUpdate"
        icon="edit"
        @select="
          () => {
            isRenaming = true
            newProjectName = projectsStore.getProjectById(projectId)?.name || PROJECT_NAME_FALLBACK
          }
        "
      >
        Rename project
      </Menu.Item>
      <Menu.Item
        v-if="canDelete"
        icon="trash"
        critical
        @select="isDeleting = true"
      >
        Delete project
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>

  <DeleteProjectDialog
    :project-id="projectId"
    :workspace-id="workspaceId"
    :open="isDeleting"
    @delete="onDeleteProject"
    @close="isDeleting = false"
  />

  <ModalDialog
    :open="isRenaming"
    class="min-w-[480px]"
    @close="isRenaming = false"
  >
    <template #header>
      <ModalDialogHeader title="Rename project" />
    </template>
    <template #body>
      <TextField
        autofocus
        size="md"
        :value="newProjectName"
        label="Project name"
        @input="newProjectName = $event"
        @submit="handleRename"
        @esc="isRenaming = false"
      />
    </template>
    <template #footer>
      <DarwinButton
        ref="cancelButton"
        variant="neutral"
        size="md"
        tabindex="1"
        @click="isRenaming = false"
      >
        Cancel
      </DarwinButton>
      <DarwinButton
        ref="confirmButton"
        size="md"
        data-test="confirm-btn"
        variant="black"
        @click="handleRename"
      >
        Save
      </DarwinButton>
    </template>
  </ModalDialog>
</template>
