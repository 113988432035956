<script setup lang="ts">
import { usePermissionsStore } from '@/modules/IdentityAndAccess/permissionsStore'
import type { Project } from '@/modules/Projects/useProjects'
import { copyToClipboard } from '@/shared/clipboard'
import { omit } from '@/shared/utils'
import { doExportProject } from '@/shared/utils/exports'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    project: Project
    open?: boolean
  }>(),
  {
    open: false,
  },
)

const emit = defineEmits<{
  rename: []
  delete: []
}>()

const MenuIds = {
  CopyProjectId: 'copy-project-id',
  Rename: 'rename',
  ExportToCSV: 'export-to-csv',
  ExportToXlsx: 'export-to-xlsx',
  Delete: 'delete',
} as const

const { workspaceId } = useRouteParams()
const permissionsStore = usePermissionsStore()
const { captureAnalyticsEvent } = useAnalytics()

const localProjectName = ref('')
watch(
  () => props.project.name,
  (next) => (localProjectName.value = next || ''),
  { immediate: true },
)

function onCrumbClick(isOpen: boolean) {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_CRUMB_MENU, {
    workspaceId: workspaceId.value,
    projectId: props.project.id,
    value: isOpen,
  })
}
</script>

<template>
  <FloatingMenu
    @select="
      (id) => {
        switch (id) {
          case MenuIds.CopyProjectId:
            copyToClipboard('Project ID', project.id)
            break
          case MenuIds.Rename:
            if (project?.name) {
              localProjectName = project.name
            }
            emit('rename')
            break
          case MenuIds.ExportToCSV:
            captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
            doExportProject(workspaceId, project.id, 200, 3000, 'csv') // keep trying for max 200 times (sleep 3000ms = 3s) = 10m
            break
          case MenuIds.ExportToXlsx:
            captureAnalyticsEvent(ANALYTICS_EVENT.PROJECT_EXPORTED)
            doExportProject(workspaceId, project.id, 200, 3000, 'xlsx') // keep trying for max 200 times (sleep 3000ms = 3s) = 10m
            break
          case MenuIds.Delete:
            emit('delete')
            break
        }
      }
    "
  >
    <template #trigger="{ triggerProps }">
      <IconButton
        size="sm"
        aria-label="Open project menu"
        variant="transparent"
        v-bind="{ ...omit(triggerProps, 'disabled'), ...$attrs }"
        :disabled="triggerProps.disabled === 'true'"
        class="text-text-subtle"
        icon="more-dots"
        @click="onCrumbClick(!!triggerProps['aria-expanded'])"
      />
    </template>
    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        class="w-[280px] min-w-max"
        v-bind="contentProps"
      >
        <div class="w-full p-0.5">
          <ToolTip
            class="w-full"
            :arrow="true"
            :placement="{ allowedPlacements: ['right'] }"
            :title="project.id"
          >
            <ListMenuItem
              label="Copy project ID"
              icon="copy"
              v-bind="omit(getItemProps({ value: MenuIds.CopyProjectId }), ['onSelect'])"
            />
          </ToolTip>
          <ListMenuItem
            v-if="permissionsStore.currentProjectPermissions.update_projects"
            label="Rename"
            icon="edit"
            v-bind="omit(getItemProps({ value: MenuIds.Rename }), ['onSelect'])"
          />
          <template v-if="permissionsStore.currentProjectPermissions.manage_project_exports">
            <ListMenuItem
              label="Export as CSV"
              icon="arrow-bottom-circle"
              v-bind="omit(getItemProps({ value: MenuIds.ExportToCSV }), ['onSelect'])"
            />
            <ListMenuItem
              label="Export as XLSX"
              icon="arrow-bottom-circle"
              v-bind="omit(getItemProps({ value: MenuIds.ExportToXlsx }), ['onSelect'])"
            />
          </template>
          <template v-if="permissionsStore.currentProjectPermissions.delete_projects">
            <div class="my-0.5 border-t border-background-gray-subtlest"></div>
            <ListMenuItem
              critical
              label="Delete project"
              icon="trash"
              v-bind="omit(getItemProps({ value: MenuIds.Delete }), ['onSelect'])"
            />
          </template>
        </div>
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
