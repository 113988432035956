<script setup lang="ts">
import ToolTip from '@/uiKit/ToolTip.vue'
import { useResizeObserver } from '@vueuse/core'
import { computed, ref } from 'vue'
import { RouterLink, useRouter, type RouteLocationRaw } from 'vue-router'

withDefaults(
  defineProps<{
    to?: RouteLocationRaw
    mask?: boolean
  }>(),
  {
    to: undefined,
    mask: false,
  },
)

const router = useRouter()
const maskEl = ref<HTMLDivElement>()
const isOverflowing = ref(false)

/**
 * We need to recalculate if text is overflowing with JS because we need to
 * conditionally truncate with ellipsis or mask based on the mask prop.
 */
useResizeObserver(maskEl, () => {
  if (!maskEl.value) return false
  const { scrollWidth, clientWidth } = maskEl.value
  isOverflowing.value = scrollWidth > clientWidth
})

/**
 * Extracts slot text to be used in tooltip
 */
const slotText = computed(() => {
  if (!maskEl.value) return ''
  return maskEl.value.textContent
})

/**
 * We only want to show tooltip if the text is overflowing
 */
const disableTooltip = computed(() => {
  return !isOverflowing.value || !slotText.value
})
</script>

<template>
  <RouterLink
    exact-active-class="!text-text hover:!bg-background-transparent cursor-default"
    :to="to || router.currentRoute.value"
    class="group relative shrink grow overflow-hidden whitespace-nowrap rounded-corner-6 bg-background-transparent px-1.5 py-1 text-sm-12px-default text-text-subtlest -outline-offset-1 hover:bg-background-transparent-hovered active:bg-background-transparent-pressed"
    :class="{ truncate: !mask }"
  >
    <div
      ref="maskEl"
      class="size-full"
      :class="{
        truncate: isOverflowing && !mask,
        '[mask-image:linear-gradient(to_right,black_50%,transparent_100%)]': mask && isOverflowing,
      }"
    >
      <ToolTip
        v-if="$slots.default"
        :placement="{ allowedPlacements: ['bottom-start'] }"
        :disabled="disableTooltip"
      >
        <slot />
        <template #content>{{ slotText }}</template>
      </ToolTip>
    </div>
  </RouterLink>
</template>
