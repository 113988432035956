import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const o1Tool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'o1',
  description:
    "OpenAI's most capable reasoning model, pre-trained with to use chain of thought. May take longer than GPTs to provide an output. Best for tasks that require complex, multi-step reasoning.",
  outputTypes: [PropertyType.text],
  provider: ToolProvider.open_ai,
  isRateLimited: true,
  icon: 'company-openai',
}
