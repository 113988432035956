import { remove } from './client'
import type { BackendMeta } from './types'

export type DeleteSpace = BackendMeta<'/api/workspaces/{workspace_id}/spaces/{space_id}', 'delete'>

export type DeleteSpaceParams = {
  workspaceId: string
  spaceId: string
}

export const deleteSpace = ({ workspaceId, spaceId }: DeleteSpaceParams) =>
  remove<undefined, DeleteSpace['successResponse'], DeleteSpace['path']>(
    `/api/workspaces/${workspaceId}/spaces/${spaceId}`,
    undefined,
  )
