import type { Ref } from 'vue'
import { useLoadSpaces } from '../AgenticChat/useLoadSpaces'
import { useLoadBilling } from '../Billing/useLoadBilling'
import { useLoadWorkspacePermissions } from '../IdentityAndAccess/useLoadWorkspacePermissions'
import { useLoadLibrary } from '../Library/useLoadLibrary'
import { useLoadModelProviders } from '../WorkspaceSettings/useLoadModelProviders'

/**
 * When the workspace changes, there is lots of auxiliary data that needs to be loaded.
 * e.g. the active billing plan, the library items, the user's permissions on the workspace, etc.
 *
 * This composable groups all the composition functions that load this data.
 */
export const useLoadWorkspaceData = (workspaceId: Ref<string>) => {
  useLoadBilling(workspaceId)
  useLoadLibrary(workspaceId)
  useLoadModelProviders(workspaceId)
  useLoadWorkspacePermissions(workspaceId)
  useLoadSpaces(workspaceId)
}
