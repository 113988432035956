<script setup lang="ts">
import CircularProgress from '@/uiKit/CircularProgress.vue'
import { computed } from 'vue'
import { RouterLink, type RouterLinkProps } from 'vue-router'
/**
 * This is a partial implementation of the main button we use in designs.
 *
 * Not all variants are implemented and some of them might need to be cleaned up.
 *
 *
 *
 * # How to use with vue-router?
 *
 * Becase we don't want the uiKit to have to depend on the router, we should wrap
 * it according to instructions in
 *
 * https://router.vuejs.org/guide/advanced/extending-router-link.html
 *
 * This way, we can pass a 'to' prop as string only and set a different variant
 * based on the link being active or not.
 *
 * ```
 * <RouterLink custom v-bind="$props" v-slot="{ isActive, navigate }" tabindex="-1">
 *   <DarwinButton
 *     ...otherProps
 *     :variant="isActive ? 'blue' : 'neutral'"
 *     @click="navigate">
 *     <slot />
 *  </DarwinButton>
 * </RouterLink>
 */

const props = defineProps<{
  variant:
    | 'black'
    | 'blue'
    | 'neutral'
    | 'outline'
    | 'transparent'
    | 'transparent-link'
    | 'critical'
    | 'critical-subtle'
    | 'critical-transparent'
  size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  rounded?: boolean
  to?: RouterLinkProps['to']
  href?: string
  active?: boolean
}>()

const tag = computed<'button' | 'a' | typeof RouterLink>(() => {
  if (props.to) {
    return RouterLink
  }

  if (props.href) {
    return 'a'
  }

  return 'button'
})

/*
 * We need to bind:
 * - the `href` attribute if the tag is an anchor
 * - the `to` prop if the tag is a router-link
 * If we don't do it like this, then we break the RouterLink implementation
 * by setting href as undefined.
 */
const linkProps = computed(() => {
  if (props.href) {
    return { href: props.href }
  }
  if (props.to) {
    return { to: props.to }
  }
  return {}
})
</script>

<template>
  <component
    :is="tag"
    class="group inline-flex min-w-max flex-nowrap items-center justify-center gap-1 transition-colors duration-300 ease-out focus:outline-none focus-visible:shadow-focus-ring-primary disabled:bg-background-disabled disabled:text-text-disabled"
    :data-active="active ? '' : undefined"
    :class="[
      size === 'xxs' && ['h-5 rounded-corner-6 p-1'],
      size === 'xs' && ['h-6 rounded-corner-6 px-1.5 py-1'],
      size === 'sm' && ['h-7 rounded-corner-8 px-2 py-1'],
      size === 'md' && ['h-8 rounded-corner-10 px-2.5 py-1.5'],
      size === 'lg' && ['h-9 rounded-corner-12 px-3 py-2'],
      variant === 'neutral' && [
        'text-text-subtle',
        'bg-background-gray-subtlest',
        'hover:bg-background-gray-subtlest-hovered',
        'active:bg-background-gray-subtlest-pressed',
        'data-[active]:bg-background-gray-subtlest-pressed',
      ],
      variant === 'black' && [
        'text-text-inverted',
        'bg-background-black',
        'hover:bg-background-black-hovered',
        'active:bg-background-black-pressed',
        'data-[active]:bg-background-black-pressed',
      ],
      variant === 'blue' && [
        'text-text-inverted-irreversible',
        'bg-background-primary',
        'hover:bg-background-primary-hovered',
        'active:bg-background-primary-pressed',
        'data-[active]:bg-background-primary-pressed',
      ],
      variant === 'critical' && [
        'text-text-inverted-irreversible',
        'bg-background-critical',
        'hover:bg-background-critical-hovered',
        'active:bg-background-critical-pressed',
        'data-[active]:bg-background-critical-pressed',
      ],
      variant === 'outline' && [
        'text-text',
        'border border-border disabled:border-none',
        'bg-background-transparent',
        'hover:bg-background-transparent-hovered',
        'active:bg-background-transparent-pressed',
        'data-[active]:bg-background-transparent-pressed',
      ],
      variant === 'transparent' && [
        'text-text',
        'bg-background-transparent',
        'hover:bg-background-transparent-hovered',
        'active:bg-background-transparent-pressed',
        'data-[active]:bg-background-transparent-pressed',
      ],
      variant === 'transparent-link' && [
        'text-text-subtle',
        'hover:text-text',
        'bg-background-transparent',
        'hover:bg-background-transparent',
        'active:bg-background-transparent-pressed',
      ],
      variant === 'critical-subtle' && [
        'text-text-critical',
        'bg-background-critical-subtle',
        'hover:bg-background-critical-subtle-hovered',
        'active:bg-background-critical-subtle-pressed',
        'data-[active]:bg-background-critical-subtle-pressed',
      ],
      variant === 'critical-transparent' && [
        'text-text-critical',
        'bg-background-critical-transparent',
        'hover:bg-background-critical-transparent-hovered',
        'active:bg-background-critical-transparent-pressed',
        'data-[active]:bg-background-critical-transparent-pressed',
      ],
      rounded && '!rounded-full',
      $attrs.disabled ? 'cursor-default' : 'cursor-pointer',
    ]"
    :type="type"
    v-bind="{ ...$attrs, ...linkProps }"
  >
    <div
      v-if="$slots['leading-icon'] && !loading"
      class="flex flex-col items-center justify-center group-disabled:text-icon-disabled"
      :class="[
        size === 'xxs' && 'size-3',
        (size === 'sm' || size === 'xs') && 'size-3.5',
        (size === 'md' || size === 'lg') && 'size-4',
        variant === 'black' && 'text-icon-inverted',
        variant === 'blue' && 'text-icon-inverted-irreversible',
        variant === 'neutral' && 'text-icon-subtle',
        variant === 'outline' && 'text-icon-subtlest',
        variant === 'transparent' && 'text-icon-subtlest',
        variant === 'transparent-link' && 'text-icon-subtlest',
        variant === 'critical' && 'text-icon-inverted-irreversible',
        variant === 'critical-subtle' && 'text-icon-critical',
        variant === 'critical-transparent' && 'text-icon-critical',
      ]"
    >
      <slot name="leading-icon" />
    </div>
    <div
      class="flex shrink-0 items-center justify-center text-ellipsis p-0.5"
      :class="[
        size === 'xxs' || size === 'sm' || size === 'xs' || size === 'md'
          ? 'p-0.5 text-sm-12px-default'
          : 'p-1 text-md-13px-default',

        variant === 'black' && 'text-text-inverted',
        ['blue', 'critical'].includes(variant) &&
          'text-text-inverted group-disabled:text-text-disabled dark:text-text-inverted-irreversible',
        ['outline', 'transparent'].includes(variant) &&
          'text-text-subtle group-disabled:text-text-disabled',
        ['critical-subtle', 'critical-transparent'].includes(variant) && 'text-text-critical',
        ['neutral'].includes(variant) && 'text-text-subtle group-disabled:text-text-disabled',
      ]"
    >
      <!-- Hide when loading so that the button keeps its size -->
      <div :class="loading && 'opacity-0'">
        <slot />
      </div>
      <div
        v-if="loading"
        class="absolute flex items-center justify-center"
      >
        <CircularProgress
          size="xs"
          class="animate-spin"
          :color="
            (
              {
                black: 'inverted',
                blue: 'white',
                neutral: 'neutral',
                outline: 'neutral',
                transparent: 'neutral',
                'transparent-link': 'neutral',
                critical: 'white',
                'critical-subtle': 'critical',
                'critical-transparent': 'critical',
              } as const
            )[variant]
          "
          :value="0.3"
        />
      </div>
    </div>
    <div
      v-if="$slots['trailing-icon'] && !loading"
      class="flex flex-col items-center justify-center group-disabled:text-icon-disabled"
      :class="[
        size === 'xxs' && 'size-3',
        (size === 'sm' || size === 'xs') && 'size-3.5',
        (size === 'md' || size === 'lg') && 'size-4',
        variant === 'black' && 'text-icon-inverted',
        variant === 'blue' && 'text-icon-inverted-irreversible',
        variant === 'neutral' && 'text-icon-subtle',
        variant === 'outline' && 'text-icon-subtlest',
        variant === 'transparent' && 'text-icon-subtlest',
        variant === 'transparent-link' && 'text-icon-subtlest',
        variant === 'critical' && 'text-icon-inverted-irreversible',
        variant === 'critical-subtle' && 'text-icon-critical',
        variant === 'critical-transparent' && 'text-icon-critical',
      ]"
    >
      <slot name="trailing-icon" />
    </div>
  </component>
</template>
