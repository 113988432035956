import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const gpt4oMiniTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'GPT 4 Omni Mini',
  description: "GPT-4 Omni Mini is a faster and cheaper version of OpenAI's GPT-4 Omni.",
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  provider: ToolProvider.open_ai,
  icon: 'company-openai',
}
