/* eslint-disable @typescript-eslint/no-explicit-any */
import { addEventListener } from '@/shared/utils/event'
import { computed, onMounted, onUnmounted, ref } from 'vue'

function addExternalScript(src: string) {
  const script = document.createElement('script')
  script.src = src
  script.setAttribute('async', 'true')
  script.setAttribute('defer', 'true')
  document.body.appendChild(script)
  return script
}

const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY
const APP_ID = import.meta.env.VITE_GOOGLE_APP_ID

/**
 * Google Drive Picker response object
 *
 * Google typedefs are not great, so this remaps them in a more readable
 * format and picks only fields we care about.
 */
export type GoogleDrivePickedData = {
  docs: Exclude<google.picker.ResponseObject['docs'], undefined>
}

export function initGoogleDrive() {
  const apiLoaded = ref(false)
  const gsiLoaded = ref(false)
  const loaded = computed(() => apiLoaded.value && gsiLoaded.value)

  /**
   * Callback after the API client is loaded. Loads the
   * discovery doc to initialize the API.
   */
  async function initializePicker() {
    const gapi = (window as any).gapi
    await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest')
    apiLoaded.value = true
  }

  const unsubCallbacks: Array<() => void> = []
  onMounted(() => {
    const apiScript = addExternalScript('https://apis.google.com/js/api.js')
    unsubCallbacks.push(
      addEventListener(apiScript, 'load', async () => {
        const gapi = (window as any).gapi
        gapi.load('client:picker', initializePicker)
      }),
    )

    const gsiScript = addExternalScript('https://accounts.google.com/gsi/client')
    unsubCallbacks.push(
      addEventListener(gsiScript, 'load', () => {
        gsiLoaded.value = true
      }),
    )
  })

  onUnmounted(() => {
    unsubCallbacks.forEach((callback) => callback())
  })

  /**
   *  Create and render a Picker object for searching images.
   */
  function createPicker(accessToken: string): Promise<GoogleDrivePickedData> {
    const google = window.google
    const view = new google.picker.DocsView().setIncludeFolders(true).setSelectFolderEnabled(true)

    return new Promise((resolve, reject) => {
      new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setDeveloperKey(API_KEY)
        .setAppId(APP_ID)
        .setOAuthToken(accessToken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setCallback((data) => {
          switch (data.action) {
            case 'picked': {
              const docs = data.docs || []
              resolve({ docs })
              break
            }
            case 'cancel':
            case 'error':
              reject()
              break
            default:
              break
          }
        })
        .build()
        .setVisible(true)
    })
  }

  return {
    createPicker,
    loaded,
  }
}
