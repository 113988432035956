<script setup lang="ts">
import { deleteConnection } from '@/backend/deleteConnection'
import IntegrationConnectionRow from '@/modules/Workspaces/KnowledgeHub/IntegrationConnectionRow.vue'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import type { IconName } from '@/uiKit/IconName'
import { computed } from 'vue'
import IntegrationCard from './IntegrationCard.vue'
import { connectionNameMap, useConnections, type ConnectionType } from './useConnections'

type Integration = {
  name: string
  icon: IconName
  description?: string
  type: ConnectionType
}

const { workspaceId } = useRouteParams()
const connectionsStore = useConnections()
const workspaceMembersStore = useWorkspaceMembers()

const integrations: Integration[] = [
  {
    name: connectionNameMap.google_drive,
    icon: 'company-google',
    description:
      'Experience superior search and use docs, sheets, slides, and other Drive files as context in your chats.',
    type: 'google_drive',
  },
]

const availableIntegrations = computed(() => {
  return integrations.filter(
    (i) => !connectionsStore.connections.some((c) => c.integration_id === i.type),
  )
})

async function removeConnection(c: (typeof connectionsStore.connections)[number]) {
  if (!workspaceId.value) return
  await deleteConnection({
    connectionId: c.id,
    integrationId: c.integration_id,
    workspaceId: workspaceId.value,
  })
  connectionsStore.load()
}

const connections = computed(() => {
  return connectionsStore.connections.map((c) => ({
    ...c,
    user: workspaceMembersStore.getMember(c.user_id),
    name: connectionNameMap[c.integration_id],
    icon: integrations.find((i) => i.type === c.integration_id)?.icon,
  }))
})
</script>

<template>
  <div class="h-full bg-surface-secondary-persist">
    <div class="h-full rounded-corner-8 bg-surface-primary px-20 pt-10">
      <h1 class="flex gap-1 text-display-sm-24px-default">
        Integrations
        <span class="text-sm-12px-default text-text-warning">
          {{ connections.length }}
        </span>
      </h1>

      <template v-if="connections.length">
        <h2 class="mt-10 text-md-13px-bold">Enabled</h2>
        <p class="text-sm-12px-light text-text-subtle">Manage your connected integrations</p>

        <IntegrationConnectionRow
          v-for="connection in connections"
          :key="connection.integration_id"
          :connection="connection"
          class="relative mt-6"
          @remove="removeConnection(connection)"
        />
      </template>

      <template v-if="availableIntegrations.length">
        <h2 class="mt-10 text-md-13px-bold">Explore more</h2>
        <p class="text-sm-12px-light text-text-subtle">
          Find more integrations to connect to your account
        </p>

        <div class="mx-auto mt-10 grid grid-cols-[repeat(auto-fill,minmax(220px,1fr))] gap-1">
          <IntegrationCard
            v-for="integration in availableIntegrations"
            :key="integration.name"
            :name="integration.name"
            :description="integration.description ?? ''"
            :icon="integration.icon"
          />
        </div>
      </template>
    </div>
  </div>
</template>
