import type { components } from '@/api'
import { stringIsValidTool } from '@/backend/guards'
import {
  type EntityResponse,
  type PropertyResponse,
  PropertyType,
  type ViewResponse,
} from '@/backend/types'
import type { BaseField, DefinedField, Field, ReferenceField } from '@/modules/Project/Fields/types'
import type { BaseProperty, Property } from '@/modules/Project/Properties/types'
import type { PartialRecord } from '@/shared/types'
import {
  coalesceEmptyObjectToNull,
  getMilisecondsFromUUIDv7,
  getUnionProperty,
  stripUndefined,
} from '@/shared/utils'
import { exhaustiveGuard, invariant } from '@/shared/utils/typeAssertions'
import { captureException } from '@sentry/vue'
import { defineStore } from 'pinia'
import { computed, type ComputedRef, ref } from 'vue'

export type Entity = {
  id: EntityResponse['id']
  activeViewIds: EntityResponse['active_view_ids']
  fields: Map<DefinedField['property_id'], Field>
  parentEntityId?: EntityResponse['parent_entity_id']
}

export type PropertyLayout = {
  propertyId: NonNullable<ViewResponse['property_layouts']>[number]['property_id']
  x: NonNullable<ViewResponse['property_layouts']>[number]['x']
  y: NonNullable<ViewResponse['property_layouts']>[number]['y']
  width: NonNullable<ViewResponse['property_layouts']>[number]['width']
  height: NonNullable<ViewResponse['property_layouts']>[number]['height']
}

export type View = {
  id: ViewResponse['id']
  name: ViewResponse['name']
  propertyIds: ViewResponse['property_ids']
  propertyLayouts: PropertyLayout[]
  filters: ViewResponse['filters']
  propertyOptions: ViewResponse['property_options']
  assignablePropertyId: ViewResponse['assignable_property_id']
  numPinnedProperties: ViewResponse['num_pinned_properties']
  entityCount?: ViewResponse['entity_count']
}

export type ViewInfo = {
  id: ViewResponse['id']
  view: View
  entities: (Entity | undefined)[] | undefined
  entityIdToIndex: Map<string, number> | undefined
}

export const serializeEntity = (response: EntityResponse): Entity =>
  stripUndefined({
    id: response.id,
    activeViewIds: response.active_view_ids,
    fields: serializeFields(response.fields),
    parentEntityId: response.parent_entity_id,
  })

export const serializeFields = (fields: EntityResponse['fields']) =>
  new Map(
    Object.values(fields)
      .filter((f): f is DefinedField => f !== undefined)
      .toSorted((a, b) => a.property_id.localeCompare(b.property_id))
      .map((field) => [field.property_id, serializeField(field)]),
  )

export const serializeField = (
  fieldResponse: components['schemas']['Projects.Entities.FieldResponse'],
): Field => {
  const toolValueUpdatedBy = stringIsValidTool(fieldResponse.tool_value.updated_by)
    ? fieldResponse.tool_value.updated_by
    : null

  const baseProps: Omit<BaseField<unknown>, 'manualValue' | 'toolValue' | 'draftValue'> & {
    draftValue: undefined
  } = {
    propertyId: fieldResponse.property_id,
    entityId: fieldResponse.entity_id,
    status: fieldResponse.status,
    errorMessage: fieldResponse.error_message,
    propertyHash: fieldResponse.property_hash,
    groundTruth: fieldResponse.ground_truth,
    updatedAt: fieldResponse.updated_at,
    manualValueUpdatedBy: fieldResponse.manual_value.updated_by,
    toolValueUpdatedBy,
    score: fieldResponse.manual_metadata?.value_score ?? null,
    draftValue: undefined,
  }

  const grounding = getUnionProperty('grounding', [
    fieldResponse.tool_value,
    fieldResponse.manual_value,
  ])

  if (fieldResponse.property_type === PropertyType.file) {
    const ocrPages = getUnionProperty(
      'ocr_pages',
      [fieldResponse.tool_value, fieldResponse.manual_value],
      (v) => Boolean(v && Array.isArray(v) && v.length),
    )

    const transcription = getUnionProperty('transcription', [
      fieldResponse.tool_value,
      fieldResponse.manual_value,
    ])

    const manualValue =
      fieldResponse.manual_value as components['schemas']['Projects.Entities.FieldFileResponse']

    const toolValue =
      fieldResponse.tool_value as components['schemas']['Projects.Entities.FieldFileResponse']

    return {
      type: 'file',
      manualValue: manualValue.value,
      manualValuePDF: manualValue.pdf?.url ?? null,
      manualFilename: manualValue.original_filename,
      toolValue: toolValue.pdf?.url ?? toolValue.value,
      toolValuePDF: manualValue.pdf?.url ?? null,
      toolFilename: toolValue.original_filename,
      ocrPages: ocrPages ?? null,
      transcription: transcription ?? null,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.text) {
    return {
      type: 'text',
      manualValue: fieldResponse.manual_value.value as string | null,
      toolValue: fieldResponse.tool_value.value as string | null,
      grounding: grounding ?? null,
      hasGrounding:
        'has_grounding' in fieldResponse.tool_value && fieldResponse.tool_value.has_grounding,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.single_select) {
    return {
      type: 'single_select',
      manualValue: fieldResponse.manual_value.value as string[] | null,
      toolValue: fieldResponse.tool_value.value as string[] | null,
      metadata: fieldResponse.tool_metadata,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.multi_select) {
    return {
      type: 'multi_select',
      manualValue: fieldResponse.manual_value.value as string[] | null,
      toolValue: fieldResponse.tool_value.value as string[] | null,
      metadata: fieldResponse.tool_metadata,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.user_select) {
    return {
      type: 'user_select',
      manualValue: fieldResponse.manual_value.value as string[] | null,
      toolValue: fieldResponse.tool_value.value as string[] | null,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.json) {
    return {
      type: 'json',
      manualValue: fieldResponse.manual_value.value as string | null,
      toolValue: fieldResponse.tool_value.value as string | null,
      grounding: grounding ?? null,
      hasGrounding:
        'has_grounding' in fieldResponse.tool_value && fieldResponse.tool_value.has_grounding,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.url) {
    return {
      type: 'url',
      metadata: fieldResponse.tool_metadata,
      manualValue: fieldResponse.manual_value.value as string | null,
      toolValue: fieldResponse.tool_value.value as string | null,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.pdf) {
    return {
      type: 'pdf',
      manualValue: fieldResponse.manual_value.value as string | null,
      manualFilename:
        'original_filename' in fieldResponse.manual_value
          ? fieldResponse.manual_value.original_filename
          : null,
      toolValue: fieldResponse.tool_value.value as string | null,
      toolFilename:
        'original_filename' in fieldResponse.tool_value
          ? fieldResponse.tool_value.original_filename
          : null,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.collection) {
    return {
      type: 'collection',
      manualValue: fieldResponse.manual_value.value as string | null,
      manualName: fieldResponse.manual_metadata?.field_name ?? null,
      toolValue: fieldResponse.tool_value.value as string | null,
      subprojectPreview: fieldResponse.subproject_preview
        ? {
            entityCount: fieldResponse.subproject_preview.total_entity_count,
            entityPreviews: fieldResponse.subproject_preview.entity_previews,
          }
        : undefined,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.file_collection) {
    return {
      type: 'file_collection',
      manualValue: fieldResponse.manual_value.value as string | string[] | null,
      toolValue: fieldResponse.tool_value.value as string | string[] | null,
      manualName: fieldResponse.manual_metadata?.field_name ?? null,
      manualFilename:
        'original_filename' in fieldResponse.manual_value
          ? fieldResponse.manual_value.original_filename
          : null,
      toolFilename:
        'original_filename' in fieldResponse.tool_value
          ? fieldResponse.tool_value.original_filename
          : null,

      subprojectPreview: fieldResponse.subproject_preview
        ? {
            entityCount: fieldResponse.subproject_preview.total_entity_count,
            entityPreviews: fieldResponse.subproject_preview.entity_previews,
          }
        : undefined,
      ...baseProps,
    }
  }

  if (fieldResponse.property_type === PropertyType.reference) {
    if (
      !('matched_entity_ids' in fieldResponse.manual_value) ||
      !('matched_entity_ids' in fieldResponse.tool_value)
    ) {
      // Our API spec isn't typed strictly enough to guarantee this, but if
      // we get here then something is very wrong and users will have a very
      // broken experience.
      captureException(
        new Error("Reference field doesn't have matched_entity_ids - something is very wrong."),
      )
    }

    const field: ReferenceField = {
      ...baseProps,
      type: 'reference',
      manualEntityIds:
        'matched_entity_ids' in fieldResponse.manual_value
          ? fieldResponse.manual_value.matched_entity_ids
          : [],
      toolEntityIds:
        'matched_entity_ids' in fieldResponse.tool_value
          ? fieldResponse.tool_value.matched_entity_ids
          : [],
      manualValue:
        'matched_entity_ids' in fieldResponse.manual_value
          ? coalesceEmptyObjectToNull(fieldResponse.manual_value.value)
          : null,
      toolValue:
        'matched_entity_ids' in fieldResponse.tool_value
          ? coalesceEmptyObjectToNull(fieldResponse.tool_value.value)
          : null,
      subprojectPreview:
        'subproject_preview' in fieldResponse ? fieldResponse.subproject_preview || null : null,
    }
    return field
  }

  if (fieldResponse.property_type === 'integration') {
    throw new Error('TODO: implement integration field')
  }

  exhaustiveGuard(
    fieldResponse.property_type,
    `Unhandled field type: ${fieldResponse.property_type}`,
  )

  throw new Error('Field type could not be inferred')
}

export const serializeProperty = (payload: PropertyResponse): Property => {
  const base: BaseProperty = {
    id: payload.id,
    name: payload.name,
    tool: payload.tool,
    isGrounded: payload.is_grounded,
    effectiveTool: payload.effective_tool,
    description: payload.description,
    inputs: payload.inputs.map((i) => ({
      entityId: i.entity_id,
      filters: i.entities_filter,
      propertyId: i.property_id,
      viaPropertyId: i.via_property_id,
    })),
    hash: payload.hash,
    slug: payload.slug,
    owner: payload.owner,
    parentProjectId: payload.parent_project_id,
    parentPropertyId: payload.parent_property_id,
    // We've received this payload from the backend, so the property will never be
    // optimistic here
    isOptimistic: false,
  }

  if (payload.type === PropertyType.single_select || payload.type === PropertyType.user_select) {
    invariant(
      'config' in payload && payload.config && 'options' in payload.config,
      'Invalid single/user select property config',
    )

    return {
      ...base,
      type: payload.type,
      config: {
        options: payload.config.options,
        defaultOption: payload.config.default_option,
      },
    }
  }

  if (payload.type === PropertyType.multi_select) {
    invariant(
      'config' in payload && payload.config && 'options' in payload.config,
      'Invalid multi select property config',
    )

    return {
      ...base,
      type: payload.type,
      config: {
        options: payload.config.options,
        maxSelected: 'max_selected' in payload.config ? payload.config.max_selected : undefined,
        defaultOption: payload.config.default_option,
      },
    }
  }

  if (payload.type === PropertyType.pdf) {
    invariant(
      'config' in payload && payload.config && 'splitter' in payload.config,
      'Invalid pdf config',
    )
    return {
      ...base,
      type: payload.type,
      config: {
        splitter: payload.config.splitter,
        subprojectConfig: payload.config.subproject_config,
      },
    }
  }

  if (payload.type === PropertyType.collection) {
    if (
      !(
        'config' in payload &&
        payload.config &&
        'properties' in payload.config &&
        payload.config.properties
      )
    ) {
      captureException(new Error('Invalid collection config'), { data: payload })
      throw new Error('Invalid collection config')
    }

    return {
      ...base,
      type: payload.type,
      config: {
        properties: payload.config.properties,
        subprojectConfig: payload.config.subproject_config,
      },
    }
  }

  if (payload.type === PropertyType.file_collection) {
    invariant(
      'config' in payload &&
        payload.config &&
        'properties' in payload.config &&
        payload.config.properties,
      'Invalid file_collection config',
    )
    return {
      ...base,
      type: payload.type,
      config: {
        properties: payload.config.properties,
        subprojectConfig: payload.config.subproject_config,
      },
    }
  }

  if (payload.type === PropertyType.reference) {
    if (
      !payload.config ||
      !('entity_limit' in payload.config) ||
      !('project_id' in payload.config)
    ) {
      throw new Error(
        "Invalid reference property config. 'entity_limit' and 'project_id' are required",
      )
    }

    return {
      ...base,
      type: payload.type,
      config: {
        entityLimit: payload.config.entity_limit,
        projectId: payload.config.project_id,
      },
    }
  }

  return {
    ...base,
    type: payload.type,
  }
}

export const serializeView = (payload: ViewResponse): View => {
  return {
    id: payload.id,
    name: payload.name,
    propertyIds: payload.property_ids,
    propertyLayouts: payload.property_layouts.map(serializePropertyLayout),
    filters: payload.filters,
    propertyOptions: payload.property_options,
    assignablePropertyId: payload.assignable_property_id,
    numPinnedProperties: payload.num_pinned_properties,
    entityCount: payload.entity_count,
  }
}

export const serializePropertyLayout = (payload: ViewResponse['property_layouts'][number]) => ({
  propertyId: payload.property_id,
  x: payload.x,
  y: payload.y,
  width: payload.width,
  height: payload.height,
})

const DEFAULT_COLUMN_WIDTH = 180

const fileURLForType = (
  field: Field,
  operation: 'display' | 'download',
  valueType: 'tool' | 'manual',
): string | null => {
  const isTool = valueType === 'tool'
  const property = isTool ? 'toolValue' : 'manualValue'
  const pdfProperty = isTool ? 'toolValuePDF' : 'manualValuePDF'

  if (field.type === 'file_collection') {
    if (Array.isArray(field[property])) {
      return field[property].at(0) || null
    }
    return field[property]
  }

  if (field.type === 'pdf') {
    return field[property]
  }

  if (field.type === 'file') {
    if (operation === 'display' && field[pdfProperty]) {
      return field[pdfProperty]
    }

    return field[property]
  }

  return null
}

export const fileURL = (field: Field, operation: 'display' | 'download') => {
  return fileURLForType(field, operation, 'manual') ?? fileURLForType(field, operation, 'tool')
}

export const useProject = defineStore('project', () => {
  const projectId = ref<string | null>(null)
  const sort = ref<{ prop: Property['id']; order: 'asc' | 'desc' } | null>(null)

  const selectedEntityIds = ref(new Set<Entity['id']>())
  const properties = ref<Property[]>([])
  const projectLoaded = ref(false)
  const entitiesPerView = ref(new Map<View['id'], (Entity | undefined)[]>())
  const views = ref<View[]>([])
  const widths = ref<Partial<Record<Property['id'], number>>>({})
  const selectedPropertyId = ref<Property['id'] | null>(null)
  const activeViewId = ref<View['id'] | null>(null)
  const mainViewId = ref<View['id'] | null>(null)
  const areEntitiesStale = ref(false)
  const parentEntityId = ref<string | null>(null)

  /**
   * Maps each Entity ID to its draft field values. These are the values shown when
   * previewing ranges of cells when pasting. These are stored separately to the
   * entities because entities are often reloaded when the view changes, which
   * would cause draft values to be lost (unless we did an expensive lookup
   * to persist draft data when loading entities).
   */
  const draftFields = ref<
    PartialRecord<Entity['id'], PartialRecord<Property['id'], Field['manualValue']>>
  >({})
  const resetDrafts = () => {
    draftFields.value = {}
  }

  const setParentEntityId = (pid?: string) => {
    parentEntityId.value = pid ?? null
  }

  const setEntitiesStale = (stale: boolean) => (areEntitiesStale.value = stale)

  const propIdToIndex = computed(() =>
    Object.fromEntries(properties.value.map((p, i) => [p.id, i])),
  )

  const entityIdToIndexPerView: ComputedRef<Map<string, Map<string, number>>> = computed(() => {
    const result = new Map()
    entitiesPerView.value.forEach((entities, viewId) =>
      result.set(viewId, new Map(entities.flatMap((e, i) => (e ? [[e.id, i]] : [])))),
    )
    return result
  })

  const viewInfo = (viewId: string): ViewInfo | undefined => {
    const view = views.value.find((v) => v.id === viewId)
    const entities = entitiesPerView.value.get(viewId)
    const entityIdToIndex = entityIdToIndexPerView.value.get(viewId)
    if (!view) return undefined

    return {
      id: viewId,
      view,
      entities,
      entityIdToIndex,
    }
  }

  const activeView = computed(() => (activeViewId.value ? viewInfo(activeViewId.value) : undefined))
  const mainView = computed(() => (mainViewId.value ? viewInfo(mainViewId.value) : undefined))

  const setSelectedProperty = (id: string | null) => {
    selectedPropertyId.value = id
  }

  const selectedProperty = computed(() => {
    if (!selectedPropertyId.value) return null
    return properties.value[propIdToIndex.value[selectedPropertyId.value]]
  })

  const getWidth = (id: string) => widths.value[id] ?? DEFAULT_COLUMN_WIDTH

  const resizeProperty = (id: Property['id'], width: number) => {
    widths.value[id] = width
  }

  const spliceEntities = (
    incomingEntities: Entity[],
    totalCountFromServer: number | null,
    start: number,
    viewId: string,
  ) => {
    let entities = entitiesPerView.value.get(viewId) ?? []

    if (typeof totalCountFromServer === 'number' && totalCountFromServer !== entities.length) {
      // if the total count has changed, we need to update the count in the store
      entities = Array.from({ length: totalCountFromServer })
    }

    entities.splice(start, incomingEntities.length, ...incomingEntities)
    entitiesPerView.value.set(viewId, entities)
  }

  const dropEntitiesOutsideOfView = (viewId: string, start: number, end: number) => {
    const info = viewInfo(viewId)

    if (!info || !info.entities) {
      entitiesPerView.value = new Map()
      return
    }

    info.entities = info.entities.fill(undefined, 0, start).fill(undefined, end)
    entitiesPerView.value = new Map([[info.id, info.entities]])
  }

  /** Sets the views and resets the entitiesPerView array to be empty */
  const setViews = (newMainViewId: string | null, newViews: View[]) => {
    views.value = newViews
    mainViewId.value = newMainViewId
    activeViewId.value = newMainViewId
    entitiesPerView.value = new Map()
    areEntitiesStale.value = false
    resetDrafts()
  }

  const upsertView = (view: View) => {
    const index = views.value.findIndex((v) => v.id === view.id)
    if (index >= 0) {
      views.value.splice(index, 1, { ...views.value[index], ...view })
    } else {
      views.value.push(view)
    }
  }

  const removeView = (id: string) => {
    views.value = views.value.filter((v) => v.id !== id)
    entitiesPerView.value.get(id)?.forEach((entity) => {
      if (!entity) return
      entity.activeViewIds = entity.activeViewIds?.filter((vId) => vId !== id)
    })
    entitiesPerView.value.delete(id)
    if (activeViewId.value == id) {
      activeViewId.value = mainViewId.value
    }
  }

  /**
   * Pushes or replaces an entity in the store.
   * Entities can be pushed directly upon successful create request, or
   * indirectly upon websocket message.
   *
   * This action ensures it happens correctly, and we don't insert duplicates.
   * This also updates the entities within each view if the entity is active in that view.
   */
  const upsertEntity = (entity: Entity) => {
    views.value.forEach((view) => {
      if (
        view.id === activeViewId.value &&
        parentEntityId.value !== null &&
        entity.parentEntityId !== parentEntityId.value
      ) {
        return
      }

      const isPartOfView = entity.activeViewIds?.includes(view.id)
      const info = viewInfo(view.id)
      if (!info || !info.entities || !info.entityIdToIndex) {
        return
      }
      const index = info.entityIdToIndex.get(entity.id) ?? -1
      if (!isPartOfView) {
        // if it is not part and is there then remove it
        if (index >= 0) info.entities.splice(index, 1)
        return
      }
      // if it is part and is there then update it
      if (index >= 0) {
        info.entities.splice(index, 1, { ...entity })
        return
      }

      // if it is part and is not there - we need to find where to insert it,
      if (isPartOfView && index === -1) {
        // we can insert it only if that entity is somewhere between the entities, if it's position is next to an undefined entity then we can't insert it

        const sortedArray = [...info.entities.filter((x): x is Entity => !!x), entity].toSorted(
          (a, b) => getMilisecondsFromUUIDv7(a.id) - getMilisecondsFromUUIDv7(b.id),
        )
        const index = sortedArray.findIndex((e) => e.id === entity.id)
        const leftNeighbour = sortedArray[index - 1]
        const rightNeighbour = sortedArray[index + 1]

        const leftNeighbourIndex = info.entityIdToIndex.get(leftNeighbour?.id) ?? -1
        const rightNeighbourIndex = info.entityIdToIndex.get(rightNeighbour?.id) ?? -1

        const rightNeighbourIsFirst = rightNeighbourIndex === 0
        if (rightNeighbourIsFirst) {
          info.entities.unshift(entity)
          return
        }
        const leftNeighbourIsLast = leftNeighbourIndex === info.entities.length - 1
        if (leftNeighbourIsLast) {
          info.entities.push(entity)
          return
        }
        const neighboursAreNextToEachOther = leftNeighbourIndex + 1 === rightNeighbourIndex
        if (neighboursAreNextToEachOther) {
          info.entities.splice(rightNeighbourIndex, 0, entity)
          return
        }
      }
    })
  }

  const removeEntityFromView = (entityId: string, viewId: string) => {
    const entity = findEntityById(entityId)
    if (!entity || !entity.activeViewIds?.includes(viewId)) return
    entity.activeViewIds = entity.activeViewIds?.filter((id) => id !== viewId)
    upsertEntity(entity)
  }

  // TODO update existing logic to use this map instead of properties.find(p => p.id === someid)
  const propertiesById = computed<Partial<Record<string, Property>>>(() =>
    Object.fromEntries(properties.value.map((p) => [p.id, p])),
  )

  const visibleProperties = computed(() => {
    return (activeView.value?.view.propertyIds ?? [])
      .map((id) => propertiesById.value[id])
      .filter((x): x is Property => !!x)
  })

  const setProperties: (p: Property[]) => void = (p) => {
    properties.value = p
  }

  const shiftPropertyToIndex = (propertyId: string, newIndex: number) => {
    const view = activeView.value?.view
    if (!view || !view.propertyIds) return { ok: false }
    const oldPropertyIds = visibleProperties.value.map((x) => x.id)
    const newPropertyIds = oldPropertyIds.slice()
    const propertyIndex = newPropertyIds.findIndex((id) => id === propertyId)
    if (propertyIndex === -1) return { ok: false }
    if (propertyIndex === newIndex) return { ok: false }
    newPropertyIds.splice(propertyIndex, 1)
    newPropertyIds.splice(newIndex, 0, propertyId)
    upsertView({ ...view, propertyIds: newPropertyIds })
    return { ok: true, oldPropertyIds } as const
  }

  const upsertProperty = (property: Property) => {
    const index = properties.value.findIndex((p) => p.id === property.id)
    if (index >= 0) {
      properties.value.splice(index, 1, property)
    } else {
      properties.value = [...properties.value, property]
    }
  }

  const removeProperty = (id: string) => {
    properties.value = properties.value.filter((p) => p.id !== id)
    entitiesPerView.value.forEach((entities) =>
      entities.forEach((entity) => entity && entity.fields.delete(id)),
    )
  }

  const subProjectIds = computed(() => {
    const subProjectIds = properties.value
      .map((p) => {
        // Currently only PDF and Collection properties have subprojects, in the future there will be more
        if (
          p.type === PropertyType.pdf ||
          p.type === PropertyType.collection ||
          p.type === PropertyType.file_collection
        ) {
          return p.config.subprojectConfig.child_project_id
        }
        return null
      })
      .filter((p): p is string => p !== null)

    return [...new Set(subProjectIds)]
  })

  const toggleEntity = (rowId: string, value: boolean) => {
    if (value) {
      selectedEntityIds.value.add(rowId)
    } else {
      selectedEntityIds.value.delete(rowId)
    }
  }

  const toggleAllEntities = () => {
    if (!activeView.value || !activeView.value.entities) return
    const entities = activeView.value.entities

    if (selectedEntityIds.value.size < entities.length) {
      entities.forEach((r) => r && selectedEntityIds.value.add(r.id))
    } else {
      selectedEntityIds.value.clear()
    }
  }

  const clearAllSelectedEntities = () => {
    selectedEntityIds.value.clear()
  }

  const extendRowSelectionTo = (entityId: string) => {
    if (!activeView.value || !activeView.value.entities || !activeView.value.entityIdToIndex) return
    if (selectedEntityIds.value.size === 0) {
      selectedEntityIds.value.add(entityId)
      return
    }
    const { entities, entityIdToIndex } = activeView.value
    const targetIndex = entityIdToIndex.get(entityId)
    if (targetIndex === undefined) return

    const firstCheckedIndex = entities.findIndex((e) => e && selectedEntityIds.value.has(e.id))
    const lastCheckedIndex = entities.findLastIndex((e) => e && selectedEntityIds.value.has(e.id))

    const from = Math.min(lastCheckedIndex, targetIndex)
    const to = Math.max(firstCheckedIndex, targetIndex)

    entities.slice(from, to + 1).forEach((e) => e && selectedEntityIds.value.add(e.id))
  }

  const allEntitiesSelected = computed(
    () => selectedEntityIds.value.size === activeView.value?.entities?.length,
  )

  const setProjectId = (id: string) => {
    projectId.value = id
  }

  const setActiveViewId = (id?: string) => {
    activeViewId.value = id ?? mainViewId.value
  }

  const removeEntitiesById = (ids: string[]) => {
    const idSet = new Set(ids)
    ids.forEach((id) => selectedEntityIds.value.delete(id))

    views.value.forEach((view) => {
      const entities = entitiesPerView.value.get(view.id)
      if (!entities) return
      entitiesPerView.value.set(
        view.id,
        entities.filter((e) => !e || !idSet.has(e.id)),
      )
    })
  }

  const findEntityById = (id: string) => {
    for (const [viewId, entityIdToIndex] of entityIdToIndexPerView.value) {
      const entities = entitiesPerView.value.get(viewId)
      const entityIndex = entityIdToIndex.get(id)
      if (entityIndex !== undefined && entities?.[entityIndex]) return entities[entityIndex]
    }
  }

  /**
   * Effectively acts as optimistic UI for setting field right after a user
   * updating it with the backend. Soon after, we will be receiving the full
   * field payload via websockets, at which point, whatever
   * we do here will be overriden.
   *
   * The goal here is to figure out whether to set the input value, or the corrected value.
   */
  const updateField = (updatedField: Field) => {
    const entity = findEntityById(updatedField.entityId)
    if (!entity) return

    entity.activeViewIds?.forEach((viewId) => {
      const viewEntities = entitiesPerView.value.get(viewId)
      const index = entityIdToIndexPerView.value.get(viewId)?.get(updatedField.entityId)

      if (index === undefined) return
      viewEntities?.[index]?.fields.set(updatedField.propertyId, updatedField)
    })
  }

  const setFileFieldValue = ({
    entityId,
    propertyId,
    value,
    filename,
  }: {
    entityId: string
    propertyId: string
    value: string
    filename: string
  }) => {
    const entity = findEntityById(entityId)
    if (!entity) return

    const field = entity.fields.get(propertyId)
    if (!field) return

    if (field.type === 'file') {
      field.manualValue = value
      field.manualFilename = filename
      updateField(field)
    }
  }

  return {
    activeView,
    allEntitiesSelected,
    areEntitiesStale,
    clearAllSelectedEntities,
    dropEntitiesOutsideOfView,
    extendRowSelectionTo,
    fileURL,
    getWidth,
    mainView,
    projectId,
    projectLoaded,
    properties,
    propertiesById,
    propIdToIndex,
    removeEntitiesById,
    removeEntityFromView,
    removeProperty,
    removeView,
    resizeProperty,
    selectedEntityIds,
    selectedProperty,
    selectedPropertyId,
    setActiveViewId,
    setEntitiesStale,
    setFileFieldValue,
    setProjectId,
    setProperties,
    setSelectedProperty,
    setViews,
    sort,
    spliceEntities,
    shiftPropertyToIndex,
    subProjectIds,
    toggleAllEntities,
    toggleEntity,
    updateField,
    upsertEntity,
    upsertProperty,
    upsertView,
    views,
    viewInfo,
    visibleProperties,
    widths,
    setParentEntityId,
    draftFields,
    resetDrafts,
  }
})
