<script setup lang="ts">
import AppHeader from '@/sharedComponents/AppHeader.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import CrumbItem from '../Crumbs/CrumbItem.vue'

defineProps<{ workspaceId: string }>()
const { isOpen } = useSidebar()
</script>

<template>
  <AppHeader class="!border-none">
    <template #crumbs>
      <CrumbItem
        class="transition-transform duration-300 ease-in-out-quint"
        :class="isOpen ? 'translate-x-0' : 'translate-x-8'"
        :to="{ name: 'KnowledgeHub' }"
        :active="true"
      >
        Knowledge Hub
      </CrumbItem>
    </template>
    <template #actions>{{}}</template>
  </AppHeader>
</template>
