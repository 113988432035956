<script setup lang="ts">
import ProjectBadge from '@/modules/Project/ProjectBadge.vue'
import { getCoverImageUrl } from '@/modules/Project/utils'
import { useProjects } from '@/modules/Projects/useProjects'
import { useProfileImage } from '@/modules/WorkspaceSettings/useProfileImage'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { getFilenameFromUrl } from '@/shared/utils/file'
import AvatarIcon from '@/uiKit/AvatarIcon.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import { computed } from 'vue'
import type { ProjectWithCoverImage } from '../InitialQuestionSource.vue'
import type { QueryMessage } from '../types'

const props = defineProps<{
  message: QueryMessage
}>()

const projectsStore = useProjects()
const projects = computed(() =>
  props.message.data
    .filter((d) => d.type === 'project_id')
    .reduce<Array<ProjectWithCoverImage>>((acc, data) => {
      const project = projectsStore.projects.find((p) => p.id === data.project_id)
      if (!project) {
        // Could be a race condition, the computed getter will reevaluate
        // when projects are loaded
        return acc
      }

      return [
        ...acc,
        {
          ...project,
          coverImageUrl: getCoverImageUrl(project, 'low'),
        },
      ]
    }, []),
)

const filenames = computed(() =>
  props.message.data
    .filter((d) => d.type === 'attachment_url')
    .map((d) => getFilenameFromUrl(d.attachment_url))
    .filter((filename): filename is string => !!filename),
)

const workspaceMemberStore = useWorkspaceMembers()
const queryUser = computed(() =>
  workspaceMemberStore.workspaceMembers.find((m) => m.id === props.message.userId),
)
const profileImageUrl = useProfileImage(computed(() => props.message.userId))
</script>

<template>
  <div class="flex gap-2">
    <div>
      <div
        class="mb-1 rounded-2xl rounded-br-corner-4 bg-background-gray-subtlest px-4 py-2 text-sm-12px-light text-text-subtle"
      >
        {{ message.query }}
      </div>
      <div class="flex items-center justify-start gap-2">
        <ProjectBadge
          v-for="project in projects"
          :key="project.id"
          :name="project.name"
          :cover-image-url="project.coverImageUrl"
          size="sm"
        />
        <BadgeItem
          v-for="(filename, index) in filenames"
          :key="index"
          :label="filename"
          leading-icon="file-fill"
          size="sm"
          variant="neutral"
        />
      </div>
    </div>
    <div class="pt-2">
      <AvatarIcon
        size="md"
        shape="circle"
        :url="profileImageUrl"
        :full-text="queryUser?.fullName"
      />
    </div>
  </div>
</template>
