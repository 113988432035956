<script setup lang="ts">
import { getEntity } from '@/backend/getEntity'
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import UrlBadge from '@/sharedComponents/UrlBadge.vue'
import { useDataLoader } from '@/sharedComposables/useDataLoader'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import TagButton from '@/uiKit/TagButton.vue'
import { captureException } from '@sentry/vue'
import { computed, ref, useId, watch } from 'vue'
import ObjectURLImage from '../Projects/ObjectURLImage.vue'
import type { Project } from '../Projects/useProjects'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import { ENTITY_NAME_FALLBACK } from './constants'
import { TYPE_ICON } from './icons'
import type { FieldPreview } from './types/fieldPreview'
import type { Entity } from './useProject'
import { serializeEntity } from './useProject'
import type { FullOrPreviewEntity } from './useReferenceField'
import { useSerializeFieldToText } from './useSerializeFieldToText'
import { getCoverImageUrl, getSelectPropertyOptionColor } from './utils'

const props = defineProps<{
  entity: FullOrPreviewEntity
  namePropertyId: string
  project: Project
  properties: Property[]
}>()

defineEmits<{
  (e: 'open:entity'): void
}>()

/**
 * The number of fields to show in preview mode. If there are more fields
 * then this, the user will need to click "See more" for the full list.
 */
const PREVIEW_FIELD_COUNT = 8

const { workspaceId } = useRouteParams()
const workspaceMembersStore = useWorkspaceMembers()

const serialize = useSerializeFieldToText()

/**
 * When in preview mode, we will only show a subset of fields. In
 * "full" mode, all fields will be shown.
 */
const viewMode = ref<'preview' | 'full'>('preview')

/**
 * The full entity, including all fields. This is loaded and set as soon
 * as the component is mounted in the case where props.entity is only a
 * preview entity.
 */
const fullEntity = ref<Entity | null>(null)

const entityLoader = useDataLoader(() =>
  getEntity(workspaceId.value, props.project.id, props.entity.id),
)

/**
 * props.entity could only be a preview entity. So when it changes (and on mount),
 * we should make sure that we have the full entity loaded.
 */
watch(
  () => props.entity,
  async (entity) => {
    fullEntity.value = null

    if ('activeViewIds' in entity) {
      fullEntity.value = entity
    } else {
      const res = await entityLoader.load()
      if (!res.ok) {
        captureException(new Error('Failed to load full entity when previewing'), {
          data: {
            message: res.error.message,
            code: res.error.code,
            projectId: props.project.id,
            workspaceId: workspaceId.value,
            entityId: props.entity.id,
          },
        })
        return
      }

      const serialized = serializeEntity(res.data)
      fullEntity.value = serialized
    }
  },
  {
    immediate: true,
  },
)

/**
 * All properties except the name property. We use this value because we don't render
 * the name property in the list of all property/field values.
 */
const notNameProperties = computed(() =>
  props.properties.filter((property) => property.id !== props.namePropertyId),
)

/**
 * All fields that should be shown in the list.
 */
const previewFields = computed<
  Array<{ property: Property; field: Field | FieldPreview; serializedValue: string }>
>(() => {
  const returnArray: Array<{
    property: Property
    field: Field | FieldPreview
    serializedValue: string
  }> = []
  const fields = Array.from(
    fullEntity.value ? fullEntity.value.fields.values() : props.entity.fields.values(),
  )

  notNameProperties.value
    .slice(0, viewMode.value === 'preview' ? PREVIEW_FIELD_COUNT : undefined)
    .forEach((property) => {
      const field = fields.find((f) => f.propertyId === property.id)
      if (field) {
        returnArray.push({ property, field, serializedValue: serialize(field) })
      }
    })

  return returnArray
})

/** The name to identify this entity by */
const name = computed(() => {
  const nameField = props.entity.fields.get(props.namePropertyId)
  if (!nameField) {
    return props.entity.id
  }

  return serialize(nameField)
})

/** True if currently in preview mode and there are more available to see */
const canSeeMore = computed(() => {
  return viewMode.value === 'preview' && notNameProperties.value.length > PREVIEW_FIELD_COUNT
})

const coverImageUrl = computed(() => getCoverImageUrl(props.project, 'low'))

const labelId = useId()
defineExpose({
  // Expose the labelId so it can be passed to an aria-labelledby in the parent
  labelId,
})
</script>

<template>
  <ListMenuContainer class="min-w-80 max-w-[420px] cursor-default">
    <template #header>
      <div class="flex min-w-0 items-center justify-stretch gap-2 p-1">
        <div class="size-12 min-w-12">
          <div
            v-if="project.coverImageDownloadError"
            class="flex size-full items-center justify-center rounded-corner-10 bg-background-gray-subtle text-text-subtle"
          >
            <IconSprite
              icon="file"
              size="xxxl"
            />
          </div>
          <ObjectURLImage
            v-else-if="coverImageUrl"
            class="size-full rounded-corner-10 object-cover"
            :url="coverImageUrl"
            :loading="false"
          />
        </div>
        <div class="flex min-w-0 flex-col items-start gap-0.5">
          <div
            :id="labelId"
            data-test="entity-preview-name"
            class="line-clamp-1 max-w-full truncate text-md-13px-bold text-text"
          >
            {{ name || ENTITY_NAME_FALLBACK }}
            <span
              v-if="!name"
              class="truncate text-text-subtle"
            >
              {{ entity.id }}
            </span>
          </div>
          <TagButton
            size="xs"
            label="Open in entity view"
            trailing-icon="arrow-top-right"
            :title="undefined"
            @click="$emit('open:entity')"
          />
        </div>
      </div>
    </template>
    <template #default>
      <div class="p-1">
        <table
          class="grid"
          :style="{
            gridTemplateColumns: 'minmax(0, auto) minmax(0, auto)',
            rowGap: '2px',
          }"
        >
          <tbody class="contents">
            <tr
              v-for="({ property, serializedValue, field }, index) in previewFields"
              :key="index"
              class="contents"
            >
              <th
                scope="row"
                class="flex max-h-7 min-h-7 max-w-max items-center p-1 text-sm-12px-light text-text-subtle"
              >
                <div class="flex items-center gap-0.5">
                  <div class="flex size-5 items-center justify-center">
                    <IconSprite
                      :icon="TYPE_ICON[property.type]"
                      class="fill-text-subtle text-text-subtle"
                      size="sm"
                    />
                  </div>
                  <div
                    class="line-clamp-1 text-left"
                    :title="property.name"
                  >
                    {{ property.name }}
                  </div>
                </div>
              </th>
              <td
                class="line-clamp-1 flex max-h-7 min-h-7 max-w-full items-center gap-1 truncate p-1 text-sm-12px-default text-text-subtle"
                :title="serializedValue"
              >
                <template
                  v-if="
                    isSelectProperty(property) &&
                    (field.type === 'single_select' ||
                      field.type === 'multi_select' ||
                      field.type === 'user_select') &&
                    (field.manualValue || field.toolValue)
                  "
                >
                  <BadgeItem
                    v-for="value in field.manualValue || field.toolValue"
                    :key="value"
                    :label="
                      property.type === 'user_select'
                        ? workspaceMembersStore.getMemberNameFromId(value)
                        : value
                    "
                    size="sm"
                    :variant="field.type === 'user_select' ? 'blue' : 'neutral'"
                    :rainbow-color="getSelectPropertyOptionColor(property, value)"
                  />
                </template>
                <UrlBadge
                  v-else-if="field.type === 'url' && (field.manualValue || field.toolValue)"
                  :url="(field.manualValue || field.toolValue) as string"
                  :favicon-url="'metadata' in field ? field.metadata?.favicon?.url : undefined"
                  :status="'status' in field ? field.status : undefined"
                />
                <template v-else>{{ serializedValue }}</template>
              </td>
            </tr>
          </tbody>
        </table>
        <DarwinButton
          v-if="canSeeMore"
          variant="transparent-link"
          size="sm"
          @click="viewMode = 'full'"
        >
          <template #leading-icon>
            <IconSprite
              icon="chevron-bottom"
              size="sm"
            />
          </template>
          See more
        </DarwinButton>
      </div>
    </template>
  </ListMenuContainer>
</template>
