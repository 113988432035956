import { SUPPORTED_MIME_TYPES } from '@/backend/types'
import { FeatureFlag } from '@/modules/App/featureFlags'
import { useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { computed } from 'vue'

export const useSupportedMimeTypes = () => {
  const xlsxSplitEnabled = useFeatureFlags(FeatureFlag.XLSX_SPLIT, false)

  return computed<string[]>(() => {
    if (!xlsxSplitEnabled.value) {
      return [...SUPPORTED_MIME_TYPES]
    }

    return [
      ...SUPPORTED_MIME_TYPES,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]
  })
}
