import { useRouteTrace } from '@/modules/Project/routing/useRouteTrace'
import { useRouter, type LocationQueryValue, type RouteParamsGeneric } from 'vue-router'

type ResolveSubProjectRouteParams = {
  workspaceId: RouteParamsGeneric[string]
  projectId: RouteParamsGeneric[string]
  parentProjectId?: RouteParamsGeneric[string]
  parentEntityId?: LocationQueryValue | LocationQueryValue[]
  viewId?: RouteParamsGeneric[string]
  parentViewId?: LocationQueryValue | LocationQueryValue[]
  parentPropertyId?: string
  trace?: string | 'same'
}

/**
 * Returns a function that is used to get the route for a given subproject.
 */
export const useResolveProjectRoute = () => {
  const router = useRouter()
  const { getDeeperTrace, getSerializedTrace } = useRouteTrace()

  return ({
    parentProjectId,
    projectId,
    workspaceId,
    parentEntityId,
    viewId,
    parentViewId,
    parentPropertyId,
    trace,
  }: ResolveSubProjectRouteParams) => {
    if (trace === 'same') {
      trace = getSerializedTrace()
    }

    if (parentProjectId) {
      if (viewId) {
        return router.resolve({
          name: 'WorkspaceSubProjectTableView',
          params: {
            workspaceId,
            projectId,
            parentProjectId,
            viewId,
          },
          query: {
            parentEntityId,
            parentViewId,
            trace: trace || getSerializedTrace(),
          },
        })
      }

      return router.resolve({
        name: 'WorkspaceSubProjectTable',
        params: {
          workspaceId,
          projectId,
          parentProjectId,
        },
        query: {
          parentEntityId,
          parentViewId,
          trace:
            trace ||
            getDeeperTrace({
              entityId: '' + parentEntityId || '',
              propertyId: parentPropertyId,
            }),
        },
      })
    }

    if (viewId) {
      return router.resolve({
        name: 'WorkspaceProjectTableView',
        params: {
          workspaceId,
          projectId,
          viewId,
        },
      })
    }

    return router.resolve({
      name: 'WorkspaceProjectTable',
      params: {
        workspaceId,
        projectId,
      },
    })
  }
}
