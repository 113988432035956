import type { components } from '@/api'
import type { PropertyType } from '@/backend/types'
import type { Property } from '@/modules/Project/Properties/types'

/** Groups a list of filters together with a conjunction. */
export type GroupFilter = components['schemas']['EntityFilters.GroupFilter']

/** A single filter */
export type SimpleFilter = components['schemas']['EntityFilters.SimpleFilter']

/**
 * A recursive data structure, since a GroupFilter contains a list of filters,
 * which in turn can be GroupFilters themselves.
 */
export type Filter = GroupFilter | SimpleFilter

export type SelectOptionValueFilter = components['schemas']['FieldSelectOptionValue']

export type TextValueFilter = components['schemas']['FieldTextValue']

export type EntityIdFilter = components['schemas']['EntityId']

export type FilenameFilter = components['schemas']['FieldFileName']

export type FieldNameFilter = components['schemas']['FieldMetadataFieldName']

export const isSimpleFilter = (filter: Filter): filter is SimpleFilter => !('conjunction' in filter)

export type UnfilterablePropertyType = 'reference'
export type FilterablePropertyType = Exclude<PropertyType, UnfilterablePropertyType>
export type FilterableProperty = Property<FilterablePropertyType>

/**
 * Returns whether we can construct a filter based on field values for
 * the given property type.
 */
export const isFilterableProperty = (
  property: Property,
): property is Property<FilterablePropertyType> => {
  const unsearchableTypes: Record<UnfilterablePropertyType, false> = {
    reference: false,
  }
  return !(property.type in unsearchableTypes)
}

/**
 * 'property_not_set' is a FE-only value that we convert to is_none_of <all_options>
 * when sending to the backend.
 */
export type MatcherName = SimpleFilter['matcher']['name'] | 'property_not_set'

export type TextFilterMatcher = TextValueFilter['matcher']['name']

/**
 * Returns true if and only if the given matcher is a valid matcher name for
 * a text filter.
 */
export const isValidTextMatcher = (
  matcher: MatcherName,
): matcher is TextValueFilter['matcher']['name'] => {
  // Use a record so that TS will error if a new matcher is added to the API
  // and not added here.
  const validMatchers: Record<TextFilterMatcher, true> = {
    property_any_of: true,
    property_contains_any_of: true,
    property_contains_none_of: true,
    property_none_of: true,
  }

  return matcher in validMatchers
}

/**
 * Will throw an error if the given matcher is not a valid text filter matcher.
 */
export function assertIsValidTextMatcher(
  matcher: MatcherName,
): asserts matcher is TextFilterMatcher {
  if (!isValidTextMatcher(matcher)) {
    throw new Error('The provided matcher is not a valid text filter matcher')
  }
}

export type SelectFilterMatcher = SelectOptionValueFilter['matcher']['name']

/**
 * Returns true if and only if the given matcher is a valid matcher name for
 * a select filter.
 */
export const isValidSelectMatcher = (matcher: MatcherName): matcher is SelectFilterMatcher => {
  const validMatchers: Record<SelectFilterMatcher, true> = {
    property_all_of: true,
    property_any_of: true,
    property_none_of: true,
  }

  return matcher in validMatchers
}

/**
 * Will throw an error if the given matcher is not a valid select filter matcher.
 */
export function assertIsValidSelectMatcher(
  matcher: MatcherName,
): asserts matcher is SelectFilterMatcher {
  if (!isValidSelectMatcher(matcher)) {
    throw new Error('The provided matcher is not a valid select filter matcher')
  }
}

/**
 * The filters that are configured by a user-entered string, with a
 * a case-sensitive/insensitive option.
 */
export type TextLikeFilter = TextValueFilter | FilenameFilter | FieldNameFilter

/** Returns true if and only if a given filter subject is the subject for a text-like filter */
export const isTextLikeFilterSubject = (
  subject?: SimpleFilter['subject'] | null,
): subject is TextLikeFilter['subject'] => {
  return (
    subject === 'field_text_value' ||
    subject === 'field_file_name' ||
    subject === 'field_metadata_field_name'
  )
}

/** Returns true if and only if a given filter is a text-like filter */
export const isTextLikeFilter = (filter: SimpleFilter): filter is TextLikeFilter =>
  isTextLikeFilterSubject(filter.subject)

/** Returns true if and only if the given property type can be filtered by text-like filters */
export const propertyHasTextLikeFilter = (propertyType: PropertyType) =>
  propertyType !== 'reference' && isTextLikeFilterSubject(getFilterSubjectForProperty(propertyType))

/** All filters that can be applied to a field's value */
export type FieldValueFilter = TextLikeFilter | SelectOptionValueFilter

/** Returns true if and only if the given filter can be directly applied to a field's value */
export const isFieldValueFilter = (filter: Filter): filter is FieldValueFilter => {
  if ('conjunction' in filter) {
    return false
  }

  return isTextLikeFilter(filter) || filter.subject === 'field_select_option_value'
}

/** Maps each filterable property type to its subject when filtering in the UI. */
const propertyFilterSubjectMap: Record<FilterablePropertyType, FieldValueFilter['subject']> = {
  collection: 'field_metadata_field_name',
  file_collection: 'field_file_name', // until we support multiple files
  file: 'field_file_name',
  json: 'field_text_value',
  multi_select: 'field_select_option_value',
  pdf: 'field_file_name',
  single_select: 'field_select_option_value',
  text: 'field_text_value',
  url: 'field_text_value',
  user_select: 'field_select_option_value',
}

/**
 * Gets the filter subject that should be used when filtering a given property type in
 * the UI. Returns null if the property type is not filterable.
 */
export const getFilterSubjectForProperty = (
  propertyType: FilterableProperty['type'],
): FieldValueFilter['subject'] => propertyFilterSubjectMap[propertyType]
