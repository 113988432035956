<script setup lang="ts">
import AppHeader from '@/sharedComponents/AppHeader.vue'
import CrumbItem from '@/sharedComponents/CrumbItem.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'

defineProps<{ workspaceId: string }>()
const { isOpen } = useSidebar()
</script>

<template>
  <AppHeader>
    <template #crumbs>
      <CrumbItem
        class="transition-transform duration-300 ease-in-out-quint"
        :class="isOpen ? 'translate-x-0' : 'translate-x-8'"
        :to="{ name: 'Workspace' }"
        :active="true"
        >Recent projects
      </CrumbItem>
    </template>
  </AppHeader>
</template>
