import { ToolProvider } from '@/backend/types'
import { defaultToolConfig, geminiOutputTypes } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const webSearchTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Web Search',
  description: 'Uses multiple web search sources and GPT-4o to answer queries.',
  outputTypes: geminiOutputTypes,
  provider: ToolProvider.open_ai,
  icon: 'globe',
  isNew: true,
}
