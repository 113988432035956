<script lang="ts">
/**
 * @name Menu
 * @url https://www.figma.com/design/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?node-id=224-10893
 */

type Menu = zagMenu.Api

// Item
export type MenuItemProps = {
  onSelect: () => void
  id: string
}
type InitItem = (props: MenuItemProps) => void

// Root
type RootProps = {
  ariaLabel?: string
  positioning?: zagMenu.Context['positioning']
  disableTeleport?: boolean
  open?: boolean
  teleportTo?: string
  closeOnSelect?: boolean
}

// Context
const CTX_KEY = Symbol('menu-context')
type MenuContext = {
  initItem: InitItem
  menu: ComputedRef<Menu>
  props: RootProps
}

export const MenuCtx = {
  get(): MenuContext {
    const ctx = inject(CTX_KEY) as MenuContext | undefined
    if (!ctx) throw new Error('Menu context not found')
    return ctx
  },
  set(value: MenuContext) {
    provide(CTX_KEY, value)
  },
}
</script>

<script setup lang="ts">
import { computed, inject, onUnmounted, provide, ref, useId, type ComputedRef } from 'vue'

import * as zagMenu from '@zag-js/menu'
import { normalizeProps, useMachine } from '@zag-js/vue'

const props = withDefaults(defineProps<RootProps>(), {
  open: false,
  ariaLabel: undefined,
  positioning: undefined,
  disableTeleport: false,
  teleportTo: 'body',
  closeOnSelect: false,
})

const emit = defineEmits<{
  (e: 'change:open', open: boolean): void
}>()

defineOptions({
  name: 'MenuRoot',
})

const items = ref<MenuItemProps[]>([])
const initItem: InitItem = (props) => {
  items.value.push(props)
  const itemIndex = computed(() => items.value.indexOf(props))

  onUnmounted(() => {
    items.value.splice(itemIndex.value, 1)
  })
}

const [state, send] = useMachine(
  zagMenu.machine({
    id: useId(),
    onOpenChange(e) {
      emit('change:open', e.open)
    },
    async onSelect({ value }) {
      const item = items.value.find((item) => item.id === value)
      item?.onSelect()
    },
    closeOnSelect: props.closeOnSelect,
    onEscapeKeyDown(e) {
      if (!menu.value.open) return
      e.stopPropagation()
    },
  }),
  {
    context: computed(() => {
      return {
        'aria-label': props.ariaLabel,
        positioning: props.positioning,
      }
    }),
  },
)
const menu = computed(() => zagMenu.connect(state.value, send, normalizeProps))

MenuCtx.set({ initItem, menu, props })

function getTriggerProps() {
  const p = menu.value.getTriggerProps()
  return {
    ...p,
    disabled: p.disabled === 'true' || p.disabled === true,
  }
}
</script>

<template>
  <slot
    :get-trigger-props="getTriggerProps"
    :menu="menu"
  />
</template>
