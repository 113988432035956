<script setup lang="ts">
import { ToolProvider } from '@/backend/types'
import { tools } from '@/modules/Project/Tools/tool-registry'
import { TOOL_PROVIDER_LABELS } from '@/modules/WorkspaceSettings/propertyConfig'
import { toast } from '@/shared/toast'
import { omit } from '@/shared/utils'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import * as sentry from '@sentry/vue'
import { computed, ref } from 'vue'
import { useModelProviders } from './modelProvidersStore'
import WorkspaceSettingsAiModelDialog from './WorkspaceSettingsAiModelDialog.vue'
import WorkspaceSettingsAiModelsProviderIcon from './WorkspaceSettingsAiModelsProviderIcon.vue'

const props = defineProps<{
  provider: ToolProvider
  enabled: boolean
  /**
   * If true, then all interactive elements will be disabled and the user cannot disable
   * models by this provider.
   */
  disableEditing: boolean
  /**
   * If true, then the user cannot toggle the provider on/off
   */
  disableToggle?: boolean
  disableCustomKey?: boolean
  tooltipText?: string
}>()

defineEmits<{
  (e: 'toggle'): void
}>()

const name = computed(() => TOOL_PROVIDER_LABELS[props.provider])

const modelNames = computed<string>(() => {
  const names: string[] = []

  for (const tool of Object.values(tools)) {
    if (tool.provider === props.provider && modelProvidersStore.availableTools.includes(tool.id)) {
      names.push(tool.label)
    }
  }

  return names.join(', ')
})

const labelId = computed(() => `workspace-settings-ai-models-${props.provider}`)

const isDialogOpen = ref(false)

const { workspaceId } = useRouteParams()
const modelProvidersStore = useModelProviders()
const onCreateKey = async ({
  secretValue,
  secretProjectId,
}: {
  secretValue: string
  secretProjectId: string
}) => {
  isDialogOpen.value = false
  const success = await modelProvidersStore.addCredentials({
    provider: props.provider,
    secretValue,
    secretProjectId,
    workspaceId: workspaceId.value,
  })

  if (success) {
    toast.success('API Key connected successfully', {
      description: `Usage for all ${name.value} models will now be provided by your own API Key.`,
    })
  } else {
    sentry.captureException('Failed to connect API Key', {
      extra: {
        provider: props.provider,
      },
    })
    modelProvidersStore.providerConfig[props.provider] = null
    toast.error('Failed to connect API Key', {
      description: `An error occurred while trying to connect your API Key. Please try again.`,
    })
  }
}

const isConnected = computed(() => modelProvidersStore.providerConfig[props.provider])

const onSelectDropdownOption = async (id: string) => {
  if (id === 'disconnect') {
    const success = await modelProvidersStore.removeCredentials({
      provider: props.provider,
      workspaceId: workspaceId.value,
    })
    if (success) {
      toast.success('API Key disconnected', {
        description: `Usage for all ${name.value} models will now come out of your Go token allowance.`,
      })
    } else {
      toast.error('Failed to connect API Key', {
        description: `An error occurred while trying to connect your API Key. Please try again.`,
      })
    }
    return
  }

  throw new Error('Unexpected option')
}

const apiKeyPrefix = computed(
  () => modelProvidersStore.providerConfig[props.provider]?.apiKeyPrefix,
)
</script>

<template>
  <div
    class="flex items-center gap-4 py-6"
    :data-test="`provider-${provider}`"
  >
    <ToolTip
      :body="
        tooltipText ||
        'Switching off a model provider will hide every associated model in the list of available AI Tools.'
      "
      :placement="{ allowedPlacements: ['top-start'] }"
      arrow
      :disabled="disableEditing"
    >
      <SwitchButton
        :checked="enabled"
        :aria-labelledby="labelId"
        :disabled="disableEditing || disableToggle"
        @change="$emit('toggle')"
      />
    </ToolTip>
    <WorkspaceSettingsAiModelsProviderIcon
      class="w-8 min-w-8"
      :provider="provider"
    />
    <div class="grow">
      <div
        :id="labelId"
        class="text-md-13px-default text-text"
      >
        <slot>
          {{ name }}
        </slot>
      </div>
      <div class="text-sm-12px-light text-text-subtle">{{ modelNames }}</div>
    </div>
    <div
      v-if="!disableCustomKey"
      class="w-[180px] min-w-[180px]"
    >
      <FloatingMenu
        v-if="isConnected"
        :positioning="{ placement: 'bottom-end', offset: { mainAxis: 4 } }"
        @select="onSelectDropdownOption"
      >
        <template #trigger="{ triggerProps }">
          <DarwinButton
            size="sm"
            variant="neutral"
            v-bind="triggerProps"
            :disabled="disableEditing"
          >
            <template #leading-icon>
              <IconSprite
                icon="check-circle-fill"
                class="text-icon-success"
              />
            </template>
            API Key Connected
            <template #trailing-icon>
              <IconSprite
                icon="chevron-bottom"
                class="text-icon-subtle"
              />
            </template>
          </DarwinButton>
        </template>
        <template #content="{ contentProps, getItemProps }">
          <ListMenuContainer
            v-bind="contentProps"
            class="min-w-60"
          >
            <template v-if="apiKeyPrefix && provider !== ToolProvider.google_ai">
              <div
                class="m-0.5 flex min-h-7 cursor-default items-center justify-between self-stretch pl-3 pr-1"
                data-test="api-key-prefix"
              >
                <div class="grow text-text-subtlest">API Key prefix</div>
                <div
                  class="flex h-5 items-center rounded-corner-4 border-b border-border-subtle bg-background-gray-subtlest px-1 font-mono text-code-sm-default text-icon-subtle"
                >
                  {{ apiKeyPrefix }}
                </div>
              </div>
              <DividerLine
                direction="horizontal"
                color="subtle"
              />
            </template>
            <div class="w-full p-0.5">
              <ListMenuItem
                class="px-[9px]"
                label="Disconnect API Key"
                icon="unlink"
                v-bind="omit(getItemProps({ value: 'disconnect' }), ['onSelect'])"
                critical
              />
            </div>
          </ListMenuContainer>
        </template>
      </FloatingMenu>
      <DarwinButton
        v-else
        variant="outline"
        :disabled="disableEditing"
        size="sm"
        class="w-full"
        @click="isDialogOpen = true"
      >
        <template #leading-icon
          ><IconSprite
            :class="disableEditing ? 'text-icon-disabled' : 'text-icon-subtlest'"
            icon="plus"
        /></template>
        {{ isConnected ? 'Connected' : 'Connect my API Key' }}</DarwinButton
      >
    </div>
  </div>
  <WorkspaceSettingsAiModelDialog
    :provider="provider"
    :open="isDialogOpen"
    @close="isDialogOpen = false"
    @create="onCreateKey"
  />
</template>
