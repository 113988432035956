<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { updateView } from '@/backend/updateView'
import { omit, toggleArray } from '@/shared/utils'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'

import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import SegmentedControl from '@/uiKit/SegmentedControl.vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { TYPE_ICON } from './icons'
import { useProject } from './useProject'

const projectStore = useProject()
const workspacesStore = useWorkspaces()

const isOpen = ref(false)

const { captureAnalyticsEvent } = useAnalytics()
onMounted(() => {
  captureAnalyticsEvent(ANALYTICS_EVENT.OPEN_PROPERTY_VISIBILITY_MENU)
})

const toggleVisibility = async (propertyItem: { id: string; label: string }) => {
  const property = projectStore.properties.find((p) => p.id === propertyItem.id)
  if (
    property &&
    projectStore.activeView &&
    workspacesStore.currentWorkspace &&
    projectStore.projectId
  ) {
    const propertyIds = toggleArray(
      projectStore.activeView.view.propertyIds ?? [],
      property.id,
      projectStore.properties.map((p) => p.id),
    )

    projectStore.upsertView({
      id: projectStore.activeView.id,
      name: projectStore.activeView.view.name,
      propertyIds,
      propertyLayouts: projectStore.activeView.view.propertyLayouts,
      filters: projectStore.activeView.view.filters,
      assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
      propertyOptions: projectStore.activeView.view.propertyOptions,
      numPinnedProperties: projectStore.activeView.view.numPinnedProperties,
    })

    await updateView({
      workspaceId: workspacesStore.currentWorkspace.id,
      projectId: projectStore.projectId,
      viewId: projectStore.activeView.id,
      name: projectStore.activeView.view.name,
      propertyIds,
      propertyLayouts: projectStore.activeView.view.propertyLayouts,
      filters: projectStore.activeView.view.filters,
      propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
      assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
    })
  }
}

const items = computed(() =>
  projectStore.properties.map((property) => ({
    id: property.id,
    data: {
      id: property.id,
      label: property.name,
      type: property.type,
      visibility: projectStore.activeView?.view.propertyIds?.includes(property.id) ?? false,
      readonly:
        projectStore.activeView?.view.propertyOptions?.find(
          (propertyOption) => propertyOption.property_id === property.id,
        )?.block_workers_edits ?? false,
    },
  })),
)

const setReadWriteOption = async (propertyItem: { id: string }, readonly: boolean) => {
  const property = projectStore.properties.find((p) => p.id === propertyItem.id)
  if (
    !property ||
    !projectStore.activeView ||
    !workspacesStore.currentWorkspace ||
    !projectStore.projectId
  ) {
    return
  }

  const propertyOptions = (projectStore.activeView.view.propertyOptions || []).slice() // Copy the array
  const optionIndex = propertyOptions.findIndex((option) => option.property_id === property.id)
  if (optionIndex !== -1) {
    propertyOptions[optionIndex] = {
      ...propertyOptions[optionIndex],
      block_workers_edits: readonly,
    }
  } else {
    // Add the new property option
    propertyOptions.push({
      property_id: property.id,
      block_workers_edits: readonly,
    })
  }
  projectStore.upsertView({
    id: projectStore.activeView.id,
    name: projectStore.activeView.view.name,
    propertyIds: projectStore.activeView.view.propertyIds,
    propertyLayouts: projectStore.activeView.view.propertyLayouts,
    filters: projectStore.activeView.view.filters,
    assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
    propertyOptions,
    numPinnedProperties: projectStore.activeView.view.numPinnedProperties,
  })
  await updateView({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    viewId: projectStore.activeView.id,
    name: projectStore.activeView.view.name,
    propertyIds: projectStore.activeView.view.propertyIds || [],
    propertyLayouts: projectStore.activeView.view.propertyLayouts,
    filters: projectStore.activeView.view.filters,
    propertyOptions,
    assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
  })
}

const canAssignWork = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
</script>

<template>
  <FloatingMenu
    :positioning="{ offset: { mainAxis: 0 } }"
    @change:open="isOpen = $event"
  >
    <template #trigger="{ triggerProps }">
      <button
        id="project-configuration"
        class="flex w-full select-none items-center border-b border-l border-border-subtle p-2.5 text-icon-subtle transition-colors hover:bg-background-transparent-hovered focus-visible:outline-none active:bg-background-transparent-pressed"
        :class="isOpen ? 'bg-background-transparent-hovered' : 'bg-background-transparent'"
        v-bind="omit(triggerProps, ['disabled'])"
        aria-label="Configure project"
        data-test="project-configuration"
      >
        <IconSprite
          icon="more-dots"
          size="sm"
        />
      </button>
    </template>
    <template #content="{ contentProps, getOptionItemProps }">
      <ListMenu
        v-bind="omit(contentProps, ['onInput'])"
        class="max-h-[500px]"
        :class="canAssignWork && 'w-[320px]'"
        :items="items"
        :group-by-predicate="(item) => (item.data.visibility ? 'Visible' : 'Hidden')"
        :group-order="['Visible', 'Hidden']"
        :has-group-titles="true"
        search-by-field="label"
        @select="toggleVisibility"
      >
        <template #item="{ key, item, active, setActiveItem, focus }">
          <ListMenuCheckboxItem
            :label="item.data.label"
            :active="active"
            :aria-selected="active"
            :checked="projectStore.activeView?.view.propertyIds?.includes(item.data.id) ?? false"
            :icon="TYPE_ICON[item.data.type]"
            :always-visible="true"
            v-bind="
              omit(
                getOptionItemProps({
                  value: item.data.id,
                  type: 'checkbox',
                  checked:
                    projectStore.activeView?.view.propertyIds?.includes(item.data.id) ?? false,
                }),
                ['onSelect'],
              )
            "
            default-hover-disabled
            @select="(toggleVisibility(item.data), focus())"
            @mousemove="setActiveItem(key)"
          >
            <template #suffix>
              <SegmentedControl
                v-if="canAssignWork"
                :items="[
                  { label: 'Read', value: 'read' },
                  { label: 'Write', value: 'read-write' },
                ]"
                :value="item.data.readonly ? 'read' : 'read-write'"
                :name="`readonly-${item.data.id}`"
                size="sm"
                @change="(event) => setReadWriteOption(item.data, event === 'read')"
                @click.stop
              />
            </template>
          </ListMenuCheckboxItem>
        </template>
      </ListMenu>
    </template>
  </FloatingMenu>
</template>
