import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const gpt4Tool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'GPT 4 Turbo',
  description: "GPT4 Omni's predecessor, with a 128k context window.",
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  provider: ToolProvider.open_ai,
  icon: 'company-openai',
}
