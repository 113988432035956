import { Plugin } from 'prosemirror-state'
import type { EditorView } from 'prosemirror-view'

export function placeholderPlugin(text: string) {
  const update = (view: EditorView) => {
    const childrenCount = view.state.doc.firstChild?.content.size || 0
    if (view.state.doc.textContent || childrenCount > 0) {
      view.dom.removeAttribute('data-placeholder')
    } else {
      view.dom.setAttribute('data-placeholder', text)
    }
  }

  return new Plugin({
    view(view) {
      update(view)

      return { update }
    },
  })
}
