<script setup lang="ts">
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import PopOver from '@/uiKit/PopOver.vue'
import { onKeyStroke } from '@vueuse/core'
import { computed } from 'vue'
import { usePreviewChanges } from './usePreviewChanges'
import { useProject } from './useProject'
import { useTable } from './useTable'
/**
 * Renders a Popover that allows the user to save or cancel changes made to
 * the preview range of cells.
 */

const { resetPreview, savePreviewChanges } = usePreviewChanges()

const projectStore = useProject()
const tableStore = useTable()
const pctComplete = computed<number>(() => {
  if (!tableStore.previewRange || !projectStore.activeView?.entities) {
    return 100
  }

  const totalEntities =
    tableStore.previewRange.end.rowIndex - tableStore.previewRange.start.rowIndex + 1

  const completedEntities =
    projectStore.activeView.entities.length - tableStore.previewRange.start.rowIndex

  return Math.round((completedEntities / totalEntities) * 100)
})

onKeyStroke('Escape', (e) => {
  e.stopPropagation()
  resetPreview()
})

onKeyStroke('Enter', (e) => {
  e.stopPropagation()
  savePreviewChanges()
})

const dialogLabelId = 'preview-changes-dialog-label'
</script>

<template>
  <PopOver
    :open="true"
    :placement="{ allowedPlacements: ['top-start'] }"
    :offset="{ mainAxis: 24, alignmentAxis: -2 }"
    role="dialog"
    :aria-labelledby="dialogLabelId"
  >
    <template #content>
      <div
        class="flex h-9 items-center rounded-xl bg-surface-tertiary"
        data-theme="dark"
      >
        <div
          :id="dialogLabelId"
          class="pl-4 pr-3 text-sm-12px-default text-text"
        >
          <div
            v-if="pctComplete < 100"
            class="flex items-center gap-2 tabular-nums"
          >
            <div>Pasting {{ pctComplete }}% complete</div>
            <CircularProgress size="sm" />
          </div>
          <template v-else> Previewing paste </template>
        </div>

        <div
          v-if="pctComplete >= 100"
          class="flex h-full items-center gap-1 rounded-xl bg-surface-primary px-1"
        >
          <DarwinButton
            variant="transparent"
            size="sm"
            @click="savePreviewChanges"
            >Save</DarwinButton
          >
          <DarwinButton
            variant="critical-transparent"
            size="sm"
            @click="resetPreview"
            >Cancel</DarwinButton
          >
        </div>
      </div>
    </template>
  </PopOver>
</template>
