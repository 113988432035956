import { useEntity } from '@/modules/Project/useEntity'
import { useProject } from '@/modules/Project/useProject'
import { computed } from 'vue'
import { useRoute, type LocationQueryValue } from 'vue-router'

export type UrlTraceItem = {
  projectId: string
  viewId: string
  entityId: string
  propertyId: string
}

/**
 * Trace is a query parameter string which contains information about how to get to the current project screen.
 * @example `root-proj-id:main-id:entity-id:prop-id|collection-proj-id:alt-view-id:entity-id:different-prop-id`
 */
type Trace = string
/**
 * This composable is used to get the current trace of the route.
 * Trace means URL query parameter `trace` which contains information about how to get to the current project screen.
 */
export function useRouteTrace() {
  const route = useRoute()
  const entityStore = useEntity()
  const projectStore = useProject()

  const currentProjectId = computed(() => projectStore.projectId)
  const currentViewId = computed(() => projectStore.activeView?.id)
  const currentEntityId = computed(() => entityStore.entityId)

  /**
   * Current trace of the route.
   */
  const currentTrace = computed<UrlTraceItem[]>(() => {
    return route.query.trace ? deserializeTrace(route.query.trace) : []
  })

  /**
   * Create a deeper trace by adding a new trace item to the current trace.
   */
  function getDeeperTrace(params: { entityId: string; propertyId?: string }): Trace {
    const projectId = currentProjectId.value ?? ''
    const viewId = currentViewId.value ?? ''
    const previousTrace = currentTrace.value.length > 0 ? currentTrace.value : []

    const newTraceItem = {
      projectId,
      viewId,
      entityId: params.entityId || currentEntityId.value || '',
      propertyId: params.propertyId || '',
    }

    return serializeTrace(...previousTrace, newTraceItem)
  }

  /**
   * Get serialized trace up to a (but not including) given index.
   */
  function getSerializedTrace(upToIndex = Infinity): Trace {
    const slice = currentTrace.value.slice(0, upToIndex)
    return serializeTrace(...slice)
  }

  return { getDeeperTrace, currentTrace, getSerializedTrace }
}

/** Delimiter to be used inside a single trace item */
const internalDelimiter = ':'
/** Delimiter to be used between trace items */
const externalDelimiter = '|'

/**
 * Serialize {@link UrlTraceItem} to a string which can be used in URL query parameter `trace`.
 */
function serializeTrace(...trace: UrlTraceItem[]): Trace {
  const parts = trace.map((item) => {
    return [item.projectId, item.viewId, item.entityId, item.propertyId].join(internalDelimiter)
  })
  if (parts.length > 1 && parts.at(-1) === parts.at(-2)) {
    parts.pop()
  }
  return parts.join(externalDelimiter)
}

/**
 * Parse string from URL query parameter `trace` to an array of {@link UrlTraceItem}.
 */
function deserializeTrace(
  trace: Trace | LocationQueryValue | LocationQueryValue[],
): UrlTraceItem[] {
  trace = trace ? String(trace) : ''
  return trace.split(externalDelimiter).map((item) => {
    const [projectId = '', viewId = '', entityId = '', propertyId = ''] =
      item.split(internalDelimiter)
    return { projectId, viewId, entityId, propertyId }
  })
}
