import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const bingSearchTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Web Search',
  description: 'Web Search retrives information from the web to empower your Go projects.',
  outputTypes: [PropertyType.json],
  provider: ToolProvider.bing_search,
  icon: 'bing-logo',
  usesPrompt: false,
}
