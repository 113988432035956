<script setup lang="ts">
import { computed, nextTick, ref, toRef, watch } from 'vue'

import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { RouterLink } from 'vue-router'
import { useResolveProjectRoute } from './useResolveProjectRoute'
import { useScrollOnSelected } from './useScrollOnSelected'
import { useTableCellFocus } from './useTableCellFocus'

const props = defineProps<{
  field: Field<'collection'>
  property: Property<'collection'>
  workspaceId: string
  projectId: string
  isFocused: boolean
  isSelected: boolean
  viewId?: string
  /**
   * This should be set to true when the child project has data that is being loaded. When true,
   * the cell will display a loading spinner instead of the collection icon.
   */
  loading?: boolean
}>()

const emit = defineEmits<{
  (e: 'saveName', value: string): void
}>()

const itemCount = computed(() => String(props.field.subprojectPreview?.entityCount ?? 0))
const collectionProjectId = computed(() => props.property.config.subprojectConfig.child_project_id)

const target = ref<HTMLElement>()

/**
 * The value of the contenteditable when the cell enters edit mode. This will
 * either be:
 * - itemCount.value if the user has pressed enter
 * - an empty string if the user has pressed backspaces, delete, or a printable character
 * This needs to be set whenever the cell enters edit mode, otherwise the contenteditable
 * 'remembers' its previous value, which causes bugs when escaping.
 */
const startValue = ref(String(props.field.subprojectPreview?.entityCount ?? 0))
const startName = ref(props.field.manualName || '')

/**
 * The current value of the text inside of the contenteditable. Is used:
 * 1. To emit an updated value with the save event
 * 2. For optimistic UI when submitting changes
 */
const localValue = ref('')
const localName = ref('')

// A stateless approach to the editable, by directly binding to the prop.value and emiting change,
// does not work, because we lose focus on every edit and re-update.
// So we have to store a local value.

const collectionNameRef = ref<HTMLInputElement | null>(null)
const isEditMode = ref(false)

const onRenameSave = () => {
  if (localName.value !== startName.value) {
    emit('saveName', localName.value)
    startName.value = localName.value
  }
  isEditMode.value = false
}

watch(
  () => itemCount.value,
  () => {
    localValue.value = itemCount.value
    startValue.value = itemCount.value
  },
  { immediate: true },
)

watch(
  () => props.field.manualName,
  () => {
    localName.value = props.field.manualName ?? ''
    startName.value = props.field.manualName ?? ''
  },
  { immediate: true },
)

useTableCellFocus({
  cell: target,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})
useScrollOnSelected(target, toRef(props, 'isSelected'))

const resolveCollectionRoute = useResolveProjectRoute()
const collectionRoute = computed(() =>
  resolveCollectionRoute({
    parentEntityId: props.field.entityId,
    projectId: collectionProjectId.value,
    parentProjectId: props.projectId,
    workspaceId: props.workspaceId,
    parentViewId: props.viewId,
    parentPropertyId: props.property.id,
  }),
)

const displayValue = computed(() => {
  if (startName.value) return startName.value
  if (!localValue.value || localValue.value === '0') return 'Empty collection'
  if (localValue.value === '1') return `${localValue.value} collection item`
  return `${localValue.value} collection items`
})

const onEdit = () => {
  isEditMode.value = true
  localName.value = startName.value
  nextTick(() => {
    if (collectionNameRef.value) {
      collectionNameRef.value.focus() // Set focus to input
    }
  })
}

const onChangeName = (event: Event) => {
  if (!(event.target instanceof HTMLInputElement)) return
  localName.value = event.target.value
}

const onCancelRenaming = () => {
  isEditMode.value = false
  localName.value = startName.value
}
</script>

<template>
  <div
    ref="target"
    class="relative size-full outline-none"
    :aria-busy="loading && 'true'"
  >
    <div
      data-test="collection-cell-content"
      data-table-cell-content
      class="line-clamp-1 flex h-8 basis-0 items-center truncate rounded-corner-4 px-1 py-2 focus-within:box-border"
      :class="{
        'bg-surface-primary shadow-md': isEditMode,
        'cursor-text': !isEditMode,
        'pl-0.5': loading,
      }"
      @click="onEdit"
    >
      <input
        v-if="isEditMode"
        ref="collectionNameRef"
        class="w-full bg-background-transparent px-2 outline-none"
        placeholder="Enter custom collection name"
        :value="localName"
        @focusout="onRenameSave"
        @input="onChangeName"
        @change="onRenameSave"
        @blur="onRenameSave"
        @keydown.escape.stop="onCancelRenaming"
      />
      <RouterLink
        v-else
        title="Open collection"
        :to="collectionRoute"
        aria-label="Open collection"
        @click.stop
      >
        <div
          v-if="localValue || property.tool === 'manual'"
          class="flex h-5 items-center rounded-corner-6 px-1 hover:bg-background-blue-subtle"
          :class="loading ? 'gap-0.5' : 'gap-1'"
        >
          <CircularProgress
            v-if="loading"
            size="xs"
          />
          <IconSprite
            v-else
            class="text-icon-primary"
            icon="collection-fill"
            size="xs"
          />
          <p
            class="border border-background-transparent border-b-icon-gray-subtle text-sm-12px-default text-icon-primary hover:border-b-background-transparent"
          >
            {{ displayValue }}
          </p>
        </div>
      </RouterLink>
    </div>
  </div>
</template>
