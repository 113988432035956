import { getEntity } from '@/backend/getEntity'
import type { Field, FileField, JsonField, TextField } from '@/modules/Project/Fields/types'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useEntity } from './useEntity'
import { useGroundingStore } from './useGroundingStore'
import { usePrevOrNextEntity } from './usePrevOrNextEntity'
import { serializeEntity, useProject, type Entity } from './useProject'

export function useOpenGrounding() {
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const entityStore = useEntity()
  const { loadAndSetEntity } = usePrevOrNextEntity()

  const groundingStore = useGroundingStore()

  function parseGroundingField(field?: Field) {
    if (!field) return undefined
    if (field.type !== 'text' && field.type !== 'json') {
      throw new Error('Invalid grounding field type')
    }
    if (field.grounding) return field
  }

  type Params = {
    propertyId: string
    entityId?: string
  }
  async function getGroundingFieldAndEntity({
    propertyId,
    entityId,
  }: Params): Promise<{ field: TextField | JsonField; entity: Entity } | null> {
    assertIsNotNullOrUndefined(
      workspacesStore.currentWorkspace,
      'No current workspace found when opening grounding modal',
    )
    assertIsNotNullOrUndefined(
      projectStore.projectId,
      'No project ID found when opening grounding modal',
    )

    if (!propertyId || !entityId) return null
    if (entityId in entityStore.entities) {
      const entity = entityStore.entities.find((e) => e.id === entityId)?.entity

      const field = entity?.fields.get(propertyId)
      const parsed = parseGroundingField(field)
      if (parsed !== undefined && entity !== undefined) return { field: parsed, entity }
    }

    const res = await getEntity(
      workspacesStore.currentWorkspace.id,
      projectStore.projectId,
      entityId,
    )
    if (!res.ok) return null

    const entity = serializeEntity(res.data)
    const field = entity.fields.get(propertyId)
    const parsed = parseGroundingField(field)
    if (parsed !== undefined && entity !== undefined) return { field: parsed, entity }
    return null
  }

  async function openGrounding({ propertyId, entityId }: Params) {
    assertIsNotNullOrUndefined(
      workspacesStore.currentWorkspace,
      'No current workspace found when opening grounding modal',
    )
    assertIsNotNullOrUndefined(
      projectStore.projectId,
      'No project ID found when opening grounding modal',
    )

    const property = projectStore.properties.find((p) => p.id === propertyId)
    if (!property) return
    const res = await getGroundingFieldAndEntity({ propertyId, entityId })
    if (!res) return
    const { field, entity } = res

    const inputPropertyIds = property.inputs.map((input) => input.propertyId)
    const inputFields = Array.from(entity.fields.values()).reduce<FileField[]>((acc, curr) => {
      if (curr.type !== 'file') {
        return acc
      }

      if (inputPropertyIds.some((id) => id === curr.propertyId)) {
        return [...acc, curr]
      }

      return acc
    }, [])

    groundingStore.inputFields = inputFields
    groundingStore.field = field
    groundingStore.activeInputId = inputFields[0].propertyId
    loadAndSetEntity(entity.id)
  }

  return { openGrounding }
}
