import { post } from './client'
import type { BackendMeta } from './types'

export type AddSpace = BackendMeta<'/api/workspaces/{workspace_id}/spaces', 'post'>

export type AddSpaceParams = {
  workspaceId: string
  query: AddSpace['body']['query']
  data: AddSpace['body']['data']
}

export const addSpace = ({ workspaceId, data, query }: AddSpaceParams) =>
  post<AddSpace['body'], AddSpace['successResponse'], AddSpace['path']>(
    `/api/workspaces/${workspaceId}/spaces`,
    {
      data,
      query,
    },
  )
