import { computed, onBeforeUnmount, watch, type Ref } from 'vue'

import { type SpaceResponse } from '@/backend/types'

import { objectMapBy } from '@/shared/utils'
import { useWebSocketChannel } from '@/sharedComposables/useWebSocketChannel'
import { SPACE_NAME_FALLBACK } from './constants'
import { serializeMessage } from './serializers'
import type { ProgressMessage, SpaceMessage } from './types'
import { useSpaceStore } from './useSpaceStore'

/**
 * Subscribes to a space's websocket channel, to receive updated messages as
 * they are sent.
 */
export const useSpaceChannel = (spaceId: Ref<string>) => {
  const spaceStore = useSpaceStore()

  const topic = computed<`spaces:${string}`>(() => `spaces:${spaceId.value}`)

  const { channel, channelState, leaveChannel } = useWebSocketChannel(topic)

  onBeforeUnmount(async () => {
    await leaveChannel()
  })

  watch(
    channel,
    () => {
      channel.value?.on(
        'spaces:messages_updated',
        (data: {
          space_id: string
          space_name: string
          status: SpaceResponse['status']
          timestamp: string
          messages: SpaceResponse['messages']
        }) => {
          spaceStore.updateSpace(data.space_id, {
            status: data.status,
            name: data.space_name || SPACE_NAME_FALLBACK,
            updatedAt: data.timestamp,
          })

          // The messages are received in a flat structure, we should put them into a hierarchical
          // structure in the store
          const trees = getMessageTrees(data.messages.map(serializeMessage))

          spaceStore.putMessages(data.space_id, trees)
        },
      )
    },
    { immediate: true },
  )

  return {
    channel,
    channelState,
    leaveChannel,
  }
}

/**
 * Takes a flat array of messages (where no messages have children), and nests children under their
 * respective parents.
 */
export function getMessageTrees(flatMessages: SpaceMessage[]): SpaceMessage[] {
  const msgRecord = objectMapBy(flatMessages, 'id')

  for (const msg of flatMessages) {
    if (msg.type === 'progress' && msg.parentMessageId) {
      const parent = msgRecord[msg.parentMessageId] as ProgressMessage
      parent.messages.push(msg)
    }
  }

  return flatMessages.filter((m) => m.type !== 'progress' || !m.parentMessageId)
}
