import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const claude3OpusTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Claude 3 Opus',
  description: "Opus is the most powerful version of Anthropic's Claude 3.",
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  provider: ToolProvider.anthropic,
  icon: 'company-anthropic',
}
