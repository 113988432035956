<script setup lang="ts">
import { confirmFieldFileUpload } from '@/backend/confirmFieldFileUpload'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import { useResizeObserver, useScroll, useTextareaAutosize } from '@vueuse/core'
import { nextTick, onMounted, ref, toRef, watch } from 'vue'

import { createSession } from '@/backend/createSession'
import { sendSessionMessage } from '@/backend/sendSessionMessage'
import { startAskGoFileUpload } from '@/backend/startAskGoFileUpload'
import { uploadFile } from '@/backend/uploadFile'
import PdfPlaceholder from '@/illustrations/library-pdf.svg'
import { serializeSession, useAskGo } from '@/modules/Project/useAskGo'
import { useAskGoChannel } from '@/modules/Project/useAskGoChannel'
import { toast } from '@/shared/toast'
import { omit } from '@/shared/utils'
import ChatLoadingSpinner from '@/sharedComponents/ChatLoadingSpinner.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import FileUpload from '@/uiKit/FileUpload.vue'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import IconButton from '@/uiKit/IconButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import { useSupportedMimeTypes } from '../Project/useSupportedMimeTypes'
import AskGoMessage from './AskGoMessage.vue'

const props = defineProps<{
  projectId: string
  sessionId: string
  workspaceId: string
}>()

const askGo = useAskGo()
useAskGoChannel(toRef(props, 'sessionId'))

const conversationContainerRef = ref<HTMLDivElement>()
const { textarea, input: intent } = useTextareaAutosize()
const isFocused = ref(false)

const containerScrollState = useScroll(conversationContainerRef, { behavior: 'smooth' })

const fileUploadContainerRef = ref<HTMLDivElement>()
const { arrivedState } = useScroll(fileUploadContainerRef)

useResizeObserver(conversationContainerRef, (entries) => {
  const entry = entries[0]
  containerScrollState.y.value = entry.target.scrollHeight
})

onMounted(() => {
  containerScrollState.y.value = 9999999
  containerScrollState.arrivedState.bottom = true
})

watch(
  () => askGo.isOpen,
  (isOpen: boolean) => {
    if (isOpen) {
      nextTick(() => {
        textarea.value?.focus()
        intent.value = ''

        containerScrollState.y.value = 9999999
      })
    } else {
      textarea.value?.blur()
    }
  },
)

watch(
  () => askGo.currentSession?.messages,
  () => {
    nextTick(() => {
      containerScrollState.y.value = 9999999
    })
  },
)

const { captureAnalyticsEvent } = useAnalytics()

const onAskGo = async () => {
  if (!askGo.currentSession) return

  if (!intent.value && !dialogFiles.value.length) {
    return
  }

  if (textarea.value) {
    textarea.value.innerText = ''
  }

  let ask = intent.value ?? `${uploadedFiles.value.length} files attached`

  const response = await sendSessionMessage({
    sessionId: askGo.currentSession.id,
    workspaceId: props.workspaceId,
    ask,
    uploadEntityIds: entityIds.value,
  })

  if (!response.ok) {
    toast.error(response.error.message)
  }

  captureAnalyticsEvent(ANALYTICS_EVENT.ASK_GO_MESSAGE_SENT, {
    workspaceId: props.workspaceId,
    projectId: props.projectId,
    sessionId: props.sessionId,
    message: ask,
  })

  intent.value = ''
  fileUpload.value?.clearFiles()

  filesToUploadQueue.value = []
  uploadedFiles.value = []
  entityIds.value = []
  fileUploadProgressMapping.value = {}
  fileUploadErrorMapping.value = {}
  willStartUpload.value = false
}

askGo.askEvent.on((input) => {
  intent.value = input
  onAskGo()
})

const createAskGoSession = async () => {
  const response = await createSession({
    projectId: props.projectId,
    workspaceId: props.workspaceId,
  })

  if (response.ok) {
    askGo.updateSession(serializeSession(response.data))
  }
}

const suggestions = [
  'Analyze 10K forms',
  'Set up an RLHF workflow',
  'Ground the answer to a question in a document',
  'Classify language of audio transcriptions',
  'Analyze web listings',
  'Extract data from invoices',
]

const onSuggestionClick = async (suggestion: string) => {
  if (!askGo.currentSession) return

  if (textarea.value) {
    textarea.value.innerText = ''
  }

  const response = await sendSessionMessage({
    sessionId: askGo.currentSession.id,
    workspaceId: props.workspaceId,
    ask: suggestion,
    uploadEntityIds: [],
  })

  if (!response.ok) {
    toast.error(response.error.message)
  }

  intent.value = ''
}

const supportedMimeTypes = useSupportedMimeTypes()

const dialogFiles = ref<File[]>([])
const fileUpload = ref<InstanceType<typeof FileUpload>>()
const uploadedFiles = ref<File[]>([])
const filesToUploadQueue = ref<File[]>([])
const willStartUpload = ref(false)
const entityIds = ref<string[]>([])
const fileUploadProgressMapping = ref<Record<string, number>>({})
const fileUploadErrorMapping = ref<Record<string, string>>({})

watch(
  () => dialogFiles.value,
  (files) => {
    // Add files to a queue to be uploaded if the aren't there already or they have been previously uploaded
    for (const file of files) {
      if (
        !uploadedFiles.value.some((f) => f.name === file.name) &&
        !filesToUploadQueue.value.some((f) => f.name === file.name)
      ) {
        filesToUploadQueue.value.push(file)
      }
    }

    if (filesToUploadQueue.value.length > 0) {
      willStartUpload.value = true
    }
  },
)

watch(
  () => willStartUpload.value,
  async () => {
    for (const file of filesToUploadQueue.value) {
      // Continue if the file is already in error state
      if (fileUploadErrorMapping.value[file.name]) {
        continue
      }

      fileUploadProgressMapping.value[file.name] = 0
      const result = await startAskGoFileUpload(props.workspaceId, props.sessionId, file.name)
      if (!result.ok) {
        // filesToUploadQueue.value = filesToUploadQueue.value.filter((f) => f.name !== file.name)
        toast.error(`Failed to upload file ${file.name}`)
        fileUploadErrorMapping.value[file.name] = 'Failed to upload file'
        continue
      }

      const uploadResult = await uploadFile(result.data.file_upload_url, file, (p) => {
        fileUploadProgressMapping.value[file.name] = p
      })
      if (!uploadResult.ok) {
        // filesToUploadQueue.value = filesToUploadQueue.value.filter((f) => f.name !== file.name)
        toast.error('Failed to upload file to S3')
        fileUploadErrorMapping.value[file.name] = 'Failed to upload file'
        continue
      }

      const confirmResult = await confirmFieldFileUpload(result.data.confirm_upload_url)
      if (!confirmResult.ok) {
        // filesToUploadQueue.value = filesToUploadQueue.value.filter((f) => f.name !== file.name)
        toast.error('Failed to confirm upload of file')
        fileUploadErrorMapping.value[file.name] = 'Failed to upload file'
        continue
      }

      uploadedFiles.value.push(file)
      filesToUploadQueue.value = filesToUploadQueue.value.filter((f) => f.name !== file.name)
      entityIds.value.push(confirmResult.data.entity_id)
      fileUploadErrorMapping.value[file.name] = ''
    }
    willStartUpload.value = false
  },
)

const scrollToBottom = () => {
  containerScrollState.y.value = 9999999
  containerScrollState.arrivedState.bottom = true
}

const menu = ref<InstanceType<typeof FloatingMenu>>()

const IDS = {
  RESET: 'reset',
}
function onSelect(id: string) {
  switch (id) {
    case IDS.RESET: {
      createAskGoSession()
      menu.value?.menu.setOpen(false)
      break
    }
    default: {
      break
    }
  }
}
</script>

<template>
  <div
    class="flex h-full flex-col overflow-hidden border-l border-border-subtle bg-surface-tertiary transition-all duration-300 ease-in-out-quint"
    :class="askGo.isOpen ? 'w-[360px]' : 'w-[0px]'"
    data-test="ask-go-sidebar"
  >
    <div
      class="flex h-full w-[360px] flex-col overflow-hidden transition duration-300 ease-in-out-quint"
      :class="askGo.isOpen ? 'scale-100 opacity-100' : 'scale-90 opacity-0'"
    >
      <!-- Header -->
      <FloatingMenu
        ref="menu"
        :positioning="{ placement: 'bottom' }"
        @select="onSelect"
      >
        <template #trigger="{ triggerProps }">
          <DarwinButton
            size="xs"
            variant="transparent"
            v-bind="{ ...omit(triggerProps, ['disabled']), ...$attrs }"
            class="mx-auto mt-2 w-min"
            @click="(e) => e.preventDefault()"
          >
            Ask Go
            <template #trailing-icon>
              <IconSprite
                icon="more-dots"
                size="xs"
              />
            </template>
          </DarwinButton>
        </template>
        <template #content="{ contentProps, getItemProps }">
          <ListMenuContainer
            v-bind="contentProps"
            class="min-w-56"
          >
            <div class="flex w-full flex-col p-0.5">
              <ListMenuItem
                element="button"
                icon="refresh"
                v-bind="omit(getItemProps({ value: IDS.RESET }), ['onSelect'])"
              >
                Reset conversation
              </ListMenuItem>
            </div>
          </ListMenuContainer>
        </template>
      </FloatingMenu>

      <!-- Conversation -->
      <FileUpload
        ref="fileUpload"
        v-slot="{
          rootProps,
          triggerProps,
          dropzoneProps,
          hiddenInputProps,
          isDragging,
          removeFile,
        }"
        :accept="supportedMimeTypes.join(', ')"
        :max-files="100"
        @change="(files) => (dialogFiles = files)"
      >
        <div
          class="flex h-[calc(100%-48px)] flex-col"
          v-bind="{ ...rootProps, ...$attrs, ...omit(dropzoneProps, ['onClick', 'onKeydown']) }"
        >
          <!-- Top gradient -->
          <div class="relative">
            <div
              v-if="!containerScrollState.arrivedState.top"
              class="absolute top-0 h-12 w-full bg-gradient-to-b from-surface-tertiary to-background-transparent transition duration-300 ease-in-out"
              :class="!containerScrollState.arrivedState.top ? 'opacity-100' : 'opacity-0'"
            />
          </div>

          <!-- Messages -->
          <div
            v-if="(askGo.currentSession?.messages || []).length > 0"
            ref="conversationContainerRef"
            class="flex w-full grow flex-col overflow-x-clip overflow-y-scroll scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
          >
            <div class="flex size-full flex-col gap-4 p-4">
              <!-- Messages -->
              <AskGoMessage
                v-for="(message, i) in askGo.currentSession?.messages.filter(
                  (m) => m.text || m.filesUploaded,
                ) ?? []"
                :key="`message-${i}`"
                :message="message"
                class="flex"
                :class="message.authorId ? 'justify-end' : 'justify-start'"
              />
              <!-- Loading message while Ask Go is thinking -->
              <ChatLoadingSpinner v-if="askGo.isAskingGo" />
            </div>
          </div>

          <!-- Empty state -->
          <div
            v-else
            class="flex size-full flex-col items-center justify-center gap-6"
          >
            <!-- Icon -->
            <div
              class="size-16 rounded-full"
              :style="{
                background: `url(/ask-go.png) no-repeat center center / contain`,
              }"
            />
            <!-- Header -->
            <div class="flex flex-col items-center gap-1 px-6">
              <h3 class="text-center text-lg-15px-bold">Hi, this is Ask Go.</h3>
              <p class="text-center text-sm-12px-default text-text-subtle">
                AskGo is an assistant that can understand your project and take actions. Building
                your project with AskGo is as simple as asking a question. How can AskGo help you
                today?
              </p>
            </div>
            <!-- Suggestions -->
            <div class="flex flex-wrap justify-center gap-2 px-3">
              <div
                v-for="(suggestion, i) in suggestions"
                :key="`suggestion-${i}`"
                class="cursor-pointer rounded-corner-8 border border-border bg-background-transparent px-2 py-1"
                @click="onSuggestionClick(suggestion)"
              >
                <p class="px-0.5 text-sm-12px-default">{{ suggestion }}</p>
              </div>
            </div>
          </div>

          <!-- Jump to bottom -->
          <div class="relative">
            <div
              class="pointer-events-none absolute bottom-0 h-12 w-full bg-gradient-to-b from-background-transparent to-surface-tertiary transition duration-300 ease-in-out"
              :class="[!containerScrollState.arrivedState.bottom ? 'opacity-100' : 'opacity-0']"
            />
            <div
              class="absolute bottom-0 left-1/2 -translate-x-1/2 transition duration-300 ease-in-out"
              :class="[!containerScrollState.arrivedState.bottom ? 'opacity-100' : 'opacity-0']"
            >
              <IconButton
                icon="arrow-bottom"
                size="md"
                rounded
                variant="black"
                @click="scrollToBottom"
              />
            </div>
          </div>

          <!-- Text Input -->
          <div class="flex w-full p-2 pt-4">
            <div
              class="flex size-full items-start rounded-corner-20 rounded-br-corner-4 bg-surface-primary"
              :class="isDragging ? 'shadow-focus-ring-primary' : 'shadow-ask-go-input'"
            >
              <div class="relative flex size-full flex-col">
                <!-- Files -->
                <div
                  v-if="[...uploadedFiles, ...filesToUploadQueue].length > 0"
                  ref="fileUploadContainerRef"
                  class="flex gap-2 overflow-x-auto rounded-tr-corner-20 py-2.5 pl-9 pr-3 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
                  :class="
                    arrivedState.right &&
                    'after:pointer-events-none after:absolute after:right-0 after:top-0 after:block after:h-[84px] after:w-14 after:rounded-tr-corner-20 after:bg-gradient-to-r after:from-background-transparent after:to-surface-primary after:transition'
                  "
                >
                  <ToolTip
                    v-for="(file, i) in [...uploadedFiles, ...filesToUploadQueue]"
                    :key="`attachment-${i}`"
                    :title="file.name"
                    arrow
                    :placement="{ allowedPlacements: ['top'] }"
                  >
                    <div class="group relative h-16 w-[52px]">
                      <PdfPlaceholder class="h-16 w-[52px]" />
                      <div
                        class="absolute -right-1 -top-1 shrink-0 rounded-full bg-surface-primary opacity-0 transition-opacity duration-250 group-hover:opacity-100"
                      >
                        <IconButton
                          class="text-icon-subtlest"
                          icon="close"
                          size="sm"
                          variant="neutral"
                          rounded
                          @click="
                            (removeFile(file),
                            uploadedFiles.some((f) => f.name === file.name)
                              ? (uploadedFiles = uploadedFiles.filter((f) => f.name !== file.name))
                              : (filesToUploadQueue = filesToUploadQueue.filter(
                                  (f) => f.name !== file.name,
                                )))
                          "
                        />
                      </div>
                      <IconButton
                        v-if="
                          !uploadedFiles.some((f) => f.name === file.name) &&
                          fileUploadErrorMapping[file.name]
                        "
                        class="absolute left-4 top-6 shrink-0"
                        icon="warning-fill"
                        size="sm"
                        variant="critical"
                        rounded
                      />
                      <CircularProgress
                        v-if="
                          !uploadedFiles.some((f) => f.name === file.name) &&
                          !fileUploadErrorMapping[file.name] &&
                          fileUploadProgressMapping.hasOwnProperty(file.name)
                        "
                        class="absolute -right-1 -top-1 shrink-0"
                        :value="fileUploadProgressMapping[file.name]"
                        size="sm"
                        color="neutral"
                      />
                    </div>
                  </ToolTip>
                </div>
                <DividerLine
                  v-if="[...uploadedFiles, ...filesToUploadQueue].length > 0"
                  color="subtle"
                  direction="horizontal"
                />
                <div class="flex gap-2 p-2">
                  <input v-bind="hiddenInputProps" />
                  <IconButton
                    class="shrink-0"
                    icon="attachment"
                    size="md"
                    rounded
                    variant="neutral"
                    v-bind="omit(triggerProps, ['disabled'])"
                  />
                  <textarea
                    ref="textarea"
                    v-model="intent"
                    role="textbox"
                    aria-label="Ask Go Chat"
                    class="max-h-72 grow resize-none overflow-auto bg-background-transparent py-1 text-sm-12px-default text-text scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md empty:before:text-text-subtlest empty:before:content-[attr(placeholder)] focus:outline-none"
                    :class="!isFocused && !intent && 'text-text-subtlest'"
                    contenteditable
                    placeholder="Ask Go..."
                    @focus="isFocused = true"
                    @blur="isFocused = false"
                    @keypress="
                      (e) => {
                        if (e.key !== 'Enter') return
                        if (e.shiftKey) {
                          // add a new line
                          intent += '\n'
                        } else {
                          e.preventDefault()
                          onAskGo()
                        }
                      }
                    "
                    @click.stop
                  />
                  <IconButton
                    v-if="(intent?.length ?? 0) > 0 || uploadedFiles.length > 0"
                    class="shrink-0"
                    icon="arrow-top"
                    size="md"
                    rounded
                    variant="brand"
                    @click="onAskGo()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FileUpload>
    </div>
  </div>
</template>
