import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const gpt4oTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'GPT 4 Omni',
  description:
    "GPT-4 Omni is the most powerful version of the OpenAI's GPT suite of models, supporting text, images and audio.",
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  provider: ToolProvider.open_ai,
  icon: 'company-openai',
}
