import { Schema, type NodeSpec, type SchemaSpec } from 'prosemirror-model'
import { schemaSpec } from '../MarkdownEditor/schema'

/** Attributes for a ProseMirror `claim_start` node. */
export type ClaimStartAttrs = {
  claimId: string
}

/** Attributes for a ProseMirror `claim` node. */
export type ClaimAttrs = {
  claimId: string
  sourceId: string
  range: [number, number]
}

/**
 * A ProseMirror schema that follows the commonmark markdown spec, with an additional:
 * - `claim` node to represent the end of a grounding claim.
 * - `claim_start` node to represent the start of a grounding claim.
 */
export const schema = new Schema({
  nodes: {
    /**
     * Maps markdown token type to a function that converts the markdown
     * token into a ProseMirror node. This object is mostly copied from here:
     * https://github.com/ProseMirror/prosemirror-markdown/blob/1.13.1/src/from_markdown.ts#L246-L271
     *
     * We need to copy it because prosemirror-markdown does not export the spec, so we can't
     * extend it to define our own markdown+featureA specs.
     */
    ...schemaSpec.nodes,
    claim_start: {
      attrs: {
        claimId: {},
      } satisfies { [K in keyof ClaimStartAttrs]: { default?: ClaimStartAttrs[K] } },

      toDOM: ({ attrs }) => {
        return [
          'span',
          {
            class: 'mention',
            'data-claim-id': attrs.claimId,
          },
          '',
        ]
      },

      group: 'inline',
      inline: true,
      selectable: false,
      atom: true,
    } satisfies NodeSpec,

    claim: {
      attrs: {
        range: {},
        claimId: {},
        sourceId: {},
      } satisfies { [K in keyof ClaimAttrs]: { default?: ClaimAttrs[K] } },

      toDOM: ({ attrs }) => {
        return [
          'span',
          {
            class: 'mention',
            'data-claim-id': attrs.claimId,
            'data-source-id': attrs.sourceId,
            'data-range': attrs.range,
          },
          `[${attrs.id}]`,
        ]
      },

      group: 'inline',
      inline: true,
      selectable: false,
      atom: true,
    } satisfies NodeSpec,
  } as SchemaSpec['nodes'],
  marks: {
    ...schemaSpec.marks,
    /**
     * When a claim is clicked, this mark will be applied to all
     * nodes outside of the claim text, to highlight the claim.
     */
    faint: {
      attrs: {},
      toDOM() {
        return [
          'span',
          {
            class: 'opacity-25 pointer-events-none',
            'data-faint': 'true',
          },
        ]
      },
    },
  } as SchemaSpec['marks'],
})
