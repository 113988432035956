export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/** Returns a pluralized or nonpluralized string based on the count. */
export const pluralize = (count: number, singular: string, plural: string): string =>
  count === 1 ? singular : plural

export const isValidUrl = (string: string) => {
  try {
    new URL(string)
    return true
  } catch {
    return false
  }
}

/**
 * Returns true if a given string has a URL scheme (e.g. "http://, ftp://").
 */
export const hasUrlScheme = (string: string) => string.match(/^\w+:\/\//) !== null
