<script setup lang="ts">
import AppHeader from '@/sharedComponents/AppHeader.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import { useSpaceStore } from './useSpaceStore'
const { isOpen } = useSidebar()

const spaceStore = useSpaceStore()
</script>

<template>
  <AppHeader>
    <template #crumbs>
      <div
        class="text-sm-12px-default text-text transition-transform duration-300 ease-in-out-quint"
        :class="isOpen ? 'translate-x-0' : 'translate-x-8'"
      >
        {{ spaceStore.space ? spaceStore.space.name : 'Ask Go' }}
      </div>
    </template>
  </AppHeader>
</template>
