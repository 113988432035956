<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import type { Property } from '@/modules/Project/Properties/types'
import { pluralize } from '@/shared/utils/string'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import PopOver from '@/uiKit/PopOver.vue'
import ToolTip from '@/uiKit/ToolTip.vue'
import {
  autoPlacement,
  autoUpdate,
  offset,
  size,
  useFloating,
  type AutoPlacementOptions,
  type OffsetOptions,
} from '@floating-ui/vue'
import { useDebounce, useInfiniteScroll } from '@vueuse/core'
import { computed, ref, toRef, useTemplateRef, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useBilling } from '../Billing/useBilling'
import { ENTITY_NAME_FALLBACK } from './constants'
import EntityDialog from './EntityDialog.vue'
import { isFilterableProperty } from './Filters/types'
import { useLocalFilter } from './Filters/useLocalFilter'
import ReferenceCellEntityPreview from './ReferenceEntityPreview.vue'
import { useRelatedProjectsStore } from './relatedProjectsStore'
import { useReferenceField, type FullOrPreviewEntity } from './useReferenceField'
import { useResolveProjectRoute } from './useResolveProjectRoute'
import { useScrollOnSelected } from './useScrollOnSelected'
import { useTableCellFocus } from './useTableCellFocus'
import { POPOVER_Z_INDEX, SELECTED_CELL_Z_INDEX } from './useTableZIndices'

const props = defineProps<{
  isSelected: boolean
  isFocused: boolean
  field: Field<'reference'>
  property: Property<'reference'>
  projectId: string
}>()

const cell = useTemplateRef('cell')
useTableCellFocus({
  cell,
  isSelected: toRef(props, 'isSelected'),
  isFocused: toRef(props, 'isFocused'),
})

const { workspaceId } = useRouteParams()

const {
  localEntityIds,
  searchText,
  resetAllData,
  loadMoreEntities,
  getRelatedEntityName,
  updateFieldValue,
  onMouseleaveListItem,
  onMouseoverListItem,
  onToggleEntity,
  previewEntities,
  nameProperty,
  listItems,
  entityPreviewPopover,
  debouncedListItemHoverElement,
  relatedProject,
  entityLimit,
  hasReachedEntityLimit,
  savedEntityIds,
  getPreviewOffset,
  allLoadedEntities,
  showSkeletonList,
  skeletonItems,
  noMatchFound,
} = useReferenceField({
  field: computed(() => props.field),
  property: computed(() => props.property),
})

const relatedProjectStore = useRelatedProjectsStore()
const billingStore = useBilling()

watch(
  () => props.isFocused,
  () => {
    searchText.value = ''
  },
)

const floatingTarget = useTemplateRef<HTMLDivElement>('floatingTarget')
useScrollOnSelected(floatingTarget, toRef(props, 'isSelected'))

const floatingMenu = useTemplateRef<HTMLDivElement & { listRef: HTMLElement }>('floatingMenu')
const { floatingStyles: floatingMenuStyles } = useFloating(floatingTarget, floatingMenu, {
  middleware: [
    autoPlacement({ allowedPlacements: ['bottom-start', 'bottom-end'] }),
    size({
      apply({ availableHeight, elements, rects }) {
        elements.floating.style.maxHeight = Math.min(availableHeight - 16, 500) + 'px'
        elements.floating.style.minWidth = rects.reference.width + 8 + 'px'
        elements.floating.style.maxWidth = '610px'
      },
    }),
    offset({ mainAxis: 4, alignmentAxis: -4 }),
  ],
  whileElementsMounted: autoUpdate,
})

watch(
  () => props.isSelected,
  async (newIsSelected) => {
    if (!newIsSelected) {
      resetAllData()
      return
    }

    await loadMoreEntities()
  },
)

useInfiniteScroll(() => floatingMenu.value?.listRef, loadMoreEntities, { distance: 200 })

/** Update the field value when the dropdown is closed */
watch(
  () => props.isFocused,
  async (newIsFocused) => {
    if (!newIsFocused) {
      await updateFieldValue()
    }
  },
)

const BADGE_RENDER_LIMIT = 10
const unrenderedCount = computed(() =>
  Math.max(0, localEntityIds.value.length - BADGE_RENDER_LIMIT),
)
/** The entities that will be rendered as badges in the table cell. */
const badgeEntities = computed(() => previewEntities.value.slice(0, BADGE_RENDER_LIMIT))

/**
 * Refs for all entity badges. The <PopOver> component is given the active
 * badge (if there is one) and uses it to position itself.
 */
const badgeRefs = ref()
/** Index of the badge that is currently hovered */
const badgeHoverIndex = ref<number | null>(null)
const debouncedBadgeHoverIndex = useDebounce(badgeHoverIndex, entityPreviewPopover.debounceMs)

const onMouseoverBadge = (index: number) => {
  badgeHoverIndex.value = index
  entityPreviewPopover.trigger.onMouseOver()
}

const onMouseleaveBadge = () => {
  badgeHoverIndex.value = null
  entityPreviewPopover.trigger.onMouseLeave()
}

const onClickBadge = () => {
  badgeHoverIndex.value = null
  entityPreviewPopover.trigger.onClick()
}
const entityPreviewRef = useTemplateRef('entityPreview')

/**
 * Information on the entity that is currently being previewed and its
 * anchor element (badge or listitem).
 */
const previewMeta = computed<{
  entity: FullOrPreviewEntity
  anchor: HTMLElement
  mouseover: () => void
  placement: AutoPlacementOptions
  offset?: OffsetOptions
} | null>(() => {
  if (debouncedBadgeHoverIndex.value !== null) {
    const anchor = badgeRefs.value[debouncedBadgeHoverIndex.value]
    if (!anchor) {
      return null
    }
    const entity = previewEntities.value[debouncedBadgeHoverIndex.value]
    const mouseover = () => {
      if (debouncedBadgeHoverIndex.value !== null) {
        onMouseoverBadge(debouncedBadgeHoverIndex.value)
      }
    }
    return {
      entity,
      anchor,
      mouseover,
      placement: {
        allowedPlacements: ['bottom-start', 'top-start', 'bottom-end', 'top-end'],
      },
    }
  }

  if (debouncedListItemHoverElement.value !== null) {
    const anchor = debouncedListItemHoverElement.value
    const entity = listItems.value.find((item) => item.id === anchor.dataset.entityId)
    if (!entity) {
      return null
    }
    const mouseover = () => onMouseoverListItem(entity.data)
    return {
      entity: entity.data,
      anchor,
      mouseover,
      placement: {
        allowedPlacements: [
          'right-start',
          'left-start',
          'right',
          'left',
          'right-end',
          'right-start',
        ],
      },
      offset: ({ placement }) => getPreviewOffset(placement, floatingMenu.value?.listRef),
    }
  }

  return null
})

const resolveProjectLink = useResolveProjectRoute()

const numberFormatter = new Intl.NumberFormat()
const entityCountLabel = computed(() => {
  if (!relatedProject.value) {
    return ''
  }

  const entityCount =
    billingStore.projectLimits[relatedProject.value.id]?.entity_count?.total?.limitUsage
  if (entityCount === undefined) {
    return '0 entities'
  }

  return `${numberFormatter.format(entityCount)} ${pluralize(entityCount, 'entity', 'entities')}`
})

const router = useRouter()
const { setFilter } = useLocalFilter()
/**
 * Go to the project table for the related project, showing only the entities
 * that are selected in this field.
 */
const goToFilteredRoute = () => {
  assertIsNotNullOrUndefined(relatedProject.value)
  const mainViewId = relatedProject.value.views ? relatedProject.value.views[0].id : null
  if (!mainViewId) {
    return
  }

  if (savedEntityIds.value.length > 0) {
    /**
     * Set the filter to show only the matched entities when loading the
     * related project. Note that although `field.toolValue` might be
     * a filter that returns the same entities, we can't use it here because
     * it could return more entities than are matched in this field because
     * of:
     * 1. The property's entityLimit config param
     * 2. Entities that were created after the tool made its inference
     */
    setFilter(
      {
        conjunction: 'and',
        filters: [
          {
            subject: 'entity_id',
            matcher: {
              name: 'any_of',
              values: savedEntityIds.value,
            },
          },
        ],
      },
      mainViewId,
    )
  }

  const route = resolveProjectLink({
    projectId: relatedProject.value.id,
    workspaceId: workspaceId.value,
  })
  router.push(route)
}

const dialogEntityId = ref<string | null>(null)
</script>

<template>
  <div
    ref="cell"
    class="relative size-full min-h-full outline-none"
    :class="!isSelected && 'overflow-hidden'"
    v-bind="$attrs"
  >
    <div
      ref="floatingTarget"
      class="absolute left-0 top-0 flex min-h-full w-[352px] min-w-full max-w-max flex-wrap items-start gap-1 p-1.5"
      :class="[
        isSelected
          ? 'overflow-x-hidden rounded-corner-4 bg-surface-primary outline outline-2 outline-border-focused'
          : '',
      ]"
      :style="{ zIndex: isSelected ? SELECTED_CELL_Z_INDEX : 0 }"
      data-test="badges-container"
    >
      <div
        v-if="noMatchFound"
        class="truncate whitespace-nowrap pl-1 text-text-subtlest"
      >
        No match found
      </div>
      <BadgeItem
        v-for="(entity, index) in badgeEntities"
        :key="entity.id"
        ref="badgeRefs"
        :label="getRelatedEntityName(entity)"
        size="sm"
        variant="neutral"
        :title="undefined"
        class="hover:bg-background-gray-subtlest-hovered"
        :class="[
          index === debouncedBadgeHoverIndex &&
            (badgeHoverIndex === null || badgeHoverIndex === index) &&
            'bg-background-gray-subtlest-hovered',
          ,
        ]"
        :trailing-icon="isFocused ? 'close' : undefined"
        @trailing-icon-click="previewEntities = previewEntities.filter((e) => e.id !== entity.id)"
        @mouseover="
          () => {
            if (isFocused) {
              return
            }
            onMouseoverBadge(index)
          }
        "
        @mouseleave="onMouseleaveBadge"
        @click="onClickBadge"
      />
      <ToolTip
        v-if="unrenderedCount > 0"
        body="Open related project"
        :placement="{ allowedPlacements: ['top'] }"
      >
        <DarwinButton
          variant="outline"
          size="xxs"
          @click="goToFilteredRoute"
          >+{{ unrenderedCount }}</DarwinButton
        ></ToolTip
      >
    </div>
    <ListMenu
      v-if="isFocused && nameProperty && relatedProject"
      ref="floatingMenu"
      :style="floatingMenuStyles"
      class="go-scrollbar box-border flex h-auto max-h-[500px] min-h-[70px] shrink grow-0 select-none flex-col overflow-y-auto"
      :items="showSkeletonList ? skeletonItems : listItems"
      :external-search="isFilterableProperty(nameProperty)"
      :group-by-predicate="(item) => item.data.group"
      :group-order="['selected', 'unselected']"
      :aria-label="`Entities from ${relatedProject.name}`"
      :hide-no-results-text="showSkeletonList"
      @update:search="searchText = $event"
      @scroll="onMouseleaveListItem"
    >
      <template #group-title="{ group }">
        <div
          v-if="group.key === 'selected'"
          class="flex h-7 items-center justify-between pl-[9px] pr-2.5 text-xs-11px-default text-text-subtlest"
        >
          <div>Related Entities</div>
          <div>{{ localEntityIds.length }}/{{ entityLimit }}</div>
        </div>
      </template>
      <template #item="{ item, active, key, setActiveItem }">
        <ListMenuCheckboxItem
          :key="item.data.id"
          :data-entity-id="item.data.id"
          :active="active"
          :checked="item.data.group === 'selected'"
          :label="item.data.name || ENTITY_NAME_FALLBACK"
          :disabled="item.data.group === 'unselected' && hasReachedEntityLimit"
          @mousemove="setActiveItem(key)"
          @mouseover="onMouseoverListItem(item.data)"
          @mouseleave="onMouseleaveListItem"
          @select="onToggleEntity(item)"
        >
          <template #default>
            <LoadingSkeleton
              :status="showSkeletonList"
              class="w-full"
            >
              <div
                class="line-clamp-1 w-max"
                :class="showSkeletonList && 'opacity-0'"
              >
                {{ item.data.name || ENTITY_NAME_FALLBACK }}
                <span
                  v-if="!item.data.name"
                  class="text-text-subtle"
                >
                  {{ item.data.id }}
                </span>
              </div>
            </LoadingSkeleton>
          </template>
        </ListMenuCheckboxItem>
      </template>
      <template #footer>
        <ListMenuItem
          default-hover-disabled
          class="pl-7 text-sm-12px-default text-text-subtlest"
          :label="entityCountLabel"
        />
      </template>
    </ListMenu>
  </div>
  <PopOver
    v-if="previewMeta?.anchor && nameProperty && relatedProject"
    :open="entityPreviewPopover.isOpen.value"
    :target-selector="previewMeta && previewMeta.anchor"
    :placement="previewMeta?.placement"
    :offset="previewMeta?.offset"
    :aria-labelledby="entityPreviewRef?.labelId"
    :floating-z-index="POPOVER_Z_INDEX"
    teleport-to="#grid-container"
  >
    <template #content>
      <ReferenceCellEntityPreview
        v-if="previewMeta"
        ref="entityPreview"
        :data-colindex="$attrs['aria-colindex']"
        :data-rowindex="$attrs['aria-rowindex']"
        :entity="previewMeta.entity"
        :name-property-id="nameProperty.id"
        :project="relatedProject"
        :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
        @mouseover="previewMeta.mouseover"
        @mouseleave="onMouseleaveBadge"
        @open:entity="dialogEntityId = previewMeta.entity.id"
      />
    </template>
  </PopOver>
  <EntityDialog
    v-if="dialogEntityId && relatedProject"
    :data-colindex="$attrs['aria-colindex']"
    :data-rowindex="$attrs['aria-rowindex']"
    :intial-entity-id="dialogEntityId"
    :initial-loaded-entities="allLoadedEntities"
    :entity-ids="localEntityIds"
    :properties="relatedProjectStore.getPropertiesForReference(props.property.id)"
    :project="relatedProject"
  />
</template>
