<script setup lang="ts">
import type { Field } from '@/modules/Project/Fields/types'
import ObjectURLImage from '@/modules/Projects/ObjectURLImage.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { computed } from 'vue'
import LoadingSkeleton from './LoadingSkeleton.vue'

const props = defineProps<{
  url: string
  faviconUrl?: string
  status: Field<'url'>['status'] | undefined
}>()

const httpUrl = computed(() => {
  const startsWithHttpOrS = props.url.match(/^https?:\/\//)
  return startsWithHttpOrS ? props.url : `https://${props.url}`
})
</script>

<template>
  <a
    :href="httpUrl"
    target="_blank"
  >
    <BadgeItem
      size="sm"
      :label="url"
      variant="neutral"
      class="cursor-pointer"
      underline
    >
      <template #leading-icon>
        <CircularProgress
          v-if="status === 'computing'"
          size="xs"
          color="neutral"
        />
        <ObjectURLImage
          v-else-if="faviconUrl"
          class="size-4 overflow-hidden rounded-corner-4"
          :url="faviconUrl"
          :loading="false"
        >
          <template #fallback>
            <LoadingSkeleton
              data-test="loading-skeleton"
              class="size-4"
              :status="true"
            />
          </template>
        </ObjectURLImage>
        <IconSprite
          v-else
          icon="link"
          size="xs"
          color="error"
        />
      </template>
    </BadgeItem>
  </a>
</template>
