import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const claude3SonnetTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Claude 3 Sonnet',
  description:
    "Sonnet is the mid-tier version of Anthropic's Claude 3, more powerful than Haiku, but faster and cheaper than Opus.",
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
  ],
  provider: ToolProvider.anthropic,
  icon: 'company-anthropic',
}
