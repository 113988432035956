/// A parser parsing unextended [CommonMark](http://commonmark.org/),

import MarkdownIt from 'markdown-it'
import type Token from 'markdown-it/lib/token.mjs'
import { MarkdownParser, type ParseSpec } from 'prosemirror-markdown'
import { schema } from './schema'

/**
 * Maps markdown token type to a function that converts the markdown
 * token into a ProseMirror node. This object is mostly copied from here:
 * https://github.com/ProseMirror/prosemirror-markdown/blob/1.13.1/src/from_markdown.ts#L246-L271
 *
 * We need to copy it because prosemirror-markdown does not export the spec, so we can't
 * extend it to define our own markdown+featureA specs.
 */
export const parseSpec: Record<string, ParseSpec> = {
  mention: {
    node: 'mention',
    getAttrs: (token) => ({
      id: token.attrGet('id'),
      icon: token.attrGet('icon'),
      label: token.attrGet('label'),
      group: token.attrGet('group'),
    }),
  },
  blockquote: { block: 'blockquote' },
  paragraph: { block: 'paragraph' },
  list_item: { block: 'list_item' },
  bullet_list: {
    block: 'bullet_list',
    getAttrs: (_, tokens, i) => ({ tight: listIsTight(tokens, i) }),
  },
  ordered_list: {
    block: 'ordered_list',
    getAttrs: (tok, tokens, i) => ({
      order: +(tok.attrGet('start') || 1),
      tight: listIsTight(tokens, i),
    }),
  },
  heading: { block: 'heading', getAttrs: (tok) => ({ level: +tok.tag.slice(1) }) },
  code_block: { block: 'code_block', noCloseToken: true },
  fence: {
    block: 'code_block',
    getAttrs: (tok) => ({ params: tok.info || '' }),
    noCloseToken: true,
  },
  hr: { node: 'horizontal_rule' },
  image: {
    node: 'image',
    getAttrs: (tok) => {
      const firstChild = tok.children ? tok.children[0] : null

      return {
        src: tok.attrGet('src'),
        title: tok.attrGet('title') || null,
        alt: (firstChild && firstChild.content) || null,
      }
    },
  },
  hardbreak: { node: 'hard_break' },

  em: { mark: 'em' },
  strong: { mark: 'strong' },
  link: {
    mark: 'link',
    getAttrs: (tok) => ({
      href: tok.attrGet('href'),
      title: tok.attrGet('title') || null,
    }),
  },
  code_inline: { mark: 'code', noCloseToken: true },
}

/**
 * Parses a markdown string (following the commonmark spec) into a
 * ProseMirror document.
 */
export const parser = new MarkdownParser(
  schema,
  MarkdownIt('commonmark', { html: false }),
  parseSpec,
)

/**
 * Copied from https://github.com/ProseMirror/prosemirror-markdown/blob/1.13.1/src/from_markdown.ts#L237-L241
 */
function listIsTight(tokens: readonly Token[], i: number) {
  while (++i < tokens.length) if (tokens[i].type != 'list_item_open') return tokens[i].hidden
  return false
}
