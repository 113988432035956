import type { SnakeToCamelCaseObj } from '@/shared/types'
import { get } from './client'
import type { BackendMeta } from './types'

export type ListConnectionsMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/integrations/all/connections',
  'get'
>

export type ListConnectionsPath = ListConnectionsMeta['path']
export type ListConnectionsResponse = ListConnectionsMeta['successResponse']
export type ListConnectionsRequest = ListConnectionsMeta['queryParams']
type Params = SnakeToCamelCaseObj<ListConnectionsMeta['pathParams']>

export const listConnections = ({ workspaceId }: Params) =>
  get<ListConnectionsRequest, ListConnectionsResponse, ListConnectionsPath>(
    `/api/workspaces/${workspaceId}/integrations/all/connections`,
    undefined,
  )
