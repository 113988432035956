import type { paths } from '@/api'
import { auth0 } from '@/auth0'
import { captureException } from '@sentry/vue'
import type { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import axios, { isAxiosError } from 'axios'
import type { APIError, APIResult } from './types'

let instance: AxiosInstance | null = null

const NOT_AUTHENTICATED_ERROR = 'User is not authenticated so cannot make API requests'

export const createClient = () => {
  const isDev = import.meta.env.MODE === 'development'
  const baseURL = isDev ? undefined : import.meta.env.VITE_API_HOSTNAME

  if (!instance) {
    instance = axios.create({
      baseURL,
    })

    instance.interceptors.request.use(
      async (config) => {
        if (!auth0.isAuthenticated.value) {
          throw new Error(NOT_AUTHENTICATED_ERROR)
        }
        const token = await auth0.getAccessTokenSilently()
        config.headers['Authorization'] = `Bearer ${token}`
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }

  return instance
}

createClient()

const isApiError = (e: unknown): e is AxiosError<APIError> => {
  if (isAxiosError(e)) {
    const data = e.response?.data
    return typeof data === 'object' && !!data && 'code' in data && 'message' in data
  }
  return false
}

/**
 * This function will be called whenever an API request fails. If the error
 * is returned from the BE in the expected format, it will be returned to the
 * calling function. Otherwise, it throws.
 */
const handleNetworkError = <ResponseType>(error: unknown): APIResult<ResponseType> => {
  if (isApiError(error) && error.response) {
    if (!!error.status && error.status >= 400 && error.status < 600) {
      // Log API errors to Sentry, otherwise they are quietly gobbled up
      // and we have no way of knowing if we're sending bad requests.
      captureException(new Error('API error'), {
        data: {
          status: error.status,
          code: error.response.data.code,
          message: error.response.data.message,
        },
      })
    }
    return { ok: false, error: error.response.data }
  }

  if (isNotAuthenticatedError(error)) {
    return { ok: false, error: { code: 'login_required', message: 'Login required' } }
  }

  if (isGoogleDriveError(error) && error.response) {
    return {
      ok: false,
      error: { code: error.response.data.details.token, message: 'Google Drive error' },
    }
  }

  // if it's not an API error, we really don't want to try and handle it
  // as something is probably going wrong
  throw error
}

const isNotAuthenticatedError = (error: unknown) =>
  error instanceof Error && error.message === NOT_AUTHENTICATED_ERROR

function isGoogleDriveError(error: unknown): error is AxiosError<{ details: { token: string } }> {
  if (!isAxiosError(error)) return false
  const data = error.response?.data
  return error instanceof Error && typeof data === 'object' && 'details' in data
}

export const get = async <Params, Response, Path extends string | never = never>(
  path: Path extends string ? Path : keyof paths,
  params: Params,
): Promise<APIResult<Response>> => {
  try {
    const response = await createClient().get<Response>(path, { params })
    return { ok: true, data: response.data }
  } catch (error) {
    return handleNetworkError<Response>(error)
  }
}

type IfEquals<T, U, IfTrue = true, IfFalse = false> = [T] extends [U] ? IfTrue : IfFalse

export const post = async <Params, Response, Path extends string | never = never>(
  path: keyof paths | Path,
  body: IfEquals<Params, never, null, Params>,
  config?: AxiosRequestConfig,
): Promise<APIResult<Response>> => {
  try {
    const response = await createClient().post<Response>(path, body, config)
    return { ok: true, data: response.data }
  } catch (error) {
    return handleNetworkError<Response>(error)
  }
}

export const put = async <Params, Response, Path extends string | never = never>(
  path: keyof paths | Path,
  body: Params,
): Promise<APIResult<Response>> => {
  try {
    const response = await createClient().put<Response>(path, body)
    return { ok: true, data: response.data }
  } catch (error: unknown) {
    return handleNetworkError<Response>(error)
  }
}

export const remove = async <Params, Response, Path extends string | never = never>(
  path: keyof paths | Path,
  body: Params,
): Promise<APIResult<Response>> => {
  try {
    const response = await createClient().delete<Response>(path, { data: body })
    return { ok: true, data: response.data }
  } catch (error) {
    return handleNetworkError<Response>(error)
  }
}
