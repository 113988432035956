import { get } from './client'
import type { BackendMeta } from './types'

export type ListSpaces = BackendMeta<'/api/workspaces/{workspace_id}/spaces', 'get'>

export type ListSpacesParams = {
  workspaceId: string
}

export const listSpaces = ({ workspaceId }: ListSpacesParams) =>
  get<undefined, ListSpaces['successResponse'], ListSpaces['path']>(
    `/api/workspaces/${workspaceId}/spaces`,
    undefined,
  )
