<script setup lang="ts">
import { useTemplateRef } from 'vue'
import { markdown } from '.'
import ProseMirror, { type ProseMirrorProps } from '../ProseMirror/ProseMirror.vue'
import ProseMirrorMention, { getMentionContainer } from './ProseMirrorMention.vue'
/**
 * Renders an editor component that renders markdown, following the commonmark spec,
 * with the addition of mentions (enabled by a prop).
 */

type Props = Omit<ProseMirrorProps, 'mode'>
defineProps<Props>()

const proseMirror = useTemplateRef('proseMirror')
defineExpose({
  focus: () => proseMirror.value?.focus(),
})
</script>

<template>
  <ProseMirror
    v-bind="{ ...$attrs, ...$props }"
    ref="proseMirror"
    :mode="{
      ...markdown,
      nodeViewOptions: [
        {
          name: 'mention',
          component: ProseMirrorMention,
          as: getMentionContainer,
        },
      ],
    }"
  />
</template>
