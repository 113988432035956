<script setup lang="ts">
import { parseMatcherName } from '@/modules/Project/useFilters'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import Select from '@/uiKit/Select'
import type { SelectItem } from '@/uiKit/Select/Select.vue'
import TextArea from '@/uiKit/TextArea.vue'
import { ref } from 'vue'
import type { MatcherName, TextFilterMatcher, TextLikeFilter } from './types'
/**
 * Renders a dialog that is used to
 */

const props = defineProps<{
  filter: TextLikeFilter
  isOpen: boolean
  propertyName: string
  teleportTo?: string
}>()

const emit = defineEmits<{
  (e: 'close', event: Event): void
  (e: 'update', data: { caseSensitive: boolean; matcherName: MatcherName; values: string[] }): void
}>()

const textFilter = ref(props.filter.matcher.values[0])

const matcherName = ref<MatcherName>(props.filter.matcher.name)
const caseSensitive = ref<boolean>(props.filter.matcher.case_sensitive ?? true)

const onSave = (event: Event) => {
  emit('update', {
    caseSensitive: caseSensitive.value,
    matcherName: matcherName.value,
    values: [textFilter.value],
  })
  emit('close', event)
}

const TEXT_MATCHER_NAMES: TextFilterMatcher[] = [
  'property_contains_any_of',
  'property_contains_none_of',
  'property_any_of',
  'property_none_of',
]

const matcherItems = TEXT_MATCHER_NAMES.map((matcherName) => ({
  value: matcherName as MatcherName,
  label: parseMatcherName({ matcherName, type: 'text' }),
})) satisfies SelectItem[]

const caseSensitivenessItems: SelectItem[] = [
  {
    label: 'case sensitive',
    value: 'case_sensitive',
  },

  {
    label: 'case insensitive',
    value: 'case_insensitive',
  },
]

const DIALOG_ID = 'text-filter-dialog'
</script>

<template>
  <ModalDialog
    :open="isOpen"
    :to="teleportTo"
    @close="emit('close', $event)"
  >
    <div
      :id="DIALOG_ID"
      class="flex w-[480px] flex-col"
    >
      <!-- Header -->
      <div class="flex items-center gap-2 p-2 pl-11 text-center">
        <h2 class="grow text-sm-12px-default text-text-subtle">
          {{ propertyName }}
        </h2>
        <IconButton
          icon="close"
          variant="transparent"
          size="md"
          @click="emit('close', $event)"
        />
      </div>
      <DividerLine
        color="subtle"
        direction="horizontal"
      />
      <!-- Body -->
      <div class="flex flex-col gap-1.5 p-5">
        <div class="flex w-full gap-1">
          <Select
            :value="matcherName"
            :items="matcherItems"
            disable-teleport
            class="w-full"
            @change="matcherName = $event"
          />
          <Select
            :value="caseSensitive ? 'case_sensitive' : 'case_insensitive'"
            :items="caseSensitivenessItems"
            disable-teleport
            class="w-full"
            @change="caseSensitive = $event === 'case_sensitive'"
          />
        </div>
        <TextArea
          class="text-sm-12px-light"
          :value="textFilter"
          size="md"
          aria-label="Content"
          placeholder="Enter content"
          @input="textFilter = $event"
        />
      </div>
      <DividerLine
        color="subtle"
        direction="horizontal"
      />
      <div class="flex items-center justify-end gap-2 p-2">
        <DarwinButton
          variant="neutral"
          size="md"
          @click="emit('close', $event)"
        >
          Cancel
        </DarwinButton>
        <DarwinButton
          variant="black"
          size="md"
          :disabled="!textFilter"
          aria-label="Save Filter Value"
          @click="onSave"
        >
          Save
        </DarwinButton>
      </div>
    </div>
  </ModalDialog>
</template>
