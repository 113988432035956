<script setup lang="ts">
import { PropertyType } from '@/backend/types'
import type { Property } from '@/modules/Project/Properties/types'
import { captureException } from '@sentry/vue'
import { computed } from 'vue'
import { useFileCollectionLoadingStore } from './useFileCollectionLoadingStore'
import { serializeField } from './useProject'
import { useProjectChannel, type ProjectChannelHandlers } from './useProjectChannel'
/**
 * This is a renderless component that will subscribe to a given file property's subproject
 * and store any created entities' file fields.
 *
 * It is a renderless component instead of a composable because the websocket subscriber needs
 * to be called 1-N times, depending on how many file_collection properties are in the table,
 * and some of these calls might need to take place after the table's setup script has run.
 */

const props = defineProps<{
  property: Property<'file_collection'>
}>()

const fileCollectionLoadingStore = useFileCollectionLoadingStore()

const subprojectId = computed(() => props.property.config.subprojectConfig.child_project_id)
const filePropertyId = computed(() => props.property.config.subprojectConfig.primary_property_id)

const handlers: ProjectChannelHandlers = {
  onCreateEntity: (entity) => {
    if (!entity.parent_entity_id) {
      captureException(new Error('Entity is missing parent_entity_id'), {
        data: {
          propertyId: props.property.id,
          entityId: entity.id,
        },
      })
      return
    }

    if (!subprojectId.value) {
      captureException(new Error('File collection is missing child project ID'), {
        data: {
          propertyId: props.property.id,
        },
      })
      return
    }

    if (!filePropertyId.value) {
      captureException(new Error('Property is missing subprojectConfig.primary_property_id'), {
        data: {
          propertyId: props.property.id,
        },
      })
      return
    }

    const fileField = Object.values(entity.fields).find(
      (field) => field.property_id === filePropertyId.value,
    )
    if (!fileField) {
      captureException(new Error('Entity is missing file field'), {
        data: {
          propertyId: props.property.id,
          entityId: entity.id,
        },
      })
      return
    }

    const serializedField = serializeField(fileField)
    if (serializedField.type !== PropertyType.file) {
      captureException(new Error('File collection primary property is not a file property'), {
        data: {
          propertyId: props.property.id,
          entityId: entity.id,
        },
      })
      return
    }

    fileCollectionLoadingStore.addField({
      parentEntityId: entity.parent_entity_id,
      field: serializedField,
      subprojectId: subprojectId.value,
    })
  },
  onDeleteActiveView: () => {},
  onDeleteEntities: () => {},
  onDeleteProperty: () => {},
  onDeleteView: () => {},
  onSetStale: () => {},
  onUpdateEntity: () => {},
  onUpdateField: () => {},
  onUpdateProperty: () => {},
  onUpdateView: () => {},
}

useProjectChannel(subprojectId, handlers)
</script>
