import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const codeTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Python Tool',
  description: 'Run code in Python',
  outputTypes: [PropertyType.text, PropertyType.json],
  provider: ToolProvider.internal,
  icon: 'python-language',
}
