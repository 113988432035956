import { defineStore } from 'pinia'

import type { components } from '@/api'
import { useLocalStorage } from '@vueuse/core'
import { computed, watch } from 'vue'
import { useProject } from './useProject'

type SortingDirection = NonNullable<components['schemas']['Pagination.OrderDirections']>[number]

export type SortingRule = {
  /**
   * Although the backend uses the property slug, we need to store the ID so
   * that the rules don't break when renaming a property.
   */
  propertyId: string
  direction: SortingDirection
}

/**
 * The project table can show an ordered list of entities on that project. This
 * store holds the state of the sorting rules that are currently applied, and provides
 * functions to add/remove/update said rules.
 */
export const useSorting = defineStore('sorting', () => {
  /**
   * Map each view ID to an array of sorting rules that should be applied
   * when loading the view.
   */
  const rulesPerView = useLocalStorage<Record<string, SortingRule[]>>(
    'sorting-rules',
    {},
    {
      deep: true,
      listenToStorageChanges: false,
      writeDefaults: true,
      mergeDefaults: true,
    },
  )
  const projectStore = useProject()
  const activeViewId = computed(() => projectStore.activeView?.id)

  const sortingRules = computed({
    get() {
      if (!activeViewId.value) {
        return []
      }
      return rulesPerView.value[activeViewId.value] ?? []
    },
    set(v) {
      if (!activeViewId.value) {
        return
      }
      rulesPerView.value[activeViewId.value] = v
    },
  })

  watch(
    () => sortingRules.value,
    () => {
      if (sortingRules.value.length) {
        return
      }
      sortingRules.value = [
        {
          propertyId: 'id',
          direction: 'asc',
        },
      ]
    },
    { immediate: true },
  )

  function hasRule(propertyId: string) {
    return sortingRules.value.some((r) => r.propertyId === propertyId)
  }

  function removeRule(propertyId: string) {
    sortingRules.value = sortingRules.value.filter((r) => r.propertyId !== propertyId)
  }

  function addRule(propertyId: string, direction?: 'desc' | 'asc') {
    sortingRules.value = [{ propertyId, direction: direction ?? 'desc' }, ...sortingRules.value]
  }

  function updateRuleDirection(propertyId: string, direction: SortingDirection) {
    sortingRules.value = sortingRules.value.map((r) =>
      r.propertyId === propertyId ? { ...r, direction } : r,
    )
  }

  function updateRuleProperty(propertyId: string, newPropertyId: string) {
    sortingRules.value = sortingRules.value.map((r) =>
      r.propertyId === propertyId ? { ...r, propertyId: newPropertyId } : r,
    )
  }

  watch(
    () => sortingRules.value,
    () => {
      // This ensures entities are reloaded on the project table
      projectStore.setEntitiesStale(true)
    },
    { deep: true },
  )

  return { sortingRules, hasRule, removeRule, addRule, updateRuleDirection, updateRuleProperty }
})
