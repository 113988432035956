<script setup lang="ts">
import { RouterLink } from 'vue-router'

import { useResolveEntityRoute } from '@/modules/Project/useResolveEntityRoute'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { computed, ref } from 'vue'
import { useRoutes } from '../App/useRoutes'

const props = defineProps<{
  entityId: string
  colIndex: number
  workspaceId: string
  projectId: string
  propertyId: string
  viewId?: string
  filename: string | null
}>()

defineEmits<{
  (e: 'delete'): void
}>()

const deleteFile = ref(false)
const showDelete = computed(() => props.filename !== null)
const showExpand = computed(() => props.colIndex === 0)

const { currentlyInSubProject, route } = useRoutes()
const { parentEntityId, parentViewId } = useRouteParams()
const resolveEntityRoute = useResolveEntityRoute()

const routeTo = computed(() => {
  const parentProjectId = currentlyInSubProject.value ? route.params.parentProjectId : undefined

  return resolveEntityRoute({
    parentProjectId,
    projectId: route.params.projectId,
    workspaceId: route.params.workspaceId,
    parentEntityId: parentEntityId.value,
    viewId: props.viewId,
    entityId: props.entityId,
    parentViewId: parentViewId.value,
  })
})
</script>

<template>
  <div>
    <div
      v-if="showDelete || showExpand"
      class="flex items-center justify-end gap-0.5 rounded-md bg-surface-primary p-0.5 text-text-subtle shadow-xs"
    >
      <RouterLink
        v-if="showExpand"
        title="Expand entity"
        :to="routeTo"
        data-test="entity-view-link"
        @click.stop
      >
        <IconButton
          icon="expand"
          size="sm"
          variant="transparent"
        />
      </RouterLink>

      <IconButton
        v-if="showDelete"
        class="hidden group-hover/cell:flex"
        title="Delete file"
        aria-label="Delete file"
        icon="trash"
        size="sm"
        variant="transparent"
        @click.stop.prevent="deleteFile = true"
        @mousedown.prevent
        @dblclick.stop
      />
    </div>

    <ConfirmationDialog
      :open="deleteFile"
      :title="`Delete ${filename}?`"
      data-test="delete-file-confirmation-dialog"
      description="This file will be deleted immediately. You can't undo this action."
      @close="deleteFile = false"
      @confirm="($emit('delete'), (deleteFile = false))"
    />
  </div>
</template>
