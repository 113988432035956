<script setup lang="ts">
import HighlightedTextarea from '@/uiKit/HighlightedTextarea.vue'
import { useElementBounding } from '@vueuse/core'
import { computed, nextTick, onBeforeUnmount, ref, toRef, useTemplateRef, watch } from 'vue'

import type { PropertyType } from '@/backend/types'
import { isSelectAllKeybinding } from '@/modules/Project/keybindings'
import MarkdownEditor from '@/sharedComponents/MarkdownEditor/MarkdownEditor.vue'
import type ProseMirror from '@/sharedComponents/ProseMirror/ProseMirror.vue'
import { injectPinnedProp } from './usePinnedColumn'
import { useScrollOnSelected } from './useScrollOnSelected'
import { useTableCellFocus } from './useTableCellFocus'
import { TELEPORTED_CELL_SELECTOR } from './useTableInteractions'
import { PINNED_SELECTED_CELL_Z_INDEX, SELECTED_CELL_Z_INDEX } from './useTableZIndices'

const props = defineProps<{
  value: string
  type?: PropertyType
  isFocused: boolean
  isSelected: boolean
  hasSelectedRange?: boolean
  json?: boolean
  isModelOutput?: boolean
  isDisabled?: boolean
  isWaitingForConfiguration?: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', value: string | null): void
  (e: 'blur' | 'focus'): void
  (e: 'next', event: KeyboardEvent): void
}>()

const target = ref<HTMLElement>()

const contentEditableRef = ref<HTMLDivElement>()
const proseMirrorInstance = ref<InstanceType<typeof ProseMirror>>()
const highlightedTextareaRef = ref<InstanceType<typeof HighlightedTextarea>>()

const isPinned = injectPinnedProp()

/**
 * The value of the contenteditable when the cell enters edit mode. This will
 * either be:
 * - props.value if the user has pressed enter
 * - an empty string if the user has pressed backspaces, delete, or a printable character
 * This needs to be set whenever the cell enters edit mode, otherwise the contenteditable
 * 'remembers' its previous value, which causes bugs when escaping.
 */
const startValue = ref(props.value)

/**
 * The current value of the text inside of the contenteditable. Is used:
 * 1. To emit an updated value with the save event
 * 2. For optimistic UI when submitting changes
 */
const localValue = ref('')

// A stateless approach to the editable, by directly binding to the prop.value and emiting change,
// does not work, because we lose focus on every edit and re-update.
// So we have to store a local value.
watch(
  () => props.value,
  () => {
    localValue.value = props.value
    startValue.value = props.value
  },
  { immediate: true },
)

/**
 * Sets cursor position to the last char
 */
const setCarret = () => {
  if (!contentEditableRef.value) {
    return
  }
  const selection = window.getSelection()
  if (!selection) {
    return
  }

  const range = selection.getRangeAt(0)

  const node = contentEditableRef.value.childNodes.item(0)
  if (!node?.textContent) {
    return
  }
  range.setStart(node, node.textContent.length)
  range.setEnd(node, node.textContent.length)
  selection.addRange(range)
}

const { height, left, right } = useElementBounding(target)
const maxHeight = '400px'
const floatingStyles = computed(() => {
  if (!props.isSelected || props.hasSelectedRange) {
    return {}
  }
  return {
    minWidth: right.value - left.value + 'px',
    width: 'auto',
    height: 'auto',
    maxHeight,
    minHeight: height.value + 'px',
    overflow: 'auto',
  }
})

const scrollAnchor = ref<HTMLElement>()
watch(
  () => props.isFocused,
  async (isFocused, wasFocused) => {
    // The cell has gained focus - focus the contentEditable
    if (isFocused) {
      await nextTick()
      // either of these should work
      if (editorType.value === 'json') {
        highlightedTextareaRef.value?.focus()
      } else {
        contentEditableRef.value?.focus()
      }
      return
    }

    // The cell has lost focus - submit the updated value
    if (!isFocused && wasFocused) {
      const valueHasChanged = localValue.value !== props.value
      if (valueHasChanged) {
        emit('submit', localValue.value)
      }
      contentEditableRef.value?.blur()
    }
  },
  { immediate: true },
)

// since URL cells when not focused are rendering different component,
// we need to still submit a blur event
// blur is handled inside a the watcher above for other types of cells which we cannot rely here
onBeforeUnmount(() => {
  const isUrl = props.type === 'url'
  const valueHasChanged = localValue.value !== props.value
  if (isUrl && valueHasChanged) {
    emit('blur')
  }
})

const contentEditable = computed(() => {
  if (props.isDisabled) return false
  return navigator.userAgent.includes('Firefox') ? true : 'plaintext-only'
})

/**
 * Event handler for when the user presses escape when editing. The desired behaviour is:
 * "When the user presses escape, the cell should revert to its original value and still be focused (but not in edit mode)."
 */
const onEscape = () => {
  // We don't want to update the value when pressing escape, so reset the local value
  localValue.value = props.value
  // Make sure that the contenteditable start value is reset so that when the cell next
  // enters edit mode, it doesn't remember the previous value
  startValue.value = props.value
  if (contentEditableRef.value) {
    contentEditableRef.value.innerText = props.value
  }

  emit('blur')
}

const submitIfUrl = () => {
  if (props.type === 'url' && localValue.value !== props.value) {
    emit('submit', localValue.value || null)
  }
}

/**
 * Handler for when the user presses enter while editing. The desired behaviour is
 * "When the user presses enter, the cell should submit the value and move focus to the next cell."
 */
const onEnter = (e: KeyboardEvent) => {
  // It looks confusing, but this is intentional. We send an update to the backend
  // when the cell loses focus, so we don't want to send 'double' updates when hitting enter.
  // This is basically an indirect way of triggering the isFocused watcher
  emit('blur')

  // submit if url type, cause different component is used for non-focused state
  // and we can't rely on the watcher in this component
  submitIfUrl()

  if (props.value === localValue.value) {
    // because the value hasn't changed, we won't trigger the `submit` event,
    // and need to manually signal that the focus should move to the next cell
    emit('next', e)
  }
}

/**
 * When in focus mode, typing a character should focus the contentEditable and
 * populate the contentEditable with the typed character, overwriting the
 * current contents.
 *
 * When in focus mode, pressing backspace or delete should clear the content and
 * focus the contentEditable
 */
const onPlaintextEditorKeydown = (e: KeyboardEvent) => {
  if (!contentEditable.value) return
  // If the user is typing into the contenteditable then return early and let
  // the contenteditable handle the event.
  if (
    contentEditableRef.value &&
    e.target instanceof HTMLElement &&
    ['true', 'plaintext-only'].includes(e.target.contentEditable)
  ) {
    return
  }
  // checks if target is somewhere within highlightedTextareaRef.value?.$el
  if (e.target instanceof HTMLElement && highlightedTextareaRef.value?.$el.contains(e.target)) {
    return
  }

  if (isSelectAllKeybinding(e)) {
    e.stopPropagation()
    contentEditableRef.value?.focus()
    emit('focus')
    return
  }

  // This is the only way I could find to differentiate between
  // 1. Keys that should be taken as input values for the text cell
  // 2. Keys that should be used for navigation or other browser commands
  // Hopefully we can come up with a better way.
  const shouldOverwriteValue =
    e.key.length === 1 && e.key !== ' ' && !e.ctrlKey && !e.metaKey && !e.altKey
  const shouldDeleteValue = (e.key === 'Backspace' || e.key === 'Delete') && !props.hasSelectedRange
  if (shouldOverwriteValue || shouldDeleteValue) {
    e.stopPropagation()
    // The event will continue propagating, so the keypress will be registered by the
    // browser against the contenteditable div
    startValue.value = ''
    localValue.value = ''
    emit('focus')
  }

  /**
   * Special behaviour when the user enters edit mode by pressing enter (instead of clicking):
   * - The caret should be set to the end of the cell
   * - We should scroll to the bottom of the cell
   */
  if (e.key === 'Enter') {
    contentEditableRef.value?.focus()
    setCarret()
    scrollAnchor.value?.scrollIntoView({ block: 'nearest' })
  }
}

const onMarkdownEditorKeydown = async (e: KeyboardEvent) => {
  // notice this handler is not called if user is typing into ProseMirror

  if (!proseMirrorInstance.value) return

  if (props.isDisabled) return false

  if (isSelectAllKeybinding(e)) {
    e.stopPropagation()
    proseMirrorInstance.value?.focus()
    emit('focus')
    return
  }

  // This is the only way I could find to differentiate between
  // 1. Keys that should be taken as input values for the text cell
  // 2. Keys that should be used for navigation or other browser commands
  // Hopefully we can come up with a better way.
  const shouldOverwriteValue =
    e.key.length === 1 && e.key !== ' ' && !e.ctrlKey && !e.metaKey && !e.altKey
  const shouldDeleteValue = (e.key === 'Backspace' || e.key === 'Delete') && !props.hasSelectedRange
  if (shouldOverwriteValue || shouldDeleteValue) {
    e.stopPropagation()
    // The event will continue propagating, so the keypress will be registered by the markdown editor
    startValue.value = ''
    localValue.value = ''
    emit('focus')
    await nextTick()
    proseMirrorInstance.value?.focus()
  }

  /**
   * Special behaviour when the user enters edit mode by pressing enter (instead of clicking):
   * - The caret should be set to the end of the cell
   * - We should scroll to the bottom of the cell
   */
  if (e.key === 'Enter') {
    proseMirrorInstance.value?.focus()
    // setCarret() TODO
    // scrollAnchor.value?.scrollIntoView({ block: 'nearest' }) TODO scroll to the bottom where the caret is (might be done automatically already)
  }
}

useTableCellFocus({
  cell: target,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})

/**
 * The first line of the cell's value. Used for display purposes when the cell
 * is not expanded, as there is a performance hit when rendering large amounts
 * of text (think of large text output multiplied by hundreds of cells).
 */
const firstLine = computed(() => {
  if (props.json) {
    const inlineJson = localValue.value.replace(/\n/g, '')
    return inlineJson.length > 100 ? inlineJson.slice(0, 100) : inlineJson
  } else {
    return localValue.value.split('\n')[0]
  }
})

const shouldFloat = computed(() => props.isFocused || (props.isSelected && !props.hasSelectedRange))

/** Use this counter to track when we should reset the prosemirror instance */
const resetCounter = ref(0)
const onProseMirrorCancel = () => {
  resetCounter.value++
  localValue.value = props.value
  startValue.value = props.value
  emit('blur')
}

const editorType = computed<'json' | 'markdown' | 'plaintext'>(() => {
  if (props.json) {
    return 'json'
  }

  if (props.type === 'text') {
    return 'markdown'
  }

  return 'plaintext'
})

function onJsonEditorKeydown(e: KeyboardEvent) {
  if (!highlightedTextareaRef.value) {
    return
  }

  if (isSelectAllKeybinding(e)) {
    e.stopPropagation()
    highlightedTextareaRef.value?.focus()
    emit('focus')
  }

  const shouldDeleteValue = (e.key === 'Backspace' || e.key === 'Delete') && !props.hasSelectedRange
  if (shouldDeleteValue) {
    e.stopPropagation()
    // The event will continue propagating, so the keypress will be registered by the markdown editor
    startValue.value = ''
    localValue.value = ''
    emit('focus')
    highlightedTextareaRef.value?.focus()
  }
}

function onKeydown(e: KeyboardEvent) {
  if (proseMirrorInstance.value) {
    onMarkdownEditorKeydown(e)
  } else if (highlightedTextareaRef.value) {
    onJsonEditorKeydown(e)
  } else {
    onPlaintextEditorKeydown(e)
  }
}

const floatingEl = useTemplateRef('floatingRef')
useScrollOnSelected(floatingEl, toRef(props, 'isSelected'))
</script>

<template>
  <div
    ref="target"
    class="relative size-full min-w-0 outline-none"
    :class="[!isSelected && 'line-clamp-1 truncate']"
    v-bind="$attrs"
    @keydown="onKeydown"
  >
    <div
      v-if="isSelected"
      ref="floatingRef"
      :style="{
        ...floatingStyles,
        zIndex: isPinned ? PINNED_SELECTED_CELL_Z_INDEX : SELECTED_CELL_Z_INDEX,
      }"
      :[`data-${TELEPORTED_CELL_SELECTOR}`]="''"
      class="go-scrollbar absolute left-0 top-0 box-border min-w-full rounded-corner-4 outline outline-2 outline-border-focused"
      :class="{
        'font-mono': json,
        'shadow-md': isFocused,
        'line-clamp-1 h-full max-h-full max-w-full overflow-hidden': !shouldFloat,
        'bg-surface-primary': !hasSelectedRange || isFocused,
        'bg-background-selected': hasSelectedRange && !isFocused,
      }"
    >
      <HighlightedTextarea
        v-if="editorType === 'json'"
        ref="highlightedTextareaRef"
        lang="json"
        :value="localValue"
        size="md"
        class="min-w-full max-w-[500px]"
        adapt-for-table-mode
        auto-size
        :max-height="maxHeight"
        @input="localValue = $event"
        @change="$emit('submit', $event)"
        @escape="onEscape"
        @enter="onEnter"
      />
      <MarkdownEditor
        v-else-if="editorType === 'markdown'"
        :key="resetCounter"
        ref="proseMirrorInstance"
        data-test="texteditor"
        class="min-w-full max-w-[500px] [&>*]:px-3 [&>*]:py-1.5"
        :value="startValue"
        :readonly="isDisabled"
        adapt-for-table-mode
        @input="localValue = $event"
        @cancel="onProseMirrorCancel"
        @tab="$emit('next', $event)"
        @enter="onEnter"
      />
      <div
        v-else
        ref="contentEditableRef"
        role="textbox"
        :contenteditable="contentEditable"
        data-test="texteditor"
        tabindex="-1"
        class="relative min-w-full max-w-max whitespace-pre-wrap p-2 pl-3 text-sm-12px-light text-text focus:outline-none"
        :class="[
          isModelOutput && !isFocused ? 'bg-background-stages-model-subtle' : undefined,
          json && 'font-mono',
          shouldFloat
            ? 'w-[352px]'
            : 'line-clamp-1 size-full max-h-full min-w-full overflow-hidden',
        ]"
        @input="localValue = contentEditableRef?.innerText || ''"
        @contextmenu.prevent
        @keydown.enter.exact.stop.prevent="onEnter"
        @keydown.shift.enter.exact.stop
        @keydown.escape.stop.prevent="onEscape"
        @paste.stop
        @blur="submitIfUrl"
        @keydown.tab.stop.prevent="(e) => emit('next', e)"
        @click="$emit('focus')"
      >
        {{ startValue }}
      </div>
      <div ref="scrollAnchor" />
    </div>
    <div
      v-else
      data-table-cell-content
      class="line-clamp-1 size-full h-6 shrink grow basis-0 truncate whitespace-pre-wrap rounded-corner-4 p-2 pl-3 text-sm-12px-light [contain:strict] focus-within:box-border"
      :class="isWaitingForConfiguration && !localValue && 'text-text-subtlest'"
    >
      {{ isWaitingForConfiguration && !localValue ? 'Waiting for configuration...' : firstLine }}
    </div>
  </div>
</template>
