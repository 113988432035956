import { computed } from 'vue'
import { useProject } from './useProject'
import { useProperty } from './useProperty'

export function usePropertyMeta() {
  const projectStore = useProject()
  const propertyStore = useProperty()

  const totalEntitiesOnActiveView = computed(() => {
    if (!projectStore.activeView?.entities) return 0
    return projectStore.activeView.entities.length
  })

  const isAiProperty = computed(() => {
    return propertyStore.editedProperty?.tool !== 'manual'
  })

  const areAnyFieldsPopulated = computed(() => {
    const propertyId = propertyStore.editedProperty?.id
    if (!propertyId) return false
    return !!projectStore.activeView?.entities?.find((entity) => {
      return !!entity?.fields.get(propertyId)?.toolValue
    })
  })

  const isRecomputeEnabled = computed(() => {
    if (!isAiProperty.value) {
      return false
    } else if (totalEntitiesOnActiveView.value === 0) {
      return false
    } else if (totalEntitiesOnActiveView.value < 100 && !areAnyFieldsPopulated.value) {
      return false
    } else {
      return true
    }
  })

  return {
    isRecomputeEnabled,
  }
}
