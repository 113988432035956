import { parser, parseSpec } from './parser'
import { schema, schemaSpec } from './schema'
import { markSerializers, serializer } from './serializer'

export const markdown = {
  /**
   * ProseMirror schema for a commonmark document.
   * @see https://commonmark.org/
   */
  schema,
  parser,
  serializer,
  markSerializers,
  parseSpec,
  schemaSpec,
}
