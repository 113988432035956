<script setup lang="ts">
import HighlightedTextarea from '@/uiKit/HighlightedTextarea.vue'
import { computed, ref, watch } from 'vue'

import MarkdownEditor from '@/sharedComponents/MarkdownEditor/MarkdownEditor.vue'
import CardTextEditor from './CardTextEditor.vue'
import type { LayoutItem } from './EntityViewCard.vue'

const props = defineProps<{
  item: LayoutItem
  json: boolean
  readonly: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', val: string): void
}>()

const value = computed(() =>
  props.item.field.type === 'text' ||
  props.item.field.type === 'json' ||
  props.item.field.type === 'url'
    ? props.item.field.manualValue || props.item.field.toolValue
    : '',
)

const status = computed(() => props.item.field.status)

const infoText = computed<string | undefined>(() => {
  if (status.value === 'error') {
    return `Error: ${props.item.field.errorMessage}`
  }

  if (status.value === 'computing') {
    return 'Calculating...'
  }

  return undefined
})

const onSave = (text: string) => {
  emit('submit', text)
}

const editorType = computed<'json' | 'markdown' | 'plaintext'>(() => {
  if (props.json) {
    return 'json'
  }

  if (props.item.field.type === 'text') {
    return 'markdown'
  }

  return 'plaintext'
})

const highlightTextareaLocalValue = ref<string>('')

watch(
  () => value.value,
  () => (highlightTextareaLocalValue.value = value.value ?? ''),
  { immediate: true },
)
</script>

<template>
  <HighlightedTextarea
    v-if="editorType === 'json'"
    ref="highlightedTextareaRef"
    lang="json"
    :value="highlightTextareaLocalValue"
    size="md"
    class="size-full"
    adapt-for-table-mode
    @input="highlightTextareaLocalValue = $event"
    @change="$emit('submit', $event)"
  />
  <MarkdownEditor
    v-else-if="editorType === 'markdown'"
    class="size-full min-h-full rounded-corner-6 [&>*]:px-3 [&>*]:py-1.5"
    :value="value ?? ''"
    :readonly="item.readonly || readonly"
    :aria-label="item.property?.name"
    @save="onSave"
  />
  <CardTextEditor
    v-else
    :info-text="infoText"
    :value="value ?? ''"
    :json="json"
    :readonly="item.readonly || readonly"
    :label="item.property?.name"
    @submit="$emit('submit', $event)"
  />
</template>
