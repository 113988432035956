import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { computed, watchEffect, type ComputedRef } from 'vue'
import { PropertyType } from '../../backend/types'
import { useLibraryStore } from '../Library/libraryStore'
import { useModelProviders } from '../WorkspaceSettings/modelProvidersStore'
import { FIELD_TYPES_LABELS } from '../WorkspaceSettings/propertyConfig'
import { useProject } from './useProject'
import { useProperty } from './useProperty'

/**
 * When toggling grounding for a property, we must change the models that are
 * selected and available. This composable helps manage the state of grounding,
 * and updates the selected model when grounding is toggled.
 */
export const useGroundingToggle = () => {
  const { captureAnalyticsEvent } = useAnalytics()
  const propertyStore = useProperty()
  const libraryStore = useLibraryStore()
  const projectStore = useProject()
  const modelProvidersStore = useModelProviders()

  const groundingConfig = computed(() =>
    propertyStore.editedProperty
      ? modelProvidersStore.groundingConfigs.get(propertyStore.editedProperty.tool)
      : undefined,
  )

  const isGroundingSupported = computed(() => !!groundingConfig.value)
  const supportedInputTypes = computed(
    () => groundingConfig.value?.supportedInputTypes.properties ?? [],
  )
  const supportedOutputTypes = computed(
    () => groundingConfig.value?.supportedOutputTypes.properties ?? [],
  )
  const isGroundingEnabled = computed(() => propertyStore.editedProperty?.isGrounded ?? false)

  const setGroundingState = (enabled: boolean) => {
    if (!propertyStore.editedProperty) {
      throw new Error('No editedProperty when setting grounding state')
    }
    if (isGroundingEnabled.value == enabled) return enabled

    if (enabled) {
      if (!areConditionsFilled.value) return false
      captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_ENABLED)
    }

    propertyStore.editedProperty.isGrounded = enabled
    return enabled
  }

  type Condition = { fulfilled: boolean; label: string }

  const orList = (list: string[]) => {
    if (list.length === 0) return ''
    if (list.length === 1) return list[0]
    return `${list.slice(0, -1).join(', ')} or ${list.at(-1)}`
  }

  const orPropertyTypes = (propertyTypes: PropertyType[]) => {
    const labels = propertyTypes.map((type) => FIELD_TYPES_LABELS[type])
    return orList(labels)
  }

  const getPropertyType = (propertyId: string): PropertyType | undefined => {
    if (libraryStore.library?.textProperty.id === propertyId) return 'text'
    if (libraryStore.library?.fileProperty.id === propertyId) return 'file'
    const property = projectStore.propertiesById[propertyId]
    return property?.type
  }

  const groundingConditions: ComputedRef<Condition[]> = computed(() => {
    const conditions = [
      {
        fulfilled: isGroundingSupported.value,
        label: 'Tool supports AI Citations',
      },
      {
        fulfilled: propertyStore.editedInputs.some(({ propertyId }) => {
          const type = getPropertyType(propertyId)
          return !type || type === PropertyType.file
        }),
        label: 'At least one input is a file property',
      },
    ]
    if (isGroundingSupported.value) {
      conditions.push(
        {
          fulfilled:
            !!propertyStore.editedProperty &&
            supportedOutputTypes.value.includes(propertyStore.editedProperty.type),
          label: `Property type is ${orPropertyTypes(supportedOutputTypes.value)}`,
        },
        {
          fulfilled: propertyStore.editedInputs.every(({ propertyId }) => {
            const type = getPropertyType(propertyId)

            // We don't currently have a good way of interrogating subprojects or other
            // projects that might be referenced by a grounded property, so if we can't
            // find the property let's assume it exists and is the correct type, and
            // let the backend handle it.
            if (!type) return true

            return supportedInputTypes.value.includes(type)
          }),
          label: `All inputs' types are ${orPropertyTypes(supportedInputTypes.value)}`,
        },
      )
    }
    return conditions
  })

  const areConditionsFilled = computed(() =>
    groundingConditions.value.every(({ fulfilled }) => fulfilled),
  )

  watchEffect(() => {
    if (
      !areConditionsFilled.value &&
      propertyStore.editedProperty?.isGrounded &&
      propertyStore.editedProperty
    )
      propertyStore.editedProperty.isGrounded = false
  })

  return {
    isGroundingEnabled,
    setGroundingState,
    groundingConditions,
    areConditionsFilled,
  }
}
