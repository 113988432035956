import { ToolProvider } from '@/backend/types'
import { defaultToolConfig, geminiOutputTypes } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const geminiProVisionTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Gemini Pro Vision',
  description: "Gemini Pro Vision is the multimodal version of Google's Gemini 1.0.",
  outputTypes: geminiOutputTypes,
  provider: ToolProvider.google_ai,
  icon: 'company-google',
}
