import type { components } from '@/api'
import { listConnections } from '@/backend/listConnections'
import type { IntegrationConnection } from '@/modules/Workspaces/KnowledgeHub/integration-types'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export type ConnectionType = components['schemas']['Integrations.IntegrationId']

export const connectionNameMap: Record<ConnectionType, string> = {
  google_drive: 'Google Drive',
}

export const useConnections = defineStore('connections', () => {
  const { workspaceId } = useRouteParams()
  const hasAccess = ref<boolean>()

  const connections = ref<IntegrationConnection[]>([])

  async function load() {
    if (!workspaceId.value) return
    const res = await listConnections({ workspaceId: workspaceId.value })

    // If the user is not an admin, he cannot access connections.
    // In the future, this logic will change in the backend, so the error is
    // the best way to check access for now.
    hasAccess.value = res.ok
    connections.value = (res.ok ? res.data.data : []) as IntegrationConnection[]
  }

  watch(() => workspaceId.value, load, { immediate: true })

  return { connections, hasAccess, load }
})
