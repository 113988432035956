<script setup lang="ts">
import ListMenuContainer from '../ListMenuContainer.vue'
import { MenuCtx } from './Root.vue'

defineOptions({
  name: 'MenuContent',
  inheritAttrs: false,
})

const { menu, props } = MenuCtx.get()
</script>

<template>
  <Teleport
    :to="menu.open ? props.teleportTo : 'body'"
    :disabled="props.disableTeleport"
  >
    <div
      v-if="menu.open || $slots['context-trigger']"
      v-bind="menu.getPositionerProps()"
    >
      <ListMenuContainer
        v-bind="{ ...menu.getContentProps(), ...$attrs }"
        class="flex w-full flex-col p-0.5"
      >
        <slot />
      </ListMenuContainer>
    </div>
  </Teleport>
</template>
