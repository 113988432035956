import type { Field } from '@/modules/Project/Fields/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * This store keeps track of subproject fields that have been created and
 * updated during the user's session, and that are not represented by the
 * parent file_collection subprojectPreview property.
 *
 * This is used to display loading states in file collection cells before
 * the file_collection field's preview is generated and sent to the frontend
 * in the field:updated websocket message (it takes some time for the preview
 * to be generated).
 */
export const useFileCollectionLoadingStore = defineStore('fileCollectionLoadingStore', () => {
  /**
   * Provides a map of file fields that are currently being loaded across
   * a range of file collections. This is used to display loading states
   * in file collection cells.
   *
   * Is kept private to this store so that consumers are forced to use the
   * simpler API provided by the methods below.
   */
  const _loadingEntities = ref<{
    [subprojectId: string]:
      | {
          [parentEntityId: string]: Field<'file'>[] | undefined
        }
      | undefined
  }>({})

  const addField = ({
    subprojectId,
    parentEntityId,
    field,
  }: {
    subprojectId: string
    parentEntityId: string
    field: Field<'file'>
  }) => {
    if (!_loadingEntities.value[subprojectId]) {
      _loadingEntities.value[subprojectId] = {}
    }

    if (!_loadingEntities.value[subprojectId][parentEntityId]) {
      _loadingEntities.value[subprojectId][parentEntityId] = []
    }

    _loadingEntities.value[subprojectId][parentEntityId].push(field)
  }

  /** Get all file fields that are currently being loaded for a parent entity */
  const getFileFields = ({
    subprojectId,
    parentEntityId,
  }: {
    subprojectId: string
    parentEntityId: string
  }): Array<Field<'file'>> => {
    if (!_loadingEntities.value[subprojectId]) {
      return []
    }

    if (!_loadingEntities.value[subprojectId][parentEntityId]) {
      return []
    }

    return _loadingEntities.value[subprojectId][parentEntityId] || []
  }

  /**
   * Clear all entities attached to a parent entity
   */
  const clearEntities = ({
    subprojectId,
    parentEntityId,
  }: {
    subprojectId: string
    parentEntityId: string
  }) => {
    if (!_loadingEntities.value[subprojectId]) {
      return
    }

    _loadingEntities.value[subprojectId][parentEntityId] = []
  }

  const reset = () => {
    _loadingEntities.value = {}
  }

  return {
    _loadingEntities,
    reset,
    clearEntities,
    addField,
    getFileFields,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFileCollectionLoadingStore, import.meta.hot))
}
