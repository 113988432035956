<script setup lang="ts">
import { nextTick, onMounted, useTemplateRef, watch } from 'vue'

type Props = {
  modelValue: string
  /**
   * If true, textarea will not allow newlines.
   * Use case for this is when we want an input for a title, but also need to wrap a long line of text.
   */
  singleLine?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  class: '',
  singleLine: false,
})

const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

const textarea = useTemplateRef('textarea')

function adjustHeight() {
  if (!textarea.value) return

  // Reset height to auto first, so that we get correct scrollHeight
  textarea.value.style.height = 'auto'
  textarea.value.style.height = `${textarea.value.scrollHeight}px`
}

function handleInput(event: Event) {
  const target = event.target as HTMLTextAreaElement
  let value = target.value
  if (props.singleLine) {
    value = value.replace(/\n/g, '')
  }

  emit('update:modelValue', value)
  adjustHeight()
}

function handleKeyDown(event: KeyboardEvent) {
  if (props.singleLine && event.key === 'Enter') {
    event.preventDefault()
  }
}

watch(
  () => props.modelValue,
  () => nextTick(() => adjustHeight()),
)

onMounted(() => {
  adjustHeight()
})
</script>

<template>
  <textarea
    ref="textarea"
    :value="modelValue"
    rows="1"
    class="block min-h-6 w-full resize-none overflow-hidden"
    @input="handleInput"
    @keydown="handleKeyDown"
  />
</template>
