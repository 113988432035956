import type { SnakeToCamelCaseObj } from '@/shared/types'
import { post } from './client'
import type { BackendMeta } from './types'

export type StartConnectionMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/integrations/{integration_id}/connections/{connection_id}/start',
  'post'
>
type Body = StartConnectionMeta['body']
type Params = StartConnectionMeta['pathParams']

export const startConnection = ({
  workspaceId,
  integrationId,
  connectionId,
  integratorConfig,
}: SnakeToCamelCaseObj<Params & Body>) =>
  post<Body, StartConnectionMeta['successResponse'], StartConnectionMeta['path']>(
    `/api/workspaces/${workspaceId}/integrations/${integrationId}/connections/${connectionId}/start`,
    { integrator_config: integratorConfig },
  )
