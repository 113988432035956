import { useParentProject } from '@/modules/Project/useParentProject'
import { useSerializeFieldToText } from '@/modules/Project/useSerializeFieldToText'
import { useCrumbItems } from '@/modules/Workspaces/Crumbs/useCrumbItems'
import { computed } from 'vue'

export function useActiveCollectionName() {
  const { collection, parentView } = useCrumbItems()
  const parentProjectStore = useParentProject()
  const serializeFieldToText = useSerializeFieldToText()

  const manualCollectionName = computed(() => {
    const field = parentProjectStore.parentField
    if (!field) return

    if (field.type === 'collection') {
      return field.manualName
    } else if (field.type === 'file_collection') {
      return field.manualFilename || field.manualName
    }

    return ''
  })

  const parentEntityName = computed(() => {
    const mainPropId = parentView.value?.propertyIds.at(0)
    if (!mainPropId) return

    const field = parentProjectStore.parentEntity?.fields.get(mainPropId)
    return field ? serializeFieldToText(field) : ''
  })

  const label = computed(() => {
    const project = collection.value
    if (!project) {
      return ''
    }
    if (manualCollectionName.value) {
      return manualCollectionName.value
    }

    let name = project.parentProperty?.name || project.name
    if (parentEntityName.value) {
      name += ` - ${parentEntityName.value}`
    }
    return name
  })

  return label
}
