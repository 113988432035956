<script setup lang="ts">
import { omit } from '@/shared/utils'
import FloatingMenu from '@/uiKit/FloatingMenu.vue'
import type { IconName } from '@/uiKit/IconName'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { computed, ref } from 'vue'
import { TYPE_ICON } from '../icons'
import { useProject } from '../useProject'
import { useSorting } from '../useSorting'

const emit = defineEmits<{
  (e: 'select', pid: string): void
}>()
const projectStore = useProject()
const sortingStore = useSorting()

const menu = ref<InstanceType<typeof FloatingMenu> | null>(null)

type RuleConfig = {
  id: string
  data: { disabled?: boolean; icon: IconName; group: string; id: string; label?: string }
}

const ruleConfigs = computed((): RuleConfig[] => {
  return [
    ...projectStore.visibleProperties.map((p) => {
      return {
        data: {
          disabled: sortingStore.hasRule(p.id),
          icon: TYPE_ICON[p.type],
          group: 'Properties',
          label: p.name,
          id: p.id,
        },
        id: p.id,
      }
    }),
    {
      data: {
        icon: 'calendar',
        group: 'Misc attributes',
        label: 'Created at',
        id: 'id',
        disabled: sortingStore.hasRule('id'),
      },
      id: 'sort',
    },
  ]
})
</script>

<template>
  <FloatingMenu
    ref="menu"
    :positioning="{ placement: 'top-start' }"
    @select="
      (v) => {
        emit('select', v)
        menu?.menu.setOpen(false)
      }
    "
  >
    <template #trigger="{ triggerProps, open }">
      <slot
        :trigger-props="triggerProps"
        :open="open"
      />
    </template>

    <template #content="{ contentProps, getItemProps }">
      <ListMenuContainer
        v-bind="contentProps"
        class="w-[240px]"
      >
        <ListMenu
          :items="ruleConfigs"
          search-by-field="label"
          :group-by-predicate="(item) => item.data.group"
        >
          <template #group-title="{ group }">
            <span class="block px-2 py-1 text-xs-11px-default text-text-subtlest">{{
              group.key
            }}</span>
          </template>
          <template #item="{ item }">
            <ListMenuItem
              v-bind="
                omit(
                  getItemProps({ value: item.data.id, disabled: item.data.disabled }),
                  'onSelect',
                )
              "
              :icon="item.data.icon"
              :class="{ 'cursor-not-allowed opacity-50': item.data.disabled }"
              :default-hover-disabled="item.data.disabled"
            >
              {{ item.data.label }}
            </ListMenuItem>
          </template>
        </ListMenu>
      </ListMenuContainer>
    </template>
  </FloatingMenu>
</template>
