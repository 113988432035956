import { PropertyTool } from '@/backend/types'
import type { Field } from '@/modules/Project/Fields/types'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useClipboard } from '@vueuse/core'
import type { Options } from 'csv-stringify/browser/esm/sync'
import { stringify } from 'csv-stringify/browser/esm/sync'
import pLimit from 'p-limit'
import { computed } from 'vue'
import { useLimitedAction } from '../Billing/useLimitedAction'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useProject, type Entity } from './useProject'
import { useSerializeFieldToText } from './useSerializeFieldToText'
import { useTable } from './useTable'

/**
 * Provides functions to interact with the selected fields in the table, e.g.
 * to copy all selected fields to the clipboard or to recalculate all values
 * of selected AI fields.
 */
export const useSelectedFields = () => {
  const serializeField = useSerializeFieldToText()
  const clipboard = useClipboard()
  const tableStore = useTable()
  const projectStore = useProject()
  const workspacesStore = useWorkspaces()

  /** All entities that are covered by the selected range of fields. */
  const selectedEntities = computed(() => {
    if (!tableStore.selectedRange || !projectStore.activeView?.entities) {
      return []
    }

    return projectStore.activeView.entities
      .slice(tableStore.selectedRange.start.rowIndex, tableStore.selectedRange.end.rowIndex + 1)
      .filter((e) => e !== undefined)
  })

  /** All properties that are covered by the selected range of fields */
  const selectedProperties = computed(() => {
    if (!projectStore.activeView || !projectStore.visibleProperties || !tableStore.selectedRange) {
      return []
    }

    return projectStore.visibleProperties.slice(
      tableStore.selectedRange.start.colIndex,
      tableStore.selectedRange.end.colIndex + 1,
    )
  })

  const selectedPropertyIds = computed(() =>
    selectedProperties.value.map((property) => property.id),
  )

  const selectedFields = computed<Field[]>(() => {
    const fields = selectedEntities.value.reduce<Field[]>((acc, entity) => {
      if (!entity) {
        return acc
      }

      const entityFields = selectedPropertyIds.value.reduce<Field[]>((acc, propertyId) => {
        const field = entity.fields.get(propertyId)
        if (!field) {
          return acc
        }

        return [...acc, field]
      }, [])

      return [...acc, ...entityFields]
    }, [])

    return fields
  })

  /**
   * Returns a function that will serialize the selected range of fields to
   * text and write it to the clipboard. The format can be either CSV or
   * spreadsheet (TSV).
   */
  const buildCopyFunction = (format: 'csv' | 'spreadsheet') => () => {
    // If editing or if no range is selected, then the browser's usual copying
    // behaviour should apply (i.e. only copy selected text)
    if (tableStore.focusedCell || !tableStore.selectedRange) {
      return
    }

    const serializedSelection = selectedEntities.value.reduce<string[][]>((acc, entity) => {
      if (!entity) {
        return acc
      }

      const serializedRow = selectedPropertyIds.value.map((propertyId) => {
        const field = entity.fields.get(propertyId)
        if (!field) {
          return ''
        }

        return serializeField(field)
      })

      return [...acc, serializedRow]
    }, [])

    const options: Options = {
      delimiter: format === 'csv' ? ',' : '\t',
      // Only add an EOF marker for CSV, so that we don't add an extra row
      // when pasting back into Go.
      eof: format === 'csv',
      quote: format === 'csv' ? '"' : '',
    }
    const formatted = stringify(serializedSelection, options)
    clipboard.copy(formatted)
  }

  const onCopyToCsv = buildCopyFunction('csv')
  const onCopyToSpreadsheet = buildCopyFunction('spreadsheet')

  const { recalculateEntity } = useLimitedAction()
  /**
   * Recalculate all selected AI fields.
   */
  const recalculateSelectedFields = async ({ force }: { force: boolean }) => {
    const recalculablePropertyIds = selectedPropertyIds.value.filter((propertyId) => {
      const property = projectStore.propertiesById[propertyId]
      if (!property) {
        return false
      }

      return property.tool !== PropertyTool.manual
    })

    const limit = pLimit(10)
    await Promise.all(
      selectedEntities.value.map((entity) =>
        limit(async () => {
          assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
          assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
          if (!entity) {
            return
          }

          return recalculateEntity({
            workspaceId: workspacesStore.currentWorkspace.id,
            projectId: projectStore.projectId,
            entityId: entity.id,
            propertyIds: recalculablePropertyIds,
            force,
          })
        }),
      ),
    )
  }

  const setFieldValue = useSetFieldValue()
  /**
   * Reset the manual value for all selected AI fields.
   */
  const resetSelectedFields = async () => {
    const resettablePropertyIds = selectedPropertyIds.value.filter((propertyId) => {
      const property = projectStore.propertiesById[propertyId]
      if (!property) {
        return false
      }

      return property.tool !== PropertyTool.manual
    })

    const limit = pLimit(10)
    await Promise.all(
      selectedEntities.value.map((entity) =>
        limit(async () => {
          assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
          assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
          if (!entity) {
            return
          }

          const fields = resettablePropertyIds.reduce<
            Record<string, { field: Field; newValue: null }>
          >((acc, propertyId) => {
            const field = entity.fields.get(propertyId)
            if (!field) {
              return acc
            }

            return {
              ...acc,
              [propertyId]: { field, newValue: null },
            }
          }, {})

          return setFieldValue({
            entityId: entity.id,
            projectId: projectStore.projectId,
            workspaceId: workspacesStore.currentWorkspace.id,
            fields,
          })
        }),
      ),
    )
  }

  const onSetFieldValues = async (
    propertyId: string,
    valueMap: Record<Entity['id'], string[] | null | undefined>,
  ) => {
    const newFields = Object.entries(valueMap).reduce<
      Array<{ field: Field; newValue: string[] | null | undefined }>
    >((acc, [entityId, newValue]) => {
      assertIsNotNullOrUndefined(projectStore.activeView?.entities, 'No entities')
      const index = projectStore.activeView.entityIdToIndex?.get(entityId)
      if (index === undefined) {
        return acc
      }

      const entity = projectStore.activeView.entities[index]
      if (!entity) {
        return acc
      }

      const field = entity.fields.get(propertyId)
      if (!field) {
        return acc
      }

      return [...acc, { field, newValue }]
    }, [])

    const limit = pLimit(10)
    await Promise.all(
      newFields.map(({ field, newValue }) =>
        limit(async () => {
          assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
          assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
          if (newValue === undefined) {
            return
          }

          return setFieldValue({
            entityId: field.entityId,
            projectId: projectStore.projectId,
            workspaceId: workspacesStore.currentWorkspace.id,
            fields: {
              [propertyId]: { newValue, field },
            },
          })
        }),
      ),
    )
  }

  return {
    onCopyToSpreadsheet,
    onCopyToCsv,
    recalculateSelectedFields,
    resetSelectedFields,
    selectedFields,
    selectedPropertyIds,
    selectedEntities,
    selectedProperties,
    onSetFieldValues,
  }
}
