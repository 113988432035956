<script setup lang="ts">
import { useResolveProjectRoute } from '@/modules/Project/useResolveProjectRoute'
import MarkdownEditor from '@/sharedComponents/MarkdownEditor/MarkdownEditor.vue'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import IconSprite from '@/uiKit/IconSprite.vue'
import TagButton from '@/uiKit/TagButton.vue'
import type { ResultMessage } from '../types'

defineProps<{
  message: ResultMessage
}>()

const { workspaceId } = useRouteParams()
const resolveProjectLink = useResolveProjectRoute()
</script>

<template>
  <div
    v-for="result in message.results"
    :key="result.type"
  >
    <template v-if="result.type === 'text'">
      <div class="mb-3 flex items-center gap-2">
        <div
          class="flex size-7 items-center justify-center rounded-full bg-background-gray-subtlest"
        >
          <IconSprite icon="ask-agi-2-fill" />
        </div>
        <h2 class="text-md-13px-bold text-text">Answer</h2>
      </div>
      <MarkdownEditor
        readonly
        :value="result.text"
      />
    </template>
    <div
      v-else
      class="flex"
    >
      <TagButton
        :to="resolveProjectLink({ projectId: result.project_id, workspaceId })"
        label="View workings"
        trailing-icon="arrow-top-right"
        size="md"
      />
    </div>
  </div>
</template>
