<script setup lang="ts">
import ProgressMessage from './Messages/InProgressMessage.vue'
import { useSpaceStore } from './useSpaceStore'

const spaceStore = useSpaceStore()
</script>

<template>
  <div>
    <div class="mb-8 text-center text-display-md-28px-light">
      <div class="text-text-subtlest">Relax</div>
      <div class="text-text">Ask Go is finding the best answer...</div>
    </div>
    <div class="flex flex-col items-stretch gap-2">
      <ProgressMessage
        v-for="message in spaceStore.progressMessages.toSorted((a, b) =>
          a.createdAt < b.createdAt ? -1 : 1,
        )"
        :key="message.id"
        :message="message"
      />
    </div>
  </div>
</template>
